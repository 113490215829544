import React from "react";
import { signOut } from "./http";
import {useRouter} from "found";
import { useQuery } from "react-query";

function SessionTimeout() {
  const { router } = useRouter();

/** Disconnects a user when the expiration is over
 *  or if the session storage is empty
 */
  useQuery(
    "sessionTimeChecker",
    async () => {
      const exp = localStorage.getItem("nfCurrentExp");
      const currentUser = localStorage.getItem("nfCurrentUser");
      if (exp !== null && currentUser !== null) {
        const moment = Date.now();
        if (moment >= exp) {
          localStorage.clear();
          signOut();
          router.push("/auth/sign-in");
          alert('Vous avez été déconnecté automatiquement car votre session a été active plus de 13 heures.');
        }
      } else {
        localStorage.clear();
        signOut();
        router.push("/auth/sign-in");
        alert("Votre session a expiré, merci de vous reconnecter pour continuer d'utiliser les services Syopé");
      }
    },
    {
      cacheTime: 0,
      refetchInterval: 600000, // 10min
    }
  );

  return (
    <></>
  );
}

export default SessionTimeout;