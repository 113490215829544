import { useQuery } from 'react-query';
import http from '../../http';
import { isAbortError } from '../../utils';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/browser';
import React, { useState } from 'react';
import PageHeader from '../../ui/PageHeader';
import styled from 'styled-components';
import theme from '../../ui/theme';
import LoadingSpinner from '../../ui/LoadingSpinner';
import ListSsoSmp from '../ListSsoSmp';
import SeniorityListBilans from '../../components/interventions/SeniorityListBilans';
import ReloadIcon from '../../ui/ReloadIcon';

export const Title = styled.h1`
  > small {
    background-color: ${theme.red};
    color: ${theme.backgroundColor};
    border-radius: 0.25rem;
    font-size: 0.6em;
    margin-left: ${theme.thin};
    padding: 0.25rem ${theme.thin};
    height: min-content;
    align-self: center;
  }

  @media (min-width: 768px) {
    display: flex;
    flex-grow: 1;
    order: 1;
    margin-bottom: 0;
  }
`;

const SsoSmp = () => {
    const controller = new AbortController();

    const [isLoading, setLoading] = useState("full");

    const {
        isLoading: payloadIsLoading,
        data: payload,
    } = useQuery(
        "activeBilans",
        async () => {
            setLoading("background");
            return await http
                .get("bilans/sso_active", {
                    signal: controller.signal,
                })
                .json()
                .then(res => {
                    setLoading(null);
                    res.dataSeniority = Date.now();
                    return res;
                })
                .catch(error => {
                    if (isAbortError(error)) return;
                    toast.warn(
                        `Une erreur est survenue pendant le rafraichissement des SSO/SMP actifs.`,
                        {
                            toastId: "activeBilans"
                        }
                    );
                    Sentry.captureException(error);
                    console.error(error.name);
                    throw error;
                });
        },
        {
            cacheTime: 0,
            refetchInterval: 5000,
        }
    );

    return (
        <>
            <PageHeader>
                <Title>
                    SSO / SMP{" "}
                    <SeniorityListBilans payload={payload}/>
                    {isLoading === "background" && (
                        <ReloadIcon />
                    )}
                </Title>
            </PageHeader>

            {(payloadIsLoading || payload === undefined) ? (
                <LoadingSpinner className="center vh-50" />
            ) : (
                <ListSsoSmp
                    bilans={payload.bilans}
                />
            )}
        </>
    );
};

export default SsoSmp;
