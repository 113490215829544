import React, { useMemo, useEffect, useState } from "react";
import http from "../../http";
import { useQuery } from "react-query";
import * as Sentry from "@sentry/browser";
import { toast } from "react-toastify";
import { isAbortError } from "../../utils";

import PageHeader, { Title, Cntnr } from "../../ui/PageHeader";
import Button, { BlockButton } from "../../ui/Button";
import LoadingSpinner from "../../ui/LoadingSpinner";
import styled from "styled-components";
import theme from "../../ui/theme";
import SearchBarCompo, { FilterBar, SearchBar } from "../../ui/SearchBar";
import SearchIcon from '@mui/icons-material/Search';
import Input, { InputSearchCntnr, InputSearchIcon } from "../../ui/Input";
import Tag from "../../ui/Tag";
import { useContext } from "react";
import LoaderBarContext from "../../ui/useLoaderBar";

const RowItem = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 950px) {
    flex-direction: column;
  }
`;

const ActionItem = styled.div`
  display: flex;
  flex-direction: row;
  & > ${BlockButton} {
    margin-left: ${theme.small};
  }
  @media (max-width: 950px) {
    margin-top: ${theme.thin};
    & > ${BlockButton}:first-child {
      margin-left: 0;
    }
  }
  @media (max-width: 300px) {
    flex-direction: column;
    & > ${BlockButton} {
      margin-left: 0;
    }
  }
`;

function MedicationItem({ medication, medicationRefetch }) {
  const { loaderBarState, setLoaderBar } = useContext(LoaderBarContext);
  const [name, setName] = useState(medication.name);

  const handleFormSubmit = async (ev) => {
    ev.preventDefault();
    if (loaderBarState) return;
    setLoaderBar(true);
    try {
      await http.patch(`medications/${encodeURIComponent(medication.id)}.json`, {
        json: {
          name,
        }
      });
      toast.success("Médicament modifié avec succès");
      medicationRefetch();
    } finally {
      setLoaderBar(false);
    };
  };

  const handleDeleteClick = async (ev) => {
    ev.preventDefault();
    if (loaderBarState) return;
    setLoaderBar(true);
    try {
      await http.delete(`medications/${encodeURIComponent(medication.id)}.json`);
      toast.success("Médicament supprimé avec succès");
      medicationRefetch();
    } finally {
      setLoaderBar(false);
    };
  };

  return (
    <form style={{paddingTop: ".5rem", paddingBottom: ".5rem"}} onSubmit={handleFormSubmit}>
      <RowItem>
        <Input value={name} onChange={ev => setName(ev.target.value)} disabled={loaderBarState} />
        <ActionItem width={1}>
          <BlockButton type="submit" disabled={loaderBarState || name === medication.name}>Sauvegarder</BlockButton>
          <BlockButton className="warn" type="button" disabled={loaderBarState} onClick={handleDeleteClick}>Supprimer</BlockButton>
        </ActionItem>
      </RowItem>
    </form>
  );
}

export default function MedicationsList() {
  const controller = new AbortController();

  const { loaderBarState, setLoaderBar } = useContext(LoaderBarContext);

  const [ search, setSearch ] = useState("");
  const [name, setName] = useState("");

  const {
    isLoading: medicationsIsLoading,
    data: medications,
    refetch: medicationRefetch,
  } = useQuery(
    "medications",
    async () => {
      return await http
      .get(`medications.json`, {
        signal: controller.signal,
      })
      .json()
      .then(res => {
        return res.data;
      })
      .catch(error => {
        if (isAbortError(error)) return;
        console.error(error);
        Sentry.captureException(error);
        toast.warn("Une erreur est survenue lors de la récupération des médicaments");
        throw error;
      });
    },
    {cacheTime: 0},
  );

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const handleFormSubmit = async (ev) => {
    ev.preventDefault();
    if (loaderBarState) return;
    setLoaderBar(true);
    try {
      await http.post("medications.json", {
        json: {
          name,
        }
      });
      toast.success("Médicament ajouté avec succès");
      setName("");
      medicationRefetch();
    } catch (e) {
      console.error(e);
      // Sentry.captureException(e);
      toast.error("Impossible de créer le médicament");
    } finally {
      setLoaderBar(false);
    };
  };

  const clearFilter = () => setSearch("");

  const medicationsFiltered = useMemo(() => {
    return medications?.filter(m => m.name.toLowerCase().includes(search.toLocaleLowerCase()));
  }, [ search, medications ]);

  return (<>
    <PageHeader>
      <Title>Médicaments</Title>
    </PageHeader>

    <SearchBarCompo
      hasTag={true}
      tags={<>
        {search.length > 0 && <Tag text={search} callback={clearFilter} />}
      </>}
    >
      <SearchBar>
        <FilterBar>
          <InputSearchCntnr>
            <InputSearchIcon>
              <SearchIcon />
            </InputSearchIcon>
            <Input
              type="search"
              value={search}
              onChange={(ev) => setSearch(ev.target.value)}
              placeholder="Médicament"
            />
          </InputSearchCntnr>
          <Button className="warn" onClick={clearFilter}>Réinitialiser les filtres</Button>
        </FilterBar>
      </SearchBar>
    </SearchBarCompo>

    {medicationsIsLoading ? (
      <LoadingSpinner className="center vh-50" />
    ) : (
      <Cntnr>
        <form onSubmit={handleFormSubmit}>
          <fieldset disabled={loaderBarState}>
            <RowItem>
              <Input value={name} onChange={ev => setName(ev.target.value)} />
              <ActionItem width={1}>
                <BlockButton type="submit">Ajouter</BlockButton>
              </ActionItem>
            </RowItem>
          </fieldset>
        </form>
        <hr/>
        {medicationsFiltered.length > 0 ? (
          <>
            {medicationsFiltered.map(medication => (
              <MedicationItem
                key={medication.id}
                medication={medication}
                medicationRefetch={() => medicationRefetch()}
              />
            ))}
          </>
        ) : (
          <p>Aucun médicament</p>
        )}
      </Cntnr>
    )}
  </>);
};
