import React, { useEffect, useContext } from "react";
import http from "../../http";
import { useQuery } from "react-query";
import * as Sentry from "@sentry/browser";
import { toast } from "react-toastify";
import { isAbortError } from "../../utils";
import LoaderBarContext from "../../ui/useLoaderBar";

import PageHeader, {Cntnr, Title} from "../../ui/PageHeader";
import LoadingSpinner from "../../ui/LoadingSpinner";
import RoleForm from "./RoleForm";

export default function DuplicateRole({ router, match }) {
  const controller = new AbortController();
  const { roleId } = match.params;

  const { setLoaderBar } = useContext(LoaderBarContext);

  const { isLoading: roleIsLoading, data: role } = useQuery(
    "roleEditRole",
    async () =>{
      return await http
      .get(`roles/${roleId}.json`, {
        signal: controller.signal,
      })
      .json()
      .catch(error => {
        if (isAbortError(error)) return;
        console.error(error);
        Sentry.captureException(error);
        toast.warn("Une erreur est survenue lors de la récupération du rôle");
        throw error;
      });
    },
    {cacheTime: 0},
  );

  const { isLoading: permIsLoading, data: permissions } = useQuery(
    "permissionsEditRole",
    async () =>{
      return await http
      .get(`permissions.json`, {
        signal: controller.signal,
      })
      .json()
      .catch(error => {
        if (isAbortError(error)) return;
        console.error(error);
        Sentry.captureException(error);
        toast.warn("Une erreur est survenue lors de la récupération des permissions");
        throw error;
      });
    },
    {cacheTime: 0},
  );

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const handleSubmit = async (payload) => {
    setLoaderBar(true);
    await http.post(`roles.json`, { 
      json: { role: payload },
      signal: controller.signal,
    }).json()
    .then(() => {
      toast.success("Le rôle a bien été dupliqué.");
      router.push("/gestionnaire/roles");
    })
    .catch(error => {
      if (isAbortError(error)) return;
      console.error(error);
      Sentry.captureException(error);
      toast.warn("Une erreur est survenue lors de la duplication du rôle");
      throw error;
    })
    .finally(() => {
      setLoaderBar(false);
    });
  };

  return <>
    <PageHeader>
      <Title>Duplication d'un rôle utilisateur</Title>
    </PageHeader>

    {(roleIsLoading || permIsLoading) ? (
      <LoadingSpinner className="center vh-50" />
    ) : (
      <Cntnr>
        <RoleForm
          permissions={permissions}
          initial={role}
          onSubmit={handleSubmit}
          duplicate={true}
        />
      </Cntnr>
    )}
  </>;
}
