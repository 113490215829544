import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import http from "../../http";
import * as _ from "lodash";
import LoaderBarContext from "../../ui/useLoaderBar";
import * as Sentry from "@sentry/browser";
import { toast } from "react-toastify";

import styled from "styled-components";
import theme from "../../ui/theme";
import Button, { BlockButton } from "../../ui/Button";
import LoadingSpinner from "../../ui/LoadingSpinner";
import PageHeader, { Title, Cntnr } from "../../ui/PageHeader";
import { Column, Row } from "../../ui/FlexGrid";
import Input from "../../ui/Input";
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';

const ListItem = styled.li`
  padding: ${theme.thin} 0;
`;

const UnstyledList = styled.ul`
  padding-left: 0;
  list-style: none;

  ${ListItem} + ${ListItem} {
    border-top: 1px solid ${theme.grey5};
  }
`;

const RowItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: ${theme.medium};
  margin-bottom: ${theme.thin};
  margin-top: ${theme.thin};

  & > *:not(:last-child) {
    margin-right: ${theme.thin};
  }

  @media (max-width: 950px) {
    margin-left: ${theme.thin};
  }
`;

const ParameterizedListsItem = ({pList, refetch}) => {
  const { loaderBarState, setLoaderBar } = useContext(LoaderBarContext);

  const [ modify, setModify ] = useState(false);
  const [ newItem, setNewItem ] = useState("");
  const [ editItems, setEditItems ] = useState([]);

  useEffect(() => {
    setEditItems(_.cloneDeep(pList.items))
  }, [ pList ]);

  const handleCreateItemChange = (ev) => {
    setNewItem(ev.target.value || "");
  };

  const handleCreateItemSubmit = async (listId) => {
    if (loaderBarState) return;
    setLoaderBar(true);
    try {
      await http.post(
        `parameterized_lists/${encodeURIComponent(listId)}/items.json`,
        { json: { value: newItem } }
      );
      toast.success(`L'item a bien été créé.`);
      setNewItem("");
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      toast.error(`Impossible de créer l'item dans la liste paramétrée.`);
    } finally {
      refetch();
      setLoaderBar(false);
    };
  };

  const handleItemChange = (ev, itemId) => {
    let temp = editItems;
    const index = editItems.findIndex(item => item.id === itemId);
    const original = pList.items[index];

    if (index !== -1) {
      temp[index].value = ev.target.value;
      if (original.value !== temp[index].value) {
        temp[index].isEdited = true;
      } else {
        temp[index].isEdited = false;
      };
    };

    setEditItems([...temp]);
  };

  const handleEditItemSubmit = async (ev, listId, item) => {
    ev.preventDefault();
    if (loaderBarState) return;
    setLoaderBar(true);
    try {
      await http.patch(
        `parameterized_lists/${encodeURIComponent(listId)}/items/${encodeURIComponent(item.id)}.json`,
        { json: { value: item.value } }
      );
      toast.success(`L'item a bien été modifié.`);
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      toast.error(`Impossible de modifier l'item de la liste paramétrée.`);
    } finally {
      refetch();
      setLoaderBar(false);
    };
  };

  const handleDeleteItem = async (ev, listId, itemId) => {
    ev.preventDefault();
    if (loaderBarState) return;
    setLoaderBar(true);
    try {
      await http.delete(`parameterized_lists/${encodeURIComponent(listId)}/items/${encodeURIComponent(itemId)}.json`);
      toast.success(`L'item a bien été supprimé.`);
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      toast.error(`Impossible de supprimer l'item de la liste paramétrée.`);
    } finally {
      refetch();
      setLoaderBar(false);
    };
  };

  const alphabeticalSort = (itemA, itemB) => {
  if (itemA.value < itemB.value) {
      return -1;
    }
    if (itemA.value > itemB.value) {
      return 1;
    }
    return 0;
  }

  return (
    <ListItem>
      <Row>
        <Column width={4} style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
          {pList.display_name}
        </Column>
        <Column width={1}>
          {!modify && (
            <BlockButton
              style={{marginBottom: 0}}
              onClick={() => setModify(!modify)}
            >Modifier</BlockButton>
          )}
        </Column>
      </Row>
      {!modify ? (
        <>
          {pList.items.sort(alphabeticalSort).map(originalIitem => (
            <RowItem key={originalIitem.id}>
              <SubdirectoryArrowRightIcon /> {originalIitem.value}
            </RowItem>
          ))}
        </>
      ) : (
        <>
          <RowItem>
            <SubdirectoryArrowRightIcon />
            <Input
              placeholder="Ajouter un item à la liste paramétrée"
              value={newItem}
              onChange={handleCreateItemChange}
            />
            <Button onClick={() => handleCreateItemSubmit(pList.id)} title="Ajouter">
              <AddIcon />
            </Button>
            <Button className="warn" onClick={() => setNewItem("")} title="Effacer">
              <ClearIcon />
            </Button>
          </RowItem>
          {editItems.sort(alphabeticalSort).map((editItem, idx) => (
            <RowItem key={editItem.id}>
              <SubdirectoryArrowRightIcon />
              <Input value={editItem.value} onChange={(ev) => handleItemChange(ev, editItem.id)} />
              <Button
                type="button"
                onClick={(ev) => handleEditItemSubmit(ev, pList.id, editItem)}
                title="Enregistrer les modifications"
                disabled={!editItem.isEdited}
              >
                <SaveIcon />
              </Button>
              <Button
                className="warn"
                type="button"
                onClick={(ev) => handleDeleteItem(ev, pList.id, editItem.id)}
                title="Supprimer"
              >
                <DeleteIcon />
              </Button>
            </RowItem>
          ))}
        </>
      )}
    </ListItem>
  );
};

const ParameterizedLists = () => {
  const controller = new AbortController();

  const {
    isLoading: pListsIsLoading,
    data: pLists,
    refetch: refetchPLists
  } = useQuery(
    [ "manage", "parameterized-lists" ],
    async () => {
      return await http
      .get(`parameterized_lists.json`, {
        signal: controller.signal,
      }).json()
      .then(res => {
        return res.data;
      })
    },
    {cacheTime: 0},
  );

  useEffect(() => {
    return controller.abort();
  }, []);

  return (
    <>
      <PageHeader>
        <Title>Listes paramétrées</Title>
      </PageHeader>

      {pListsIsLoading ? (
        <LoadingSpinner className="center vh-50" />
      ) : (
        <Cntnr>
          <UnstyledList>
          {pLists.map(pList => (
            <ParameterizedListsItem key={pList.id} pList={pList} refetch={refetchPLists} />
          ))}
          </UnstyledList>
        </Cntnr>
      )}
    </>
  );
};

export default ParameterizedLists;