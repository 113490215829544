import React, { useContext, useState, useEffect } from "react";
import http from "../../http";
import { useQuery } from "react-query";
import {toast} from "react-toastify";
import * as Sentry from "@sentry/browser";
import { hasPerm, isAbortError } from "../../utils";
import CurrentUserContext from "../../CurrentUserContext";
import LoaderBarContext from "../../ui/useLoaderBar";

import List from "../List";
import PageHeader, {Title, Actions} from "../../ui/PageHeader";
import LoadingSpinner from "../../ui/LoadingSpinner";
import BoundedPagination from "../../ui/BoundedPagination";
import Button, {LinkButton} from "../../ui/Button";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ShareIcon from '@mui/icons-material/Share';
import { ActionsBtnDialog, Dialog } from "../../ui/Modal";
import FormGroup from "../../ui/FormGroup";
import Label from "../../ui/Label";
import Input from "../../ui/Input";
import SeniorityListBilans from '../../components/interventions/SeniorityListBilans';
import ReloadIcon from '../../ui/ReloadIcon';

function Active({ match }) {
  const [page, setPage] = useState(
    parseInt(match.location.query.page || "1", 10)
  );

  const controller = new AbortController();

  const {
    isLoading: payloadIsLoading,
    data: payload,
    refetch: refetchPayload,
  } = useQuery(
    "activeBilans",
    async () => {
      setLoading("background");
      return await http
      .get("bilans", {
        signal: controller.signal,
        searchParams: {
          page: page,
        }
      })
      .json()
      .then(res => {
        setLoading(null);
        res.dataSeniority = Date.now();
        return res;
      })
      .catch(error => {
        if (isAbortError(error)) return;
        toast.warn(
          `Une erreur est survenue pendant le rafraichissement des interventions actives.`,
          {
            toastId: "activeBilans"
          }
        );
        Sentry.captureException(error);
        console.error(error.name);
        throw error;
      });
    },
    {
      cacheTime: 0,
      refetchInterval: 5000,
    }
  );

  const {currentUser} = useContext(CurrentUserContext);
  const { loaderBarState, setLoaderBar } = useContext(LoaderBarContext);

  const [isLoading, setLoading] = useState("full");

  const [ codeSharingView, setCodeSharingView ] = useState(false);
  const [ codeSharing, setCodeSharing ] = useState("");

  useEffect( () => {
    refetchPayload();
  }, [ match ] );

  useEffect( () => {
    return () => {
      controller.abort();
    };
  }, [] );

  const goTo = p => {
    setPage(p);
    setLoading("full");
  };

  const count =
    payload === null || payload === undefined ? 0 : Math.ceil(payload.total / payload.per_page);
  const pagination = (
    <div style={{paddingLeft: "1rem"}}>
      <BoundedPagination
        count={count}
        current={page}
        location={p => `/interventions/active?page=${p}`}
        onClick={p => goTo(p)}
      />
    </div>
  );

  const toggleCodeSharing = (() => {
    setCodeSharingView(!codeSharingView);
    if (!codeSharingView) {
      setCodeSharing("");
    };
  });

  const handleCodeSharing = (ev) => {
    setCodeSharing(ev.target.value);
  };

  const saveCodeSharing = async ev => {
    ev.preventDefault();
    setLoaderBar(true);

    const payload = {
      code: codeSharing,
    };

    try {
      const opts = { json: payload };
      await http.post(`share/claims`, opts);
      toast.success("Le partage par code a été soumis avec succès. L'intervention sera disponible d'ici quelques secondes.");
      toggleCodeSharing();
    } catch  (error) {
      const { errors } = await error.response.json();
      error.errrors = JSON.stringify(errors);
      console.error(error);
      if (errors === undefined || errors.length === 0) {
        Sentry.captureException(error);
        toast.warn("Le partage par code a échoué.");
      } else {
        toast.warn(errors.join(" "));
      };
    } finally {
      setLoaderBar(false);
    };
  };

  return (
    <>
      <PageHeader>
        <Title>
          Interventions{" "}
          <SeniorityListBilans payload={payload}/>
          {isLoading === "background" && (
            <ReloadIcon />
          )}
        </Title>
        <Actions>
          {hasPerm(currentUser, "web:interventions:integrer-par-code") && (
            <Button onClick={() => toggleCodeSharing()} type="button">
              <ShareIcon className="icon-left" />Intégration par code
            </Button>
          )}
          <Dialog
            isOpen={codeSharingView}
            toggleDialog={() => toggleCodeSharing()}
            title="Intégration par code"
            children={
              <div>
                <form onSubmit={saveCodeSharing}>
                  <FormGroup>
                    <Label htmlFor="code-sharing">Code d'intégration</Label>
                    <Input
                      id="code-sharing"
                      type="text"
                      value={codeSharing}
                      onChange={ev => handleCodeSharing(ev)}
                    />
                  </FormGroup>
                  <hr />
                  <ActionsBtnDialog>
                    <Button type="button" className="warn" onClick={() => toggleCodeSharing()}>Annuler</Button>
                    <Button type="submit" disabled={(loaderBarState || codeSharing.length === 0)}>Soumettre le code</Button>
                  </ActionsBtnDialog>
                </form>
              </div>
            }
          />
          {hasPerm(currentUser, "interventions:dispatch") && (
            <LinkButton to={`/interventions/declenchement`}>
              <NoteAddIcon className="icon-left" />Nouvelle intervention
            </LinkButton>
          )}
        </Actions>
      </PageHeader>

      {(payloadIsLoading || payload === undefined) ? (
        <LoadingSpinner className="center vh-50" />
      ) : (
        <>
          {pagination}
          <List
            bilans={payload.bilans}
            listingIdPerm="web:interventions_active:listing:identite"
            showPriority={hasPerm(currentUser, "web:intervention:priorite")}
            visualIndicator={hasPerm(currentUser, "web:interventions:visualIndicator")}
          />
          {pagination}
        </>
      )}
    </>
  );
}

export default Active;
