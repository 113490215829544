import React, { useEffect, useState, useContext } from "react";
import http from "../../http";
import { useQuery } from "react-query";
import * as Sentry from "@sentry/browser";
import { toast } from "react-toastify";
import { isAbortError } from "../../utils";
import LoaderBarContext from "../../ui/useLoaderBar";

import PageHeader, { Cntnr, Title } from "../../ui/PageHeader";
import Input from "../../ui/Input";
import { BlockButton } from "../../ui/Button";
import LoadingSpinner from "../../ui/LoadingSpinner";
import styled from "styled-components";
import theme from "../../ui/theme";

const RowItem = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 950px) {
    flex-direction: column;
  }
`;

const ActionItem = styled.div`
  display: flex;
  flex-direction: row;
  & > ${BlockButton} {
    margin-left: ${theme.small};
  }
  @media (max-width: 950px) {
    margin-top: ${theme.thin};
    & > ${BlockButton}:first-child {
      margin-left: 0;
    }
  }
  @media (max-width: 300px) {
    flex-direction: column;
    & > ${BlockButton} {
      margin-left: 0;
    }
  }
`;

function ProtocolItem({ protocol, protocolRefetch }) {
  const { loaderBarState, setLoaderBar } = useContext(LoaderBarContext);

  const [name, setName] = useState(protocol.name);

  const handleFormSubmit = async (ev) => {
    ev.preventDefault();
    if (loaderBarState) return;
    setLoaderBar(true);
    try {
      await http.patch(`nursing_protocols/${encodeURIComponent(protocol.id)}.json`, {
        json: {
          name,
        }
      });
      toast.success("PISU modifié avec succès");
      protocolRefetch();
    } finally {
      setLoaderBar(false);
    };
  };

  const handleDeleteClick = async (ev) => {
    ev.preventDefault();
    if (loaderBarState) return;
    setLoaderBar(true);
    try {
      await http.delete(`nursing_protocols/${encodeURIComponent(protocol.id)}.json`);
      toast.success("PISU supprimé avec succès");
      protocolRefetch();
    } finally {
      setLoaderBar(false);
    };
  };

  return (<form style={{paddingTop: ".5rem", paddingBottom: ".5rem"}} onSubmit={handleFormSubmit}>
    <RowItem>
      <Input value={name} onChange={ev => setName(ev.target.value)} disabled={loaderBarState} />
      <ActionItem width={1}>
        <BlockButton type="submit" disabled={loaderBarState || name === protocol.name}>Sauvegarder</BlockButton>
        <BlockButton type="button" className="warn" disabled={loaderBarState} onClick={handleDeleteClick}>Supprimer</BlockButton>
      </ActionItem>
    </RowItem>
  </form>);
}

export default function NursingProtocolsList() {
  const controller = new AbortController();

  const { loaderBarState, setLoaderBar } = useContext(LoaderBarContext);

  const [name, setName] = useState("");

  const {
    isLoading: nursingProtocolsIsLoading,
    data: nursingProtocols,
    refetch: nursingProtocolsRefetch,
  } = useQuery(
    "pisu",
    async () => {
      return await http
      .get(`nursing_protocols.json`, {
        signal: controller.signal,
      })
      .json()
      .then(res => {
        return res.data;
      })
      .catch(error => {
        if (isAbortError(error)) return;
        console.error(error);
        Sentry.captureException(error);
        toast.warn("Une erreur est survenue lors de la récupération des PISU");
        throw error;
      });
    },
    {cacheTime: 0},
  );

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const handleFormSubmit = async (ev) => {
    ev.preventDefault();
    if (loaderBarState) return;
    setLoaderBar(true);
    try {
      await http.post("nursing_protocols.json", {
        json: {
          name,
        }
      });
      toast.success("PISU ajouté avec succès");
      setName("");
      nursingProtocolsRefetch();
    } catch (e) {
      console.error(e);
      // Sentry.captureException(e);
      toast.error("Impossible de créer le protocole infirmier");
    } finally {
      setLoaderBar(false);
    };
  };

  return (<>
    <PageHeader>
      <Title>PISU</Title>
    </PageHeader>

    {nursingProtocolsIsLoading ? (
      <LoadingSpinner className="center vh-50" />
    ) : (
      <Cntnr>
        <form onSubmit={handleFormSubmit}>
          <RowItem>
            <Input disabled={loaderBarState} value={name} onChange={ev => setName(ev.target.value)} />
            <ActionItem width={1}>
              <BlockButton type="submit" disabled={loaderBarState}>Ajouter</BlockButton>
            </ActionItem>
          </RowItem>
        </form>
        <hr/>
        {nursingProtocols.map(protocol => (
          <ProtocolItem
            key={protocol.id}
            protocol={protocol}
            protocolRefetch={() => nursingProtocolsRefetch()}
          />
        ))}
      </Cntnr>
    )}
  </>);
}
