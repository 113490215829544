import React, {useState} from "react";
import styled from "styled-components";
import theme from "../ui/theme";

import {Tab, TabList, TabPanel, TabPanels, Tabs} from "@reach/tabs";

const SideBar = styled.aside`
  border-left: 2px dashed ${theme.blueBilan};
  z-index: 9;
  transition: width .2s ease-in-out, opacity .2s ease-in-out, background-color .3s ease-in-out, border-left .3s ease-in-out;
  background-color: ${theme.backgroundColor};
  position: fixed;
  top: 0px;
  right: 0px;
  @media (max-width: 1240px) {
    z-index: 10;
  }
`;

const SidebarTabs = styled(Tabs)`
  display: flex;
  flex-direction: row;
  height: 100vh;
  position: sticky;
  top: 0;
  transition: width .2s ease-in-out, opacity .2s ease-in-out;
`;

const SidebarTabList = styled(TabList)`
  background-color: ${theme.backgroundColor};
  display: flex;
  flex-direction: column;
  transition: width .2s ease-in-out, opacity .2s ease-in-out, background-color .3s ease-in-out;
`;

const SidebarTab = styled(Tab)`
  &[data-selected] {
    border-bottom-color: ${theme.blue};
  }

  border: 2px solid ${theme.blue};
  border-radius: 0.25rem;
  padding: ${theme.thin};
  margin: ${theme.thin};
  width: 44px;
  background-color: ${theme.backgroundColor};
  transition: background-color .3s ease-in-out, border .3s ease-in-out;

  &.top {
    margin-top: ${theme.small};
  }

  & > span {
    color: ${theme.blue};
    padding: 0px;
    writing-mode: vertical-rl;
    text-orientation: sideways-right;
  }
`;

const SidebarTabPanels = styled(TabPanels)`
  transition: width .2s ease-in-out, opacity .2s ease-in-out;
  max-width: calc(-62px + 100vw);
`;

const SidebarTabPanel = styled(TabPanel)`
  height: 100%;
  overflow-y: scroll;
`;

const SidebarNeighbor = styled.div`
  width: calc(100% - (${props => `${props.width}px`} + 62px));
  width: ${props => props.hasSide ? `calc(100% - (${props.width}px + 62px))` : `100%`};
  transition: width .2s ease-in-out;
  margin-right: ${props => props.hasSide ? `62px` : `0px`};
  @media (max-width: 1240px) {
    width: 100%;
  }
`;

export default function SideBarDyn ({panels, hasSide, children}) {
  const [panelIndex, setPanelIndex] = useState(0);

  const handlePanelChange = (idx) => {
    if (idx === panelIndex) {
      setPanelIndex(0);
    } else {
      setPanelIndex(idx);
    };
  };

  const DEFAULT_PANEL_WIDTH = 320;

  function panelWidth(panels, index) {
    if (index === 0) {
      return 0;
    };
    if (panels[index - 1].opts != null && panels[index - 1].opts.width != null) {
      return panels[index - 1].opts.width;
    };
    return DEFAULT_PANEL_WIDTH;
  };

  return (
    <>
      <SidebarNeighbor width={panelWidth(panels, panelIndex)} hasSide={hasSide}>
        {children}
      </SidebarNeighbor>
      {hasSide && (
        <SideBar>
          <SidebarTabs index={panelIndex} onChange={handlePanelChange}>
            <SidebarTabPanels style={{width: panelWidth(panels, panelIndex), opacity: panelIndex === 0 ? 0 : 1}}>
              <SidebarTabPanel />
              {panels.map(p =>
                <SidebarTabPanel key={p.key}>
                  {p.contents}
                </SidebarTabPanel>
              )}
            </SidebarTabPanels>
            <SidebarTabList>
              <SidebarTab tabIndex={-1} style={{display: 'none', visibility: 'hidden'}} />
              {panels.map((p, idx) =>
                <SidebarTab key={p.key} title={p.opts.title} className={`${idx === 0 ? "top" : ""}`} data-sentry-id={`side-${p.opts.title}`}>
                  <span>
                    {(p.opts || {}).icon} {(p.opts || {}).title || p.key}
                  </span>
                </SidebarTab>
              )}
            </SidebarTabList>
          </SidebarTabs>
        </SideBar>
      )}
    </>
  );
};