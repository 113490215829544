import React, { useState, useMemo} from "react";
import { Link } from "found";
import http from "../../http";
import { useQuery } from "react-query";
import * as Sentry from "@sentry/browser";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { isAbortError } from "../../utils";
import {statusLabel} from "../utils";
import InfiniteScroll from "react-infinite-scroll-component";

import styled from "styled-components";
import theme from "../../ui/theme";
import PageHeader, { Title } from "../../ui/PageHeader";
import Card from "../../ui/Card";
import LoadingSpinner from "../../ui/LoadingSpinner";
import { BtnTabs, Tabs, TabsCntnr } from "../../ui/Tabs";
import SearchBarCompo, { FilterBar, SearchBar } from "../../ui/SearchBar";
import Input, { InputSearchCntnr, InputSearchIcon } from "../../ui/Input";
import SearchIcon from '@mui/icons-material/Search';
import Button from "../../ui/Button";
import Tag from "../../ui/Tag";

const LicenceList = styled.ul`
  padding: 0 ${theme.small};
`;

const LicenceCard = styled(Card)`
  max-width: none;
  min-width: none;
  &:not(:last-child) {
    margin-bottom: ${theme.small};
  }
`;

const LicenceLink = styled(Link)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  color: ${theme.text};
  &:hover {
    text-decoration: none;
  color: ${theme.blue};
  }

  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const LicenceListHeader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const LicenceCardItem = styled.span`
  margin: ${theme.thin};
  width: 30%;
  &.small {
    width: auto;
  }

  @media (max-width: 1200px) {
    width: 48%;
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const perPage = 20;

export default function List() {
  const controller = new AbortController();

  const [ tabs, setTabs ] = useState("active");
  const [ search, setSearch ] = useState("");
  const [ hasMore, setHasMore ] = useState(true);
  const [ page, setPage ] = useState(perPage);

  const {
    isLoading: licensesIsLoading,
    isError: licensesIsError,
    data: licenses
  } = useQuery(
    ["lisences", "active"],
    async () => {
      return await http
      .get('licenses.json', {
        signal: controller.signal,
        searchParams: {
          status: "active",
        }
      })
      .json()
      .then(res => {
        return res.data;
      })
      .catch(error => {
        if (isAbortError(error)) return;
        console.error(error);
        Sentry.captureException(error);
        toast.warn("Une erreur est survenue lors de la récupération des licences");
        throw error;
      });
    },
    {
      refetchInterval: 10000,
      cacheTime: 0
    },
  );

  const {
    isLoading: inactiveIsLoading,
    isError: inactiveIsError,
    data: inactive
  } = useQuery(
    ["lisences", "inactive"],
    async () => {
      return await http
      .get('licenses.json', {
        signal: controller.signal,
        searchParams: {
          status: "inactive",
        }
      })
      .json()
      .then(res => {
        return res.data;
      })
      .catch(error => {
        if (isAbortError(error)) return;
        console.error(error);
        Sentry.captureException(error);
        toast.warn("Une erreur est survenue lors de la récupération des licences");
        throw error;
      });
    },
    {
      refetchInterval: 10000,
      cacheTime: 0
    },
  );

  const {
    isLoading: revokedIsLoading,
    isError: revokedIsError,
    data: revoked
  } = useQuery(
    ["lisences", "revoked"],
    async () => {
      return await http
      .get('licenses.json', {
        signal: controller.signal,
        searchParams: {
          status: "revoked",
        }
      })
      .json()
      .then(res => {
        return res.data;
      })
      .catch(error => {
        if (isAbortError(error)) return;
        console.error(error);
        Sentry.captureException(error);
        toast.warn("Une erreur est survenue lors de la récupération des licences");
        throw error;
      });
    },
    {
      refetchInterval: 10000,
      cacheTime: 0
    },
  );

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const handleTabsChange = (value) => {
    setTabs(value);
  };

  const clearFilter = () => {
    setPage(perPage);
    setSearch("");
  };

  const searchLicense = (licenses) => {
    let temp = [];
    licenses.map(l => {
      if (
        l.name.toLowerCase().includes(search.toLocaleLowerCase()) ||
        l.device.toLowerCase().includes(search.toLocaleLowerCase()) ||
        l.public_id.toLowerCase().includes(search.toLocaleLowerCase())
      ) temp.push(l);
    })
    return temp;
  };

  const getLicensesList = useMemo(() => {
    setPage(perPage);
    switch (tabs) {
      case "active":
        return licenses || [];
      case "inactive":
        return inactive || [];
      case "revoked":
        return revoked || [];
      default:
        return [];
    }
  }, [ tabs, licenses, inactive, revoked ]);

  const licensesFiltered = useMemo(() => {
    return searchLicense(getLicensesList);
  }, [ search, getLicensesList ]);

  const licensesInfinite = useMemo(() => {
    const temp = licensesFiltered.slice(0, page);
    temp.length >= licensesFiltered.length ? setHasMore(false) : setHasMore(true);
    return temp;
  }, [ page, licensesFiltered ]);

  const tabsBtnOptions = [
    {
      option: "inactive",
      length: searchLicense(inactive || [])?.length,
      text: "Inactive",
    },
    {
      option: "active",
      length: searchLicense(licenses || [])?.length,
      text: "Active",
    },
    {
      option: "revoked",
      length: searchLicense(revoked || [])?.length,
      text: "Révoquée",
    },
  ];

  const fetchMoreData = () => {
    if (licensesInfinite.length >= licensesFiltered.length) return setHasMore(false);
    setPage(page + perPage);
  };

  return (<>
    <PageHeader>
      <Title>Licences</Title>
    </PageHeader>

    <SearchBarCompo
      hasTag={true}
      tags={<>
        {search.length > 0 && <Tag text={search} callback={clearFilter} />}
      </>}
    >
      <SearchBar>
        <FilterBar>
          <InputSearchCntnr>
            <InputSearchIcon>
              <SearchIcon />
            </InputSearchIcon>
            <Input
              type="search"
              value={search}
              onChange={(ev) => setSearch(ev.target.value)}
              placeholder="Nom, appareil et numéro de licence"
            />
          </InputSearchCntnr>
          <Button className="warn" onClick={clearFilter}>Réinitialiser les filtres</Button>
        </FilterBar>
      </SearchBar>
    </SearchBarCompo>

    {licensesIsLoading ? (
      <LoadingSpinner className="center vh-50" />
    ) : (
      <>
        <TabsCntnr>
          <Tabs>
            {tabsBtnOptions.map((btn, idx) => (
              <BtnTabs
                key={idx}
                onClick={() => handleTabsChange(btn.option)}
                className={`tabButton${tabs === btn.option ? " active" : ""}`}
              >{btn.text}{btn.length > 1 && "s"} {btn.length}</BtnTabs>
            ))}
          </Tabs>
        </TabsCntnr>

        <InfiniteScroll
          dataLength={licensesInfinite.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<div style={{height: "75px", marginTop: theme.medium}}>
            <LoadingSpinner className="center" />
          </div>}
        >
          <LicenceList>
            <LicenceListHeader>
              <LicenceCardItem>Nom</LicenceCardItem>
              <LicenceCardItem>Appareil</LicenceCardItem>
              <LicenceCardItem>Numéro de licence</LicenceCardItem>
              <LicenceCardItem className="small">Status</LicenceCardItem>
            </LicenceListHeader>
            {licensesIsLoading &&
            (licensesIsError && tabs === "active") ||
            (inactiveIsError || inactiveIsLoading && tabs === "inactive") ||
            (revokedIsError || revokedIsLoading && tabs === "revoked") ? (
              <LoadingSpinner className="center vh-50" />
            ) : (
              <>
                {licensesInfinite?.length > 0 ? (
                  <>
                    {licensesInfinite.map((licence, idx) => (
                      <LicenceCard  key={idx}>
                          <LicenceLink to={`/licenses/${licence.id}`}>
                            <LicenceCardItem>{licence.name || (<em>Aucun nom renseigné</em>)}</LicenceCardItem>
                            <LicenceCardItem>{licence.device || (<em>Aucun appareil assigné</em>)}</LicenceCardItem>
                            <LicenceCardItem>{licence.public_id}</LicenceCardItem>
                            <LicenceCardItem className="small">{statusLabel(licence)}</LicenceCardItem>
                          </LicenceLink>
                      </LicenceCard>
                    ))}
                  </>
                ) : (
                  <>
                    <hr />
                    <span>Aucune licence</span>
                  </>
                )}
              </>
            )}
          </LicenceList>
        </InfiniteScroll>
      </>
    )}
  </>);
};
