import { hasPerm } from "../../utils";
import { template as syoTemplate } from "@nfsave/syo-bilan";

export function makeXabcdeLike(sectionNames = ["X", "A", "B", "C", "D", "E"]) {
  return [
    {
      "@type": "grid",
      "key": "eaa87a98-83a6-4784-854b-dffb7dc49f4f",
      columns: 1,
      children: [
        { // Intervention / Équipe
          "@type": "card",
          key: "4843d864-5253-44ae-8ac2-620a298dbd3c",
          title: "Intervention / Équipe",
          gridColumn: "1/2",
          className: "cardCntnr",
          children: [
            {
              "@type": "grid",
              key: "104374a0-ac4b-4050-82fb-e19aec828593",
              columns: 3,
              children: [
                ...syoTemplate.ticketNumMotif,
                ...syoTemplate.equipeInfos,
              ],
            },
          ]
        },
      ],
    },
    {
      "@type": "grid",
      "key": "916903b2-0521-44e9-a3d4-32d26acc69b1",
      columns: 5,
      children: [
        { // Circonstances
          "@type": "card",
          key: "c2456c66-1b2f-42ee-a533-af0f20669e66",
          title: (cUser) => hasPerm(cUser, "assessment:sdis64") ? "Atteintes circonstancielles" : "Circonstances",
          gridColumn: "1/3",
          children: [
            ...syoTemplate.circonstanciel,
            ...syoTemplate.atteinteCirconstanciel,
            {
              "@type": "permission",
              key: "49326b13-16b1-466b-8a9e-688458e571cd",
              permission: ["assessment:sdis64"],
              children: [
                ...syoTemplate.atteinteCirconstancielAccidents
              ],
            },
            ...syoTemplate.situation,
          ],
        },
        { // Identité
          "@type": "card",
          key: "5516f091-9a5e-4f98-912b-c85ca7bf2232",
          title: "Identité",
          gridColumn: "3/5",
          children: [
            ...syoTemplate.identite,
          ],
        },
        { // Contacts
          "@type": "card",
          key: "59060cb6-4180-4b82-b4b1-78da2dee2e2a",
          title: (cUser) => hasPerm(cUser, "assessment:sdis64") ? "Informations complémentaires" : "Contacts",
          gridColumn: "5/6",
          children: [
            ...syoTemplate.contact,
          ],
        },
      ],
    },
    {
      "@type": "grid",
      key: "9adba675-83f0-491c-8a02-e23b2391a749",
      columns: 5,
      permission: ["assessment:sampler"],
      children: [
        { // SAMPLER
          "@type": "card",
          key: "fc99196b-6a9e-4232-8851-e5af09f375e4",
          title: "SAMPLER",
          gridColumn: "1/5",
          className: "cardCntnr",
          children: [
            {
              "@type": "grid",
              key: "257c84c2-6e02-4353-8d26-2d5352901219",
              columns: 2,
              children: [
                ...syoTemplate.sampler,
              ],
            },
          ],
        },
        { // OPQRST
          "@type": "card",
          key: "d109959d-e0e1-4233-9cad-203d56d5c769",
          title: (cUser) => hasPerm(cUser, "identity:pqrst:DisplayOnset") ? "OPQRST" : "Compléments SAMPLER",
          gridColumn: "5/6",
          children: [
            ...syoTemplate.opqrst,
            ...syoTemplate.pqrst,
          ],
        },
      ],
    },
    {
      "@type": "grid",
      key: "8bf13c5d-1802-4fbf-b40c-0c8f36a928af",
      columns: 2,
      permission: ["!assessment:sampler"],
      children: [
        { // MHTA SI PAS LA PERM POUR LE SAMPLER
          "@type": "card",
          key: "ccf612a0-b57e-4e77-91c4-29662724b96e",
          title: "MHTA",
          gridColumn: "1/2",
          children: [
            ...syoTemplate.mhta,
            ...syoTemplate.lunchSampler,
          ],
        },
        { // OPQRST
          "@type": "card",
          key: "99beec31-11f8-4e13-8187-eb93bf26c690",
          title: "OPQRST",
          gridColumn: "2/3",
          children: [
            ...syoTemplate.opqrst,
            ...syoTemplate.pqrst,
          ],
        },
      ],
    },
    {
      "@type": "grid",
      "key": "6c832786-63cc-4cf2-8911-6729baa5bd50",
      columns: 3,
      children: [
        { // Anamnèse
          "@type": "card",
          key: "71f96f13-44fe-4df7-a3e1-ca1e07480de1",
          title: "Anamnèse",
          gridColumn: "1/2",
          children: [
            ...syoTemplate.anamnese,
          ],
        },
        { // Examen clinique
          "@type": "card",
          key: "61082c62-7bbd-483f-af71-8e0c697d5db9",
          title: "Examen clinique",
          gridColumn: "2/3",
          children: [
            ...syoTemplate.examen,
          ],
        },
        { // Hypothèse diagnostique
          "@type": "card",
          key: "18beaa2c-c8c5-4c55-8380-9293b5dce23f",
          title: "Hypothèse diagnostique",
          gridColumn: "3/4",
          children: [
            ...syoTemplate.hypotheseDiag,
          ],
        },
      ],
    },
    {
      "@type": "grid",
      key: "6641c2b2-950d-4327-a580-56fbfe4701ad",
      columns: 1,
      children: [
        { // ACR Arrêt cardio-respiratoire
          "@type": "card",
          key: "6f652677-05e7-4f25-b2f1-3069a0fa6a96",
          title: "ACR",
          children: [
            ...syoTemplate.acr,
          ],
        }
      ],
    },
    {
      "@type": "grid",
      key: "8c0be844-5e7c-46d5-ac24-3a2b9f1dfe77",
      columns: 3,
      children: [
        { // Obstétrique
          "@type": "card",
          key: "8ad340a6-9cc0-4adf-8e77-101e7300d71a",
          title: "Obstétrique",
          gridColumn: "1/2",
          children: [
            ...syoTemplate.obstetrique,
          ],
        },
        { // Secours routier
          "@type": "card",
          key: "f047d819-1691-414d-8bba-ab7f45339350",
          title: "Secours routier / Accidents",
          gridColumn: "2/3",
          children: [
            ...syoTemplate.secoursRoutier,
            {
              "@type": "permission",
              key: "045b0d54-78c3-43f1-a1e9-5d13689fbce5",
              permission: ["!assessment:sdis64"],
              children: [
                ...syoTemplate.atteinteCirconstancielAccidents
              ],
            },
          ],
        },
        { // Douleur Thoracique
          "@type": "card",
          key: "bcdc4e9d-167e-4daf-b529-c39b5cde3a6f",
          title: "Douleur Thoracique",
          gridColumn: "3/4",
          children: [
            ...syoTemplate.douleurThoracique,
          ]
        },
      ],
    },
    {
      "@type": "grid",
      key: "bd9994df-06d2-4402-ad67-178ecbdfbe4c",
      columns: 3,
      children: [
        { // PISU
          "@type": "card",
          key: "9a578f8a-a5fa-4d5c-8cc4-84d12791078a",
          title: "PISU / GASSPPE",
          gridColumn: "1/2",
          children: [
            ...syoTemplate.pisu,
          ],
        },
        { // Gestes techniques
          "@type": "card",
          key: "f5ae4ce7-d02e-48c7-9486-c0d8e881a0b8",
          title: "Gestes techniques",
          gridColumn: "2/3",
          children: [
            ...syoTemplate.gesteTechnique,
          ],
        },
        { // NIHSS
          "@type": "card",
          key: "f6a58a1d-5537-4d07-896e-f4d9e272ade0",
          title: "NIHSS",
          gridColumn: "3/4",
          children: [
            {
              "@type": "permission",
              key: "02fdb98c-c04b-4650-80a5-5f8bf2bd2162",
              permission: ["!web:bilan:extract-fast-out-of-neuro"],
              children: [
                ...syoTemplate.nihss,
              ],
            },
            {
              "@type": "permission",
              key: "d3ba77d4-fd5f-4890-b307-0900cd897c1e",
              permission: ["web:bilan:extract-fast-out-of-neuro"],
              children: [
                ...syoTemplate.nihssSansFast,
              ],
            },
          ]
        },
      ],
    },
    { // ABCDE LESIONNEL
      "@type": "grid",
      key: "15e293e3-9920-4b5b-b929-09af03176e68",
      columns: 5,
      children: [
        {
          "@type": "card",
          key: "b0919117-e2f7-4456-b1be-481f7485b05c",
          gridColumn: "1 / 4",
          className: "cardCntnr",
          children: [
            {
              "@type": "grid",
              key: "f2cc2274-741a-469d-a3e1-3401c06dc348",
              columns: 2,
              permission: ["gestes:condense-sdis38:visible", "assessment:gestes:onglet-rcp"],
              children: [
                {
                  "@type": "card",
                  key: "e9daad01-8b24-4ba8-a317-ea13fdf87404",
                  gridColumn: "1 / 3",
                  title: "RCP",
                  children: [
                    {
                      "@type": "grid",
                      key: "5fae5224-3896-4c61-9bfe-6c0b212c3bf5",
                      columns: 2,
                      children: [
                        ...syoTemplate.rcp,
                      ],
                    },
                  ],
                },
              ],
            },
            {
              "@type": "grid",
              key: "2ed9d17d-947a-4f54-b9a8-c376c70b819f",
              columns: 3,
              children: [
                {
                  "@type": "card",
                  key: "bbdf133a-ad5e-4214-ae29-f27db4f948b9",
                  gridColumn: "1 / 3",
                  title: `${sectionNames[0]}`,
                  children: [
                    {
                      "@type": "grid",
                      key: "7c2058f9-5ce0-426f-a298-780daa327573",
                      columns: 2,
                      children: [
                        ...syoTemplate.xAbcde,
                      ],
                    },
                  ],
                },
                {
                  "@type": "card",
                  key: "13b11e11-e7ab-47a0-9bce-ef5d2a30faaf",
                  gridColumn: "3 / 4",
                  title: "Gestes",
                  children: [
                    ...syoTemplate.xAbcdeGeste,
                  ],
                },
              ],
            },
            {
              "@type": "grid",
              key: "7d4cf16c-6cbd-4c69-9c6d-b126d5291966",
              columns: 3,
              children: [
                {
                  "@type": "card",
                  key: "bbdf133a-ad5e-4214-ae29-f27db4f948b9",
                  gridColumn: "1 / 3",
                  title: `${sectionNames[1]}`,
                  children: [
                    {
                      "@type": "grid",
                      key: "7c2058f9-5ce0-426f-a298-780daa327573",
                      columns: 2,
                      children: [
                        ...syoTemplate.aAbcde,
                      ],
                    },
                  ],
                },
                {
                  "@type": "card",
                  key: "e02ffba6-9423-4ac6-9021-090036ce7f4f",
                  gridColumn: "3 / 4",
                  title: "Gestes",
                  children: [
                    ...syoTemplate.aAbcdeGeste,
                  ],
                },
              ],
            },
            {
              "@type": "grid",
              key: "62d461bd-2550-43b4-a49f-9aca72f4f5d5",
              columns: 3,
              children: [
                {
                  "@type": "card",
                  key: "a370ded9-0959-4646-aeb8-89ee2206a615",
                  gridColumn: "1 / 3",
                  title: `${sectionNames[2]}`,
                  children: [
                    {
                      "@type": "grid",
                      key: "7c2058f9-5ce0-426f-a298-780daa327573",
                      columns: 2,
                      children: [
                        ...syoTemplate.bAbcde,
                      ],
                    },
                  ],
                },
                {
                  "@type": "card",
                  key: "47af03ca-145f-4b4d-a0cd-dfbfbcc54d6a",
                  gridColumn: "3 / 4",
                  title: "Gestes",
                  children: [
                    ...syoTemplate.bAbcdeGeste,
                    ...syoTemplate.gesteSmur,
                  ],
                },
              ],
            },
            {
              "@type": "grid",
              key: "767a0d34-5d5b-4c65-9271-1f3b02b920bb",
              columns: 3,
              children: [
                {
                  "@type": "card",
                  key: "853869c5-5e0a-44d3-9c72-5f02716001d0",
                  gridColumn: "1 / 3",
                  title: `${sectionNames[3]}`,
                  children: [
                    {
                      "@type": "grid",
                      key: "5fa0701a-82cb-4422-9cac-a6b7c027f7da",
                      columns: 2,
                      children: [
                        ...syoTemplate.cAbcde,
                      ],
                    },
                  ],
                },
                {
                  "@type": "card",
                  key: "69472023-f5a7-4edb-910d-f2aa86f6529f",
                  gridColumn: "3 / 4",
                  title: "Gestes",
                  children: [
                    ...syoTemplate.cAbcdeGeste,
                  ],
                },
              ],
            },
            {
              "@type": "grid",
              key: "9b8ceeb6-0c16-45ec-85ce-e7aaa0b49834",
              columns: 3,
              children: [
                {
                  "@type": "card",
                  key: "f4c2aa87-6814-4181-a7c9-27d53b266de4",
                  gridColumn: "1 / 3",
                  title: `${sectionNames[4]}`,
                  children: [
                    {
                      "@type": "permission",
                      key: "072f3f19-9efe-46e6-805a-fc56c0331088",
                      permission: ['!web:bilan:extract-fast-out-of-neuro'],
                      children: [
                        {
                          "@type": "grid",
                          key: "0c22be7d-26f8-4192-88ff-76b6ee015bf5",
                          columns: 2,
                          children: [
                            ...syoTemplate.dAbcde,
                          ],
                        },
                      ],
                    },
                    {
                      "@type": "permission",
                      key: "fb0add8c-927b-4387-ae09-10f4ece314a6",
                      permission: ['web:bilan:extract-fast-out-of-neuro'],
                      children: [
                        {
                          "@type": "grid",
                          key: "5257ca0a-3fc6-4d07-beba-4e16139c948d",
                          columns: 2,
                          children: [
                            ...syoTemplate.neurologiqueSansFast,
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  "@type": "card",
                  key: "46444252-25a9-4089-8a46-44502ab45e37",
                  gridColumn: "3 / 4",
                  title: "Gestes",
                  children: [
                    ...syoTemplate.dAbcdeGeste,
                  ],
                },
              ],
            },
            {
              "@type": "grid",
              key: "a9e00c90-40b5-454a-bd9d-06e9568c6c24",
              columns: 3,
              children: [
                {
                  "@type": "card",
                  key: "956b8036-8821-4138-b98a-acb776edbd2d",
                  gridColumn: "1 / 3",
                  title: `${sectionNames[5]}`,
                  children: [
                    {
                      "@type": "grid",
                      key: "62ce8474-1775-4a82-9ed4-1366689a0b21",
                      columns: 2,
                      children: [
                        ...syoTemplate.eAbcde,
                      ],
                    },
                  ],
                },
                {
                  "@type": "card",
                  key: "0adbd38f-0633-41fd-8317-8d0e6bbac89e",
                  gridColumn: "3 / 4",
                  title: "Gestes",
                  children: [
                    ...syoTemplate.eAbcdeGeste,
                  ],
                },
              ],
            },
          ],
        },
        {
          "@type": "card",
          key: "8a5895ce-2b87-4cc0-a6cc-7ea1fd25ef04",
          gridColumn: "4 / 6",
          className: "cardCntnr",
          children: [
            {
              "@type": "card",
              key: "e97b976d-7d26-4bd2-a199-4e578079d8fe",
              title: "Lésionnel",
              children: [
                ...syoTemplate.lesionnel,
                ...syoTemplate.lesionnelProtocol,
              ],
            },
            { // FAST
              "@type": "card",
              key: "6ee02cf0-200e-4dc3-ade5-a390f108840e",
              title: "FAST",
              children: [
                ...syoTemplate.neurologiqueFast,
                {
                  "@type": "permission",
                  key: "5d16624f-ee47-47df-9415-ffb3372de79b",
                  permission: ['web:bilan:extract-fast-out-of-neuro'],
                  children: [
                    ...syoTemplate.nihssParalysieFacialeFast,
                    ...syoTemplate.nihssMotriciteMembreFast,
                    ...syoTemplate.neuroHeureAppDeficitFast,
                    ...syoTemplate.neuroTroubleParoleFast,
                  ],
                },
              ],
            },
            { // Témoin
              "@type": "card",
              key: "e812724e-b54c-42f4-93a8-c47ce6793903",
              title: "Témoin",
              children: [
                ...syoTemplate.gesteTemoin,
              ],
            },
            { // Autres gestes
              "@type": "card",
              key: "ec30e2f8-39d5-446e-a4e7-161e53bf146d",
              title: "Autres gestes",
              children: [
                ...syoTemplate.gesteSmur35,
                ...syoTemplate.gesteAutres,
              ],
            },
            { // Prélèvement d'un bilan sanguin
              "@type": "card",
              key: "7d43280e-785f-478f-82c2-619dc9c468e7",
              title: "Prélèvement d'un bilan sanguin",
              children: [
                ...syoTemplate.gesteBilanSanguin,
              ],
            },
            {
              "@type": "card",
              key: "f13c870f-e648-4d94-9859-35e40ae51bc5",
              title: "Devenir",
              children: [
                ...syoTemplate.devenir,
              ],
            },
          ]
        },
      ],
    },
    {
      "@type": "grid",
      key: "1fbd91e1-671d-46d2-9d11-12afd0d1f886",
      columns: 1,
      children: [
        { // Surveillances
          "@type": "card",
          key: "22fcd2af-d4ae-4e78-9826-e63aca89d3ca",
          title: "Surveillances",
          children: [
            ...syoTemplate.surveillances,
          ],
        },
      ],
    },
    // {
    //   "@type": "grid",
    //   key: "5256eb23-c554-44c2-ae0f-9c31fc04bc67",
    //   columns: 1,
    //   permission: ["web:bilan:multiparam"],
    //   children: [
    //     { // Multiparamétrique
    //       "@type": "card",
    //       key: "7c7bdf10-01a8-48ec-bfd7-994b6f84ba94",
    //       title: "Multiparamétrique",
    //       children: [
    //         ...syoTemplate.multiparam,
    //       ],
    //     },
    //   ],
    // },
  ];
};

export function makeAbcdeLike(sectionNames = ["A", "B", "C", "D", "E"]) {
  return [
    {
      "@type": "grid",
      "key": "8bcd1274-8719-48b9-be78-26d09fc98ed4",
      columns: 1,
      children: [
        { // Intervention / Équipe
          "@type": "card",
          key: "205720fc-c77a-4385-a062-da10c61532de",
          title: "Intervention / Équipe",
          gridColumn: "1/2",
          className: "cardCntnr",
          children: [
            {
              "@type": "grid",
              key: "cd077ee0-4f13-46c7-aa8f-2ac729d282ed",
              columns: 3,
              children: [
                ...syoTemplate.ticketNumMotif,
                ...syoTemplate.equipeInfos,
              ],
            },
          ]
        },
      ],
    },
    {
      "@type": "grid",
      "key": "916903b2-0521-44e9-a3d4-32d26acc69b1",
      columns: 5,
      children: [
        { // Circonstances
          "@type": "card",
          key: "c2456c66-1b2f-42ee-a533-af0f20669e66",
          title: "Circonstances",
          gridColumn: "1/3",
          children: [
            ...syoTemplate.circonstanciel,
            ...syoTemplate.situation,
          ],
        },
        { // Identité
          "@type": "card",
          key: "5516f091-9a5e-4f98-912b-c85ca7bf2232",
          title: "Identité",
          gridColumn: "3/5",
          children: [
            ...syoTemplate.identite,
          ],
        },
        { // Contacts
          "@type": "card",
          key: "59060cb6-4180-4b82-b4b1-78da2dee2e2a",
          title: "Contacts",
          gridColumn: "5/6",
          children: [
            ...syoTemplate.contact,
          ],
        },
      ],
    },
    {
      "@type": "grid",
      key: "efd92c6e-f49f-4514-8ddf-b00a6d008772",
      columns: 5,
      permission: ["assessment:sampler"],
      children: [
        { // SAMPLER
          "@type": "card",
          key: "f8e25770-99db-4afb-b5b2-e0cf7f2f587d",
          title: "SAMPLER",
          gridColumn: "1/5",
          className: "cardCntnr",
          children: [
            {
              "@type": "grid",
              key: "17255119-e464-40c6-8d29-fdbc2c117c4a",
              columns: 2,
              children: [
                ...syoTemplate.sampler,
              ],
            },
          ],
        },
        { // OPQRST
          "@type": "card",
          key: "11c12c70-a0fe-4a90-96c5-feb3b33da904",
          title: (cUser) => hasPerm(cUser, "identity:pqrst:DisplayOnset") ? "OPQRST" : "Compléments SAMPLER",
          gridColumn: "5/6",
          children: [
            ...syoTemplate.opqrst,
            ...syoTemplate.pqrst,
          ],
        },
      ],
    },
    {
      "@type": "grid",
      key: "49e28a2b-60fa-4052-b758-b0a74437ed5c",
      columns: 2,
      permission: ["!assessment:sampler"],
      children: [
        { // MHTA SI PAS LA PERM POUR LE SAMPLER
          "@type": "card",
          key: "ccf612a0-b57e-4e77-91c4-29662724b96e",
          title: "MHTA",
          gridColumn: "1/2",
          children: [
            ...syoTemplate.mhta,
            ...syoTemplate.lunchSampler,
          ],
        },
        { // OPQRST
          "@type": "card",
          key: "9e5d9a30-45f9-4c85-8270-553997e81a41",
          title: "OPQRST",
          gridColumn: "2/3",
          children: [
            ...syoTemplate.opqrst,
            ...syoTemplate.pqrst,
          ],
        },
      ],
    },
    {
      "@type": "grid",
      "key": "6c832786-63cc-4cf2-8911-6729baa5bd50",
      columns: 3,
      children: [
        { // Anamnèse
          "@type": "card",
          key: "71f96f13-44fe-4df7-a3e1-ca1e07480de1",
          title: "Anamnèse",
          gridColumn: "1/2",
          children: [
            ...syoTemplate.anamnese,
          ],
        },
        { // Examen clinique
          "@type": "card",
          key: "61082c62-7bbd-483f-af71-8e0c697d5db9",
          title: "Examen clinique",
          gridColumn: "2/3",
          children: [
            ...syoTemplate.examen,
          ],
        },
        { // Hypothèse diagnostique
          "@type": "card",
          key: "18beaa2c-c8c5-4c55-8380-9293b5dce23f",
          title: "Hypothèse diagnostique",
          gridColumn: "3/4",
          children: [
            ...syoTemplate.hypotheseDiag,
          ],
        },
      ],
    },
    {
      "@type": "grid",
      key: "6641c2b2-950d-4327-a580-56fbfe4701ad",
      columns: 1,
      children: [
        { // ACR Arrêt cardio-respiratoire
          "@type": "card",
          key: "6f652677-05e7-4f25-b2f1-3069a0fa6a96",
          title: "ACR",
          children: [
            ...syoTemplate.acr,
          ],
        }
      ],
    },
    {
      "@type": "grid",
      key: "8c0be844-5e7c-46d5-ac24-3a2b9f1dfe77",
      columns: 3,
      children: [
        { // Obstétrique
          "@type": "card",
          key: "8ad340a6-9cc0-4adf-8e77-101e7300d71a",
          title: "Obstétrique",
          gridColumn: "1/2",
          children: [
            ...syoTemplate.obstetrique,
          ],
        },
        { // Secours routier
          "@type": "card",
          key: "f047d819-1691-414d-8bba-ab7f45339350",
          title: "Secours routier / Accidents",
          gridColumn: "2/3",
          children: [
            ...syoTemplate.secoursRoutier,
          ],
        },
        { // Douleur Thoracique
          "@type": "card",
          key: "6561a852-422e-4708-9a4f-e0606f9d93ad",
          title: "Douleur Thoracique",
          gridColumn: "3/4",
          children: [
            ...syoTemplate.douleurThoracique,
          ]
        },
      ],
    },
    {
      "@type": "grid",
      key: "dfef7ebf-9a7f-42f6-93b5-ba252dcc5262",
      columns: 3,
      children: [
        { // PISU
          "@type": "card",
          key: "48870de3-51a1-4433-9250-215f0396301f",
          title: "PISU / GASSPPE",
          gridColumn: "1/2",
          children: [
            ...syoTemplate.pisu,
          ],
        },
        { // Gestes techniques
          "@type": "card",
          key: "8c2b171b-32ae-4a3d-8ddf-caef228bdad7",
          title: "Gestes techniques",
          gridColumn: "2/3",
          children: [
            ...syoTemplate.gesteTechnique,
          ],
        },
        { // NIHSS
          "@type": "card",
          key: "52500a20-1164-43c3-9d07-e14fecc6e51a",
          title: "NIHSS",
          gridColumn: "3/4",
          children: [
            {
              "@type": "permission",
              key: "86bff3ef-3e0c-4d5d-9349-b28319823a73",
              permission: ["!web:bilan:extract-fast-out-of-neuro"],
              children: [
                ...syoTemplate.nihss,
              ],
            },
            {
              "@type": "permission",
              key: "8111b890-c62c-4ae2-949c-82e5cb16eaee",
              permission: ["web:bilan:extract-fast-out-of-neuro"],
              children: [
                ...syoTemplate.nihssSansFast,
              ],
            },
          ]
        },
      ],
    },
    { // ABCDE LESIONNEL
      "@type": "grid",
      key: "15e293e3-9920-4b5b-b929-09af03176e68",
      columns: 5,
      children: [
        {
          "@type": "card",
          key: "b0919117-e2f7-4456-b1be-481f7485b05c",
          gridColumn: "1 / 4",
          className: "cardCntnr",
          children: [
            {
              "@type": "grid",
              key: "f2cc2274-741a-469d-a3e1-3401c06dc348",
              columns: 2,
              permission: ["gestes:condense-sdis38:visible", "assessment:gestes:onglet-rcp"],
              children: [
                {
                  "@type": "card",
                  key: "e9daad01-8b24-4ba8-a317-ea13fdf87404",
                  gridColumn: "1 / 3",
                  title: "RCP",
                  children: [
                    {
                      "@type": "grid",
                      key: "5fae5224-3896-4c61-9bfe-6c0b212c3bf5",
                      columns: 2,
                      children: [
                        ...syoTemplate.rcp,
                      ],
                    },
                  ],
                },
              ],
            },
            {
              "@type": "grid",
              key: "d22beff9-2e70-4a7f-ad88-72452abe1e73",
              columns: 3,
              permissions: ['assessment:Abcde_x'],
              children: [
                {
                  "@type": "card",
                  key: "f20e34d3-1cf8-49d2-bf31-a9ce697007fd",
                  gridColumn: "1 / 3",
                  title: `Sauvegarde`,
                  children: [
                    {
                      "@type": "grid",
                      key: "e40a4959-15e6-40dd-85bb-b31125900bfc",
                      columns: 2,
                      children: [
                        ...syoTemplate.xAbcde,
                      ],
                    },
                  ],
                },
                {
                  "@type": "card",
                  key: "e2b99739-bb92-421e-aaa4-94475d27045d",
                  gridColumn: "3 / 4",
                  title: "Gestes",
                  children: [
                    ...syoTemplate.xAbcdeGeste,
                  ],
                },
              ],
            },
            {
              "@type": "grid",
              key: "7d4cf16c-6cbd-4c69-9c6d-b126d5291966",
              columns: 3,
              children: [
                {
                  "@type": "card",
                  key: "bbdf133a-ad5e-4214-ae29-f27db4f948b9",
                  gridColumn: "1 / 3",
                  title: `${sectionNames[0]}`,
                  children: [
                    {
                      "@type": "grid",
                      key: "7c2058f9-5ce0-426f-a298-780daa327573",
                      columns: 2,
                      children: [
                        ...syoTemplate.aAbcde,
                      ],
                    },
                  ],
                },
                {
                  "@type": "card",
                  key: "e02ffba6-9423-4ac6-9021-090036ce7f4f",
                  gridColumn: "3 / 4",
                  title: "Gestes",
                  children: [
                    ...syoTemplate.aAbcdeGeste,
                  ],
                },
              ],
            },
            {
              "@type": "grid",
              key: "62d461bd-2550-43b4-a49f-9aca72f4f5d5",
              columns: 3,
              children: [
                {
                  "@type": "card",
                  key: "a370ded9-0959-4646-aeb8-89ee2206a615",
                  gridColumn: "1 / 3",
                  title: `${sectionNames[1]}`,
                  children: [
                    {
                      "@type": "grid",
                      key: "7c2058f9-5ce0-426f-a298-780daa327573",
                      columns: 2,
                      children: [
                        ...syoTemplate.bAbcde,
                      ],
                    },
                  ],
                },
                {
                  "@type": "card",
                  key: "47af03ca-145f-4b4d-a0cd-dfbfbcc54d6a",
                  gridColumn: "3 / 4",
                  title: "Gestes",
                  children: [
                    ...syoTemplate.bAbcdeGeste,
                    ...syoTemplate.gesteSmur,
                  ],
                },
              ],
            },
            {
              "@type": "grid",
              key: "767a0d34-5d5b-4c65-9271-1f3b02b920bb",
              columns: 3,
              children: [
                {
                  "@type": "card",
                  key: "853869c5-5e0a-44d3-9c72-5f02716001d0",
                  gridColumn: "1 / 3",
                  title: `${sectionNames[2]}`,
                  children: [
                    {
                      "@type": "grid",
                      key: "5fa0701a-82cb-4422-9cac-a6b7c027f7da",
                      columns: 2,
                      children: [
                        ...syoTemplate.cAbcde,
                      ],
                    },
                  ],
                },
                {
                  "@type": "card",
                  key: "69472023-f5a7-4edb-910d-f2aa86f6529f",
                  gridColumn: "3 / 4",
                  title: "Gestes",
                  children: [
                    ...syoTemplate.cAbcdeGeste,
                  ],
                },
              ],
            },
            {
              "@type": "grid",
              key: "9b8ceeb6-0c16-45ec-85ce-e7aaa0b49834",
              columns: 3,
              children: [
                {
                  "@type": "card",
                  key: "f4c2aa87-6814-4181-a7c9-27d53b266de4",
                  gridColumn: "1 / 3",
                  title: `${sectionNames[3]}`,
                  children: [
                    {
                      "@type": "permission",
                      key: "072f3f19-9efe-46e6-805a-fc56c0331088",
                      permission: ['!web:bilan:extract-fast-out-of-neuro'],
                      children: [
                        {
                          "@type": "grid",
                          key: "0c22be7d-26f8-4192-88ff-76b6ee015bf5",
                          columns: 2,
                          children: [
                            ...syoTemplate.dAbcde,
                          ],
                        },
                      ],
                    },
                    {
                      "@type": "permission",
                      key: "fb0add8c-927b-4387-ae09-10f4ece314a6",
                      permission: ['web:bilan:extract-fast-out-of-neuro'],
                      children: [
                        {
                          "@type": "grid",
                          key: "5257ca0a-3fc6-4d07-beba-4e16139c948d",
                          columns: 2,
                          children: [
                            ...syoTemplate.neurologiqueSansFast,
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  "@type": "card",
                  key: "46444252-25a9-4089-8a46-44502ab45e37",
                  gridColumn: "3 / 4",
                  title: "Gestes",
                  children: [
                    ...syoTemplate.dAbcdeGeste,
                  ],
                },
              ],
            },
            {
              "@type": "grid",
              key: "a9e00c90-40b5-454a-bd9d-06e9568c6c24",
              columns: 3,
              children: [
                {
                  "@type": "card",
                  key: "956b8036-8821-4138-b98a-acb776edbd2d",
                  gridColumn: "1 / 3",
                  title: `${sectionNames[4]}`,
                  children: [
                    {
                      "@type": "grid",
                      key: "62ce8474-1775-4a82-9ed4-1366689a0b21",
                      columns: 2,
                      children: [
                        ...syoTemplate.eAbcde,
                      ],
                    },
                  ],
                },
                {
                  "@type": "card",
                  key: "0adbd38f-0633-41fd-8317-8d0e6bbac89e",
                  gridColumn: "3 / 4",
                  title: "Gestes",
                  children: [
                    ...syoTemplate.eAbcdeGeste,
                  ],
                },
              ],
            },
          ],
        },
        {
          "@type": "card",
          key: "8a5895ce-2b87-4cc0-a6cc-7ea1fd25ef04",
          gridColumn: "4 / 6",
          className: "cardCntnr",
          children: [
            {
              "@type": "card",
              key: "e97b976d-7d26-4bd2-a199-4e578079d8fe",
              title: "Lésionnel",
              children: [
                ...syoTemplate.lesionnel,
                ...syoTemplate.lesionnelProtocol,
              ],
            },
            { // FAST
              "@type": "card",
              key: "9a8f88bb-796d-4ec6-8d7f-0e222a7aa1db",
              title: "FAST",
              children: [
                ...syoTemplate.neurologiqueFast,
                {
                  "@type": "permission",
                  key: "e1c09319-d722-4616-b756-716119abe92c",
                  permission: ['web:bilan:extract-fast-out-of-neuro'],
                  children: [
                    ...syoTemplate.nihssParalysieFacialeFast,
                    ...syoTemplate.nihssMotriciteMembreFast,
                    ...syoTemplate.neuroHeureAppDeficitFast,
                    ...syoTemplate.neuroTroubleParoleFast,
                  ],
                },
              ],
            },
            { // Témoin
              "@type": "card",
              key: "22ceeb5e-a553-4f7c-8081-1ab7259c6c41",
              title: "Témoin",
              children: [
                ...syoTemplate.gesteTemoin,
              ],
            },
            { // Autres gestes
              "@type": "card",
              key: "ec30e2f8-39d5-446e-a4e7-161e53bf146d",
              title: "Autres gestes",
              children: [
                ...syoTemplate.gesteSmur35,
                ...syoTemplate.gesteAutres,
              ],
            },
            { // Prélèvement d'un bilan sanguin
              "@type": "card",
              key: "a5286942-c1c8-4810-9ec9-ba7b6ba27ccf",
              title: "Prélèvement d'un bilan sanguin",
              children: [
                ...syoTemplate.gesteBilanSanguin,
              ],
            },
            {
              "@type": "card",
              key: "f13c870f-e648-4d94-9859-35e40ae51bc5",
              title: "Devenir",
              children: [
                ...syoTemplate.devenir,
              ],
            },
          ]
        },
      ],
    },
    {
      "@type": "grid",
      key: "1fbd91e1-671d-46d2-9d11-12afd0d1f886",
      columns: 1,
      children: [
        { // Surveillances
          "@type": "card",
          key: "22fcd2af-d4ae-4e78-9826-e63aca89d3ca",
          title: "Surveillances",
          children: [
            ...syoTemplate.surveillances,
          ],
        },
      ],
    },
    // {
    //   "@type": "grid",
    //   key: "72c96937-3a3d-4407-bf32-4523ac44d0e2",
    //   columns: 1,
    //   permission: ["web:bilan:multiparam"],
    //   children: [
    //     { // Multiparamétrique
    //       "@type": "card",
    //       key: "14f6df9f-3a4b-4f3b-b881-202907a150d6",
    //       title: "Multiparamétrique",
    //       children: [
    //         ...syoTemplate.multiparam,
    //       ],
    //     },
    //   ],
    // },
  ];
}
