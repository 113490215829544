import { template as syoTemplate } from '@nfsave/syo-bilan';

export const makeSsoSmpLike = () => {
    return [
        {
            "@type": "grid",
            "key": "",
            columns: 3,
            children: [
                { // Contexte
                    "@type": "card",
                    key: "4843d864-5253-44ae-8ac2-620a2k8dbd3c",
                    title: "Contexte",
                    gridColumn: "1/3",
                    className: "cardCntnr",
                    children: [
                        {
                            "@type": "grid",
                            key: "104374a0-ac4b-4050-8jfb-e19aec828593",
                            columns: 2,
                            children: [
                                ...syoTemplate.ssoContext,
                            ],
                        },
                    ]
                },
                { // SMP
                    "@type": "card",
                    key: "4843d864-5253-44ae-8ac2-620a29cdbd3c",
                    title: "SMP",
                    gridColumn: "3/4",
                    className: "cardCntnr",
                    children: [
                        {
                            "@type": "grid",
                            key: "104374a0-ac4b-4050-82fb-e19nec828593",
                            columns: 1,
                            children: [
                                ...syoTemplate.ssoSmp,
                            ],
                        },
                    ]
                },
            ],
        },
        {
            "@type": "grid",
            "key": "16dcb74c-204f-45be-937c-6e67f42af2b8",
            columns: 5,
            children: [
                { // Personnes sur place
                    "@type": "card",
                    key: "d2721b0f-fd8b-4276-b9f3-d602e3cde828",
                    title: "Personnes sur place",
                    gridColumn: "1/3",
                    className: "cardCntnr",
                    children: [
                        {
                            "@type": "grid",
                            key: "21707595-681e-4659-9153-951b07365de0",
                            columns: 1,
                            children: [
                                ...syoTemplate.ssoContextPersonnesSurPlaces,
                            ],
                        },
                    ]
                },
                { // Contact MAD
                    "@type": "card",
                    key: "dc947b4e-140b-4e8c-af9e-6c9638d2fe2b",
                    title: "Contact MAD",
                    gridColumn: "3/4",
                    className: "cardCntnr",
                    children: [
                        {
                            "@type": "grid",
                            key: "0bb9a6ba-0fc0-4c70-a64f-671f6ba911e9",
                            columns: 1,
                            children: [
                                ...syoTemplate.ssoContextContactMads,
                            ],
                        },
                    ]
                },
                { // Actions
                    "@type": "card",
                    key: "54811f6f-4ed3-4055-bbbd-172c75c81f14",
                    title: "Actions mise en oeuvre",
                    gridColumn: "4/6",
                    className: "cardCntnr",
                    children: [
                        {
                            "@type": "grid",
                            key: "c315ef9d-fa73-4b08-a701-2a51b8b93e65",
                            columns: 1,
                            children: [
                                ...syoTemplate.ssoContextActionSyntheses,
                            ],
                        },
                    ]
                },
            ],
        },
        {
            "@type": "grid",
            "key": "203ab853-9fb1-4aeb-988f-75abf96485db",
            columns: 1,
            children: [
                { // Examens SSO
                    "@type": "card",
                    key: "9ae522d6-0c1e-470b-b0ad-b5793c3f8db1",
                    title: "Examens SSO",
                    gridColumn: "1/2",
                    className: "cardCntnr",
                    children: [
                        {
                            "@type": "grid",
                            key: "0adb74d8-b8c0-49af-a669-c177ca121d4a",
                            columns: 1,
                            children: [
                                ...syoTemplate.ssoExamens,
                            ],
                        },
                    ]
                },
            ],
        },
        {
            "@type": "grid",
            "key": "f4e57608-5be4-4840-904d-e510482d83be",
            columns: 1,
            children: [
                { // Examens SMP
                    "@type": "card",
                    key: "7d46e441-56c0-4061-8a84-fc4a42f95f6b",
                    title: "Examens SMP",
                    gridColumn: "1/2",
                    className: "cardCntnr",
                    children: [
                        {
                            "@type": "grid",
                            key: "bd74171d-4f4a-4135-9661-dba2e8152d3a",
                            columns: 1,
                            children: [
                                ...syoTemplate.smpExamens,
                            ],
                        },
                    ]
                },
            ],
        },
    ];
};

export const makesSsoSmpExamenSso = (title, bilansLength) => {
    return [
        {
            "@type": "grid",
            "key": "b4c29966-84d7-4fa9-8b7f-f690ee74ad06",
            columns: 1,
            children: [
                { // Examens SSO
                    "@type": "disclosure",
                    key: "1ddc0b62-5fde-4d8e-92f7-d31384ecb916",
                    title: title || 'Examen',
                    gridColumn: "1/2",
                    className: "cardCntnr",
                    children: [
                        {
                            "@type": "grid",
                            key: "444a1a60-7496-4fcf-a7ba-fdcee512cb20",
                            columns: 4,
                            children: [
                                ...syoTemplate.ssoExamen,
                            ],
                        },
                        { // Bilans examen SSO
                            "@type": "disclosure",
                            key: "c2aaf999-c44d-40ca-9694-c0f8abe39b74",
                            title: `Passage${bilansLength > 0 ? 's' : ''} (${bilansLength})`,
                            gridColumn: "1/2",
                            className: "cardCntnr withArrayRowCntnr",
                            children: [
                                ...syoTemplate.ssoExamenBilans,
                            ]
                        },
                    ]
                },
            ],
        },
    ];
};

export const makesSsoSmpExamenSmp = (title, bilansLength) => {
    return [
        {
            "@type": "grid",
            "key": "dc00f8fa-f3ae-4654-91bd-d3e9f801d8e8",
            columns: 1,
            children: [
                { // Examens SMP
                    "@type": "disclosure",
                    key: "3b97e722-d86b-4770-ad08-b324e62e9165",
                    title: title || 'Examen',
                    gridColumn: "1/2",
                    className: "cardCntnr",
                    children: [
                        {
                            "@type": "grid",
                            key: "4721813a-81c1-4f41-92a0-5df10bf55b9a",
                            columns: 4,
                            children: [
                                ...syoTemplate.smpExamen,
                            ],
                        },
                        { // Bilans examen SMP
                            "@type": "disclosure",
                            key: "ffc302c5-6d33-4841-a086-e1eb42c818b1",
                            title: `Passage${bilansLength > 0 ? 's' : ''} (${bilansLength})`,
                            gridColumn: "1/2",
                            className: "cardCntnr withArrayRowCntnr",
                            children: [
                                ...syoTemplate.smpExamenBilans,
                            ]
                        },
                    ]
                },
            ],
        },
    ];
};

export const makesSsoSmpExamenBilanSso = () => {
    return [

        {
            "@type": "grid",
            key: "104374a0-ac4b-4050-82fb-e19ahc828593",
            columns: 4,
            className: "withArrayRowCntnr",
            children: [
                ...syoTemplate.ssoExamenBilan,
            ],
        },
    ];
};

export const makesSsoSmpExamenBilanSmp = () => {
    return [

        {
            "@type": "grid",
            key: "9e6b6719-8859-4d1f-9f49-e491da239db4",
            columns: 4,
            className: "withArrayRowCntnr",
            children: [
                ...syoTemplate.smpExamenBilan,
            ],
        },
    ];
};

export const makesSsoSmpContextPersonInPlace = () => {
    return [
        {
            "@type": "tableItem",
            key: "195aac0d-4389-4573-8b81-4f8d58451ca5",
            children: [
                ...syoTemplate.ssoContextPersonneSurPlace,
            ],
        },
    ];
};

export const makesSsoSmpContextContactMad = () => {
    return [
        {
            "@type": "tableItem",
            key: "ae43e011-6776-4b95-854c-b19b077d7a75",
            children: [
                ...syoTemplate.ssoContextContactMad,
            ],
        },
    ];
};

export const makesSsoSmpContextActionSynthese = () => {
    return [
        {
            "@type": "tableItem",
            key: "141f62b4-d7ec-4fe5-9df3-fdc55089a581",
            children: [
                ...syoTemplate.ssoContextActionSynthese,
            ],
        },
    ];
};
