import React, { useState, useMemo, useEffect, useContext } from "react";
import { DateTime } from "luxon";
import http from "../../http";
import { useQuery } from "react-query";
import * as Sentry from "@sentry/browser";
import { toast } from "react-toastify";
import { isAbortError } from "../../utils";
import LoaderBarContext from "../../ui/useLoaderBar";

import PageHeader, { Cntnr, Title } from "../../ui/PageHeader";
import { BlockButton } from "../../ui/Button";
import Alert from "../../ui/Alert";
import LoadingSpinner from "../../ui/LoadingSpinner";

function CGU() {
  const controller = new AbortController();

  const { isLoading: userIsLoading } = useQuery(
    "user/me",
    async () => {
      return await http
      .get(`user/me`, {
        signal: controller.signal,
      })
      .json()
      .then(res => {
        setAccepted(res.terms_of_usage_approval);
      })
      .catch(error => {
        if (isAbortError(error)) return;
        console.error(error);
        Sentry.captureException(error);
        toast.warn("Une erreur est survenue lors de la récupération de l'historique de connexion");
        throw error;
      });
    },
    {cacheTime: 0}
  );

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const { setLoaderBar } = useContext(LoaderBarContext);

  const [ accepted, setAccepted ] = useState(null);
  const [ isLoading, setLoading ] = useState(false);

  const alert = useMemo(() => {
    if (accepted == null) {
      return null;
    }
    const localized = DateTime.fromISO(accepted).setLocale('fr').toLocaleString(DateTime.DATE_FULL);
    return { kind: "success", text: `CGU lues et acceptées le ${localized}` };
  }, [accepted]);

  const acceptTerms = async () => {
    setLoading(true);
    setLoaderBar(true);
    try {
      await http.post('terms_of_usage_approvals');
      setAccepted(true);
      toast.success("Votre demande d'acceptation des CGU a été traitée.");
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      toast.error("Votre demande d'acceptation des CGU n'a pas pu être traitée.");
    } finally {
      setLoading(false);
      setLoaderBar(false);
    }
  };

  return (
    <>
      <PageHeader>
        <Title>Conditions Générales d'Utilisation</Title>
      </PageHeader>

      {userIsLoading ? (
        <LoadingSpinner className="center vh-50" />
      ) : (
        <Cntnr>
          {(alert !== null && accepted !== null) ? (
            <Alert kind={alert.kind}>{alert.text}</Alert>
          ) : (
            <BlockButton onClick={acceptTerms} disabled={isLoading} style={{marginTop: '1.5rem', marginBottom: '1.5rem'}}>J'ai lu et j'accepte les CGU</BlockButton>
          )}
    
          <h2>ARTICLE 1. PRESENTATION GENERALE DU LOGICIEL</h2>
    
          <p>
            Le Logiciel propose à l'Utilisateur de réaliser et transmettre des
            bilans, via un Terminal.
          </p>
    
          <p>
            La Plateforme permet à l'utilisateur de visualiser, modifier parties du
            bilan et envoyer une nouvelle version sur le serveur et sur le Logiciel.
          </p>
    
          <p>
            Lors d'une intervention auprès d'un Patient, l'Utilisateur entre au sein
            du Logiciel l'ensemble des informations nécessaires à sa prise en charge
            par les professionnels de santé, dans le cadre de la chaîne de soin
            selon le processus suivant :
          </p>
    
          <p>
            1 - Arrivée des secours sur les lieux - Bilan effectué sur le Terminal
            grâce au Logiciel
            <br/>2 - Le chef d'agrès décide d'envoyer son bilan au SAMU grâce au
            Logiciel <br/>3 - Régulation par le SAMU sur la Plateforme. Envoie
            d'informations au Logiciel
          </p>
    
          <p>
            L'Utilisateur a accès aux services décrits sur la Plateforme, sous une
            forme et selon les fonctionnalités et moyens techniques que Syopé juge
            les plus appropriés.
          </p>
    
          <h2>ARTICLE 2. CRÉATION D'UN COMPTE UTILISATEUR </h2>
    
          <p>2.1 Processus de création d'un Compte Utilisateur</p>
    
          <p>
            Avant la date de livraison, Syopé créé le profil de l'Organisme sur la
            Plateforme et créé le premier Compte Utilisateur lié à l'Organisme.
          </p>
    
          <p>Par suite, les autres Comptes Utilisateurs sont :</p>
    
          <p>
            - Soit créés par Syopé après transmission par le responsable de
            l'Organisme d'un fichier formaté permettant la création des Comptes
            Utilisateurs ;<br/>- Soit créés par le premier Utilisateur auquel il
            est attribué le droit de créer les autres Comptes Utilisateurs
          </p>
    
          <p>
            Dans tous les cas, le responsable de l'Organisme ou le premier
            Utilisateur est seul responsable de la désignation des Utilisateurs
            autorisés à accéder à la Plateforme, de l'orthographe de leur nom, de
            leur rôle et de leurs droits d'accès à la Plateforme.{" "}
          </p>
    
          <p>2.2 Acceptation des CGU</p>
    
          <p>
            L'accès et l'utilisation du Logiciel supposent la création d'un Compte
            Utilisateur. Lors sa première connexion, l'Utilisateur doit
            préalablement lire les présentes CGU et les accepter sans réserve en
            cochant la mention « En cochant cette case, j'accepte et je reconnais
            avoir pris connaissance des Conditions Générales d'Utilisation ».{" "}
          </p>
    
          <p>
            Toute modification des CGU est portée à la connaissance de l'Utilisateur
            par email à l'adresse renseignée lors de la création de son Compte
            Utilisateur. Pour poursuivre l'utilisation du Logiciel et des autres
            services souscrits, ce dernier s'engage à accepter la version actualisée
            des CGU en cochant la mention « En cochant cette case, j'accepte et je
            reconnais avoir pris connaissance des Conditions Générales d'Utilisation
            ».
          </p>
    
          <p>2.3 Utilisation du Compte Utilisateur</p>
    
          <p>
            Chaque Compte Utilisateur est nominatif et l'Organisme doit être en
            mesure de savoir, pour les comptes génériques, quel Utilisateur a eu
            accès au Logiciel. Dans le cas de compte générique, Syopé décline toute
            responsabilité de non-traçabilité de la donnée. Si cette technique est
            malgré tout utilisée, chaque utilisateur doit signer les CGU au format
            papier apposant également la mention &ldquo;J'accepte et je reconnais
            avoir pris connaissance des Conditions Générales d'Utilisation&rdquo;
            ainsi que sa signature.
          </p>
    
          <p>
            L'Utilisateur garantit que les informations qu'il fournit sont exactes,
            à jour et complètes concernant son identité et ses coordonnées. Il
            s'engage notamment à être titulaire de l'adresse électronique
            communiquée dans le cadre de l'inscription, que celle-ci est valide et
            qu'il la consulte régulièrement. L'Utilisateur s'engage également à
            mettre à jour les informations communiquées sur son Compte Utilisateur,
            dès que l'une d'entre elles est modifiée et ce, afin de maintenir
            l'exactitude des informations.
          </p>
    
          <p>
            L'Utilisateur s'engage à ce que son Compte Utilisateur ne soit pas
            utilisé par un tiers directement ou indirectement, notamment en gardant
            les informations relatives à son identifiant et à son mot de passe
            personnelles et confidentielles.
          </p>
    
          <p>
            L'Utilisateur reconnaît que Syopé se réserve le droit de suspendre ou
            de lui retirer la possibilité d'accéder au Logiciel, dès lors qu'il ne
            respecterait pas les modalités d'inscription et particulièrement s'il
            fournit des données fausses, inexactes, incomplètes ou non mises à jour
            ou si le Compte Utilisateur est utilisé par un tiers.
          </p>
    
          <p>
            En aucun cas, la responsabilité de Syopé ne peut être engagée en cas
            d'erreur, d'omission ou d'imprécision dans les informations communiquées
            par l'Utilisateur sous sa seule responsabilité.
          </p>
    
          <h2>ARTICLE 3. ACCES A LA PLATEFORME</h2>
    
          <p>3.1 Conditions d'accès</p>
    
          <p>
            Pour accéder à Plateforme, l'Utilisateur doit disposer :<br/>
            - d'un accès internet, et <br/>- d'un Compte Utilisateur.
          </p>
    
          <p>
            L'Utilisateur est informé et accepte que l'Utilisation du Logiciel
            nécessite d'être connecté à Internet et la qualité de l'accès à la
            Plateforme dépend directement de cette connexion, dont il, ou son
            Organisme, est responsable. Les frais liés à la connexion à la
            Plateforme facturés par les fournisseurs d'accès ou opérateurs
            téléphoniques restent à la charge exclusive de l'Utilisateur ou son
            Organisme.
          </p>
    
          <p>
            Le Service Syopé est accessible sans interruption, sous réserve des
            aléas liés à la disponibilité du réseau Internet choisi par
            l'Utilisateur. L'Utilisateur est en outre informé que le Service Syopé
            peut être temporairement indisponible pour maintenance ou réparation.{" "}
          </p>
    
          <p>3.2 Limitation d'accès</p>
    
          <p>
            Chaque Utilisateur a un statut hiérarchique fonction de son rôle au sein
            de l'Organisme. Les droits de l'Utilisateur d'accéder aux différentes
            fonctions du Logiciel dépend de son statut hiérarchique.
          </p>
    
          <p>
            L'accès au Compte Utilisateur est limité à un (1) Terminal en simultané.
          </p>
    
          <p>
            Pour chaque Compte Utilisateur, l'utilisation du Logiciel est limitée à
            un (1) Terminal. Si Syopé constate une utilisation du Logiciel via des
            identifiants correspondants à plus d'un (1) Terminal, l'utilisation du
            Logiciel ou de la Plateforme sera considérée comme anormale. En cas
            d'utilisation anormale du Logiciel, Syopé se réserve le droit de
            facturer à l'unité chaque poste supplémentaire au tarif en vigueur.
          </p>
    
          <p>
            Syopé se réserve le droit, en cas de force majeure, de décision
            judiciaire ou administrative, sans préavis ni indemnité, de fermer
            temporairement l'accès au Logiciel et n'est pas responsable des dommages
            de toute nature pouvant survenir de ce fait.
          </p>
    
          <p/>
    
          <h2>ARTICLE 4. OBLIGATIONS DE L'UTILISATEUR</h2>
    
          <p>4.1 Obligations</p>
    
          <p>
            L'Utilisateur est seul responsable de l'utilisation qui est faite de son
            Compte Utilisateur. Il s'engage à informer immédiatement Syopé de toute
            utilisation qu'il n'aurait pas autorisée et de toute atteinte à la
            confidentialité ou sécurité de ses données d'identification, par e-mail
            à l'adresse électronique suivante : contact@syope.fr
          </p>
    
          <p>
            L'Utilisateur s'engage, dans son usage du Logiciel et de la Plateforme,
            à respecter les lois et règlements en vigueur et à ne pas porter
            atteinte aux droits de tiers ou à l'ordre public.
          </p>
    
          <p>
            L'Utilisateur s'engage à changer son mot de passe de manière tri
            annuelle afin réduire les risques de connexion par une tiers personne à
            son compte.{" "}
          </p>
    
          <p>
            L'Utilisateur reconnaît avoir pris connaissance des caractéristiques et
            contraintes, notamment techniques, du Logiciel. Il est seul responsable
            de son utilisation du Logiciel.
          </p>
    
          <p>
            L'Utilisateur s'engage à faire un usage strictement nominatif et
            professionnel du Logiciel et de la Plateforme. Il s'interdit en
            conséquence de céder, concéder ou transférer tout ou partie de ses
            droits ou obligations au titre des présentes à un tiers, de quelque
            manière que ce soit.
          </p>
    
          <p>
            L'Utilisateur doit prendre les mesures nécessaires pour renseigner les
            informations nécessaires (données des Patients, identité du chef
            d'agrès, etc.) sur le Logiciel et les sauvegarder par ses propres
            moyens. L'Utilisateur de la Plateforme est pleinement responsable des
            informations renseignées par le biais de son compte (Centre hospitalier
            de destination, numéro de dossier, ...).
          </p>
    
          <p>4.2 Comportements prohibés</p>
    
          <p>
            Il est strictement interdit d'utiliser le Logiciel aux fins suivantes :{" "}
          </p>
    
          <p>
            - l'exercice d'activités illégales, frauduleuses ou portant atteinte aux
            droits ou à la sécurité des tiers,
            <br/>
            - l'atteinte à l'ordre public ou la violation des lois et règlements en
            vigueur,
            <br/>
            - l'intrusion dans le système informatique d'un tiers ou toute activité
            de nature à nuire, contrôler, interférer, ou intercepter tout ou partie
            du système informatique d'un tiers, en violer l'intégrité ou la
            sécurité,
            <br/>
            - l'aide ou l'incitation, sous quelque forme et de quelque manière que
            ce soit, à un ou plusieurs des actes et activités décrits ci-dessus,
            <br/>- et plus généralement toute pratique détournant le Logiciel à des
            fins autres que celles pour lesquelles ils ont été conçus.
          </p>
    
          <p>
            Sont également strictement interdits : (i) tous comportements de nature
            à interrompre, suspendre, ralentir ou empêcher le fonctionnement du
            Logiciel, (ii) toutes intrusions ou tentatives d'intrusions dans les
            systèmes de Syopé, (iii) tous détournements des ressources système du
            Logiciel, (iv) toutes actions de nature à imposer une charge
            disproportionnée sur les infrastructures de ce dernier, (v) toutes
            atteintes aux mesures de sécurité et d'authentification, (vi) tous actes
            de nature à porter atteinte aux droits et intérêts de Syopé, et enfin
            plus généralement (vii) tout manquement aux présentes conditions
            générales.
          </p>
    
          <p>4.3 Sanctions</p>
    
          <p>
            En cas de manquement à l'une quelconque des dispositions des présentes
            conditions générales ou plus généralement, d'infraction aux lois et
            règlements en vigueur par un Utilisateur, Syopé se réserve le droit de
            prendre toute mesure appropriée et notamment de :
          </p>
    
          <p>
            (i) suspendre ou résilier l'accès à la Plateforme de l'Utilisateur,
            auteur du manquement ou de l'infraction, ou y ayant participé,
            <br/>
            (ii) avertir toute autorité concernée,
            <br/>
            (iii) engager toute action judiciaire.
            <br/>
          </p>
          <h2>ARTICLE 5. SERVICE CLIENT ET SUPPORT TECHNIQUE </h2>
    
          <p>
            Pour toutes les questions liées à un problème d'utilisation du Logiciel,
            l'Utilisateur est invité à contacter Syopé à l'adresse email suivante :
            contact@syope.fr.
          </p>
    
          <h2>ARTICLE 6. DONNÉES PERSONNELLES</h2>
    
          <p>
            Dans le cadre de la fourniture du Logiciel proposé et plus généralement
            de son utilisation, Syopé collecte des données à caractère personnel de
            l'Organisme, des Utilisateurs et des Patients (ci-après les « Données
            Personnelles ») strictement nécessaires à l'exécution du Contrat telles
            que : nom, prénom, genre, mensurations, groupe sanguin, poids, taille,
            date de naissance, adresse, historique des maladies, hospitalisations,
            traitements et allergies.
          </p>
    
          <p>
            Syopé s'engage à collecter et traiter les Données Personnelles dans le
            respect de la Loi n°78-17 du 6 janvier 1978 modifiée dite « Informatique
            et Libertés », du Règlement (UE) 2016/679 du 27 avril 2016 relatif à la
            protection des personnes physiques à l'égard du traitement des données à
            caractère personnel et à la libre circulation de ces données dit « RGPD
            » (ci-après dénommés ensemble la « Règlementation »).
          </p>
    
          <p>
            Les Données Personnelles sont susceptibles d'être transmises à des
            sous-traitants domiciliées dans l'Union Européenne, notamment dans le
            cadre de leur hébergement, lorsque cela est strictement nécessaire à
            l'exécution du Contrat. En conformité avec la Règlementation, les
            Données Personnelles collectées sont chiffrées en tout ou partie et, au
            minimum, codées.
          </p>
    
          <p>
            En toute hypothèse, Syopé garantit assurer un niveau de protection
            suffisant et approprié, notamment en encadrant les transferts de ces
            Données Personnelles vers des pays ne présentant pas un niveau de
            protection adéquate, par les clauses contractuelles types validées par
            la Commission européenne ou par des règles d'entreprise contraignantes
            intragroupe, conformément à la Réglementation.{" "}
          </p>
    
          <p>
            Syopé met en œuvre des mesures techniques, organisationnelles, logiques
            et physiques en matière de sécurité afin de protéger les Données
            Personnelles de ses clients contre toute altération, perte, accès ou
            usage non autorisé et diffusion.{" "}
          </p>
    
          <p>
            Syopé conserve ces Données Personnelles pour la durée strictement
            nécessaire à la mise en œuvre de la relation commerciale et plus
            généralement des finalités précitées. Au-delà de ce délai, Syopé
            s'engage à détruire les données en sa possession, sauf stipulation
            contraire.{" "}
          </p>
    
          <p>
            Les personnes concernées par les traitements de données disposent des
            droits suivants : droit d'accès, de rectification, d'effacement,
            d'opposition, droit à la portabilité des Données Personnelles et à la
            limitation des traitements ainsi qu'au choix du sort de ces mêmes
            données après la mort. Ces droits peuvent être exercés en s'adressant à
            Syopé à l'adresse email suivante : contact@syope.fr.{" "}
          </p>
    
          <p>
            Par ailleurs, et conformément à la Réglementation, toute personne
            dispose du droit de saisir la Commission Nationale de l'Informatique et
            des Libertés (dont les coordonnées figurent à l'adresse
            https://www.cnil.fr/fr/vous-souhaitez-contacter-la-cnil) dans
            l'hypothèse où le Client n'aurait pas fait suite à une requête dans un
            délai d'un mois suivant cette demande. Ces délais peuvent néanmoins être
            ajustés en fonction de la complexité et du nombre de demandes à traiter.
          </p>
    
          <h2>ARTICLE 7. LOI APPLICABLE – JURIDICTION COMPETENTE</h2>
    
          <p>Les présentes CGU sont soumises au droit français.</p>
    
          <p>
            Toute contestation relative à l'interprétation et/ou la conclusion et/ou
            l'exécution et/ou la résiliation du contrat ou de la relation
            commerciale ou de ses suites est de la compétence exclusive du Tribunal
            de commerce de Rennes, et ce, même en cas d'appel en garantie ou de
            pluralité de défendeurs.
            <br/>
          </p>
        </Cntnr>
      )}
    </>
  );
}

export default CGU;
