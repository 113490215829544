import React, {useContext, useEffect, useMemo, useState} from "react";
import http from "../../http";
import { useQuery } from "react-query";
import * as Sentry from "@sentry/browser";
import { toast } from "react-toastify";
import { isAbortError } from "../../utils";
import LoaderBarContext from "../../ui/useLoaderBar";

import PageHeader, { Title, Cntnr } from "../../ui/PageHeader";
import Input, { InputSearchCntnr, InputSearchIcon } from "../../ui/Input";
import Button, {BlockButton} from "../../ui/Button";
import {Column, Row} from "../../ui/FlexGrid";
import styled from "styled-components";
import theme from "../../ui/theme";
import LoadingSpinner from "../../ui/LoadingSpinner";
import SearchBarCompo, { FilterBar, SearchBar } from "../../ui/SearchBar";
import SearchIcon from '@mui/icons-material/Search';
import Tag from "../../ui/Tag";

const ListItem = styled.li`
  padding: ${theme.thin} 0;
`;

const UnstyledList = styled.ul`
  padding-left: 0;
  list-style: none;

  ${ListItem} + ${ListItem} {
    border-top: 1px solid ${theme.grey5};
  }
`;

function VehicleItem ({ vehicle, vehiclesRefetch }) {
  const { loaderBarState, setLoaderBar } = useContext(LoaderBarContext);

  const handleDeleteVehicle = (vehicle) => async (ev) => {
    ev.preventDefault();
    if (loaderBarState) return;
    setLoaderBar(true);
    try {
      await http.delete(`vehicles/${encodeURIComponent(vehicle.id)}.json`);
      toast.success(`L'engin « ${vehicle.name} » a bien été supprimé.`);
      vehiclesRefetch();
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      toast.error(`Impossible de supprimer l'engin « ${vehicle.name} ».`);
    } finally {
      setLoaderBar(false);
    };
  };

  return (
    <ListItem key={vehicle.id}>
      <Row>
        <Column width={4} style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
          {vehicle.name}
        </Column>
        <Column width={1}>
          <BlockButton
            style={{marginBottom: 0}}
            className="warn"
            disabled={loaderBarState}
            onClick={handleDeleteVehicle(vehicle)}
          >Supprimer</BlockButton>
        </Column>
      </Row>
    </ListItem>
  );
};

export default function Vehicles() {
  const controller = new AbortController();

  const {
    isLoading: vehiclesIsLoading,
    data: vehicles,
    refetch: vehiclesRefetch,
  } = useQuery(
    "vehicles",
    async () => {
      return await http
      .get(`vehicles.json`, {
        signal: controller.signal,
      })
      .json()
      .then(res => {
        return res.data.sort((left, right) => {
          const lhs = (left.name || "").toUpperCase();
          const rhs = (right.name || "").toUpperCase();
          return lhs > rhs ? 1 : -1;
        });
      })
      .catch(error => {
        if (isAbortError(error)) return;
        console.error(error);
        Sentry.captureException(error);
        toast.warn("Une erreur est survenue lors de la récupération des engins");
        throw error;
      });
    },
    {cacheTime: 0},
  );

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const { loaderBarState, setLoaderBar } = useContext(LoaderBarContext);

  const [newVehicle, setNewVehicle] = useState("");
  const [ search, setSearch ] = useState("");

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    if (loaderBarState) return;
    setLoaderBar(true);
    try {
      await http.post("vehicles.json", { json: { name: newVehicle } });
      toast.success(`Engin ajouté avec succès.`);
      setNewVehicle("");
      vehiclesRefetch();
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
    } finally {
      setLoaderBar(false);
    };
  };

  const clearFilter = () => {
    setSearch("");
  };

  const vehiclesFiltered = useMemo(() => {
    return vehicles?.filter(v => v.name.toLowerCase().includes(search.toLocaleLowerCase())) || [];
  }, [ vehicles, search ]);

  return (<>
    <PageHeader>
      <Title>Engins</Title>
    </PageHeader>

    <SearchBarCompo
      hasTag={true}
      tags={<>
        {search.length > 0 && <Tag text={search} callback={clearFilter} />}
      </>}
    >
      <SearchBar>
        <FilterBar>
          <InputSearchCntnr>
            <InputSearchIcon>
              <SearchIcon />
            </InputSearchIcon>
            <Input
              type="search"
              value={search}
              onChange={(ev) => setSearch(ev.target.value)}
              placeholder="Engin"
            />
          </InputSearchCntnr>
          <Button className="warn" onClick={clearFilter}>Réinitialiser les filtres</Button>
        </FilterBar>
      </SearchBar>
    </SearchBarCompo>

    {vehiclesIsLoading ? (
      <LoadingSpinner className="center vh-50" />
    ) : (
      <Cntnr>
        <UnstyledList>
          <ListItem>
            <form onSubmit={handleSubmit}>
              <Row>
                <Column width={4}>
                  <Input value={newVehicle} onChange={ev => setNewVehicle(ev.target.value)} disabled={loaderBarState} />
                </Column>
                <Column width={1}>
                  <BlockButton type="submit" disabled={loaderBarState}>Ajouter</BlockButton>
                </Column>
              </Row>
            </form>
          </ListItem>
          {vehiclesFiltered.map(vehicle => (
            <VehicleItem key={vehicle.id} vehicle={vehicle} vehiclesRefetch={vehiclesRefetch} />
          ))}
        </UnstyledList>
      </Cntnr>
    )}
  </>);
}
