import { DateTime } from "luxon";
import {
  LAYOUT_SSO_CONTEXT_ACTION_SYNTHESE,
  LAYOUT_SSO_CONTEXT_CONTACT_MAD,
  LAYOUT_SSO_CONTEXT_PERSON_IN_PLACE,
  LAYOUT_SSO_SMP_EXAMEN_BILAN_SMP,
  LAYOUT_SSO_SMP_EXAMEN_BILAN_SSO,
  LAYOUT_SSO_SMP_EXAMEN_SMP,
  LAYOUT_SSO_SMP_EXAMEN_SSO,
} from './BilanAdaptative';

export function getBit(n, k) {
  if (n == null) {
    return false;
  };
  return (n >> k) & 1;
};

/** Checks if the object has a valid number value */
export const hasData = (o) => {
  for (const v of Object.values(o)) {
    if (typeof v === "number" && v > 0) {
      return true;
    }
  }
  return false;
};

// dec2hex :: Integer -> String
export function dec2hex(dec) {
  return ("0" + dec.toString(16)).substr(-2);
}

// generateId :: Integer -> String
export function generateId(len) {
  const arr = new Uint8Array((len || 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join("");
}

/**
 * L'utilisateur fait-il partie de l'organisation Puy du Fou ?
 * Egalement activé pour les utilisateurs de l'organisation Syopé, à des fins de débogage.
 */
export const isPdf = () => {
  const currentUser = JSON.parse(localStorage.getItem("nfCurrentUser"));
  return [1, 38].includes(currentUser.organization_id);
};

/**
* @return {string}
*/
export function ValueLabel({value, labels}) {
 const tuple = labels.find(([v]) => value === v);
 return tuple != null ? tuple[1] : "";
}

export const MEDIC_UNITS = new Map([
  [1, "mg"],
  [2, "g"],
  [3, "mL"],
  [4, "L/min"],
  [5, "Dose(s)"],
  [6, "Sachet(s)"],
  [7, "Comprimé(s)"],
  [8, "Ampoule(s)"],
  [9, "Suppositoire(s)"], // TODO: à retirer ?
  [10, "NA"],
  [11, "mg/h"],
  // [11, "Goutte(s)"],
  [12, "Bouffée(s)"],
]);

export const MEDIC_ROUTES = new Map([
  [0, "Voie NR"],
  [1, "Inhalé"],
  [2, "Intramusculaire"],
  [3, "Intranasal"],
  [4, "Intrarectal"],
  [5, "IV flash"],
  [6, "IV lente"],
  [7, "Per cutanée"],
  [8, "Per OS"],
  [9, "PSE"],
  [10, "Sous-cutané"],
  [11, "Sublinguale"],
]);

export const healthStructureServiceData = (currentUser, hopitals, healthStructureId) => {
  const DEFAULT_DEPARTMENT = 35;
  const BORDERING = new Map([
    [1, [38, 73, 74, 39, 71, 69]],
    [2, [59, 80, 60, 77, 51, 8]],
    [3, [42, 71, 58, 18, 23, 63]],
    [4, [83, 6, 5, 26, 84, 13]],
    [5, [4, 26, 38, 73]],
    [6, [83, 4, 20]],
    [7, [48, 30, 84, 26, 38, 69, 43]],
    [8, [55, 51, 2, 59]],
    [9, [31, 11, 66]],
    [10, [89, 21, 52, 51, 77]],
    [11, [9, 31, 81, 34]],
    [12, [34, 30, 48, 15, 46, 82, 81]],
    [13, [30, 84, 83, 20]],
    [14, [27, 61, 50]],
    [15, [48, 43, 63, 19, 46, 12]],
    [16, [17, 79, 86, 87, 24]],
    [17, [85, 79, 16, 33]],
    [18, [36, 41, 45, 89, 58, 3, 23]],
    [19, [23, 87, 24, 46, 15, 63]],
    [20, [13, 83, 6]],
    [21, [10, 89, 58, 71, 39, 70, 52]],
    [22, [29, 56, 35]],
    [23, [87, 19, 63, 3, 18, 36]],
    [24, [17, 16, 87, 19, 46, 47, 33]],
    [25, [39, 70, 90]],
    [26, [84, 4, 5, 38, 69, 7]],
    [27, [76, 60, 95, 78, 28, 61, 14]],
    [28, [27, 78, 91, 45, 41, 72, 61]],
    [29, [22, 56]],
    [30, [34, 12, 48, 7, 26, 84, 13]],
    [31, [65, 32, 82, 81, 11, 9]],
    [32, [31, 65, 64, 40, 47, 82]],
    [33, [17, 24, 47, 40]],
    [34, [11, 81, 12, 30]],
    [35, [50, 53, 49, 44, 56, 22]],
    [36, [37, 41, 18, 23, 87, 86]],
    [37, [41, 36, 86, 49, 72]],
    [38, [5, 73, 1, 69, 7, 26, 5]],
    [39, [1, 71, 21, 70, 25]],
    [40, [33, 47, 32, 64]],
    [41, [72, 37, 36, 18, 45, 28]],
    [42, [43, 69, 71, 3, 63]],
    [43, [48, 7, 69, 42, 63, 15]],
    [44, [56, 35, 53, 49, 85]],
    [45, [91, 77, 28, 41, 18, 58, 89]],
    [46, [15, 19, 24, 47, 82, 12]],
    [47, [33, 40, 32, 82, 46, 24]],
    [48, [12, 30, 7, 43, 15]],
    [49, [44, 53, 72, 37, 86, 79, 85]],
    [50, [14, 61, 53, 35]],
    [51, [2, 77, 10, 52, 55, 8]],
    [52, [21, 70, 88, 55, 51, 10]],
    [53, [49, 72, 61, 50, 35, 44]],
    [54, [57, 67, 88, 55]],
    [55, [54, 88, 52, 51, 8]],
    [56, [29, 22, 35, 44]],
    [57, [67, 88, 54]],
    [58, [3, 71, 21, 89, 45, 18]],
    [59, [2, 80, 62, 8]],
    [60, [2, 80, 76, 27, 95, 77]],
    [61, [14, 27, 28, 72, 53, 50]],
    [62, [80, 59, 2]],
    [63, [15, 43, 42, 3, 23, 19]],
    [64, [40, 32, 65]],
    [65, [64, 32, 31]],
    [66, [9, 11]],
    [67, [68, 88, 54, 57]],
    [68, [90, 70, 88, 67]],
    [69, [1, 71, 42, 43, 7, 26, 38]],
    [70, [21, 39, 25, 90, 88, 52]],
    [71, [3, 42, 69, 1, 39, 21, 58]],
    [72, [28, 61, 53, 49, 37, 41]],
    [73, [74, 1, 38, 5]],
    [74, [73, 1]],
    [75, [92, 93, 94]],
    [76, [80, 60, 27]],
    [77, [89, 10, 51, 2, 60, 95, 91, 45]],
    [78, [95, 27, 28, 91, 92]],
    [79, [17, 85, 49, 86, 16]],
    [80, [62, 59, 2, 60, 76]],
    [81, [11, 34, 12, 82, 31]],
    [82, [32, 31, 81, 12, 46, 47]],
    [83, [13, 84, 4, 6, 20]],
    [84, [13, 4, 26, 7, 30]],
    [85, [44, 49, 79, 17]],
    [86, [36, 37, 49, 79, 16, 87]],
    [87, [23, 19, 24, 16, 86]],
    [88, [70, 90, 68, 67, 57, 54, 55, 52]],
    [89, [58, 21, 10, 77, 45]],
    [90, [25, 70, 88, 68]],
    [91, [45, 28, 77, 78, 92, 94]],
    [92, [75, 95, 78, 93]],
    [93, [92, 95, 94]],
    [94, [77, 75, 92, 93, 91]],
    [95, [78, 27, 60, 93, 92]],
  ]);

  function shiftDepartment(department, organizationDepartment) {
    if (department === organizationDepartment || department === 0) {
      return department;
    };
    const bordering = BORDERING.get(organizationDepartment) || [];
    if (bordering.includes(department)) {
      return department + 1000;
    };
    return department + 10000;
  };

  const organizationDepartment = currentUser?.organization?.departement ?? DEFAULT_DEPARTMENT;
  const healthStructures = [];
  const healthServicesByStructure = new Map();
  for (const h of hopitals) {
    if (!healthServicesByStructure.has(h.organization.id)) {
      healthStructures.push(h.organization);
      healthServicesByStructure.set(h.organization.id, []);
    }
    healthServicesByStructure.get(h.organization.id).push(h);
  };
  healthStructures.sort((lhs, rhs) => {
    const lhsDepartment = shiftDepartment(lhs.departement, organizationDepartment);
    const rhsDepartment = shiftDepartment(rhs.departement, organizationDepartment);
    if (lhsDepartment !== rhsDepartment) {
      return lhsDepartment - rhsDepartment;
    }
    return lhs.name.localeCompare(rhs.name);
  });
  let healthServicesForCurrentStructure = [];
  if (healthStructureId != null) {
    healthServicesForCurrentStructure = (healthServicesByStructure.get(healthStructureId) || []).map(h => ({
      value: h.id,
      label: h.short_name || h.nom,
    }));
  };
  const healthStructureOptions = healthStructures.map(h => ({
    value: h.id,
    label: `[${h.departement}] ${h.name}`,
  }));

  return {
    healthStructureOptions: healthStructureOptions,
    healthServicesByStructure: healthServicesByStructure,
    healthServicesForCurrentStructure: healthServicesForCurrentStructure,
  };
};

/**
   * Adds the information contained in b to each entry of iv_fluids or medications
   * @param {Array}   a     bilan.injections.iv_fluids || bilan.injections.medications
   * @param {Object}  b     bilan.injections
   */
export const addInfoInject = (a, b) => {
  a.forEach(e => {
    const time = DateTime.fromISO(e.injected_at).toLocaleString(DateTime.TIME_24_SIMPLE).split(":");
    e.heure = time[0];
    e.minute = time[1];
    e.comment = b.comment;
    e.route_of_administration = b.route_of_administration;
    e.type = "injection";
  });
  return a;
};

/** Sorts monitors - First by hours, then by minutes */
export const sortSurv = (s) => {
  s.sort((a, b) => {
    if (a.heure < b.heure) return -1;
    if (a.heure > b.heure) return 1;

    if (a.minute < b.minute) return -1;
    if (a.minute > b.minute) return 1;

    return 0;
  });
};

/** Permet de générer un objet du bilan en vue d'une mise à jour de celui-ci */
export const generatePayloadBilan = (bilanScope, bilan, enCours, hopitalId, numeroDossier, numeroVictime) => {
  let lesionnel_V2 = {};
  if (bilan.lesionnel_avant_v2) lesionnel_V2 = {v2_lesionnel_avant: bilan.lesionnel_avant_v2};
  if (bilan.lesionnel_arriere_v2) lesionnel_V2 = {...lesionnel_V2, v2_lesionnel_arriere: bilan.lesionnel_arriere_v2};
  let ssoSmp = {};
  if (bilanScope === "SSO_SMP") {
    ssoSmp = {
      sso_intervention: {
        context: {
          ...bilan?.sso_context,
          excel_action_synthese: [ ...bilan?.sso_context?.excel_action_synthese ] || [],
          excel_contact_mad: [ ...bilan?.sso_context?.excel_contact_mad ] || [],
          excel_person_in_place: [ ...bilan?.sso_context?.excel_person_in_place ] || [],
        },
        smp: bilan?.sso_smp || {},
        sso_examens: [ ...bilan?.sso_examens ] || [],
        smp_examens: [ ...bilan?.smp_examens ] || [],
      },
    }
  }
  return {
    type: bilanScope, // "SSO_SMP" || "BILAN"
    fiche_bilan: {
      bilan: {
        en_cours: enCours,
        simplifie: bilan.simplifie,
        priorisation: bilan.priorisation,
      },
      fiche: {
        respiration: bilan.respiration,
        geste: {
          ...bilan.geste,
          medics: bilan.medicaments
        },
        circonstance: bilan.circonstance,
        victime: bilan.victime,
        circulatoire: bilan.circulatoire,
        neurologique: bilan.neurologique,
        devenir: {
          ...bilan.devenir,
          hopital_id : hopitalId,
        },
        trauma: {
          ...bilan.lesionnel,
          dummy: {
            lesionnel_avant: bilan.lesionnel_avant,
            lesionnel_arriere: bilan.lesionnel_arriere,
            ...lesionnel_V2,
          }
        },
        surveillances: bilan.surveillances,
        obstetrique: bilan.obstetrique,
        medic_info: bilan.medic_info,
        nihss: bilan.nihss || {},
        acr: bilan.acr || {},
        douleur_thoracique: bilan.douleur_thoracique || {},
      },
      equipe: bilan.equipe,
      equipage_plaintext: bilan.equipage_plaintext || {},
    },
    info_tech: {
      ...bilan.info_tech,
      conformite: numeroDossier,
      conformite_id: numeroVictime,
    },
    ...ssoSmp,
  };
};

export const getLayout = (template, elem) => {
  let tempLayout = [];
  switch (template) {
    case 'SSO_EXAMEN':
      tempLayout = LAYOUT_SSO_SMP_EXAMEN_SSO(`${elem.nom}`, elem?.bilans?.length || 0);
      break;
    case 'SMP_EXAMEN':
      tempLayout = LAYOUT_SSO_SMP_EXAMEN_SMP(`${elem.nom}`, elem?.bilans?.length || 0);
      break;
    case 'SSO_EXAMEN_BILAN':
      tempLayout = LAYOUT_SSO_SMP_EXAMEN_BILAN_SSO;
      break;
    case 'SMP_EXAMEN_BILAN':
      tempLayout = LAYOUT_SSO_SMP_EXAMEN_BILAN_SMP;
      break;
    case 'SSO_CONTEXT_PERSON_IN_PLACE':
      tempLayout = LAYOUT_SSO_CONTEXT_PERSON_IN_PLACE;
      break;
    case 'SSO_CONTEXT_CONTACT_MAD':
      tempLayout = LAYOUT_SSO_CONTEXT_CONTACT_MAD;
      break;
    case 'SSO_CONTEXT_ACTION_SYNTHESE':
      tempLayout = LAYOUT_SSO_CONTEXT_ACTION_SYNTHESE;
      break;
    default:
      tempLayout = [];
      break;
  }
  return tempLayout;
};