import React, { useRef, useEffect } from "react";
import LoadingSpinner from "./ui/LoadingSpinner";
import {DateTime} from "luxon";

export function loadingRenderer({ Component, props }) {
  if (!Component || !props) {
    const styles = {
      display: "block",
      textAlign: "center",
      paddingTop: "15%"
    };
    return (
      <div style={styles}>
        <LoadingSpinner />
      </div>
    );
  }
  return <Component {...props} />;
}

export function useInterval(delayMillis, callback) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delayMillis !== null) {
      let id = setInterval(tick, delayMillis);
      return () => clearInterval(id);
    }
  }, [delayMillis]);
}

export function triggerDownload(filename, blob) {
  return new Promise(resolve => {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, filename);
      resolve(filename);
    } else {
      const elem = window.document.createElement("a");
      elem.href = window.URL.createObjectURL(blob);
      elem.download = filename;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
      resolve(filename);
    }
  });
}

/**
 * Allows automatic disconnection after 13 hours by default.
 * Customizable duration thanks to the permission  automatic:disconnect:after:${TEMP_EN_HEURE}
 */
export function setTimeoutSession(customDisconnect) {
  let expSession = Date.now() + ((600000 * 6) * 13); // 13h
  if (customDisconnect !== undefined) {
    const customVal = customDisconnect.key.split(':').at(-1);
    const customInt = parseInt(customVal, 10);
    if (!isNaN(customInt)) expSession = Date.now() + ((600000 * 6) * customInt);
  }
  localStorage.setItem("nfCurrentExp", JSON.stringify(expSession));
}

/** Save the path on login to return to this page on logout */
export const setSignInUrl = (url = "/auth/sign-in") => {
  localStorage.setItem("nfSignOutUrl", url);
};

/**
 * Allows to check if the current user has a permission
 * @param {*} currentUser
 * @param {*} requestedPermissionKey
 */
export const hasPerm = (currentUser, requestedPermissionKey) => {
  const userPermissions = currentUser.permissions || [];
  return userPermissions.find(p => p.key === requestedPermissionKey) != null;
};

export const isFormation = () => window.location.hostname.includes("formation");

export const isAbortError = (error) => {
  return error.name === "AbortError";
};

export const fmtTime = datetime =>
  datetime == null
    ? "NR"
    : DateTime.fromISO(datetime).toLocaleString({
      hour: "2-digit",
      minute: "2-digit"
  });

export const isoIsValid = dt => {
  return DateTime.fromISO(dt).isValid;
};

export const MONTHS = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Aout",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre"
];

export const DAYS = [
  "L", "M", "M", "J", "V", "S", "D"
];

export const getProvider = (phone, provider) => {
  if (phone !== null && phone !== undefined) {
    return "SMS";
  } else if (provider === "email") {
    return "Email";
  } else if (provider !== null || provider !== undefined) {
    return "AD";
  } else {
    return "-";
  };
};

export const corpulsLiveDataUrl = (currentUser) => {
  if (hasPerm(currentUser, "integrations:corpuls:tenant:sdis-73")) {
    return (missionId) => `https://sdis73-live.eu.corpuls.cloud/live/livedata/?missionid=${encodeURIComponent(missionId)}`
  }
  return (missionId) => `https://demo.connectmedlive.fr/corpuls.web/live/livedata/?missionid=${encodeURIComponent(missionId)}`
};

/**
 * Permet de naviguer vers la cible enregistrer dans le session storage
 * @param router
 * @param defaultTarget Par défault '/interventions/active' peut être personnalisé au besoin
 */
export const goToTarget = (router, defaultTarget = '/interventions/active') => {
  const target = sessionStorage.getItem('nfTarget');
  if (target) {
    sessionStorage.removeItem('nfTarget');
    router.push(target);
  } else {
    router.push(defaultTarget);
  }
};

export const orgaWithAAD = [
  4, // SDIS 35
  47, // SDIS 38
  97, // SDIS 49
  593, // SDIS 73
  90, // SDIS 79
];