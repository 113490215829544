import React, { useEffect } from "react";
import http from "../../http";
import { useQuery } from "react-query";
import * as Sentry from "@sentry/browser";
import { toast } from "react-toastify";
import { isAbortError } from "../../utils";

import styled from "styled-components";
import theme from "../../ui/theme";
import PageHeader, {Actions, Cntnr, Title} from "../../ui/PageHeader";
import {LinkButton} from "../../ui/Button";
import LoadingSpinner from "../../ui/LoadingSpinner";

const UnstyledList = styled.ul`
  list-style: none;
  padding: 0;
`;

const ListItem = styled.li`
  border: 1px solid ${theme.grey6};
  border-radius: ${theme.borderRadius};
  padding: ${theme.thin};
  margin-bottom: ${theme.small};

  ul {
    margin-bottom: ${theme.small};
  }
`;

const ItemActions = styled.div`
  margin: 0 -${theme.thin} -${theme.thin};
  background: ${theme.grey6};
  padding: ${theme.small} ${theme.thin};
  text-align: right;
`;

export default function OrganizationalUnits() {
  const controller = new AbortController();

  const { isLoading: organizationIsLoading, data: organizationalUnits } = useQuery(
    "organizationalUnits",
    async () => {
      return await http
      .get(`organizational_units.json`, {
        signal: controller.signal,
      })
      .json()
      .then(res => {
        return res.data;
      })
      .catch(error => {
        if (isAbortError(error)) return;
        console.error(error);
        Sentry.captureException(error);
        toast.warn("Une erreur est survenue lors de la récupération des unités organisationnelles");
        throw error;
      });
    },
    {cacheTime: 0},
  );

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  return <>
    <PageHeader>
      <Title>Unités organisationnelles</Title>
      <Actions>
        <LinkButton to="/gestionnaire/unites-organisationnelles/nouvelle">Ajouter</LinkButton>
      </Actions>
    </PageHeader>

    {organizationIsLoading ? (
      <LoadingSpinner className="center vh-50" />
    ) : (
      <Cntnr>
        <UnstyledList>
          {organizationalUnits.map((unit, idx) =>
            <ListItem key={idx}>
              <h2>{unit.name} (<small>{unit.short_name}</small>)</h2>
              <ul>
                <li>0 utilisateurs</li>
                <li>0 véhicules</li>
              </ul>
              <ItemActions>
                <LinkButton className="filled" to={`/gestionnaire/unites-organisationnelles/${unit.id}/modifier`}>Modifier</LinkButton>
              </ItemActions>
            </ListItem>
          )}
        </UnstyledList>
      </Cntnr>
    )}
  </>;
}
