import { Route } from "found";
import React from "react";

import List from "./screens/List";
import Edit from "./screens/Edit";
import Create from "./screens/Create";

export const routes = (
  <Route path="utilisateurs">
    <Route path="/" Component={List} />
    <Route path="/nouveau" Component={Create} />
    <Route path="/:userId" Component={Edit} />
  </Route>
);
