import React, { useContext, useEffect } from "react";
import http from "../../http";
import { useQuery } from "react-query";
import * as Sentry from "@sentry/browser";
import { toast } from "react-toastify";
import { isAbortError } from "../../utils";
import LoaderBarContext from "../../ui/useLoaderBar";

import PageHeader, {Cntnr, Title} from "../../ui/PageHeader";
import LoadingSpinner from "../../ui/LoadingSpinner";
import RoleForm from "./RoleForm";

export default function NewRole({ router }) {
  const controller = new AbortController();

  const { setLoaderBar } = useContext(LoaderBarContext);

  const { isLoading: permIsLoading, data: permissions } = useQuery(
    "permissionsNewRole",
    async () => {
      return await http
      .get(`permissions.json`, {
        signal: controller.signal,
      })
      .json()
      .catch(error => {
        if (isAbortError(error)) return;
        console.error(error);
        Sentry.captureException(error);
        toast.warn("Une erreur est survenue lors de la récupération des permissions");
        throw error;
      });
    },
    {cacheTime: 0},
  );

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const handleSubmit = async (payload) => {
    setLoaderBar(true);
    await http.post("roles.json", {
      json: { role: payload },
      signal: controller.signal,
    }).json()
    .then(() => {
      toast.success("Le rôle a bien été créé.");
      router.push("/gestionnaire/roles");
    })
    .catch(error => {
      if (isAbortError(error)) return;
      console.error(error);
      Sentry.captureException(error);
      toast.warn("Une erreur est survenue lors de la création du rôle");
      throw error;
    })
    .finally(() => {
      setLoaderBar(false);
    });
  };

  return <>
    <PageHeader>
      <Title>Nouveau rôle utilisateur</Title>
    </PageHeader>

    {permIsLoading ? (
      <LoadingSpinner className="center vh-50" />
    ) : (
      <Cntnr>
        <RoleForm permissions={permissions} onSubmit={handleSubmit} />
      </Cntnr>
    )}
  </>;
}
