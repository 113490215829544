import { template as syoTemplate } from "@nfsave/syo-bilan";

export const makeMedicalLike = (sectionNames = ["A", "B", "C", "D", "E"]) => {
  return [
    {
      "@type": "grid",
      key: "71b31eae-9ae6-4f6e-a7e0-230e1ce0ec98",
      columns: 1,
      children: [
        { // Intervention / Équipe
          "@type": "card",
          key: "ff75b32a-eb56-4585-a296-61650731d26c",
          title: "Intervention / Équipe",
          gridColumn: "1/2",
          className: "cardCntnr",
          children: [
            {
              "@type": "grid",
              key: "fbf3076c-90b1-4bf8-b4c0-81bc90c7d8f6",
              columns: 3,
              children: [
                ...syoTemplate.ticketNumMotif,
                ...syoTemplate.equipeInfos,
              ],
            },
          ]
        },
      ],
    },{
      "@type": "grid",
      key: "b418df95-2f75-474d-861e-e28e5eecdb1a",
      columns: 4,
      children: [
        { // Synthèse
          "@type": "card",
          key: "b3b3419c-b5a1-4922-aecf-eeb032c57501",
          title: "Synthèse",
          gridColumn: "1/2",
          children: [
            ...syoTemplate.circonstanciel,
            ...syoTemplate.anamnese,
            ...syoTemplate.examen,
            ...syoTemplate.hypotheseDiag,
            ...syoTemplate.situation,
          ],
        },
        { // Circonstances -> Contexte
          "@type": "card",
          key: "58a53eaa-07f3-4f50-a65d-87517d7ba12f",
          title: "Antécédents",
          gridColumn: "2/3",
          children: [
            ...syoTemplate.mhta,
            ...syoTemplate.lunchSampler
          ],
        },
        { // Identité
          "@type": "card",
          key: "afe8faab-2865-4beb-81fc-323e50b53b17",
          title: "Identité",
          gridColumn: "3/4",
          children: [
            ...syoTemplate.identite,
          ],
        },
        { // Contact
          "@type": "card",
          key: "6057b87f-980c-4808-a6cd-b1ec643d7f04",
          title: "Contact",
          gridColumn: "4/5",
          children: [
            ...syoTemplate.contact,
          ],
        },
      ],
    },
    {
      "@type": "grid",
      key: "3d5ef1ae-3aa2-408d-a99e-75bb86b2b6ad",
      columns: 2,
      children: [
        { // OPQRST
          "@type": "card",
          key: "8509c1f7-ab50-4ebe-840e-bb8f638badc0",
          title: "OPQRST",
          children: [
            {
              "@type": "grid",
              key: "5cb09c59-a34a-4cc2-95e7-ada27c162944",
              columns: 2,
              className: "cardGridLarge",
              children: [
                ...syoTemplate.opqrst,
                ...syoTemplate.pqrst,
              ],
            },
          ],
        },
        { // Vital
          "@type": "card",
          key: "59254c87-fd4a-4757-90a2-3fc3764cc8af",
          title: "Vital",
          children: [
            {
              "@type": "grid",
              key: "a1d54d27-0ff2-4dd9-a669-98f63fed1bd4",
              columns: 2,
              className: "cardGridLarge",
              children: [
                ...syoTemplate.obva,
                ...syoTemplate.circulatoireVital,
                ...syoTemplate.neurologiqueVital,
                ...syoTemplate.respiratoireVital,
              ],
            },
          ],
        }
      ],
    },
    {
      "@type": "grid",
      key: "b4f25b4e-5f8f-4ebc-9495-8dd98bedd16f",
      columns: 1,
      children: [
        { // ACR
          "@type": "card",
          key: "5bc9ace9-6bc8-4f7d-8b4e-7b44f8a4dee0",
          title: "ACR",
          children: [
            ...syoTemplate.acr
          ],
        }
      ],
    },
    {
      "@type": "grid",
      key: "1979e327-1d97-42a9-bb58-7cd42d811c8c",
      columns: 1,
      children: [
        { // Douleur thoracique
          "@type": "card",
          key: "5bc9ace9-6bc8-4f7d-8b4e-7b44f8a4dee0",
          title: "Douleur thoracique",
          gridColumn: 1/2,
          children: [
            {
              "@type": "grid",
              key: "b2dc067e-a25f-4a64-ae78-a3a17f5890ee",
              columns: 3,
              children: [
                ...syoTemplate.douleurThoracique
              ],
            },
          ],
        }
      ],
    },
    // {
    //   "@type": "grid",
    //   key: "45a6cad8-90e2-4f9e-a9e8-59941fddba24",
    //   columns: 1,
    //   children: [
    //     { // Mort inattendue
    //       "@type": "card",
    //       key: "5bc9ace9-6bc8-4f7d-8b4e-7b44f8a4dee0",
    //       title: "Mort inattendue",
    //       children: [
    //         ...syoTemplate.mortInattendue
    //       ],
    //     }
    //   ],
    // },
    // {
    //   "@type": "grid",
    //   key: "14286271-fa1f-4b9d-96c6-ed134722c72d",
    //   columns: 1,
    //   children: [
    //     { // Transfert pédia
    //       "@type": "card",
    //       key: "4ef00adc-1b59-4106-9b6b-592e917b3267",
    //       title: "Transfert pédiatrique",
    //       children: [
    //         ...syoTemplate.transportPedia,
    //       ],
    //     }
    //   ],
    // },
    {
      "@type": "grid",
      key: "3822e6d6-1eaa-4d34-b2d1-8004dd82af2b",
      columns: 3,
      children: [
        { // RCP
          "@type": "card",
          key: "e36c4270-2e2e-4df5-abf5-f6b7847df15a",
          title: "RCP",
          gridColumn: "1/2",
          children: [
            {
              "@type": "grid",
              key: "141f38ba-a53e-461e-b5e5-2570f9a652a2",
              columns: 2,
              className: "cardGrid",
              children: [
                ...syoTemplate.rcp,
              ]
            },
          ],
        },
        { // Témoin
          "@type": "card",
          key: "2aaa0566-0b2e-46d0-8186-12c040d53b81",
          title: "Témoin",
          gridColumn: "2/3",
          children: [
            {
              "@type": "grid",
              key: "7a390b66-b2a6-46ad-8ae3-bd1ffd2bf72b",
              columns: 2,
              className: "cardGrid",
              children: [
                ...syoTemplate.gesteTemoin,
              ],
            },
          ],
        },
        { // Secours routier
          "@type": "card",
          key: "9a048110-2895-4f41-890f-3c1cef4f07c5",
          title: "Secours routier / Accidents",
          gridColumn: "3/4",
          children: [
            {
              "@type": "grid",
              key: "7302540d-de27-43eb-8d5b-251f41487170",
              columns: 2,
              className: "cardGrid",
              children: [
                ...syoTemplate.secoursRoutier
              ],
            },
          ],
        },
      ],
    },
    {
      "@type": "grid",
      key: "0118180d-e3c2-4feb-8b18-ee0218292706",
      columns: 3,
      children: [
        { // Neurologique
          "@type": "card",
          key: "0b0d8423-3a20-4f01-a92f-7850f5447d07",
          title: "Neurologique",
          gridColumn: "1/2",
          children: [
            {
              "@type": "permission",
              key: "072f3f19-9efe-46e6-805a-fc56c0331088",
              permission: ['!web:bilan:extract-fast-out-of-neuro'],
              children: [
                {
                  "@type": "grid",
                  key: "0c22be7d-26f8-4192-88ff-76b6ee015bf5",
                  columns: 2,
                  className: "cardGrid",
                  children: [
                    ...syoTemplate.neurologique,
                  ],
                },
              ],
            },
            {
              "@type": "permission",
              key: "fb0add8c-927b-4387-ae09-10f4ece314a6",
              permission: ['web:bilan:extract-fast-out-of-neuro'],
              children: [
                {
                  "@type": "grid",
                  key: "5257ca0a-3fc6-4d07-beba-4e16139c948d",
                  columns: 2,
                  className: "cardGrid",
                  children: [
                    ...syoTemplate.neurologiqueSansFast,
                  ],
                },
              ],
            },
          ],
        },
        { // Circulatoire
          "@type": "card",
          key: "8827605e-29ba-4bfd-b2d9-2d5f05fad6ca",
          title: "Circulatoire",
          gridColumn: "2/3",
          children: [
            {
              "@type": "grid",
              key: "1d0428a7-8215-4c61-8acb-dc9c1ba7b16f",
              columns: 2,
              className: "cardGrid",
              children: [
                ...syoTemplate.circulatoireNRC,
                ...syoTemplate.circulatoire,
              ],
            },
          ],
        },
        { // Respiratoire
          "@type": "card",
          key: "d1f6bdf9-c633-4435-a8ee-b161d0db7ee9",
          title: "Respiratoire",
          gridColumn: "3/4",
          children: [
            {
              "@type": "grid",
              key: "18d43b7a-78b3-41de-8f57-219f54e00c0b",
              columns: 2,
              className: "cardGrid",
              children: [
                ...syoTemplate.respirationReguliereNRC,
                ...syoTemplate.respiration,
              ],
            },
          ],
        },
      ],
    },
    {
      "@type": "grid",
      key: "d5c139ba-6181-4038-8dee-6ba7bfd74432",
      columns: 3,
      children: [
        { // Obstétrique
          "@type": "card",
          key: "bf24f51f-aaa1-4cdc-be3c-957b033850c9",
          title: "Obstétrique",
          gridColumn: "1/2",
          children: [
            {
              "@type": "grid",
              key: "da2e37d9-5d3b-4ef0-8616-34d38ef3d78b",
              columns: 2,
              className: "cardGrid",
              children: [
                ...syoTemplate.obstetrique,
              ]
            },
          ],
        },
        { // PISU / GASSPPE
          "@type": "card",
          key: "edd01315-0740-4871-bf9c-f7453f5cbf1c",
          title: "PISU / GASSPPE",
          gridColumn: "2/3",
          children: [
            {
              "@type": "grid",
              key: "9bcbeeef-46fe-4f6f-82c9-a4bfe4eb0428",
              columns: 2,
              className: "cardGrid",
              children: [
                ...syoTemplate.pisu,
                ...syoTemplate.gassppe,
              ],
            },
          ],
        },
        { // NIHSS
          "@type": "card",
          key: "3fbd23ae-2b2f-4477-84fc-e489b1e86a26",
          title: "NIHSS",
          gridColumn: "3/4",
          children: [
            {
              "@type": "permission",
              key: "aad6739b-a5a6-44c8-9e7c-832f0ff639e0",
              permission: ["!web:bilan:extract-fast-out-of-neuro"],
              children: [
                ...syoTemplate.nihss,
              ],
            },
            {
              "@type": "permission",
              key: "11b013aa-9dab-4723-904d-48ee226dff70",
              permission: ["web:bilan:extract-fast-out-of-neuro"],
              children: [
                ...syoTemplate.nihssSansFast,
              ],
            },
          ]
        },
      ],
    },
    {
      "@type": "grid",
      key: "90cf74ee-7250-440b-ae9a-89c2bdd688a6",
      columns: 2,
      children: [
        {
          "@type": "card",
          key: "ab4a2246-d05c-4489-9c1d-16841ee1b4e3",
          gridColumn: "1/2",
          className: "cardCntnr",
          children: [
            { // FAST
              "@type": "card",
              key: "aa0929c8-3553-4d5a-b9dd-915e125c6df7",
              title: "FAST",
              children: [
                ...syoTemplate.neurologiqueFast,
                {
                  "@type": "permission",
                  key: "42fa0f6a-a7b1-4735-bc45-84fef3a16960",
                  permission: ['web:bilan:extract-fast-out-of-neuro'],
                  children: [
                    ...syoTemplate.nihssParalysieFacialeFast,
                    ...syoTemplate.nihssMotriciteMembreFast,
                    ...syoTemplate.neuroHeureAppDeficitFast,
                    ...syoTemplate.neuroTroubleParoleFast,
                  ],
                },
              ],
            },
            { // Gestes effectués
              "@type": "card",
              key: "067a8388-e607-4011-ac51-af17e995f963",
              title: "Gestes effectués",
              children: [
                {
                  "@type": "grid",
                  key: "34bb58fa-ab95-4aa4-8c5a-278dd548dd4f",
                  columns: 2,
                  className: "cardGrid",
                  children: [
                    ...syoTemplate.gesteA,
                    ...syoTemplate.gesteB,
                    ...syoTemplate.gesteC,
                    ...syoTemplate.gesteD,
                    ...syoTemplate.gesteE,
                    ...syoTemplate.gesteSmur,
                    ...syoTemplate.gesteInsufflation,
                    ...syoTemplate.gesteGuedel,
                    ...syoTemplate.gesteMaintienTete,
                    ...syoTemplate.gestePosition,
                    ...syoTemplate.gesteRetournementCollier,
                    ...syoTemplate.gesteDegagementUrg,
                    ...syoTemplate.gesteRetraitCasque,
                    ...syoTemplate.gesteKitProtection,
                    ...syoTemplate.gestePansementCompressif,
                    ...syoTemplate.gesteLiberationOBVA,
                    ...syoTemplate.gesteSmur35,
                    ...syoTemplate.gesteAutres,
                    ...syoTemplate.gesteTechnique,
                  ],
                },
              ],
            },
            { // Prélèvement d'un bilan sanguin
              "@type": "card",
              key: "622ac537-6800-44ac-97f2-97ca48fdb5d4",
              title: "Prélèvement d'un bilan sanguin",
              children: [
                ...syoTemplate.gesteBilanSanguin,
              ],
            },
            { // Devenir
              "@type": "card",
              key: "56a3e8cd-92bc-40f6-a565-aa50b6b7d845",
              title: "Devenir",
              children: [
                {
                  "@type": "grid",
                  key: "bfe6e8ea-8c5e-444f-bb8f-0b1ff71fe989",
                  columns: 2,
                  className: "cardGrid",
                  children: [
                    ...syoTemplate.devenir,
                  ],
                },
              ],
            },
          ],
        },
        {
          "@type": "card",
          key: "8e3c5b94-5d62-4274-8e4c-df6ad2f99d3b",
          gridColumn: "2/3",
          className: "cardCntnr",
          children: [
            { // Lésionnel
              "@type": "card",
              key: "c5ba7056-4087-4531-9ca2-1581de1ff72d",
              title: "Lésionnel",
              children: [
                ...syoTemplate.lesionnel,
                ...syoTemplate.lesionnelProtocol,
                ...syoTemplate.expositionLesionnel,
              ],
            },
          ],
        },
      ]
    },
    {
      "@type": "grid",
      key: "dce8ee36-fc01-4c91-9a47-48d38fa8fcd4",
      columns: 1,
      children: [
        { // Surveillances
          "@type": "card",
          key: "ff0f6baf-5277-48aa-9893-bc8e323813de",
          title: "Surveillances",
          children: [
            ...syoTemplate.surveillances,
          ],
        },
      ],
    },
  ];
};