import _get from "lodash/get";
import * as CLIP from "./layout/clip";
import {
  textifyFront as dummyFrontDescription,
  textifyBack as dummyBackDescription,
  textifyFrontHandRight,
  textifyBackHandRight,
  textifyFrontHandLeft,
  textifyBackHandLeft,
  textifyFrontFootRight,
  textifyBackFootRight,
  textifyFrontFootLeft,
  textifyBackFootLeft,
  textifyFrontHead,
} from "./Mannequin";
import { addInfoInject, hasData, sortSurv } from "./utils";
import {clone, cloneDeep, uniqBy} from "lodash";
import {
  Lesionnel,
  Spec,
  Surveillances,
  template as syoTemplate,
  utils as syoUtils,
} from "@nfsave/syo-bilan";
import {DateTime} from "luxon";

const emptyishTab = syoUtils.emptyishTab;

const processClip = (bilan, cUser) => {
  return function (d) {

    let value, type, kind;
    type = d["@type"] || d.type;
    if (d.valuePath instanceof Function) {
      value = d.valuePath.apply(null, [bilan]);
    } else {
      value = _get(bilan, d.valuePath, null);
      type = d.type;
    }

    let unit;
    if (d.unitClip instanceof Function) {
      unit = d.unitClip.apply(null, [bilan]);
    } else if (d.unit instanceof Function) {
      unit = d.unit.apply(null, [cUser]);
    } else {
      unit = d.unit;
    }

    if (d.label instanceof Function) d.label = d.label(cUser);

    if (d.contentInverted) {
      if (value === "y") {
        value = "n";
      } else if (value === "n") {
        value = "y";
      }
    }

    return {
      label: d.label,
      unit,
      value,
      kind: kind || d.kind || d.color,
      type: type,
      options: d.options,
      optionnal: d.optionnal,
      contentInverted: d.contentInverted,
    };
  };
};

function isNotEmptyish(d) {
  return !emptyishTab.includes(d.value) || (emptyishTab.includes(d.value) && d.optionnal === false);
}

function contentIsNotEmptyish(c) {
  return c.length > 0;
}

function formatValue(d, orgaId, full = false) {
  let formatted = d.value;
  if (["positive", "negative"].includes(d.kind)) {
    formatted = getNrText(orgaId);
    if (d.value === false || d.value === "n") {
      formatted = "Non";
    } else if (d.value === true || d.value === "y") {
      formatted = "Oui";
    }
  }

  if (full) {
    if (emptyishTab.includes(d.value)) formatted = getNrText(orgaId);
    return `${d.label} : ${formatted}`;
  }

  return {...d, rawValue: d.value, value: `${formatted}${d.unit || ""}`};
}

const formatV2 = (spec) => {
  switch (spec.type) {
    case "multiselect":
      return `${spec.label} : ${spec.contents.map(i => i.label).join(", ")}`;
    case "text":
      return `${spec.label} : ${spec.value}`;
    case "category":
      return `${spec.label?.toUpperCase()}`;
    case "surveillance":
      return `${spec.label}${spec.unit ? `(${spec.unit})` : ""}=${spec.value}${spec.valueUnit ? `${spec.valueUnit}` : ""}`;

    default:
      return `${spec.label} : ${spec.content}`;
  }
};
const formatFullV2 = (spec, orgaId) => {
  if (spec.emptyish) return `${spec.label} : ${getNrText(orgaId)}`;
  return formatV2(spec);
};

/**
 * Formats the value to show:
 * * Tristate to yes: only the label
 * * Other: label + value + unit
 */
function formatGestureShortV2(d) {
  switch (d.type) {
    case "tristate":
      if (d.content === "Non") {
        return `${d.label} : ${d.content}`;
      }
      return d.label;
    case "text":
      return `${d.label} : ${d.value}`;
    case "multiselect":
      return `${d.label} : ${d.contents.map(i => i.label).join(", ")}`;
    default:
      return `${d.label} : ${d.content}`;
  }
}

const ID_NFSAVE_DEMO = 98;
const ID_SAMU_72 = 529;
const ID_SAMU_50 = 563;

const neantTab = [
  ID_SAMU_50,
  ID_SAMU_72,
];

const getNrText = (orgaId) => neantTab.includes(orgaId) ? "NÉANT" : "NR";

const getLocalsExport = (bilan, numeroDossier, hopitals, hopitalId, orgaId, cUser) => {
  const locals = {};


  // INFOS BILAN
  // INFOS BILAN -> NUMÉRO DE DOSSIER
  locals.drmSerial_isNotEmpty = false;
  if (parseInt(numeroDossier, 10) === 0) {
    locals.drmSerial = getNrText(orgaId)
  } else {
    locals.drmSerial = numeroDossier;
    locals.drmSerial_isNotEmpty = true;
  }
  // INFOS BILAN -> NUMÉRO PRIMO-INTERVENANT
  locals.primo = bilan.equipe.intervention_num === "" ? getNrText(orgaId) : bilan.equipe.intervention_num;
  // INFOS BILAN -> DÉCISION DESTINATION
  const chosenDetination = hopitals.find(h => h.id === hopitalId);
  if (chosenDetination != null) {
    locals.decisionDestination = chosenDetination.nom;
  } else {
    locals.decisionDestination = getNrText(orgaId);
  }
  // INFOS BILAN -> PRIORISATION
  locals.priority = () => {
    switch (bilan.priorisation) {
      case 1:
        return "Bilan blanc";
      case 2:
        return "Bilan jaune";
      case 3:
        return "Bilan rouge";
      default:
        return "Bilan blanc";
    }
  };
  // INFOS BILAN -> ADRESSE INTERVENTION
  locals.location = bilan.equipe.adresse || "Adresse non renseignée";

  // IDENTITÉ
  locals.identite_v2 = {};
  locals.identite_v2.content = syoTemplate.identite.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i.emptyish && i.hasPerm).map(i => formatV2(i, orgaId));
  locals.identite_v2.contentIsNotEmpty = contentIsNotEmptyish(locals.identite_v2.content);
  // IDENTITÉ -> NOM COMPLET
  let fullName = [{value: bilan.victime.prenom}, {value: bilan.victime.nom}].filter(f => isNotEmptyish(f)).map(it => it.value);
  locals.identite_v2.fullName_isNotEmpty = false;
  if (fullName.length === 0) {
    locals.identite_v2.fullName = getNrText(orgaId);
  } else {
    locals.identite_v2.fullName_isNotEmpty = true;
    locals.identite_v2.fullName = fullName.join(" ");
  }
  // IDENTITÉ -> AGE
  const ageFormatted = new Spec(syoTemplate.identite.find(i => i.value.toString().includes("victime.age"))).getValue(bilan, bilan);
  locals.identite_v2.age = ageFormatted.emptyish ? getNrText(orgaId) : ageFormatted.value;
  // IDENTITÉ -> SEXE
  const sexFormatted = new Spec(syoTemplate.identite.find(i => i.value.toString().includes("victime.sexe"))).getValue(bilan, bilan);
  locals.identite_v2.sex = sexFormatted.emptyish ? getNrText(orgaId) : sexFormatted.content;
  // IDENTITÉ -> NAISSANCE
  const naissanceFormatted = new Spec(syoTemplate.identite.find(i => i.value.toString().includes("victime.date_naissance"))).getValue(bilan, bilan);
  const naissanceHeureFormatted = new Spec(syoTemplate.identite.find(i => i.value.toString().includes("victime.heure_naissance"))).getValue(bilan, bilan);
  locals.identite_v2.naissance =
    naissanceFormatted.emptyish && naissanceHeureFormatted.emptyish
      ? null
      : `${naissanceFormatted.value}${naissanceHeureFormatted.value ? ` ${naissanceHeureFormatted.content}` : ""}`;
  // IDENTITÉ -> SECU
  const secuFormatted = new Spec(syoTemplate.identite.find(i => i.value.toString().includes("victime.secu"))).getValue(bilan, bilan);
  locals.identite_v2.socialSecurityNumber = secuFormatted.emptyish ? getNrText(orgaId) : secuFormatted.value;
  // IDENTITÉ -> CAISSE
  const caisseFormatted = new Spec(syoTemplate.identite.find(i => i.value.toString().includes("victime.caisse_assurance_maladie"))).getValue(bilan, bilan);
  locals.identite_v2.caisseAssuranceMaladie = caisseFormatted.emptyish ? getNrText(orgaId) : caisseFormatted.value;
  // IDENTITÉ -> MUTUELLE
  const mutuelleFormatted = new Spec(syoTemplate.identite.find(i => i.value.toString().includes("victime.mutuelle"))).getValue(bilan, bilan);
  locals.identite_v2.caisseAssuranceMaladie = mutuelleFormatted.emptyish ? getNrText(orgaId) : mutuelleFormatted.value;


  // CONTACT
  locals.contact_v2 = {};
  locals.contact_v2.content = syoTemplate.contact.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i.emptyish && i.hasPerm).map(i => formatV2(i, orgaId));
  locals.contact_v2.contentIsNotEmpty = contentIsNotEmptyish(locals.contact_v2.content);


  // CIRCONSTANCIEL
  locals.circonstanciel_v2 = {};
  locals.circonstanciel_v2.content = syoTemplate.circonstanciel.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i.emptyish && i.hasPerm).map(i => formatV2(i, orgaId));
  locals.circonstanciel_v2.contentIsNotEmpty = contentIsNotEmptyish(locals.circonstanciel_v2.content);
  locals.circonstanciel_v2.contentFull = syoTemplate.circonstanciel.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => i.hasPerm).map(i => formatFullV2(i, orgaId));
  // CIRCONSTANCIEL -> LIEU D'INTER
  locals.circonstanciel_v2.circumstantial_lieu = () => {
    switch (bilan.circonstance.lieu) {
      case "DOMICILEPERS":
        return "Domicile personnel";
      case "NR":
        return getNrText(orgaId);
      default:
        return bilan.circonstance.lieu;
    }
  };
  // CIRCONSTANCIEL -> MOTIF D'INTER
  locals.circonstanciel_v2.circumstantial_reason = bilan.circonstance.circonstances || getNrText(orgaId);
  // CIRCONSTANCIEL -> CIRCONSTANCES
  locals.circonstanciel_v2.circumstantial_comment = bilan.circonstance.commentaire || getNrText(orgaId);


  // TICKET
  locals.ticketNumMotif_v2 = {};
  locals.ticketNumMotif_v2.content = syoTemplate.ticketNumMotif.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i.emptyish && i.hasPerm).map(i => formatV2(i, orgaId));
  locals.ticketNumMotif_v2.contentFull = syoTemplate.ticketNumMotif.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => i.hasPerm).map(i => formatFullV2(i, orgaId));
  locals.ticketNumMotif_v2.contentIsNotEmpty = contentIsNotEmptyish(locals.ticketNumMotif_v2.contentFull);


  // EQUIPE/INFOS
  locals.equipeInfos = {};
  const categorisation = new Spec(syoTemplate.equipeInfos.find(i => i.value.toString().includes("victime.categorisation"))).getValue(bilan, bilan);
  locals.equipeInfos.categorisation = categorisation.emptyish ? getNrText(orgaId) : categorisation.content;


  // SAMPLER
  locals.sampler_v2 = {};
  locals.sampler_v2.content = syoTemplate.sampler.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i.emptyish && i.hasPerm).map(i => formatV2(i, orgaId));
  locals.sampler_v2.contentIsNotEmpty = contentIsNotEmptyish(locals.sampler_v2.content);
  // SAMPLER (pqrstSampler) -> SIGNES SYMPTOMES PLAINTE
  locals.sampler_v2.sampler_s = bilan.victime.plainte || getNrText(orgaId);
  locals.sampler_v2.sampler_s_optional = bilan.victime.plainte;
  locals.sampler_v2.sampler_s_not_empty = !!bilan.victime.plainte;
  // SAMPLER (mhta) -> ALLERGIES
  locals.sampler_v2.sampler_a = bilan.victime.antecedents || getNrText(orgaId);
  locals.sampler_v2.sampler_a_optional = bilan.victime.antecedents;
  locals.sampler_v2.sampler_a_not_empty = !!bilan.victime.antecedents;
  // SAMPLER (mhta) -> MEDICAMENTS
  locals.sampler_v2.sampler_m = bilan.victime.traitements || getNrText(orgaId);
  locals.sampler_v2.sampler_m_optional = bilan.victime.traitements;
  locals.sampler_v2.sampler_m_not_empty = !!bilan.victime.traitements;
  // SAMPLER (mhta) -> PASSÉ MÉDICAL
  locals.sampler_v2.sampler_p = bilan.victime.hospitalisations || getNrText(orgaId);
  locals.sampler_v2.sampler_p_optional = bilan.victime.hospitalisations;
  locals.sampler_v2.sampler_p_not_empty = !!bilan.victime.hospitalisations;
  // SAMPLER -> BOOLEAN
  locals.sampler_v2.sampler_not_empty = !!(bilan.victime.plainte || bilan.victime.antecedents || bilan.victime.traitements || bilan.victime.hospitalisations);


  // PQRST
  locals.pqrst_v2 = {};
  locals.pqrst_v2.content = syoTemplate.pqrst.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i?.emptyish && i?.hasPerm).map(i => formatV2(i, orgaId));
  locals.pqrst_v2.contentIsNotEmpty = contentIsNotEmptyish(locals.pqrst_v2.content);


  // OPQRST
  locals.opqrst_v2 = {};
  locals.opqrst_v2.content = [...syoTemplate.pqrst, ...syoTemplate.opqrst].map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i?.emptyish && i?.hasPerm).map(i => formatV2(i, orgaId));
  locals.opqrst_v2.contentIsNotEmpty = contentIsNotEmptyish(locals.opqrst_v2.content);


  // MHTA
  locals.mhta_v2 = {};
  locals.mhta_v2.content = syoTemplate.mhta.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i?.emptyish && i?.hasPerm).map(i => formatV2(i, orgaId));
  locals.mhta_v2.contentIsNotEmpty = contentIsNotEmptyish(locals.mhta_v2.content);
  // MHTA -> RISQUES SURAJOUTÉS + ALLERGIES
  locals.mhta_antecedents = [bilan.victime.maladies, bilan.victime.antecedents].filter(isNotEmptyish).filter(s => s !== "RAS").join(", ");
  // MHTA -> MEDICAMENTS
  locals.mhta_treatments = bilan.victime.traitements;


  // AFFICHE LE MHTA OU LE SAMPLER EN FONCTION DE LA PERMISSION UTILISATEUR
  // (SDIS 38 UTILISE LE SAMPLER + OPQRST - SAMU 38 UTILISE MHTA + PQRST)
  locals.dyn_mhta_sampler_38 = {};
  if (cUser.permissions.find(p => p.key === "assessment:sampler") !== undefined) {
    locals.dyn_mhta_sampler_38.content = syoTemplate.sampler.map(i => new Spec(i).getValue(bilan, cUser)).filter(i => !i.emptyish && i.hasPerm).map(i => formatV2(i, orgaId));
  } else {
    locals.dyn_mhta_sampler_38.content = [
      ...syoTemplate.mhta,
      ...syoTemplate.signeSampler,
      ...syoTemplate.lunchSampler,
      ...syoTemplate.evenementSampler
    ].map(i => new Spec(i).getValue(bilan, cUser)).filter(i => !i.emptyish && i.hasPerm).map(i => formatV2(i, orgaId));
  }
  locals.dyn_mhta_sampler_38.contentIsNotEmpty = contentIsNotEmptyish(locals.dyn_mhta_sampler_38.content);


  // SITUATION
  locals.situation_v2 = {};
  locals.situation_v2.content = syoTemplate.situation.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i?.emptyish && i?.hasPerm).map(i => formatV2(i, orgaId));
  locals.situation_v2.contentRaw = syoTemplate.situation.map(i => new Spec(i).getValue(bilan, bilan));
  locals.situation_v2.contentIsNotEmpty = contentIsNotEmptyish(locals.situation_v2.content);


  // CIRCONSTANCES
  locals.circonstances_v2 = {};
  locals.circonstances_v2.content = syoTemplate.circonstances.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i?.emptyish && i?.hasPerm).map(i => formatV2(i, orgaId));
  locals.circonstances_v2.contentRaw = syoTemplate.circonstances.map(i => new Spec(i).getValue(bilan, bilan));
  locals.circonstances_v2.contentIsNotEmpty = contentIsNotEmptyish(locals.circonstances_v2.content);


  // CIRCONSTANCES & ANAMNÈSE
  locals.circonstances_anamnese_v2 = {};
  locals.circonstances_anamnese_v2.content = [...syoTemplate.circonstances, ...syoTemplate.anamnese].map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i?.emptyish && i?.hasPerm).map(i => formatV2(i, orgaId));
  locals.circonstances_anamnese_v2.contentIsNotEmpty = contentIsNotEmptyish(locals.circonstances_anamnese_v2.content);


  // ANAMNÈSE
  locals.anamnese_v2 = {};
  locals.anamnese_v2.content = syoTemplate.anamnese.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i?.emptyish && i?.hasPerm).map(i => formatV2(i, orgaId));
  locals.anamnese_v2.contentRaw = syoTemplate.anamnese.map(i => new Spec(i).getValue(bilan, bilan));
  locals.anamnese_v2.contentIsNotEmpty = contentIsNotEmptyish(locals.anamnese_v2.content);


  // EXAMEN
  locals.examen_v2 = {};
  locals.examen_v2.content = syoTemplate.examen.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i?.emptyish && i?.hasPerm).map(i => formatV2(i, orgaId));
  locals.examen_v2.contentRaw = syoTemplate.examen.map(i => new Spec(i).getValue(bilan, bilan));
  locals.examen_v2.contentIsNotEmpty = contentIsNotEmptyish(locals.examen_v2.content);


  // HYPOTHÈSE DIAGNOSTIQUE
  locals.hypotheseDiag_v2 = {};
  locals.hypotheseDiag_v2.content = syoTemplate.hypotheseDiag.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i?.emptyish && i?.hasPerm).map(i => formatV2(i, orgaId));
  locals.hypotheseDiag_v2.contentRaw = syoTemplate.hypotheseDiag.map(i => new Spec(i).getValue(bilan, bilan));
  locals.hypotheseDiag_v2.contentIsNotEmpty = contentIsNotEmptyish(locals.hypotheseDiag_v2.content);


  // ÉVOLUTIONS
  locals.evolutions_v2 = {};
  locals.evolutions_v2.content = syoTemplate.evolutions.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i?.emptyish && i?.hasPerm).map(i => formatV2(i, orgaId));
  locals.evolutions_v2.contentRaw = syoTemplate.evolutions.map(i => new Spec(i).getValue(bilan, bilan));
  locals.evolutions_v2.contentIsNotEmpty = contentIsNotEmptyish(locals.evolutions_v2.content);


  // ACR
  locals.acr_v2 = {};
  locals.acr_v2.array = [];
  locals.acr_v2.array_raw = [];
  const flat = (array) => {
    array.forEach(element => {
      if (Array.isArray(element.children)) {
        if (element.title) locals.acr_v2.array.push(element);
        flat(element.children);
      } else {
        locals.acr_v2.array.push(element);
        locals.acr_v2.array_raw.push(element);
      }
    });
  };
  flat(syoTemplate.acr);
  // Avec les titres de catégorie
  locals.acr_v2.content = locals.acr_v2.array.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i?.emptyish && i?.hasPerm).map(i => formatV2(i, orgaId));
  locals.acr_v2.contentDanger = locals.acr_v2.array.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i?.emptyish && i?.isInDanger && i?.hasPerm).map(i => formatV2(i, orgaId));
  // Sans les titres de catégorie
  locals.acr_v2.content_raw = locals.acr_v2.array_raw.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i?.emptyish && i?.hasPerm).map(i => formatV2(i, orgaId));
  locals.acr_v2.contentDanger_raw = locals.acr_v2.array_raw.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i?.emptyish && i?.isInDanger && i?.hasPerm).map(i => formatV2(i, orgaId));
  locals.acr_v2.contentIsNotEmpty = contentIsNotEmptyish(locals.acr_v2.content_raw);
  locals.acr_v2.contentDangerIsNotEmpty = contentIsNotEmptyish(locals.acr_v2.contentDanger_raw);

  // OBSTETRIQUE
  locals.obstetrique_v2 = {};
  locals.obstetrique_v2.content = syoTemplate.obstetrique.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i.emptyish && i.hasPerm).map(i => formatV2(i, orgaId));
  locals.obstetrique_v2.contentDanger = syoTemplate.obstetrique.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i.emptyish && i.isInDanger && i.hasPerm).map(i => formatV2(i, orgaId));
  locals.obstetrique_v2.contentIsNotEmpty = contentIsNotEmptyish(locals.obstetrique_v2.content);
  locals.obstetrique_v2.contentDangerIsNotEmpty = contentIsNotEmptyish(locals.obstetrique_v2.contentDanger);
  // ACCOUCHEMENT
  locals.accouchement_v2 = {};
  locals.accouchement_v2.content = syoTemplate.accouchement.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i.emptyish && i.hasPerm).map(i => formatV2(i, orgaId));
  locals.accouchement_v2.contentIsNotEmpty = contentIsNotEmptyish(locals.accouchement_v2.content);
  // APGAR
  locals.apgar_v2 = {};
  locals.apgar_v2.content = syoTemplate.apgar.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i.emptyish && i.hasPerm).map(i => formatV2(i, orgaId));
  locals.apgar_v2.contentIsNotEmpty = contentIsNotEmptyish(locals.apgar_v2.content);


  // SECOURS ROUTIERS / ACCIDENTS
  locals.secoursRoutier_v2 = {};
  locals.secoursRoutier_v2.content = syoTemplate.secoursRoutier.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i.emptyish && i.hasPerm).map(i => formatV2(i, orgaId));
  locals.secoursRoutier_v2.contentDanger = syoTemplate.secoursRoutier.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i.emptyish && i.isInDanger && i.hasPerm).map(i => formatV2(i, orgaId));
  locals.secoursRoutier_v2.contentIsNotEmpty = contentIsNotEmptyish(locals.secoursRoutier_v2.content);


  // DOULEUR THORACIQUE
  locals.douleurThoracique_v2 = {};
  locals.douleurThoracique_v2.content = syoTemplate.douleurThoracique.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i?.emptyish && i?.hasPerm).map(i => formatV2(i, orgaId));
  locals.douleurThoracique_v2.contentDanger = syoTemplate.douleurThoracique.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i?.emptyish && i?.isInDanger && i?.hasPerm).map(i => formatV2(i, orgaId));
  locals.douleurThoracique_v2.contentIsNotEmpty = contentIsNotEmptyish(locals.douleurThoracique_v2.content);
  locals.douleurThoracique_v2.contentDangerIsNotEmpty = contentIsNotEmptyish(locals.douleurThoracique_v2.contentDanger);


  // NIHSS
  locals.nihss_v2 = {};
  locals.nihss_v2.content = syoTemplate.nihss.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i?.emptyish && i?.hasPerm).map(i => formatV2(i, orgaId));
  locals.nihss_v2.contentDanger = syoTemplate.nihss.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i?.emptyish && i?.isInDanger && i?.hasPerm).map(i => formatV2(i, orgaId));
  locals.nihss_v2.contentIsNotEmpty = contentIsNotEmptyish(locals.nihss_v2.content);
  locals.nihss_v2.contentDangerIsNotEmpty = contentIsNotEmptyish(locals.nihss_v2.contentDanger);


  // RCP
  locals.rcp_v2 = {};
  locals.rcp_v2.content = syoTemplate.rcp.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i?.emptyish && i?.hasPerm).map(i => formatV2(i, orgaId));
  locals.rcp_v2.contentIsNotEmpty = contentIsNotEmptyish(locals.rcp_v2.content);


  // XABCDE
  // XABCDE -> X
  locals.xAbcde_v2 = {};
  locals.xAbcde_v2.contentDanger = syoTemplate.xAbcde.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i.emptyish && i.isInDanger && i.hasPerm).map(i => formatV2(i, orgaId));
  locals.xAbcde_v2.contentDangerInline = locals.xAbcde_v2.contentDanger.join(" / ");
  locals.xAbcde_v2.gestures = syoTemplate.xAbcdeGeste.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i.emptyish && i.hasPerm).map(i => formatGestureShortV2(i, orgaId));
  locals.xAbcde_v2.gesturesInline = syoTemplate.xAbcdeGeste.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i.emptyish && i.hasPerm).map(i => formatGestureShortV2(i, orgaId)).join(", ");
  locals.xAbcde_v2.contentDangerIsNotEmpty = contentIsNotEmptyish(locals.xAbcde_v2.contentDanger);
  locals.xAbcde_v2.contentDangerGesturesIsNotEmpty = contentIsNotEmptyish(locals.xAbcde_v2.contentDanger) || contentIsNotEmptyish(locals.xAbcde_v2.gestures);
  // XABCDE -> A
  locals.aAbcde_v2 = {};
  locals.aAbcde_v2.contentDanger = syoTemplate.aAbcde.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i.emptyish && i.isInDanger && i.hasPerm).map(i => formatV2(i, orgaId));
  locals.aAbcde_v2.contentDangerInline = locals.aAbcde_v2.contentDanger.join(" / ");
  locals.aAbcde_v2.gestures = syoTemplate.aAbcdeGeste.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i.emptyish && i.hasPerm).map(i => formatGestureShortV2(i, orgaId));
  locals.aAbcde_v2.gesturesInline = syoTemplate.aAbcdeGeste.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i.emptyish && i.hasPerm).map(i => formatGestureShortV2(i, orgaId)).join(", ");
  locals.aAbcde_v2.contentDangerIsNotEmpty = contentIsNotEmptyish(locals.aAbcde_v2.contentDanger);
  locals.aAbcde_v2.contentDangerGesturesIsNotEmpty = contentIsNotEmptyish(locals.aAbcde_v2.contentDanger) || contentIsNotEmptyish(locals.aAbcde_v2.gestures);
  // XABCDE -> A -> SAMU38
  locals.aAbcde_38 = {};
  const obva = bilan.respiration.obva;
  const obvaSelect_v2 = new Spec(syoTemplate.obva.find(i => i.value.toString().includes("respiration.obva_select")));
  const obvaSelectFormatted = obvaSelect_v2.getValue(bilan, bilan);
  const obvaSelectFormattedIndex = syoTemplate.obva.findIndex(i => i.key === obvaSelect_v2.key);
  locals.aAbcde_38.contentDanger = cloneDeep(syoTemplate.aAbcde);
  locals.aAbcde_38.obva =
    obvaSelectFormatted.emptyish && emptyishTab.includes(obva)
      ? getNrText(orgaId)
      : obvaSelectFormatted.emptyish && obva === "y"
        ? "Oui"
        : obvaSelectFormatted.emptyish && obva === "n"
          ? "non"
          : obvaSelectFormatted.content;
  if (locals.aAbcde_38.obva !== "Oui" && locals.aAbcde_38.obva !== "Non") locals.aAbcde_38.contentDanger.splice(obvaSelectFormattedIndex, 1);
  locals.aAbcde_38.contentDanger = locals.aAbcde_38.contentDanger.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i.emptyish && i.isInDanger && i.hasPerm).map(i => formatV2(i, orgaId));
  // XABCDE -> B
  locals.bAbcde_v2 = {};
  locals.bAbcde_v2.contentDanger = syoTemplate.bAbcde.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i.emptyish && i.isInDanger && i.hasPerm).map(i => formatV2(i, orgaId));
  locals.bAbcde_v2.contentDangerInline = locals.bAbcde_v2.contentDanger.join(" / ");
  locals.bAbcde_v2.gestures = syoTemplate.bAbcdeGeste.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i.emptyish && i.hasPerm).map(i => formatGestureShortV2(i, orgaId));
  locals.bAbcde_v2.gesturesInline = syoTemplate.bAbcdeGeste.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i.emptyish && i.hasPerm).map(i => formatGestureShortV2(i, orgaId)).join(", ");
  locals.bAbcde_v2.contentDangerIsNotEmpty = contentIsNotEmptyish(locals.bAbcde_v2.contentDanger);
  locals.bAbcde_v2.contentDangerGesturesIsNotEmpty = contentIsNotEmptyish(locals.bAbcde_v2.contentDanger) || contentIsNotEmptyish(locals.bAbcde_v2.gestures);
  locals.bAbcde_38 = {};
  locals.bAbcde_38.contentDanger = syoTemplate.bAbcde.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i.emptyish && i.isInDanger && i.hasPerm).map(i => formatV2(i, orgaId));
  const asyIndex = locals.bAbcde_38.contentDanger.findIndex(i => i === "Asymétrie thoracique : Oui");
  if (asyIndex !== -1) locals.bAbcde_38.contentDanger.splice(asyIndex, 1);
  // XABCDE -> C
  locals.cAbcde_v2 = {};
  locals.cAbcde_v2.contentDanger = syoTemplate.cAbcde.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i.emptyish && i.isInDanger && i.hasPerm).map(i => formatV2(i, orgaId));
  locals.cAbcde_v2.contentDangerInline = locals.cAbcde_v2.contentDanger.join(" / ");
  locals.cAbcde_v2.gestures = syoTemplate.cAbcdeGeste.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i.emptyish && i.hasPerm).map(i => formatGestureShortV2(i, orgaId));
  locals.cAbcde_v2.gesturesInline = syoTemplate.cAbcdeGeste.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i.emptyish && i.hasPerm).map(i => formatGestureShortV2(i, orgaId)).join(", ");
  locals.cAbcde_v2.contentDangerIsNotEmpty = contentIsNotEmptyish(locals.cAbcde_v2.contentDanger);
  locals.cAbcde_v2.contentDangerGesturesIsNotEmpty = contentIsNotEmptyish(locals.cAbcde_v2.contentDanger) || contentIsNotEmptyish(locals.cAbcde_v2.gestures);
  // XABCDE -> C -> SAMU38
  locals.cAbcde_38 = {};
  const hemoDetails_v2 = new Spec(syoTemplate.cAbcde.find(i => i.value.toString().includes("circulatoire.hemorragie_detail")));
  const hemoDetailsFormatted = hemoDetails_v2.getValue(bilan, bilan);
  const hemoDetailsFormattedIndex = syoTemplate.cAbcde.findIndex(i => i.key === hemoDetails_v2.key);
  locals.cAbcde_38.contentDanger = cloneDeep(syoTemplate.cAbcde);
  locals.cAbcde_38.hemorragie =
  hemoDetailsFormatted.emptyish && emptyishTab.includes(bilan.circulatoire.hemorragie)
    ? getNrText(orgaId)
    : hemoDetailsFormatted.emptyish && bilan.circulatoire.hemorragie === "y"
      ? "Oui"
      : hemoDetailsFormatted.emptyish && bilan.circulatoire.hemorragie === "n"
        ? "Non"
        : hemoDetailsFormatted.contents?.map(i => i.label).join(", ");
  if (locals.cAbcde_38.hemorragie !== "Oui" && locals.cAbcde_38.hemorragie !== "Non") locals.cAbcde_38.contentDanger.splice(hemoDetailsFormattedIndex, 1);
  locals.cAbcde_38.contentDanger = locals.cAbcde_38.contentDanger.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i.emptyish && i.isInDanger && i.hasPerm).map(i => formatV2(i, orgaId));
  // XABCDE -> D
  locals.dAbcde_v2 = {};
  locals.dAbcde_v2.contentDanger = syoTemplate.dAbcde.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i.emptyish && i.isInDanger && i.hasPerm).map(i => formatV2(i, orgaId));
  locals.dAbcde_v2.contentDangerInline = locals.dAbcde_v2.contentDanger.join(" / ");
  locals.dAbcde_v2.gestures = syoTemplate.dAbcdeGeste.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i.emptyish && i.hasPerm).map(i => formatGestureShortV2(i, orgaId));
  locals.dAbcde_v2.gesturesInline = syoTemplate.dAbcdeGeste.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i.emptyish && i.hasPerm).map(i => formatGestureShortV2(i, orgaId)).join(", ");
  locals.dAbcde_v2.contentDangerIsNotEmpty = contentIsNotEmptyish(locals.dAbcde_v2.contentDanger);
  locals.dAbcde_v2.contentDangerGesturesIsNotEmpty = contentIsNotEmptyish(locals.dAbcde_v2.contentDanger) || contentIsNotEmptyish(locals.dAbcde_v2.gestures);
  // XABCDE -> D -> SAMU38
  locals.dAbcde_v2.pci_38 = bilan.neurologique.pci === -1 ? getNrText(orgaId) :
    bilan.neurologique.pci > 0 ? "oui" : "non";
  // XABCDE -> D -> D SANS FAST
  locals.dAbcde_v2_sans_fast = {};
  locals.dAbcde_v2_sans_fast.contentDanger = syoTemplate.neurologiqueSansFast.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i.emptyish && i.isInDanger && i.hasPerm).map(i => formatV2(i, orgaId));
  // XABCDE -> E
  locals.eAbcde_v2 = {};
  locals.eAbcde_v2.contentDanger = syoTemplate.eAbcde.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i.emptyish && i.isInDanger && i.hasPerm).map(i => formatV2(i, orgaId));
  locals.eAbcde_v2.contentDangerInline = locals.eAbcde_v2.contentDanger.join(" / ");
  locals.eAbcde_v2.gestures = syoTemplate.eAbcdeGeste.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i.emptyish && i.hasPerm).map(i => formatGestureShortV2(i, orgaId));
  locals.eAbcde_v2.gesturesInline = syoTemplate.eAbcdeGeste.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i.emptyish && i.hasPerm).map(i => formatGestureShortV2(i, orgaId)).join(", ");
  locals.eAbcde_v2.contentDangerIsNotEmpty = contentIsNotEmptyish(locals.eAbcde_v2.contentDanger);
  locals.eAbcde_v2.contentDangerGesturesIsNotEmpty = contentIsNotEmptyish(locals.eAbcde_v2.contentDanger) || contentIsNotEmptyish(locals.eAbcde_v2.gestures);

  // FAST
  locals.fast_v2 = {};
  locals.fast_v2.contentDanger = syoTemplate.fast.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i.emptyish && i.isInDanger && i.hasPerm).map(i => formatV2(i, orgaId));


  // NRC
  // NRC -> NEUROLOGIQUE
  locals.neurologique_v2 = {};
  locals.neurologique_v2.contentDanger = syoTemplate.neurologique.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i.emptyish && i.isInDanger && i.hasPerm).map(i => formatV2(i, orgaId));
  locals.neurologique_v2.contentDangerInline = locals.neurologique_v2.contentDanger.join(" / ")
  locals.neurologique_v2.contentDangerIsNotEmpty = contentIsNotEmptyish(locals.neurologique_v2.contentDanger);
  // NRC -> RESPIRATOIRE
  locals.respiration_v2 = {};
  locals.respiration_v2.contentDanger = [...syoTemplate.respirationReguliereNRC, ...syoTemplate.respiration].map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i.emptyish && i.isInDanger && i.hasPerm).map(i => formatV2(i, orgaId));
  locals.respiration_v2.contentDangerInline = locals.respiration_v2.contentDanger.join(" / ")
  locals.respiration_v2.contentDangerIsNotEmpty = contentIsNotEmptyish(locals.respiration_v2.contentDanger);
  // NRC -> CIRCULATOIRE
  locals.circulatoire_v2 = {};
  locals.circulatoire_v2.contentDanger = [...syoTemplate.circulatoireNRC, ...syoTemplate.circulatoire].map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i.emptyish && i.isInDanger && i.hasPerm).map(i => formatV2(i, orgaId));
  locals.circulatoire_v2.contentDangerInline = locals.circulatoire_v2.contentDanger.join(" / ")
  locals.circulatoire_v2.contentDangerIsNotEmpty = contentIsNotEmptyish(locals.circulatoire_v2.contentDanger);


  // VITAL
  locals.vital_v2 = {};
  locals.vital_v2.contentDanger = [
    ...syoTemplate.obva,
    ...syoTemplate.circulatoireVital,
    ...syoTemplate.neurologiqueVital,
    ...syoTemplate.respiratoireVital
  ].map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i.emptyish && i.isInDanger && i.hasPerm).map(i => formatV2(i, orgaId));
  locals.vital_v2.contentDangerIsNotEmpty = contentIsNotEmptyish(locals.vital_v2.contentDanger);
  // VITAL -> RAS
  locals.vital_v2.vital_ras = locals.vital_v2.contentDanger.length === 0 ? " R.A.S" : "";


  // LESIONNEL
  const lesionnel = new Lesionnel(bilan);
  const version = (hasData(bilan.lesionnel_avant_v2 ?? {}) || hasData(bilan.lesionnel_arriere_v2 ?? {})) ? "v2" : "v1";
  const descFront = bilan.lesionnel.desc_avant !== "" ? bilan.lesionnel.desc_avant : null;
  const dummyFront =
    version === "v1"
    ? dummyFrontDescription(bilan.lesionnel_avant).map(([_, line]) => `\t${line}`).join('\n')
    : dummyFrontDescription(bilan.lesionnel_avant_v2).map(([_, line]) => `\t${line}`).join('\n');
  locals.dummy_front = dummyFront ? `LÉSIONNEL AVANT :\n${dummyFront}${descFront !== null ? `\n\tDescription supplémentaire : ${descFront}` : ""}` : null;
  locals.dummy_frontIsNotEmpty = !!dummyFront;

  const descBack = bilan.lesionnel.desc_arriere !== "" ? bilan.lesionnel.desc_arriere : null;
  const dummyBack =
    version === "v1"
    ? dummyBackDescription(bilan.lesionnel_arriere).map(([_, line]) => `\t${line}`).join('\n')
    : dummyBackDescription(bilan.lesionnel_arriere_v2).map(([_, line]) => `\t${line}`).join('\n');
  locals.dummy_back = dummyBack ? `LÉSIONNEL ARRIÈRE :\n${dummyBack}${descBack !== null ? `\n\tDescription supplémentaire : ${descBack}` : ""}` : null;
  locals.dummy_backIsNotEmpty = !!dummyBack;
  locals.dummy_isNotEmpty = !!(dummyBack || dummyFront);

  const descHandRight = bilan.lesionnel.desc_main_droit !== '' ? bilan.lesionnel.desc_main_droit : null;
  const dummyFrontHandRight = textifyFrontHandRight(lesionnel.lesionnelMainDroitV2).map(([_, line]) => `\t${line}`).join('\n');
  const dummyBackHandRight = textifyBackHandRight(lesionnel.lesionnelMainDroitV2).map(([_, line]) => `\t${line}`).join('\n');
  locals.dummy_hand_right = (dummyFrontHandRight || dummyBackHandRight) ? `LÉSIONNEL MAIN DROITE :\n${dummyFrontHandRight} ${dummyBackHandRight}${descHandRight ? `\n\tDescription supplémentaire : ${descHandRight}` : ""}` : null;
  locals.dummy_hand_right_isNotEmpty = !!(dummyFrontHandRight || dummyBackHandRight);

  const descHandLeft = bilan.lesionnel.desc_main_droit !== '' ? bilan.lesionnel.desc_main_droit : null;
  const dummyFrontHandLeft = textifyFrontHandLeft(lesionnel.lesionnelMainGaucheV2).map(([_, line]) => `\t${line}`).join('\n');
  const dummyBackHandLeft = textifyBackHandLeft(lesionnel.lesionnelMainGaucheV2).map(([_, line]) => `\t${line}`).join('\n');
  locals.dummy_hand_left = (dummyFrontHandLeft || dummyBackHandLeft) ? `LÉSIONNEL MAIN GAUCHE :\n${dummyFrontHandLeft} ${dummyBackHandLeft}${descHandLeft ? `\n\tDescription supplémentaire : ${descHandLeft}` : ""}` : null;
  locals.dummy_hand_left_isNotEmpty = !!(dummyFrontHandLeft || dummyBackHandLeft);

  const descFootRight = bilan.lesionnel.desc_pied_droit !== '' ? bilan.lesionnel.desc_pied_droit : null;
  const dummyFrontFootRight = textifyFrontFootRight(lesionnel.lesionnelPiedDroitV2).map(([_, line]) => `\t${line}`).join('\n');
  const dummyBackFootRight = textifyBackFootRight(lesionnel.lesionnelPiedDroitV2).map(([_, line]) => `\t${line}`).join('\n');
  locals.dummy_foot_right = (dummyFrontFootRight || dummyBackFootRight) ? `LÉSIONNEL PIED DROITE :\n${dummyFrontFootRight} ${dummyBackFootRight}${descFootRight ? `\n\tDescription supplémentaire : ${descFootRight}` : ""}` : null;
  locals.dummy_foot_right_isNotEmpty = !!(dummyFrontFootRight || dummyBackFootRight);

  const descFootLeft = bilan.lesionnel.desc_pied_droit !== '' ? bilan.lesionnel.desc_pied_droit : null;
  const dummyFrontFootLeft = textifyFrontFootLeft(lesionnel.lesionnelPiedGaucheV2).map(([_, line]) => `\t${line}`).join('\n');
  const dummyBackFootLeft = textifyBackFootLeft(lesionnel.lesionnelPiedGaucheV2).map(([_, line]) => `\t${line}`).join('\n');
  locals.dummy_foot_left = (dummyFrontFootLeft || dummyBackFootLeft) ? `LÉSIONNEL PIED GAUCHE :\n${dummyFrontFootLeft} ${dummyBackFootLeft}${descFootLeft ? `\n\tDescription supplémentaire : ${descFootLeft}` : ""}` : null;
  locals.dummy_foot_left_isNotEmpty = !!(dummyFrontFootLeft || dummyBackFootLeft);

  const descHeadFront = bilan.lesionnel.desc_tete_avant !== '' ? bilan.lesionnel.desc_tete_avant : null;
  const dummyHeadFront = textifyFrontHead(lesionnel.lesionnelAvantTeteV2).map(([_, line]) => `\t${line}`).join('\n');
  locals.dummy_head_front = dummyHeadFront ? `LÉSIONNEL TÊTE :\n${dummyHeadFront}${descHeadFront ? `\n\tDescription supplémentaire : ${descHeadFront}` : ""}` : null;
  locals.dummy_head_front_isNotEmpty = !!dummyHeadFront;

  locals.dummy_isNotEmpty = !!(dummyBack || dummyFront || dummyHeadFront || dummyFrontHandRight || dummyBackHandRight || dummyFrontHandLeft || dummyBackHandLeft || dummyFrontFootRight || dummyBackFootRight || dummyFrontFootLeft || dummyBackFootLeft);

  // SURVEILLANCE INITIALES
  locals.initial_vitals = CLIP.CLIP_INITIAL_VITALS.map(processClip(bilan, cUser)).filter(isNotEmptyish).map(f => formatValue(f, orgaId));
  locals.initial_vitals_72 = CLIP.CLIP_INITIAL_VITALS_FV_EVA.map(processClip(bilan, cUser)).filter(isNotEmptyish).map(f => formatValue(f, orgaId, true));
  locals.initial_vitals_53 = CLIP.CLIP_INITIAL_VITALS_53.map(processClip(bilan, cUser)).filter(isNotEmptyish).map(f => formatValue(f, orgaId));
  locals.initial_vitals_56 = CLIP.CLIP_INITIAL_VITALS_56.map(processClip(bilan, cUser)).filter(isNotEmptyish).map(f => formatValue(f, orgaId));
  locals.initial_vitals_02 = CLIP.CLIP_INITIAL_VITALS_02.map(processClip(bilan, cUser)).filter(isNotEmptyish).map(f => formatValue(f, orgaId));
  locals.initial_vitals_49 = CLIP.CLIP_INITIAL_VITALS_49.map(processClip(bilan, cUser)).filter(isNotEmptyish).map(f => formatValue(f, orgaId));
  locals.initial_en_temp_49 = CLIP.CLIP_INITIAL_EN_TEMP_49.map(processClip(bilan, cUser)).filter(isNotEmptyish).map(f => formatValue(f, orgaId));
  locals.initial_vitalsIsNotEmpty = contentIsNotEmptyish(locals.initial_vitals);
  locals.initial_vitals_53_isNotEmpty = contentIsNotEmptyish(locals.initial_vitals_53);
  locals.initial_vitals_49_IsNotEmpty = contentIsNotEmptyish(locals.initial_vitals_49);
  locals.initial_en_temp_49_IsNotEmpty = contentIsNotEmptyish(locals.initial_en_temp_49);
  // SURVEILLANCE INITIALES -> FR/FV
  locals.initial_vitals.fv_38 = bilan.respiration.fv === -1 ? getNrText(orgaId) : `${bilan.respiration.fv}cpm`;
  // SURVEILLANCE INITIALES -> FC
  locals.initial_vitals.fc_38 = bilan.circulatoire.fc === -1 ? getNrText(orgaId) : `${bilan.circulatoire.fc}bpm`;
  // SURVEILLANCE INITIALES -> PABD
  const pad = bilan.circulatoire.tension_droite;
  const pabd = pad === "" ? `${getNrText(orgaId)}(D)`: `${pad}${syoUtils.computePam(pad) && `(${syoUtils.computePam(pad)})`}D`;
  locals.initial_vitals.pabd_38 = pabd;
  // SURVEILLANCE INITIALES -> PABG
  const pag = bilan.circulatoire.tension_gauche;
  const pabg = pag === "" ? `${getNrText(orgaId)}(G)`: `${pag}${syoUtils.computePam(pag) && `(${syoUtils.computePam(pag)})`}G`;
  locals.initial_vitals.pabg_38 = pabg;
  // SURVEILLANCE INITIALES -> SÉVÉRITÉ
  locals.initial_vitals.en_38 = bilan.circonstance.severite === "-1" ? getNrText(orgaId) : `${bilan.circonstance.severite}/10`;
  // SURVEILLANCE INITIALES -> SAT/SpO² AMBIANT
  locals.initial_vitals.sat_38 = bilan.respiration.o2aa === -1 ? getNrText(orgaId) : `${bilan.respiration.o2aa}%`;
  // SURVEILLANCE INITIALES -> MATÉRIEL + DÉBIT O2
  locals.initial_vitals.materiel_debit_02 = [...syoTemplate.debitO2, ...syoTemplate.materielO2].map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i.emptyish && i.hasPerm).map(i => formatV2(i, orgaId));
  locals.initial_vitals.materiel_debit_02 = locals.initial_vitals.materiel_debit_02.map(i => i.split(" : ").join(":"));
  locals.initial_vitals.materiel_debit_02_isNotEmpty = locals.initial_vitals.materiel_debit_02.length > 0;
  // SURVEILLANCE INITIALES -> BLOCK EMPTY
  locals.initial_vitals_49_block_isNotEmpty = (
    locals.initial_vitals_49_IsNotEmpty
    || locals.initial_en_temp_49_IsNotEmpty
    || locals.initial_vitals.materiel_debit_02_isNotEmpty
  ) ? true : false;


  // SURVEILLANCES
  locals.monitoring = null;
  const syoSurveillances = new Surveillances(bilan);
  const survs = syoSurveillances.getSurveillances();
  const survs_with_inject = syoSurveillances.getSurveillancesInject();

  const surveillances = bilan.surveillances.filter(s => s.heure !== "-1" && s.minute !== "-1");
  const surveillances_with_medic = clone(surveillances);

  // MÉDICAMENTS
  const injections = clone(bilan.injections);
  const iv_fluids = [];
  const medications = [];

  // Extract the iv_fluids and medications to add them to the monitors
  injections.forEach(i => {
    if (i.iv_fluids.length > 0) {
      iv_fluids.push(...addInfoInject(i.iv_fluids, i));
    }

    if (i.medications.length > 0) {
      medications.push(...addInfoInject(i.medications, i));
    }
  });
  surveillances_with_medic.push(...iv_fluids, ...medications);
  const medications_only = [...iv_fluids, ...medications];

  sortSurv(surveillances);
  sortSurv(surveillances_with_medic);
  sortSurv(medications_only);

  if (surveillances.length > 0) {
    const hasDispoOxy = bilan.geste.dispositif_oxygeno !== 0;
    const isSpo2AA = bilan.geste.dispositif_oxygeno === 1;
    const hasSpo2o2 = bilan.respiration.o2o2 !== -1 && bilan.respiration.o2o2 !== null;
    locals.oldmonitoring = {
      assessments: surveillances.map(s => {
        if (s.spo2) {
          if (hasSpo2o2 || (hasDispoOxy && !isSpo2AA)) {
            s.o2o2 = s.spo2;
          } else {
            s.o2aa = s.spo2;
          }
        }
        return {
          timestamp: `${s.heure.padStart(2, "0")}:${s.minute.padStart(2, "0")}`,
          vitals: CLIP.CLIP_SURVEILLANCE_VITALS.map(processClip(s, cUser)).filter(isNotEmptyish).map(f => formatValue(f, orgaId)),
          vitals_72: CLIP.CLIP_SURVEILLANCE_VITALS_FV_EVA.map(processClip(s, cUser)).filter(isNotEmptyish).map(f => formatValue(f, orgaId)),
          vitals_53: CLIP.CLIP_SURVEILLANCE_VITALS_53.map(processClip(s, cUser)).filter(isNotEmptyish).map(f => formatValue(f, orgaId)),
          vitals_56: CLIP.CLIP_SURVEILLANCE_VITALS_56.map(processClip(s, cUser)).filter(isNotEmptyish).map(f => formatValue(f, orgaId)),
      }}),
      assessments_with_medic: surveillances_with_medic.map(s => {
        if (s.spo2) {
          if (hasSpo2o2 || (hasDispoOxy && !isSpo2AA)) {
            s.o2o2 = s.spo2;
          } else {
            s.o2aa = s.spo2;
          }
        }
        return {
          timestamp: `${s.heure.padStart(2, "0")}:${s.minute.padStart(2, "0")}`,
          vitals_with_medic: CLIP.CLIP_SURVEILLANCE_VITALS_WITH_MEDIC.map(processClip(s, cUser)).filter(isNotEmptyish).map(f => formatValue(f, orgaId)),
          vitals_72_with_medic: CLIP.CLIP_SURVEILLANCE_VITALS_FV_EVA_WITH_MEDIC.map(processClip(s, cUser)).filter(isNotEmptyish).map(f => formatValue(f, orgaId)),
          vitals_53_with_medic: CLIP.CLIP_SURVEILLANCE_VITALS_53_WITH_MEDIC.map(processClip(s, cUser)).filter(isNotEmptyish).map(f => formatValue(f, orgaId)),
          vitals_56_with_medic: CLIP.CLIP_SURVEILLANCE_VITALS_56_WITH_MEDIC.map(processClip(s, cUser)).filter(isNotEmptyish).map(f => formatValue(f, orgaId)),
      }}),
    };
  }

  if (survs.length > 0) {
    locals.monitoring = {
      assessments: survs.map(s => {
        return {
          timestamp: `${s.heure.padStart(2, "0")}:${s.minute.padStart(2, "0")}`,
          index: s.index + 1,
          vitals: CLIP.CLIP_SURVEILLANCE_VITALS.map(processClip(s, cUser)).filter(isNotEmptyish).map(f => formatValue(f, orgaId)),
          vitals_72: CLIP.CLIP_SURVEILLANCE_VITALS_FV_EVA.map(processClip(s, cUser)).filter(isNotEmptyish).map(f => formatValue(f, orgaId)),
          vitals_53: CLIP.CLIP_SURVEILLANCE_VITALS_53.map(processClip(s, cUser)).filter(isNotEmptyish).map(f => formatValue(f, orgaId)),
          vitals_56: CLIP.CLIP_SURVEILLANCE_VITALS_56.map(processClip(s, cUser)).filter(isNotEmptyish).map(f => formatValue(f, orgaId)),
          exos_37: CLIP.CLIP_SURVEILLANCE_VITALS_EXOS_37.map(processClip(s, cUser)).filter(isNotEmptyish).map(f => formatValue(f, orgaId)),
      }}),
    };
  }

  if (survs_with_inject.length > 0) {
    locals.monitoring_with_medic = {
      assessments_with_medic: survs_with_inject.map(s => {
        return {
          timestamp: `${s.heure.padStart(2, "0")}:${s.minute.padStart(2, "0")}`,
          index: s.index + 1,
          vitals_with_medic: CLIP.CLIP_SURVEILLANCE_VITALS_WITH_MEDIC.map(processClip(s, cUser)).filter(isNotEmptyish).map(f => formatValue(f, orgaId)),
          vitals_72_with_medic: CLIP.CLIP_SURVEILLANCE_VITALS_FV_EVA_WITH_MEDIC.map(processClip(s, cUser)).filter(isNotEmptyish).map(f => formatValue(f, orgaId)),
          vitals_53_with_medic: CLIP.CLIP_SURVEILLANCE_VITALS_53_WITH_MEDIC.map(processClip(s, cUser)).filter(isNotEmptyish).map(f => formatValue(f, orgaId)),
          vitals_56_with_medic: CLIP.CLIP_SURVEILLANCE_VITALS_56_WITH_MEDIC.map(processClip(s, cUser)).filter(isNotEmptyish).map(f => formatValue(f, orgaId)),
        }}),
    };
  }

  if (medications_only.length > 0) {
    locals.medications = {
      assessments: medications_only.map((m, index) => {
        return {
          timestamp: `${m.heure.padStart(2, "0")}:${m.minute.padStart(2, "0")}`,
          index: index + 1,
          medic: CLIP.CLIP_MEDICATIONS.map(processClip(m, cUser)).filter(isNotEmptyish).map(f => formatValue(f, orgaId)),
          medic_37: CLIP.CLIP_MEDICATIONS_37.map(processClip(m, cUser)).filter(isNotEmptyish).map(f => formatValue(f, orgaId)),
        };
      }),
    };
  }


  // GESTES
  const gestesAll_v2 = [
    ...syoTemplate.gesteA,
    ...syoTemplate.gesteB,
    ...syoTemplate.gesteC,
    ...syoTemplate.gesteD,
    ...syoTemplate.gesteE,
    ...syoTemplate.gesteSmur,
    ...syoTemplate.gesteInsufflation,
    ...syoTemplate.gesteGuedel,
    ...syoTemplate.gesteMaintienTete,
    ...syoTemplate.gestePosition,
    ...syoTemplate.gesteRetournementCollier,
    ...syoTemplate.gesteDegagementUrg,
    ...syoTemplate.gesteRetraitCasque,
    ...syoTemplate.gesteKitProtection,
    ...syoTemplate.gestePansementCompressif,
    ...syoTemplate.gesteLiberationOBVA,
    ...syoTemplate.gesteSmur35,
    ...syoTemplate.gesteAutres,
    ...syoTemplate.gesteTechnique,
    ...syoTemplate.gesteTemoin,
    ...syoTemplate.pisu,
    ...syoTemplate.gassppe,
  ];
  locals.gestes_v2 = {};
  locals.gestes_v2.contentAll = gestesAll_v2.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i?.emptyish && i?.hasPerm).map(i => formatGestureShortV2(i, orgaId));
  locals.gestes_v2.contentAllInline = gestesAll_v2.map(i => new Spec(i).getValue(bilan, bilan)).filter(i => !i?.emptyish && i?.hasPerm).map(i => formatGestureShortV2(i, orgaId)).join(", ");
  locals.gestes_v2.contentAllIsNotEmpty = contentIsNotEmptyish(locals.gestes_v2.contentAll);


  locals.green_dangers = CLIP.CLIP_Y_GREEN.map(processClip(bilan, cUser))
    .filter(d => d.value === false || d.value === "n")
    .map(d => `${d.label} : Non`);
  locals.green_safeties = CLIP.CLIP_Y_GREEN.map(processClip(bilan, cUser))
    .filter(d => d.value === true || d.value === "y")
    .map(d => `${d.label} : Oui`);
  locals.red_dangers = CLIP.CLIP_Y_RED.map(processClip(bilan, cUser))
    .filter(d => d.value === true || d.value === "y")
    .map(d => `${d.label} : Oui`);
  locals.red_safeties = CLIP.CLIP_Y_RED.map(processClip(bilan, cUser))
    .filter(d => d.value === false || d.value === "n")
    .map(d => `${d.label} : Non`);


  locals.infos = CLIP.CLIP_INFOS_BILAN.map(processClip(bilan, cUser)).filter(isNotEmptyish);

  return locals;
};

export default getLocalsExport;

export const getAbondonmentExport = (dayData) => {
  const ch_list = uniqBy(dayData.abandoned || [], e => e.display_name_ch?.toLowerCase()).map(e => e.display_name_ch);
  const locals = {
    date: dayData.date,
    abandoned_by_ch: [
        ...ch_list.map(name => {
          return {
            display_name_ch: name,
            abandoned_data: dayData.abandoned.filter(e => e.display_name_ch === name)
          };
        })
    ],
  };
  return locals;
};
