import React, {useContext, useEffect, useState} from "react";
import LoaderBarContext from "../ui/useLoaderBar";
import {useQuery} from "react-query";
import http from "../http";
import {isAbortError} from "../utils";
import * as Sentry from "@sentry/browser";
import {toast} from "react-toastify";
import {data as syoData} from "@nfsave/syo-bilan";
import {Disclosure} from "../ui/Disclosure";
import LoadingSpinner from "../ui/LoadingSpinner";
import {Column, Container, Row} from "../ui/FlexGrid";
import Label from "../ui/Label";
import Select from "../ui/Select";
import Input from "../ui/Input";
import {SwitchDouble} from "../ui/Switch";
import styled from "styled-components";
import theme from "../ui/theme";

const CotMed = styled.div`
  margin-top: ${theme.medium};
  margin-left: ${theme.thin};
  label {
    margin-top: ${theme.small};
  }
`;

const InlineSwitch = styled.div`
  display: flex;
  & .title {
    margin-right: ${theme.small};
  }
`;

const CotationMedicale = ({bilan, setEdit}) => {
	const controller = new AbortController();

	const { loaderBarState } = useContext(LoaderBarContext);

	const [ optionsPisuGassppe, setOptionsPisuGassppe ] = useState([]);
	const [ optionsPathologie, setOptionsPathologie ] = useState([]);

	const [ ccmu, setCcmu ] = useState(bilan.equipe.cotation_ccmu || null);
	const [ pathologie, setPahologie ] = useState(bilan.equipe.cotation_sdis38_pathologie || null);
	const [ pisuGassppe, setPisuGassppe ] = useState(bilan.equipe.cotation_sdis38_pisu || []);
	const [ indic, setIndic ] = useState(bilan.equipe.cotation_sdis38_indicateurs || []);
	const [ notComp, setNotComp ] = useState(bilan.equipe.cotation_sdis38_notation_comportement || null);
	const [ notPisu, setNotPisu ] = useState(bilan.equipe.cotation_sdis38_notation_pisu || null);
	const [ notRedac, setNotRedac ] = useState(bilan.equipe.cotation_sdis38_notation_redaction || null);
	const [ notGassppe, setNotGassppe ] = useState(bilan.equipe.cotation_sdis38_notation_gassppe || null);
	const [ checkValue, setCheckValue ] = useState(bilan.equipe.cotation_sdis38_smur_sur_place || false);
	const [ antalInit, setAntalInit ] = useState(bilan.equipe.cotation_sdis38_antalgie_evn_initial || 0);
	const [ antalFinal, setAntalFinal ] = useState(bilan.equipe.cotation_sdis38_antalgie_evn_final || 0);

	const {
		isLoading: nursingProtocolsIsLoading,
		data: nursingProtocols,
	} = useQuery(
		[ "bilan", "cotation", "pisu" ],
		async () => {
			return await http
			.get(`nursing_protocols.json`, {
				signal: controller.signal,
			})
			.json()
			.then(res => {
				return res.data;
			})
			.catch(error => {
				if (isAbortError(error)) return;
				console.error(error);
				Sentry.captureException(error);
				toast.warn("Une erreur est survenue lors de la récupération des PISU");
				throw error;
			});
		},
		{cacheTime: 0},
	);

	const {
		isLoading: paramListsIsLoading,
		data: paramLists,
	} = useQuery(
		[ "bilan", "cotation", "parameterized-lists" ],
		async () => {
			return await http
			.get(`parameterized_lists.json`, {
				signal: controller.signal,
			}).json()
			.then(res => {
				return res.data;
			})
			.catch(error => {
				if (isAbortError(error)) return;
				console.error(error);
				Sentry.captureException(error);
				toast.warn("Une erreur est survenue lors de la récupération des listes paramétrées");
				throw error;
			});
		},
		{cacheTime: 0},
	);

	const handlePisuGassppeChange = (e) => {
		if (e ==  null) {
			setEdit({path: "fiche_bilan.equipe.cotation_sdis38_pisu", value: []});
		} else {
			const v = Array.isArray(e) ? e.map(x => x.name) : [];
			setPisuGassppe(v);
			setEdit({path: "fiche_bilan.equipe.cotation_sdis38_pisu", value: v});
		}
	};

	const handleIndicChange = (e) => {
		if (e ==  null) {
			setEdit({path: syoData.COTATION_MEDICALE_INDICATEURS.path, value: []});
		} else {
			const v = Array.isArray(e) ? e.map(x => x.value) : [];
			setIndic(v);
			setEdit({path: syoData.COTATION_MEDICALE_INDICATEURS.path, value: v});
		}
	};

	const handleChange = (e, path, setter) => {
		if (e == null) {
			setter(null);
			setEdit({path: path, value: null})
		} else {
			setter(e.value);
			setEdit({path: path, value: e.value});
		}
	};

	const handleInputChange = (e, path, setter) => {
		if (e.target.value === "") {
			setter(0);
			setEdit({path: path, value: 0} || 0);
		} else {
			setter(parseInt(e.target.value, 10));
			setEdit({path: path, value: parseInt(e.target.value, 10) || 0});
		}
	};

	useEffect(() => {
		return () => {
			controller.abort();
		};
	}, []);

	useEffect(() => {
		if (!nursingProtocolsIsLoading && !paramListsIsLoading) {
			const gassppeIndex = paramLists.findIndex(i => i.name === "gassppe");
			let gassppeOptions = [];

			const pathologieIndex = paramLists.findIndex(i => i.name === "pathologies-suspectees");
			let pathologieOptions = [];

			if (gassppeIndex > -1) gassppeOptions = paramLists[gassppeIndex].items.map(i => {
				return {
					id: i.id,
					name: i.value,
				};
			});

			if (pathologieIndex > -1) {
				pathologieOptions = paramLists[pathologieIndex].items
				.map(i => {
					return {
						value: i.id,
						label: i.value,
					};
				}).sort((left, right) => {
					const lhs = (left.label || "").toLowerCase();
					const rhs = (right.label || "").toLowerCase();
					return lhs > rhs ? 1 : -1;
				});
			}

			const tempPisuGassppe = [
				...nursingProtocols,
				...gassppeOptions
			].sort((left, right) => {
				const lhs = (left.name || "").toLowerCase();
				const rhs = (right.name || "").toLowerCase();
				return lhs > rhs ? 1 : -1;
			});

			setOptionsPisuGassppe([...tempPisuGassppe]);
			setOptionsPathologie([...pathologieOptions]);
		}
	}, [ nursingProtocolsIsLoading, nursingProtocols, paramListsIsLoading, paramLists ]);

	return (
		<CotMed>
			<Disclosure
				title="Cotation médicale"
				defaultOpen={false}
				children={<>
					{nursingProtocolsIsLoading ? (
						<LoadingSpinner className="center" />
					) : (
						<Container>
							<Row>
								<Column>
									<Label>CCMU</Label>
									<Select
										isClearable
										isSearchable
										isDisabled={loaderBarState}
										options={syoData.COTATION_MEDICALE_CCMU.data}
										value={syoData.COTATION_MEDICALE_CCMU.data.find(h => h.value === ccmu) || null}
										onChange={(e) => handleChange(e, syoData.COTATION_MEDICALE_CCMU.path, setCcmu)}
										placeholder="CCMU"
										noOptionsMessage={() => "Aucune CCMU"}
										data-sentry-id="cotation-ccmu"
									/>

									<Label>PISU / GASSPPE</Label>
									<Select
										isClearable
										isSearchable
										isMulti
										isDisabled={loaderBarState || (nursingProtocolsIsLoading && paramListsIsLoading)}
										options={optionsPisuGassppe}
										getOptionLabel={option => `${option.name}`}
										getOptionValue={option => `${option.name}`}
										value={optionsPisuGassppe.filter(obj => pisuGassppe.includes(obj.name))}
										onChange={(e) => handlePisuGassppeChange(e)}
										placeholder="PISU / GASSPPE"
										noOptionsMessage={() => "Aucun PISU / GASSPPE"}
										data-sentry-id="cotation-pisu"
									/>

									<Label>Notation PISU</Label>
									<Select
										isClearable
										isSearchable
										isDisabled={loaderBarState}
										options={syoData.COTATION_MEDICALE_NOTATION_PISU.data}
										value={syoData.COTATION_MEDICALE_NOTATION_PISU.data.find(h => h.value === notPisu) || null}
										onChange={(e) => handleChange(e, syoData.COTATION_MEDICALE_NOTATION_PISU.path, setNotPisu)}
										placeholder="Notation PISU"
										noOptionsMessage={() => "Aucune notation"}
										data-sentry-id="cotation-pisu-not"
									/>

									<Label>Notation comportement</Label>
									<Select
										isClearable
										isSearchable
										isDisabled={loaderBarState}
										options={syoData.COTATION_MEDICALE_NOTATION_COMPORTEMENT.data}
										value={syoData.COTATION_MEDICALE_NOTATION_COMPORTEMENT.data.find(h => h.value === notComp) || null}
										onChange={(e) => handleChange(e, syoData.COTATION_MEDICALE_NOTATION_COMPORTEMENT.path, setNotComp)}
										placeholder="Notation comportement"
										noOptionsMessage={() => "Aucune notation"}
										data-sentry-id="cotation-comportment"
									/>

									<Label>Antalgie EVN initial</Label>
									<Input
										type="number"
										value={antalInit}
										disabled={loaderBarState}
										onChange={(e) => handleInputChange(e, "fiche_bilan.equipe.cotation_sdis38_antalgie_evn_initial", setAntalInit)}
										data-sentry-id="cotation-antal-init"
									/>

									<InlineSwitch>
										<Label className="title">SMUR sur place&thinsp;:</Label>
										<SwitchDouble
											labels={["Non", "Oui"]}
											onChange={() => {
												setEdit({path: "fiche_bilan.equipe.cotation_sdis38_smur_sur_place", value: !checkValue});
												setCheckValue(!checkValue);
											}}
											switchValue={checkValue}
											disabled={loaderBarState}
											data-sentry-id="cotation-smur"
										/>
									</InlineSwitch>
								</Column>
								<Column>
									<Label>Pathologie suspectée</Label>
									<Select
										isClearable
										isSearchable
										isDisabled={loaderBarState || (nursingProtocolsIsLoading && paramListsIsLoading)}
										options={optionsPathologie}
										value={optionsPathologie.find(h => h.value === pathologie) || null}
										onChange={(e) => handleChange(e, "fiche_bilan.equipe.cotation_sdis38_pathologie", setPahologie)}
										placeholder="Pathologie suspectée"
										noOptionsMessage={() => "Aucune pathologie suspectée"}
										data-sentry-id="cotation-path"
									/>

									<Label>Indicateurs</Label>
									<Select
										isClearable
										isSearchable
										isMulti
										isDisabled={loaderBarState}
										options={syoData.COTATION_MEDICALE_INDICATEURS.data}
										value={syoData.COTATION_MEDICALE_INDICATEURS.data.filter(obj => indic.includes(obj.value))}
										onChange={(e) => handleIndicChange(e)}
										placeholder="Indicateurs"
										noOptionsMessage={() => "Aucun indicateurs"}
										data-sentry-id="cotation-indic"
									/>

									<Label>Notation GASSPPE</Label>
									<Select
										isClearable
										isSearchable
										isDisabled={loaderBarState}
										options={syoData.COTATION_MEDICALE_NOTATION_GASSPPE.data}
										value={syoData.COTATION_MEDICALE_NOTATION_GASSPPE.data.find(h => h.value === notGassppe) || null}
										onChange={(e) => handleChange(e, syoData.COTATION_MEDICALE_NOTATION_GASSPPE.path, setNotGassppe)}
										placeholder="Notation GASSPPE"
										noOptionsMessage={() => "Aucune notation"}
										data-sentry-id="cotation-gassppe-not"
									/>
									<Label>Notation rédaction</Label>
									<Select
										isClearable
										isSearchable
										isDisabled={loaderBarState}
										options={syoData.COTATION_MEDICALE_NOTATION_REDACTION.data}
										value={syoData.COTATION_MEDICALE_NOTATION_REDACTION.data.find(h => h.value === notRedac) || null}
										onChange={(e) => handleChange(e, syoData.COTATION_MEDICALE_NOTATION_REDACTION.path, setNotRedac)}
										placeholder="Notation rédaction"
										noOptionsMessage={() => "Aucune notation"}
										data-sentry-id="cotation-react"
									/>
									<Label>Antalgie EVN final</Label>
									<Input
										type="number"
										value={antalFinal}
										disabled={loaderBarState}
										onChange={(e) => handleInputChange(e, "fiche_bilan.equipe.cotation_sdis38_antalgie_evn_final", setAntalFinal)}
										data-sentry-id="cotation-antal-final"
									/>
								</Column>
							</Row>
						</Container>
					)}
				</>}
			/>
		</CotMed>
	);
};

export default CotationMedicale;
