import React, {useContext, useEffect, useState} from "react";
import QRCode from "qrcode.react";
import { DateTime } from "luxon";
import * as Sentry from "@sentry/browser";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import { isAbortError } from "../../utils";
import {statusLabel} from "../utils";
import http from "../../http";
import LoaderBarContext from "../../ui/useLoaderBar";

import styled, {keyframes} from "styled-components";
import theme from "../../ui/theme";
import PageHeader, {Title} from "../../ui/PageHeader";
import {Column, Container, Row as RowBase} from "../../ui/FlexGrid";
import Button from "../../ui/Button";
import FormGroup from "../../ui/FormGroup";
import Label from "../../ui/Label";
import Input from "../../ui/Input";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import LoadingSpinner from "../../ui/LoadingSpinner";

const Row = styled(RowBase)`
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const UnstyledList = styled.ul`
  list-style: none;
  padding: 0;
`;

const ListItem = styled.li`
  padding: ${theme.small};
  margin-bottom: ${theme.small};
  border: 1px solid ${theme.grey5};
  border-radius: .25rem;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const ReloadIcon = styled.span`
  margin-left: ${theme.thin};
  display: inline-flex;
  align-self: center;

  animation: ${rotate} 2s linear infinite;
  color: ${theme.grey3};
`;

const QrCodeCntnr = styled.div`
  padding: ${theme.thin};
  background-color: ${theme.white};
  width: min-content;
`;

export default function List({ router, match }) {
  const controller = new AbortController();

  const { loaderBarState, setLoaderBar } = useContext(LoaderBarContext);

  const [licenceName, setLicenceName] = useState("");
  const [licenceDevice, setLicenceDevice] = useState("");

  const licenceId = match.params.licenceId;

  const {
    isLoading: licenceIsLoading,
    data: licence,
    refetch: refetchLicense,
  } = useQuery(
    ["license", licenceId],
    async () => {
      return await http
      .get(`licenses/${licenceId}.json`, {
        signal: controller.signal,
      })
      .json()
      .then(res => {
        setLicenceName(res.name);
        setLicenceDevice(res.device);
        return res;
      })
      .catch(error => {
        if (isAbortError(error)) return;
        console.error(error);
        Sentry.captureException(error);
        toast.warn("Une erreur est survenue lors de la récupération d'une licence");
        throw error;
      });
    },
    {cacheTime: 0},
  );

  const enrollments = useQuery(
    ["license", "enrollments"],
    async() => {
      const licenceId = match.params.licenceId;
      return await http
        .get(`licenses/${licenceId}/enrollments.json`, {
          signal: controller.signal,
        })
        .json()
        .then(res => {
          return res.data;
        })
        .catch(error => {
          if (isAbortError(error)) return;
          console.error(error);
          Sentry.captureException(error);
          toast.warn("Une erreur est survenue pendant le rafraichissement des approbations en attente");
          throw error;
        });
    },
    {
      refetchInterval: 2000,
      retry: true,
      cacheTime: 0,
    }
  );

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  function l(isoDatetime) {
    const dt = DateTime.fromISO(isoDatetime);
    return dt.toRelative();
  }

  const onEnrollmentApprove = (enrollment) => async (ev) => {
    ev.preventDefault();
    if (loaderBarState) return;
    try {
      setLoaderBar(true);
      await http.post(`enrollments/${enrollment.id}/approve.json`);
      router.push("/licenses");
    } catch (e) {
      Sentry.captureException(e);
      console.error(e);
      toast.error("Impossible d'associer la licence à l'appareil demandé. Si le problème persiste, contactez notre service d'assistance.");
    } finally {
      setLoaderBar(false);
    }
  }

  const handleFormSubmit = async (ev) => {
    ev.preventDefault();
    if (loaderBarState) return;
    try {
      setLoaderBar(true);
      await http.patch(`licenses/${licence.id}.json`, { json: { name: licenceName, device: licenceDevice } }).json();
      toast.success("Vos changements ont bien été sauvegardés.");
      refetchLicense();
    } catch (e) {
      Sentry.captureException(e);
      console.error(e);
      toast.error("Impossible de sauvegarder le nom de votre licence. Si le problème persiste, contactez notre service d'assistance.");
    } finally {
      setLoaderBar(false);
    };
  };

  return (<>
    <PageHeader>
      <Title>Détails de la licence {(!licenceIsLoading && licence.revoked_at !== null) && <small>Licence révoquée</small>}</Title>
    </PageHeader>
    {licenceIsLoading ? (
      <LoadingSpinner className="center vh-50" />
    ) : (
      <Container>
        <Row>
          <Column width={2}>
            <form onSubmit={handleFormSubmit}>
              <FormGroup>
                <Label>Nom</Label>
                <Input type="text" disabled={loaderBarState} value={licenceName} onChange={ev => setLicenceName(ev.target.value)} />
              </FormGroup>

              <FormGroup>
                <Label>Appareil</Label>
                <Input type="text" disabled={loaderBarState} value={licenceDevice} onChange={ev => setLicenceDevice(ev.target.value)} />
              </FormGroup>

              <Button type="submit" disabled={loaderBarState}>Sauvegarder</Button>
            </form>

            <hr/>

            <dl>
              <dt>Numéro de licence</dt>
              <dd><code>{licence.public_id}</code></dd>
              <dt>Statut</dt>
              <dd>{statusLabel(licence)}</dd>
            </dl>

            {(licence.activated_at !== null && licence.revoked_at === null) && (
              <details>
                <summary>Clé publique</summary>
                <pre><code>{licence.public_key}</code></pre>
              </details>
            )}

            {(licence.activated_at === null && licence.revoked_at === null) && (
              <>
                <h2>
                  Approbations en attente
                  {(enrollments.isLoading || enrollments.isFetching) && (
                    <ReloadIcon>
                      <AutorenewIcon />
                    </ReloadIcon>
                  )}
                </h2>
                {(!enrollments.isLoading && enrollments.isSuccess) && (
                  (enrollments.isSuccess && enrollments.data.length === 0) ? (
                    <p><em>Aucune approbation en attente.</em></p>
                  ) : (
                    <UnstyledList>
                      {enrollments.data.map(enrollment => (
                        <ListItem key={enrollment.id}>
                          <h4>{enrollment.ip}</h4>
                          <dl>
                            <dt>Date de la demande</dt>
                            <dd>{l(enrollment.created_at)}</dd>
                            <dt>Détails sur l'appareil</dt>
                            <dd>{enrollment.user_agent}</dd>
                          </dl>
                          <div>
                            <Button disabled={loaderBarState} onClick={onEnrollmentApprove(enrollment)}>Activer</Button>
                          </div>
                        </ListItem>
                      ))}
                    </UnstyledList>
                  )
                )}
              </>
            )}
          </Column>
          <Column>
            {licence.activated_at === null && (
              <>
                <h3>QR Code</h3>
                <p>Scannez ce code avec votre tablette lors de sa mise en place.</p>
                <QrCodeCntnr>
                  <QRCode value={licence.public_id || ""} />
                </QrCodeCntnr>
              </>
            )}
          </Column>
        </Row>
      </Container>
    )}
  </>);
}
