import { cloneDeep, remove } from "lodash";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import http from "../../http";
import { useMemo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import styled from "styled-components";
import theme from "../../ui/theme";
import Button from "../../ui/Button";
import LoadingSpinner from "../../ui/LoadingSpinner";
import PageHeader, { Title, Cntnr } from "../../ui/PageHeader";
import { Column, Row } from "../../ui/FlexGrid";
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import SearchBarCompo, { FilterBar, SearchBar } from "../../ui/SearchBar";
import Tag from "../../ui/Tag";
import Input, { InputSearchCntnr, InputSearchIcon } from "../../ui/Input";
import SearchIcon from '@mui/icons-material/Search';

const ListItem = styled.li`
  padding: ${theme.thin} 0;
`;

const UnstyledList = styled.ul`
  padding-left: 0;
  list-style: none;

  ${ListItem} + ${ListItem} {
    border-top: 1px solid ${theme.grey5};
  }
`;

const RelevantFactItem = ({fact}) => {
  return (
    <ListItem key={fact.id}>
      <Row>
        <Column width={4} style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
          {fact.display_name}
        </Column>
      </Row>
      {fact.data.map((item, idx) => (
        <Row key={idx}>
          <Column width={4} style={{marginLeft: theme.medium, marginTop: theme.thin}}>
            <SubdirectoryArrowRightIcon /> {item.display_name}
          </Column>
        </Row>
      ))}
    </ListItem>
  );
};

const perPage = 10;

const RelevantFacts = () => {
  const controller = new AbortController();

  const [ search, setSearch ] = useState("");
  const [ page, setPage] = useState(perPage);
  const [ hasMore, setHasMore ] = useState(false);

  const {
    isLoading: rFactsIsLoading,
    data: rFacts,
  } = useQuery(
    [ "manage", "relevant-facts" ],
    async () => {
      return await http
      .get(`relevant_facts.json`, {
        signal: controller.signal,
      }).json()
      .then(res => {
        return sortRFacts(res.data);
      })
    },
    {cacheTime: 0},
  );

  const clearFilter = () => {
    setSearch("");
  };

  const fetchMoreData = () => {
    if (factsInfinite.length >= factsFiltered.length) return setHasMore(false);
    setPage(page + perPage);
  };

  useEffect(() => {
    return controller.abort();
  }, []);

  const sortRFacts = (facts) => {
    let tempFacts = cloneDeep(facts);
    let temp = remove(tempFacts, (f) => f.is_leaf);
    tempFacts.map(fact => {
      fact.data = remove(temp, f => f.classification.split(".")[0] === fact.classification);
    });
    return tempFacts;
  };

  const factsFiltered = useMemo(() => {
    return rFacts?.filter(rF => rF.display_name.toLowerCase().includes(search.toLowerCase())) || [];
  }, [ rFacts, search ]);

  const factsInfinite = useMemo(() => {
    const temp = factsFiltered.slice(0, page);
    temp.length >= factsFiltered.length ? setHasMore(false) : setHasMore(true);
    return temp;
  }, [ page, factsFiltered ]);

  return (
    <>
      <PageHeader>
        <Title>Faits remarquables</Title>
        {/* <Actions>
          <LinkButton to="/gestionnaire/faits-remarquables/nouveau">Ajouter</LinkButton>
        </Actions> */}
      </PageHeader>

      <SearchBarCompo
        hasTag={true}
        tags={<>
          {search.length > 0 && <Tag text={search} callback={clearFilter} />}
        </>}
      >
        <SearchBar>
          <FilterBar>
            <InputSearchCntnr>
              <InputSearchIcon>
                <SearchIcon />
              </InputSearchIcon>
              <Input
                type="search"
                value={search}
                onChange={(ev) => setSearch(ev.target.value)}
                placeholder="Fait remarquable"
              />
            </InputSearchCntnr>
            <Button className="warn" onClick={clearFilter}>Réinitialiser les filtres</Button>
          </FilterBar>
        </SearchBar>
      </SearchBarCompo>

      {rFactsIsLoading ? (
        <LoadingSpinner className="center vh-50" />
      ) : (
        <InfiniteScroll
          dataLength={factsInfinite.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<div style={{height: "75px", marginTop: theme.medium}}>
            <LoadingSpinner className="center" />
          </div>}
        >
          <Cntnr>
            <UnstyledList>
            {factsInfinite.map(fact => (
              <RelevantFactItem fact={fact} key={fact.id} />
            ))}
            </UnstyledList>
          </Cntnr>
        </InfiniteScroll>
      )}
    </>
  );
};

export default RelevantFacts;