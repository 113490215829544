import React, { useContext, useEffect, useMemo, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import http from "../../http";
import { useQuery } from "react-query";
import * as Sentry from "@sentry/browser";
import { toast } from "react-toastify";
import {DateTime} from "luxon";
import { healthStructureServiceData, generateId, isPdf } from "../utils";
import { isAbortError } from "../../utils";
import LoaderBarContext from "../../ui/useLoaderBar";
import CurrentUserContext from "../../CurrentUserContext";
import { data as syoData } from "@nfsave/syo-bilan";

import styled from "styled-components";
import theme from "../../ui/theme";
import { Actions, FloatingHeader, Title } from "../../ui/PageHeader";
import Button from "../../ui/Button";
import FormGroup from "../../ui/FormGroup";
import Label from "../../ui/Label";
import Input from "../../ui/Input";
import Select from "../../ui/Select";
import Textarea from "../../ui/Textarea";
import { SwitchDouble } from "../../ui/Switch";
import { Container, Column, Row as RowCntnr } from "../../ui/FlexGrid";
import DateTimePicker from "../../ui/DateTimePicker";
import { Disclosure } from "../../ui/Disclosure";
import LoadingSpinner from "../../ui/LoadingSpinner";

const Cntnr = styled.div`
  padding: 0 ${theme.thin};
  margin-top: ${theme.small};
`;

const Row = styled(RowCntnr)`
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const DoubleSelectCntnr = styled.div`
  display: flex;
  flex-direction: row;
  & > div {
    width: 50%;
    &:first-child {
      margin-right: ${theme.thin};
    }
    &:last-child {
      margin-left: ${theme.thin};
    }
  }

  @media (max-width: 500px) {
    flex-direction: column;
    & > div {
      width: 100%;
      &:first-child {
        margin-right: 0px;
        margin-bottom: ${theme.small};
      }
      &:last-child {
        margin-left: 0px;
      }
    }
  }
`;

function Trigger() {
  const controller = new AbortController();

  const date = DateTime.now();
  const {currentUser} = useContext(CurrentUserContext);
  const { loaderBarState, setLoaderBar } = useContext(LoaderBarContext);

  const [form, setForm] = useState({
    secouristeId: null,
    numeroIntervention: "",
    pdfAdresse: "",
    sexe: 0,
    age: "",
    nom: "",
    prenom: "",
    motifDepart: null,
    circonstanciel: "",
    heureDepart: DateTime.now().toFormat("yyyy-MM-dd'T'HH:mm:ssZZZ"),
    heureFin: null,
    hopitalId: null,
    pdfCotation: 0,
    pdfSalarie: null,
  });
  const [today, setToday] = useState({
    year: date.year,
    month: date.month,
    day: date.day,
    hour: date.hour,
    minute: date.minute,
  });
  const [ifDeclenchement, setIfDeclenchement] = useState(false);

  const [healthStructureId, setHealthStructureId] = useState(() => {
    if (form.hopitalId == null) {
      return null;
    }
    const healthService = hopitals.find(h => h.id === form.hopitalId);
    if (healthService == null) {
      return null;
    }
    return healthService.organization.id;
  });

  const { isLoading: usersIsLoading, data: users} = useQuery(
    "usersTrigger",
    async () => {
      return await http
      .get(`user`, {signal: controller.signal})
      .json()
      .then(res => {
        return res.filter(user => !user.blocked);
      })
      .catch(error => {
        if (isAbortError(error)) return;
        console.error(error);
        Sentry.captureException(error);
        toast.warn("Une erreur est survenue pendant la récupération des utilisateurs.");
        throw error;
      });
    },
    {
      cacheTime: 0,
      enabled: ifDeclenchement,
    },
  );
  const { isLoading: hopitalsIsLoading, data: hopitals} = useQuery(
    "hopitalsTrigger",
    async () => {
      return await http
      .get(`hopital`, {signal: controller.signal})
      .json()
      .catch(error => {
        if (isAbortError(error)) return;
        console.error(error);
        Sentry.captureException(error);
        toast.warn("Une erreur est survenue pendant la récupération des hôpitaux.");
        throw error;
      });
    },
    {cacheTime: 0},
  );
  const { isLoading: motivesIsLoading, data: motives} = useQuery(
    "motivesTrigger",
    async () => {
      return await http
      .get(`motives.json`, {signal: controller.signal})
      .json()
      .then(res => {
        return res.data;
      })
      .catch(error => {
        if (isAbortError(error)) return;
        console.error(error);
        Sentry.captureException(error);
        toast.warn("Une erreur est survenue pendant la récupération des motifs d'interventions.");
        throw error;
      });
    },
    {cacheTime: 0},
  );

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const healthStructureService = useMemo(() => {
    return healthStructureServiceData(currentUser, hopitals || [], healthStructureId);
  }, [currentUser, hopitals, healthStructureId]);

  const handleFormChange = (field, value) => {
    if (value === undefined) value = null;
    if (field === "age" && isNaN(value)) return;
    setForm({
      ...form,
      [field]: value
    });
  };

  const handleReset = () => {
    setForm({
      secouristeId: null,
      numeroIntervention: "",
      pdfAdresse: "",
      sexe: 0,
      age: "",
      nom: "",
      prenom: "",
      motifDepart: null,
      circonstanciel: "",
      heureDepart: DateTime.now().toFormat("yyyy-MM-dd'T'HH:mm:ssZZZ"),
      heureFin: null,
      hopitalId: null,
      pdfCotation: 0,
      pdfSalarie: null,
    });
    setHealthStructureId(null);
    const date = DateTime.now();
    setToday({
      year: date.year,
      month: date.month,
      day: date.day,
      hour: date.hour,
      minute: date.minute,
    });
  };

  const handleFormSubmit = async ev => {
    try {
      ev.preventDefault();
      setLoaderBar(true);
      const payload = cloneDeep(syoData.BILAN_TMPL);
      payload.fiche_bilan.bilan.en_cours = ifDeclenchement ? "new" : "yes";
      payload.fiche_bilan.equipe.adresse = form.pdfAdresse;
      payload.fiche_bilan.equipe.intervention_num = form.numeroIntervention;
      payload.fiche_bilan.equipe.heure_fin_inter = !ifDeclenchement ? form.heureFin : null;
      payload.fiche_bilan.equipe.cotation_puy_du_fou = form.pdfCotation === 0 ? null : parseInt(form.pdfCotation);
      payload.fiche_bilan.fiche.devenir.heure_depart = form.heureDepart;
      payload.fiche_bilan.fiche.devenir.hopital_id = !ifDeclenchement ? form.hopitalId : null;
      payload.fiche_bilan.fiche.victime.sexe = form.sexe;
      payload.fiche_bilan.fiche.victime.age = form.age;
      payload.fiche_bilan.fiche.victime.nom = form.nom;
      payload.fiche_bilan.fiche.victime.prenom = form.prenom;
      payload.fiche_bilan.fiche.victime.pdf_salarie = form.pdfSalarie;
      payload.fiche_bilan.fiche.circonstance.circonstances = form.motifDepart || "";
      payload.fiche_bilan.fiche.circonstance.commentaire = form.circonstanciel;
      payload.defer_to = ifDeclenchement ? form.secouristeId : null;
      payload.info_tech.id_intervention = `0-${generateId()}`;

      await http.post(`bilans`, {json: payload}).json();
      handleReset();
      toast.success(`${ifDeclenchement ? "Déclenchement créé" : "Main courante créée"} avec succès`);
    } catch (err) {
      toast.error("La création de l'intervention a échoué");
      console.error(err);
      Sentry.captureException({
        message: "La création de l'intervention a échoué",
        error: err
      });
    } finally {
      setLoaderBar(false);
    };
  };

  const handleHeureDebut = (data) => {
    handleFormChange("heureDepart", DateTime.fromObject(data).toFormat("yyyy-MM-dd'T'HH:mm:ssZZZ"));
  };
  const handleHeureFin = (data) => {
    handleFormChange("heureFin", DateTime.fromObject(data).toFormat("yyyy-MM-dd'T'HH:mm:ssZZZ"));
  };

  const handleSwitch = () => {
      setIfDeclenchement(!ifDeclenchement);
  };

  const handleHealthStructureChange = (value) => {
    if (value == null) {
      setHealthStructureId(null);
      handleFormChange("hopitalId", null);
    } else if (healthStructureId !== value.value) {
      setHealthStructureId(value.value);
      const services = healthStructureService.healthServicesByStructure.get(value.value) || [];
      if (services.length === 1) {
        handleFormChange("hopitalId", services[0].id);
      } else {
        handleFormChange("hopitalId", null);
      }
    }
  };

  return (
    <>
      <FloatingHeader>
        <Title>Nouvelle intervention</Title>
        <Actions>
          <Button type="button" onClick={handleReset} disabled={loaderBarState} className="warn">Réinitialiser tous les champs</Button>
          <Button type="submit" onClick={handleFormSubmit} disabled={loaderBarState}>{ifDeclenchement ? "Déclencher l'intervention" : "Enregistrer la main courante" }</Button>
        </Actions>
      </FloatingHeader>

      <Cntnr>
        {isPdf() ? (
          <SwitchDouble
            labels={["Main courante", "Déclenchement"]}
            onChange={() => handleSwitch()}
            switchValue={ifDeclenchement}
          />
        ) : (
          <h3>Main courante</h3>
        )}

        {(usersIsLoading || hopitalsIsLoading || motivesIsLoading) ? (
          <LoadingSpinner className="center vh-50" />
        ) : (
          <form>
            <Disclosure
              title="Équipe"
              children={
                <Container>
                  <Row>
                    <Column>
                      <FormGroup>
                        <Label htmlFor="intervention-numero-input">
                          Numéro d'intervention
                        </Label>
                        <Input
                          id="intervention-numero-input"
                          value={form.numeroIntervention}
                          onChange={(ev) => handleFormChange("numeroIntervention", ev.target.value)}
                        />
                      </FormGroup>
                    </Column>
                    <Column>
                      {ifDeclenchement ? (
                        <FormGroup>
                          <Label htmlFor="intervention-intervenant-input">
                            Personnel assigné <span className="light">(Requis)</span>
                          </Label>
                          <Select
                            id="intervention-intervenant-input"
                            isRequired={true}
                            options={users}
                            value={users?.find(u => u.id === form.secouristeId) || null}
                            onChange={(value) => handleFormChange("secouristeId", value?.id)}
                            getOptionValue={option => option.id}
                            getOptionLabel={option => `${option.full_name}${option.matricule && (` - ${option.matricule}`)}`}
                            placeholder=""
                          />
                        </FormGroup>
                      ) : (
                        <FormGroup>
                          <Label htmlFor="intervention-suite-select">Suite</Label>
                          <DoubleSelectCntnr>
                            <Select
                              id="intervention-suite-select"
                              isClearable={true}
                              isSearchable={true}
                              options={healthStructureService.healthStructureOptions}
                              value={healthStructureService.healthStructureOptions.find(h => h.value === healthStructureId) || null}
                              onChange={handleHealthStructureChange}
                              placeholder=""
                            />
                            <Select
                              id="intervention-suite-select-bis"
                              isDisabled={healthStructureId == null}
                              isClearable={true}
                              isSearchable={true}
                              options={healthStructureService.healthServicesForCurrentStructure}
                              value={healthStructureService.healthServicesForCurrentStructure.find(h => h.value === form.hopitalId) || null}
                              onChange={(value) => {
                                if (value == null) {
                                  handleFormChange("hopitalId", null);
                                } else {
                                  handleFormChange("hopitalId", value.value);
                                }
                              }}
                              placeholder=""
                            />
                          </DoubleSelectCntnr>
                        </FormGroup>
                      )}
                    </Column>
                  </Row>
                </Container>
              }
            />

            <Disclosure
              title="Circonstantiel"
              children={
                <Container>
                  <Row>
                    <Column>
                      {isPdf() ? (
                        <FormGroup>
                          <Label htmlFor="intervention-adresse-input">Adresse / Lieu</Label>
                          <Select
                            id="intervention-adresse-input"
                            isClearable={true}
                            isSearchable={true}
                            options={syoData.PDF_ADRESSE_OPTIONS}
                            value={syoData.PDF_ADRESSE_OPTIONS.find(h => h.label === form.pdfAdresse) || null}
                            onChange={(value) => handleFormChange("pdfAdresse", value?.label)}
                            placeholder=""
                          />
                        </FormGroup>
                      ) : (
                        <FormGroup>
                          <Label htmlFor="intervention-adresse-input">Adresse</Label>
                          <Input
                            id="intervention-adresse-input"
                            value={form.pdfAdresse}
                            onChange={(ev) => handleFormChange("pdfAdresse", ev.target.value)}
                          />
                        </FormGroup>
                      )}
                      <FormGroup>
                        <Label htmlFor="intervention-motif-input">Motif de départ</Label>
                        <Select
                          id="intervention-motif-input"
                          isClearable={true}
                          isSearchable={true}
                          options={motives}
                          value={motives.find(m => m.value === form.motifDepart) || null}
                          getOptionLabel={option => `${option.value}`}
                          onChange={(value) => handleFormChange("motifDepart", value?.value)}
                          placeholder=""
                        />
                      </FormGroup>
                    </Column>
                    <Column>
                      <FormGroup>
                        <Label htmlFor="intervention-circonstanciel-input">
                          Bilan circonstanciel
                        </Label>
                        <Textarea
                          id="intervention-circonstanciel-input"
                          rows={4}
                          value={form.circonstanciel}
                          onChange={(ev) => handleFormChange("circonstanciel", ev.target.value)}
                        />
                      </FormGroup>
                    </Column>
                  </Row>
                </Container>
              }
            />

            <Disclosure
              title="Identité"
              children={
                <Container>
                  <Row>
                    <Column>
                      <FormGroup>
                        <Label htmlFor="intervention-victime-nom">Nom de la victime</Label>
                        <Input
                          id="intervention-victime-nom"
                          value={form.nom}
                          onChange={(ev) => handleFormChange("nom", ev.target.value)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="intervention-victime-sexe-input">
                          Sexe de la victime
                        </Label>
                        <Select
                          id="intervention-victime-sexe-input"
                          isClearable={false}
                          isSearchable={true}
                          options={syoData.SEX_OPTIONS}
                          value={syoData.SEX_OPTIONS.find(s => s.value === form.sexe) || null}
                          onChange={(value) => handleFormChange("sexe", value?.value)}
                          placeholder=""
                        />
                      </FormGroup>
                      {isPdf() && (
                        <FormGroup>
                          <Label htmlFor="intervention-victime-type-select">Type de victime</Label>
                          <Select
                            id="intervention-victime-type-select"
                            isClearable={true}
                            isSearchable={true}
                            options={syoData.PDF_SALARIE_OPTIONS}
                            value={syoData.PDF_SALARIE_OPTIONS.find(h => h.label === form.pdfSalarie) || null}
                            onChange={(value) => handleFormChange("pdfSalarie", value?.label)}
                            placeholder=""
                          />
                        </FormGroup>
                      )}
                    </Column>
                    <Column>
                      <FormGroup>
                        <Label htmlFor="intervention-victime-prenom-input">Prénom de la victime</Label>
                        <Input
                          id="intervention-victime-prenom-input"
                          value={form.prenom}
                          onChange={(ev) => handleFormChange("prenom", ev.target.value)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="intervention-victime-age-input">
                          Age de la victime
                        </Label>
                        <Input
                          id="intervention-victime-age-input"
                          type="tel"
                          value={form.age}
                          onChange={(ev) => handleFormChange("age", ev.target.value)}
                        />
                      </FormGroup>
                    </Column>
                  </Row>
                </Container>
              }
            />

            <Disclosure
              title="Devenir"
              children={
                <Container>
                  <Row>
                    <Column>
                      <FormGroup>
                        <Label htmlFor="intervention-devenir-heure-depart-input">Heure de départ</Label>
                        <DateTimePicker
                          id="intervention-devenir-heure-depart-input"
                          callback={handleHeureDebut}
                          picker="dateTime"
                          today={today}
                          title="Heure de départ"
                        />
                      </FormGroup>
                      {(!ifDeclenchement && isPdf()) && (
                        <FormGroup>
                          <Label htmlFor="intervention-devenir-cotation-select">Cotation</Label>
                          <Select
                            id="intervention-devenir-cotation-select"
                            isClearable={true}
                            isSearchable={true}
                            options={syoData.PDF_COTATION_OPTIONS}
                            value={syoData.PDF_COTATION_OPTIONS.find(c => c.value === form.pdfCotation) || null}
                            onChange={(value) => handleFormChange("pdfCotation", value?.value)}
                            placeholder=""
                          />
                        </FormGroup>
                      )}
                    </Column>
                    <Column>
                      {!ifDeclenchement && (
                        <FormGroup>
                          <Label htmlFor="intervention-devenir-heure-fin-input">Heure de fin d'intervention</Label>
                          <DateTimePicker
                            id="intervention-devenir-heure-fin-input"
                            empty={true}
                            callback={handleHeureFin}
                            today={today}
                            title="Heure de fin d'intervention"
                          />
                        </FormGroup>
                      )}
                      {(ifDeclenchement && isPdf) && (
                        <FormGroup>
                          <Label htmlFor="intervention-devenir-cotation-select">Cotation</Label>
                          <Select
                            id="intervention-devenir-cotation-select"
                            isClearable={true}
                            isSearchable={true}
                            options={syoData.PDF_COTATION_OPTIONS}
                            value={syoData.PDF_COTATION_OPTIONS.find(c => c.value === form.pdfCotation) || null}
                            onChange={(value) => handleFormChange("pdfCotation", value?.value)}
                            placeholder=""
                          />
                        </FormGroup>
                      )}
                    </Column>
                  </Row>
                </Container>
              }
            />
          </form>
       )}
      </Cntnr>
    </>
  );
};

export default Trigger;
