import styled from "styled-components";
import React, {useEffect, useState, useContext} from "react";
import { useQuery } from "react-query";
import http from "../http";
import { toast } from "react-toastify";
import {DateTime} from "luxon";
import {triggerDownload} from "../utils";

import theme from "../ui/theme";
import LoadingSpinner from "../ui/LoadingSpinner";
import Carousel from "../ui/Carrousel";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import LoaderBarContext from "../ui/useLoaderBar";
import Label from "../ui/Label";
import Textarea from "../ui/Textarea";
import Button from "../ui/Button";
import Input from "../ui/Input";
import {SwitchDouble} from "../ui/Switch";
import Select from "../ui/Select";

const AttachmentList = styled.ul`
  padding: 0;
  margin: 0;
`;

const AttachmentItem = styled.li`
  list-style: none;
  display: flex;
  padding-top: ${theme.thin};
  margin-bottom: ${theme.small};
  background-color: ${theme.grey6};
  border-radius: ${theme.borderRadius};
  padding: ${theme.thin};

  &:hover {
    cursor: pointer;
  }  
`;

const AttachmentThumbnail = styled.img`
  flex: 0 1 90px;
  border-radius: 4px;
  max-width: 90px;
  height: fit-content;
  max-height: 90px;
  margin-right: 1rem;

  &:hover {
    cursor: pointer;
  }
`;

const AttachmentDetails = styled.div`
  flex: 1 1 auto;

  div:hover {
    cursor: pointer;
  }
`;

const PdfDetails = styled.div`
  flex: 1 1 auto;

  button {
    font-weight: 500;
    color: ${theme.blue};
    text-decoration: none;
    background-color: transparent;
    border: none;
    &:hover {
      color: ${theme.text};
      text-decoration: underline;
    }
  }
`;

const AttachmentLink = styled.a`
  font-weight: 500;
`;

const AttachmentMetadata = styled.div`
  color: ${theme.grey3};
`;

const AttachmentIndicator = styled.div`
  color: ${theme.grey3};
  svg {
    margin-right: ${theme.thin};
    color: ${theme.grey3};
    &.new {
      color: ${theme.blue}
    }
  }
`;

const RETURN_DISTANCE_ATSU_35_OPTIONS = [
  {
    value: 1,
    label: '0 à 20Kms',
  },
  {
    value: 2,
    label: '20 à 40Kms',
  },
  {
    value: 3,
    label: 'supérieurs à 40Kms',
  },
];

export default function AttachmentsList({interventionId, bilanId, annex = [], liaisonFormPerm, compteRenduSmurPerm, sheetFormCallback, materialHelicoPerm, hisPui}) {
  const controller = new AbortController();

  const { loaderBarState, setLoaderBar } = useContext(LoaderBarContext);

  const [ showDiapo, setShowDiapo ] = useState(false);
  const [ currentImg, setCurrentImg ] = useState(null);
  const [ attachments, setAttachments ] = useState(null);
  const [ annexRefusList, setAnnexRefusList ] = useState([]);
  const [ annexLiaisonList, setAnnexLiaisonList ] = useState([]);
  const [ annexCompteRenduSmurList, setAnnexCompteRenduSmurList ] = useState([]);
  const [ annexDocumentList, setAnnexDocumentList ] = useState([]);
  const [ formData, setFormData ] = useState({});
  const [ checkWithSmur, setCheckWithSmur ] = useState(false);
  const [ checkDsa, setCheckDsa ] = useState(false);
  const [ checkBariatric, setCheckBariatric ] = useState(false);

  const {isLoading, error, data} = useQuery(['interventions', interventionId, 'attachments'], () =>
    http.get(`interventions/${encodeURIComponent(interventionId)}/attachments.json`, {
      signal: controller.signal,
    }).json()
    .then(res => {
      return res.data;
    }),
    {
      // Refetch the data every second
      refetchInterval: hisPui ? 0 : 1000,
      onSuccess: (data) => {
        if (attachments === null || attachments.length !== data.length) {
          const temp = data;
          temp.map(d => {
            const localAttachment = localStorage.getItem(`nf-attachment-${d.id}`);
            d.lastAccess = null;
            d.hasBeenSeen = false;
            if (localAttachment !== null) {
              d.lastAccess = localAttachment;
              const dLocal = DateTime.fromISO(localAttachment);
              if (dLocal > DateTime.fromISO(d.created_at)) d.hasBeenSeen = true;
            }
            d.isEcg = ["ecg", "ecg-pdf"].includes(d.category.key);
          });
          console.log(temp)
          setAttachments(temp);
        }
      }
    }
  );

  useEffect(() => {
    return () => {
      localStorage.setItem(interventionId, DateTime.local().toISO());
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (interventionId && data) {
      let previousUserVisit = localStorage.getItem(interventionId);
      if (previousUserVisit == null) {
        previousUserVisit = DateTime.fromSeconds(0);
      } else {
        previousUserVisit = DateTime.fromISO(previousUserVisit);
      }
      const newAttachments = data?.filter(attachment => {
        const attachementCreatedAt = DateTime.fromISO(attachment.created_at);
        return attachementCreatedAt > previousUserVisit;
      }) || [];
      const tempAnnexRefus = [];
      const tempAnnexLiaison = [];
      const tempAnnexCompteRenduSmur = [];
      const tempAnnexDocument = [];
      const newAnnex = annex.filter(sheet => {
        const sheetCreatedAt = DateTime.fromISO(sheet.created_at);
        switch (sheet.kind) {
          case 'RefusTransport':
          case 'RefusTransportSdis38':
            sheet.title = 'Refus de transport';
            tempAnnexRefus.push(sheet);
            break;
          case 'RefusTransportEnglish':
            sheet.title = 'Refus de transport (EN)';
            tempAnnexRefus.push(sheet);
            break;
          case 'RefusSignature':
            sheet.title = 'Refus de signature';
            tempAnnexRefus.push(sheet);
            break;
          case 'LiaisonFormAtsu35':
            sheet.title = 'Fiche de liaison';
            tempAnnexLiaison.push(sheet);
            break;
          case 'RecuperationMaterielHelico':
            sheet.title = 'Récupération matériel hélico';
            tempAnnexDocument.push(sheet);
            break;
          case 'AutorisationSoinMineur':
            sheet.title = 'Autorisation de soins sur mineur';
            tempAnnexDocument.push(sheet);
            break;
          case 'CertificatNaissance':
            sheet.title = 'Certificat de naissance';
            tempAnnexDocument.push(sheet);
            break;
          case 'CompteRenduSmur':
            sheet.title = 'Compte rendu intervention SMUR';
            tempAnnexCompteRenduSmur.push(sheet);
            break;
          default:
            sheet.title = 'Document';
            tempAnnexDocument.push(sheet);
            break;
        }
        if (sheet.kind === 'LiaisonFormAtsu35' && !liaisonFormPerm) return false;
        if (sheet.kind === 'RecuperationMaterielHelico' && !materialHelicoPerm) return false;
        return sheetCreatedAt > previousUserVisit;
      }) || [];
      setAnnexRefusList(tempAnnexRefus);
      setAnnexLiaisonList(tempAnnexLiaison);
      setAnnexCompteRenduSmurList(tempAnnexCompteRenduSmur);
      setAnnexDocumentList(tempAnnexDocument);
      if (newAttachments.length + newAnnex.length > 1) {
        toast.warn(`${newAttachments.length + newAnnex.length} nouvelles pièce-jointes depuis votre dernière consultation`, {
          type: "info",
          position: toast.POSITION.BOTTOM_RIGHT
        });
      } else if (newAttachments.length + newAnnex.length === 1) {
        toast.warn("1 nouvelle pièce-jointe depuis votre dernière consultation", {
          type: "info",
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
      localStorage.setItem(interventionId, DateTime.local().toISO());
    }
  }, [ interventionId, data, annex, liaisonFormPerm ]);

  const handleIsSeen = (current) => {
    const dCurrent = DateTime.local().toISO();
    const temp = attachments;
    temp[current].hasBeenSeen = true;
    temp[current].lastAccess = dCurrent;
    setAttachments(temp);
    localStorage.setItem(`nf-attachment-${attachments[current].id}`, dCurrent);
  };

  const handleImgClick = (ev, current) => {
    ev.stopPropagation();
    setCurrentImg(current);
    handleIsSeen(current);
    toggleDiapo();
  };

  const handleDownloadPdf = async (id, kind) => {
    setLoaderBar(true);
    try {
      const pdfResponse = await http.get(`bilans/${encodeURIComponent(bilanId)}/export`, {
        headers: {
          Accept: "application/pdf"
        },
        searchParams: {
          annex_sheet_id: id
        }
      });

      const contentDisposition = pdfResponse.headers.get("content-disposition");
      const baseFilename = contentDisposition.match(
        /^attachment;\s+filename="(.+)\.pdf"$/i
      )[1];
      const pdfPayload = await pdfResponse.arrayBuffer();
      try {
        const pdfBlob = new Blob([pdfPayload], {
          type: "application/pdf"
        });
        await triggerDownload(`${baseFilename}-${kind}.pdf`, pdfBlob);
      } catch (err) {
        console.error(err);
        toast(
          `Impossible de télécharger le refus PDF de l'intervention.`,
          {
            type: toast.TYPE.ERROR,
            autoClose: false
          }
        );
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoaderBar(false);
    }
  };

  const handleInputChange = (ev) => {
    setFormData({...formData, [ev.id]: ev.value});
  };

  const handleCreateLiaison = (ev) => {
    ev.preventDefault();
    sheetFormCallback('LiaisonFormAtsu35', formData);
  };
  const handleCreateCompteRenduSmur = (ev) => {
    ev.preventDefault();
    sheetFormCallback('CompteRenduSmur', formData);
  };

  const toggleDiapo = () => {
    setShowDiapo(!showDiapo);
  };

  const getAccess = (lastAccess) => DateTime.fromISO(lastAccess).toLocaleString(DateTime.TIME_24_SIMPLE);

  if (isLoading || !attachments) {
    return (<div style={{display: "flex", flexDirection: "column", height: "100%"}}>
      <h3 style={{padding: '1rem', paddingBottom: '1.5rem'}}>Pièces jointes</h3>
      <div style={{textAlign: 'center'}}>
        <LoadingSpinner/>
      </div>
    </div>);
  }

  if (error) {
    return (<div style={{display: "flex", flexDirection: "column", height: "100%"}}>
      <h3 style={{padding: '1rem', paddingBottom: '1.5rem'}}>Pièces jointes</h3>
      <div>
        Une erreur est survenue lors de la récupération des pièces jointes.
      </div>
    </div>);
  }

  return (<div style={{padding: '1rem'}}>
    <h3>Pièces jointes</h3>
    <hr/>
    <AttachmentList>
      {attachments.map((attachment, idx) =>
        <AttachmentItem key={idx} onClick={ev => handleImgClick(ev, idx)}>
          {attachment.isEcg
           ? <MonitorHeartIcon style={{fontSize: 50, marginRight: "1rem"}} data-sentry-id="attachment-pdf-view" />
           : <AttachmentThumbnail src={attachment.public_url} data-sentry-id="attachment-img-view"/>
          }
          <AttachmentDetails>
            <div>
              <span>{attachment.description}</span>
              <AttachmentMetadata>Téléchargé par {attachment.author_name}</AttachmentMetadata>
              {attachment.created_at && (
                <AttachmentMetadata>
                  {DateTime.fromISO(attachment.created_at).setLocale("fr-fr").toLocaleString(DateTime.DATETIME_SHORT)}
                </AttachmentMetadata>
              )}
            </div>
            <AttachmentLink
              onClick={ev => ev.stopPropagation()}
              href={attachment.public_url}
              target="_blank"
              rel="noreferrer noopener"
              data-sentry-id="attachment-img-download"
            >Télécharger l'{attachment.isEcg ? 'ECG' : 'image'}</AttachmentLink>
            <AttachmentIndicator>
              <VisibilityOutlinedIcon className={`${!attachment.hasBeenSeen ? "new" : ""}`} />
              {attachment.lastAccess === null ? "Non consulté" : `Consulté à ${getAccess(attachment.lastAccess)}`}
            </AttachmentIndicator>
          </AttachmentDetails>
        </AttachmentItem>
      )}
      {annexRefusList.length > 0 && (
        <>
          <h3>Refus</h3>
          <hr/>
          {annexRefusList.map((sheet, idx) => (
            <AttachmentItem key={idx} onClick={() => handleDownloadPdf(sheet.id, sheet.kind)}>
              <PictureAsPdfIcon style={{fontSize: 50, marginRight: "1rem"}} />
              <PdfDetails>
                <div>
                  <span>{sheet.title}</span>
                </div>
                {sheet.created_at && (
                  <AttachmentMetadata>
                    {DateTime.fromISO(sheet.created_at).setLocale("fr-fr").toLocaleString(DateTime.DATETIME_SHORT)}
                  </AttachmentMetadata>
                )}
                <button onClick={() => handleDownloadPdf(sheet.id, sheet.kind)} data-sentry-id="attachment-pdf-download">Télécharger le PDF</button>
              </PdfDetails>
            </AttachmentItem>
          ))}
        </>
      )}
      {annexDocumentList.length > 0 && (
        <>
          <h3>Document</h3>
          <hr/>
          {annexDocumentList.map((sheet, idx) => (
            <AttachmentItem key={idx} onClick={() => handleDownloadPdf(sheet.id, sheet.kind)}>
              <PictureAsPdfIcon style={{fontSize: 50, marginRight: "1rem"}} />
              <PdfDetails>
                <div>
                  <span>{sheet.title}</span>
                </div>
                {sheet.created_at && (
                  <AttachmentMetadata>
                    {DateTime.fromISO(sheet.created_at).setLocale("fr-fr").toLocaleString(DateTime.DATETIME_SHORT)}
                  </AttachmentMetadata>
                )}
                <button onClick={() => handleDownloadPdf(sheet.id, sheet.kind)} data-sentry-id="attachment-pdf-download">Télécharger le PDF</button>
              </PdfDetails>
            </AttachmentItem>
          ))}
        </>
      )}
      {(compteRenduSmurPerm) && (
        <>
          <h3>Compte rendu intervention SMUR</h3>
          <hr />
          {annexCompteRenduSmurList.length > 0 ? (
            annexCompteRenduSmurList.map((sheet, idx) => (
              <AttachmentItem key={idx} onClick={() => handleDownloadPdf(sheet.id, sheet.kind)}>
                <PictureAsPdfIcon style={{fontSize: 50, marginRight: "1rem"}} />
                <PdfDetails>
                  <div>
                    <span>{sheet.title}</span>
                  </div>
                  {sheet.created_at && (
                    <AttachmentMetadata>
                      {DateTime.fromISO(sheet.created_at).setLocale("fr-fr").toLocaleString(DateTime.DATETIME_SHORT)}
                    </AttachmentMetadata>
                  )}
                  <button onClick={() => handleDownloadPdf(sheet.id, sheet.kind)} data-sentry-id="attachment-pdf-liaison-download">Télécharger le PDF</button>
                </PdfDetails>
              </AttachmentItem>
            ))
          ) : (
            <Button
              type="button"
              style={{marginBottom: '1rem', marginTop: '.5rem'}}
              onClick={ev => handleCreateCompteRenduSmur(ev)}
              data-sentry-id="attachment-pdf-compte-rendu-SMUR"
              disabled={loaderBarState}
            >Générer un compte rendu</Button>
          )}
        </>
      )}
      {liaisonFormPerm && (
        <>
          <h3>Fiche de liaison</h3>
          <hr/>
          {annexLiaisonList.length > 0 ? (
            annexLiaisonList.map((sheet, idx) => (
              <AttachmentItem key={idx} onClick={() => handleDownloadPdf(sheet.id, sheet.kind)}>
                <PictureAsPdfIcon style={{fontSize: 50, marginRight: "1rem"}} />
                <PdfDetails>
                  <div>
                    <span>{sheet.title}</span>
                  </div>
                  {sheet.created_at && (
                    <AttachmentMetadata>
                      {DateTime.fromISO(sheet.created_at).setLocale("fr-fr").toLocaleString(DateTime.DATETIME_SHORT)}
                    </AttachmentMetadata>
                  )}
                  <button onClick={() => handleDownloadPdf(sheet.id, sheet.kind)} data-sentry-id="attachment-pdf-liaison-download">Télécharger le PDF</button>
                </PdfDetails>
              </AttachmentItem>
            ))
          ) : (
            <form>
              <Label htmlFor='organization_name'>Raison social</Label>
              <Textarea id='organization_name' rows={5} value={formData.organization_name || ''} onChange={ev => handleInputChange(ev.target)} data-sentry-id='liaison-form-organization-name' />
              <Label htmlFor='organization_tel' style={{marginTop: '.5rem'}}>Tél</Label>
              <Input id='organization_tel' value={formData.organization_tel || ''} onChange={ev => handleInputChange(ev.target)} data-sentry-id='liaison-form-organization-tel' />
              <Label htmlFor='organization_fax' style={{marginTop: '.5rem'}}>Fax</Label>
              <Input id='organization_fax' value={formData.organization_fax || ''} onChange={ev => handleInputChange(ev.target)} data-sentry-id='liaison-form-organization-fax' />
              <Label htmlFor='organization_am' style={{marginTop: '.5rem'}}>Numéro AM</Label>
              <Input id='organization_am' value={formData.organization_am || ''} onChange={ev => handleInputChange(ev.target)} data-sentry-id='liaison-form-organization-am' />
              <hr />
              <Label htmlFor='with_smur'>Avec SMUR</Label>
              <SwitchDouble
                id={'with_smur'}
                labels={["Non", "Oui"]}
                onChange={() => {
                  setFormData({...formData, with_smur: !checkWithSmur});
                  setCheckWithSmur(!checkWithSmur);
                }}
                switchValue={checkWithSmur}
                data-sentry-id="liaison-form-with-smur"
              />
              <Label htmlFor='dsa_dea_mr'>Utilisation du DSA/DEA (Sur consigne du Médecin Régulateur)</Label>
              <SwitchDouble
                id={'dsa_dea_mr'}
                labels={["Non", "Oui"]}
                onChange={() => {
                  setFormData({...formData, dsa_dea_mr: !checkDsa});
                  setCheckDsa(!checkDsa);
                }}
                switchValue={checkDsa}
                data-sentry-id="liaison-form-dsa-dea-mr"
              />
              <Label htmlFor='bariatric_transport'>Transport bariatrique</Label>
              <SwitchDouble
                id={'bariatric_transport'}
                labels={["Non", "Oui"]}
                onChange={() => {
                  setFormData({...formData, bariatric_transport: !checkBariatric});
                  setCheckBariatric(!checkBariatric);
                }}
                switchValue={checkBariatric}
                data-sentry-id="liaison-form-bariatric-transport"
              />
              <Label htmlFor='return_distance'>Distance retour</Label>
              <Select
                id={'return_distance'}
                placeholder={'Sélectionnez une distance'}
                options={RETURN_DISTANCE_ATSU_35_OPTIONS}
                value={RETURN_DISTANCE_ATSU_35_OPTIONS.filter(opt => opt.value === formData.return_distance) || null}
                onChange={obj => setFormData({...formData, return_distance: obj.value})}
                data-sentry-id="liaison-form-return-distance"
              />
              <Button
                style={{marginBottom: '.5rem', marginTop: '.5rem'}}
                onClick={ev => handleCreateLiaison(ev) }
                type='submit'
                data-sentry-id='liaison-form-create'
                disabled={loaderBarState}
              >
                Générer la fiche de liaison
              </Button>
            </form>
          )}
        </>
      )}
    </AttachmentList>
    <Carousel
      imgs={attachments}
      idx={currentImg}
      isOpen={showDiapo}
      toggleOpen={toggleDiapo}
      onNavigate={handleIsSeen}
    />
  </div>);
}
