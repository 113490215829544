import { Route, Redirect } from "found";
import React from "react";

import Active from "./screens/Active";
import Archive from "./screens/Archive";
import PharmacyCompliance from "./screens/PharmacyCompliance";
import ShowPuiBilan from "./screens/ShowPuiBilan";
import ShowBilan from "./screens/ShowBilan";
import Trigger from "./screens/Trigger";
import Mobilizations from "./screens/Mobilizations";
import Abandonment from "./screens/Abandonment";
import MobilizationTrigger from "./screens/MobilizationTrigger";
import ShowSsoSmp from './screens/ShowSsoSmp';
import SsoSmp from './screens/SsoSmp';
import SsoSmpArchive from './screens/SsoSmpArchive';

export const routes = (
  <Route path="interventions">
    <Redirect from="/" to="/interventions/active" />
    <Route path="active" Component={Active} />
    <Route path="archive" Component={Archive} />
    <Route path="sso-smp" Component={SsoSmp} />
    <Route path="sso-smp-archive" Component={SsoSmpArchive} />
    <Route path="sso-smp/:interventionId/bilans/:bilanId" Component={ShowSsoSmp} />
    <Route path="pui" Component={PharmacyCompliance} />
    <Route path="pui/:interventionId/bilans/:bilanId" Component={ShowPuiBilan} />
    <Route path=":interventionId/bilans/:bilanId" Component={ShowBilan} />
    <Route path="declenchement" Component={Trigger} />
    <Route path="mobilisations">
      <Route path="/" Component={Mobilizations} />
      <Route path="/declenchement" Component={MobilizationTrigger} />
    </Route>
    <Route path="abandon" Component={Abandonment} />
  </Route>
);
