import React, { useState, useEffect, useContext } from "react";
import http from "../../http";
import { useQuery } from "react-query";
import {toast} from "react-toastify";
import * as Sentry from "@sentry/browser";
import { isAbortError } from "../../utils";
// import CurrentUserContext from "../../CurrentUserContext";
import queryString from "query-string";

// import List from "../List";
import PageHeader, {Title} from "../../ui/PageHeader";
// import LoadingSpinner from "../../ui/LoadingSpinner";
// import BoundedPagination from "../../ui/BoundedPagination";
import Button from "../../ui/Button";
import Label from "../../ui/Label";
import Input from "../../ui/Input";
import styled from "styled-components";
import theme from "../../ui/theme";
import SearchBarCompo, { SearchBar } from "../../ui/SearchBar";
import Select from "../../ui/Select";
import LoaderBarContext from "../../ui/useLoaderBar";
import Card from "../../ui/Card";
import { sortedUniqBy, uniqBy } from "lodash";
import {getAbondonmentExport} from "../textualExport";
import {CLIP_TEXT_TEMPLATE_ABANDONED_DAY} from "../layout/clip";
import Mustache from "mustache";
import * as clipboard from "clipboard-polyfill";

const FilterBar = styled.form`
  width: 100%;

  div.row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    > * {
      padding-bottom: ${theme.small};
    }
    > *:not(:last-child) {
      padding-right: ${theme.small};
    }
    & .item-primary {
      width: 35%;
    }
    & .item-secondary {
      width: 30%;
    }
  }

  div.rowWrap {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    > * {
      padding-bottom: ${theme.small};
    }
    > *:not(:last-child) {
      padding-right: ${theme.small};
    }
    > div {
      width: 25%;
    }
  }

  div.rowAction {
    display: flex;
    justify-content: flex-end;
    > button {
      margin-bottom: ${theme.small};
    }
    > button:first-child {
      margin-right: ${theme.small};
    }
  }

  @media (max-width: 1000px) {
    flex-direction: column;

    div.row {
      > *:not(:last-child) {
        padding-right: ${theme.small};
      }
    }

    div.rowWrap {
      > *:nth-child(even) {
        padding-right: 0;
      }
      > div {
        width: 50%;
      }
    }
  }

  @media (max-width: 680px) {
    div.row {
      flex-direction: column;
      > * {
        padding-right: 0 !important;
      }
      & .item-primary,
      & .item-secondary {
        width: 100%;
      }
    }

    div.rowWrap {
      > *:not(:last-child) {
        padding-right: 0;
      }
      > div {
        width: 100%;
      }
    }

    div.rowAction {
      flex-direction: column;
      > button {
        width: 100%;
      }
      > button:first-child {
        margin-right: 0;
      }
    }
  }
`;

const BilanList = styled.ul`
  padding: ${theme.small};
  margin-bottom: 0;
`;

const BilanCard = styled(Card)`
  max-width: none;
  min-width: none;
  margin-bottom: ${theme.small};
  padding: ${theme.small};
  border: none;
  position: relative;
  cursor: auto;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SplitList = styled.ul`
  width: 50%;
  padding: ${theme.small};
`;

function Abandonment({ match, router }) {
  const { setLoaderBar } = useContext(LoaderBarContext);

  const controller = new AbortController();

  const {
    isLoading: payloadIsLoading,
    isFetching: payloadIsFetching,
    data: payload,
    refetch: refetchPayload,
  } = useQuery(
    "archiveBilans",
    async () => {
      setLoaderBar(true);
      return await http
      .get(`evacuations`, {
        signal: controller.signal,
        //searchParams: searchParamsString
      })
      .json()
      .then(res => {
        return res.data;
      })
      .catch(error => {
        if (isAbortError(error)) return;
        toast.warn(
          `Une erreur est survenue pendant la récupération des interventions.`,
          {
            toastId: "archiveBilans"
          }
        );
        Sentry.captureException(error);
        console.error(error);
      })
      .finally(() => {
        setLoaderBar(false);
      });
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );

  const [filterCreatedAfter, setFilterCreatedAfter] = useState(
    match.location.query.createdAfter || ""
  );
  const [filterCreatedBefore, setFilterCreatedBefore] = useState(
    match.location.query.createdBefore || ""
  );
  const [filterSortBilan, setFilterSortBilan] = useState(match.location.query.createdEntity || "bilans");
  // const currentPage = parseInt(match.location.query.page || "1", 10);

  const sortBilanOptions = [
    {value: "bilans", label: "Réception du bilan"},
    {value: "interventions", label: "Création du bilan"},
  ];

  useEffect( () => {
    refetchPayload();
  }, [ match ] );

  useEffect( () => {
    return () => {
      controller.abort();
    };
  }, [] );

  const handleFormSubmit = ev => {
    ev.preventDefault();
    const options = {};
    options.createdEntity = filterSortBilan || "bilans";
    if (filterCreatedAfter !== "") options.createdAfter = filterCreatedAfter;
    if (filterCreatedBefore !== "") options.createdBefore = filterCreatedBefore;
    router.push({
      pathname: "/interventions/abandon",
      query: {
        page: "1",
        ...options
      }
    });
  };

  const handleFilterCreatedAfterChange = ev => {
    setFilterCreatedAfter(ev.target.value);
  };

  const handleFilterCreatedBeforeChange = ev => {
    setFilterCreatedBefore(ev.target.value);
  };

  const handleSortBilanChange = ev => {
    setFilterSortBilan(ev.value);
  };

  const handleResetFilter = () => {
    setFilterCreatedAfter("");
    setFilterCreatedBefore("");
    setFilterSortBilan("bilans");
  };

  // const count =
  //   payload === null || payload === undefined ? 0 : Math.ceil(payload.total / payload.per_page);

  // const goTo = p =>
  //   router.push({
  //     pathname: "/interventions/abandon",
  //     query: {
  //       ...match.location.query,
  //       page: p
  //     }
  //   });

  // const pagination = (
  //   <BoundedPagination
  //     total={payload && payload.total}
  //     perPage={payload && payload.per_page}
  //     count={count}
  //     current={currentPage}
  //     onClick={goTo}
  //   />
  // );

  return (
    <>
      <PageHeader>
        <Title>Abandon matériels (7 derniers jours)</Title>
      </PageHeader>
      <div>
        <BilanList>
          {(payload && payload.length > 0) &&
            payload.map((abandoned, idx) => (
              <AbandonmentDay key={idx} dayData={abandoned} />
            ))
          }
        </BilanList>
      </div>
    </>
  );
}

export default Abandonment;

const AbandonmentDay = ({dayData}) => {
  const ch_list = uniqBy(dayData.abandoned || [], e => e.display_name_ch?.toLowerCase()).map(e => e.display_name_ch);

  const handleExport = async (ev) => {
    ev.preventDefault();
    const locals = getAbondonmentExport(dayData);
    const template = CLIP_TEXT_TEMPLATE_ABANDONED_DAY;
    const renderer = Mustache.render(template, locals);
    await clipboard.writeText(renderer);
    toast("Le contenu de l'abandon a été copié dans votre presse papier.", {
      type: 'info',
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  return (
    <BilanCard>
      <Row>
        <h3>{dayData.date}</h3>
        <Button type="button" onClick={ev => handleExport(ev)}>Exporter</Button>
      </Row>
      <div>
        {ch_list.map((chName, idx) =>
            <AbandonmentCh key={idx} chName={chName} abandonedChData={dayData.abandoned.filter(e => e.display_name_ch === chName)} />
        )}
      </div>
    </BilanCard>
  )
}

const AbandonmentCh = ({chName, abandonedChData}) => {
  const totalItems = [];

  abandonedChData.map(data => {
  data.abandoned_items?.map(elem => {
      const idx = totalItems.findIndex(e => e.label === elem);
      if (idx === -1) {
        totalItems.push({
          label: elem,
          count: 1,
        }) ;
      } else {
        totalItems[idx].count++;
      }
    })
  });

  return (
    <>
      <h4>{(!chName || chName.length === 0) ? 'Destination NR' : chName}</h4>
      <Row>
        <SplitList>
          <p>Liste&thinsp;:</p>
          {totalItems.map((total, idx) => (
            <li key={idx}>{total.count} {total.label}</li>
          ))}
        </SplitList>
        <SplitList>
          <p>Détails&thinsp;:</p>
          {abandonedChData.map((elem, idx) => (
            <li key={idx}>
              <h5>Véhicule&thinsp;: {(elem.display_name_vehicle && elem.display_name_vehicle.length > 0) ? elem.display_name_vehicle : 'NR'}</h5>
              <h6>Service&thinsp;: {(elem.display_name_ch_service && elem.display_name_ch_service.length > 0) ? elem.display_name_ch_service : 'NR'}</h6>
              <ul>
                {elem.abandoned_items.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </ul>
            </li>
          ))}
        </SplitList>
      </Row>
    </>
  )
}
