import React, { useContext } from "react";
import styled from "styled-components";
import ReactSelect, { components } from "react-select";
import ThemeModeContext from "./useDarkMode";
import theme, { getSelectTheme } from "./theme";

const SelectReact = styled(ReactSelect)``;

function Select (props) {
  const {themeMode} = useContext(ThemeModeContext);
  const reactSelectTheme = getSelectTheme(themeMode);
  const customStyles = {
    control: base => ({
      ...base,
      minHeight: 38,
      transition: "background-color 0.3s ease-in-out"
    })
  };

  return (
    <SelectReact
      theme={(theme) => ({
        ...theme,
        colors: {
          ...reactSelectTheme
        }
      })}
      styles={customStyles}
      {...props}
    />
  );
};

const ItemCounter = styled.div`
  position: absolute;
  right: 0px;
  padding: 2px 10px;
  border-radius: 20px;
  background-color: ${theme.blueBilan};
`;

export const ValueContainerLimitSelected = ({ children, ...props}) => {
  const hasValue = Array.isArray(children[0]);
  const firstValue = children[0]?.[0];

  return (
    <components.ValueContainer {...props}>
      {hasValue
        ? [firstValue, children[1]]
        : children
      }
      {(hasValue && children[0].length > 1) && <ItemCounter title={children[0].slice(1).map(i => i.key)} >+ {children[0].length - 1}</ItemCounter>}
    </components.ValueContainer>
  );
};

export default Select;
