import React, { useState, useEffect, useContext, useMemo } from "react";
import http from "../../http";
import { useQuery } from "react-query";
import {toast} from "react-toastify";
import * as Sentry from "@sentry/browser";
import { isAbortError } from "../../utils";
import CurrentUserContext from "../../CurrentUserContext";
import { utils as syoUtils } from "@nfsave/syo-bilan";
import queryString from "query-string";

import PageHeader, {Title} from "../../ui/PageHeader";
import LoadingSpinner from "../../ui/LoadingSpinner";
import BoundedPagination from "../../ui/BoundedPagination";
import Button from "../../ui/Button";
import Label from "../../ui/Label";
import Input from "../../ui/Input";
import styled from "styled-components";
import theme from "../../ui/theme";
import SearchBarCompo, { SearchBar } from "../../ui/SearchBar";
import Select, { ValueContainerLimitSelected as ValueContainer } from "../../ui/Select";
import LoaderBarContext from "../../ui/useLoaderBar";
import ListSsoSmp from '../ListSsoSmp';

const FilterBar = styled.form`
  width: 100%;

  div.row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    > * {
      padding-bottom: ${theme.small};
    }
    > *:not(:last-child) {
      padding-right: ${theme.small};
    }
    & .item-primary {
      width: 35%;
    }
    & .item-secondary {
      width: 30%;
    }
  }

  div.rowWrap {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    > * {
      padding-bottom: ${theme.small};
    }
    > *:not(:last-child) {
      padding-right: ${theme.small};
    }
    > div {
      width: 25%;
    }
  }

  div.rowAction {
    display: flex;
    justify-content: flex-end;
    > button {
      margin-bottom: ${theme.small};
    }
    > button:first-child {
      margin-right: ${theme.small};
    }
  }

  @media (max-width: 1000px) {
    flex-direction: column;

    div.row {
      > *:not(:last-child) {
        padding-right: ${theme.small};
      }
    }

    div.rowWrap {
      > *:nth-child(even) {
        padding-right: 0;
      }
      > div {
        width: 50%;
      }
    }
  }

  @media (max-width: 680px) {
    div.row {
      flex-direction: column;
      > * {
        padding-right: 0 !important;
      }
      & .item-primary,
      & .item-secondary {
        width: 100%;
      }
    }

    div.rowWrap {
      > *:not(:last-child) {
        padding-right: 0;
      }
      > div {
        width: 100%;
      }
    }

    div.rowAction {
      flex-direction: column;
      > button {
        width: 100%;
      }
      > button:first-child {
        margin-right: 0;
      }
    }
  }
`;

function Archive({ match, router }) {
  const {currentUser} = useContext(CurrentUserContext);
  const { setLoaderBar } = useContext(LoaderBarContext);

  const controller = new AbortController();

  const {
    isLoading: payloadIsLoading,
    isFetching: payloadIsFetching,
    data: payload,
    refetch: refetchPayload,
  } = useQuery(
    "archiveBilansSsoSmp",
    async () => {
      setLoaderBar(true);
      const options = {};
      if (match.location.query.page) options.page = match.location.query.page;
      if (match.location.query.createdAfter) options.created_after = match.location.query.createdAfter;
      if (match.location.query.createdBefore) options.created_before = match.location.query.createdBefore;
      if (match.location.query.createdEntity) options.created_entity = match.location.query.createdEntity;
      if (match.location.query.vehicleNames) {
        if (Array.isArray(match.location.query.vehicleNames)) {
          options.vehicle_names = match.location.query.vehicleNames;
        } else if (typeof match.location.query.vehicleNames === "string") {
          options.vehicle_names = [match.location.query.vehicleNames];
        };
      };
      if (match.location.query.firstResponderSerial) options.first_responder_serial = match.location.query.firstResponderSerial;

      const searchParamsString = queryString.stringify(options, {arrayFormat: 'bracket'});

      setFilters();

      try {
        return await http
        .get(`bilans/sso_archive`, {
          signal: controller.signal,
          searchParams: searchParamsString
        })
        .json()
        .then(res => {
          return res
        })
      } catch (error) {
        if (isAbortError(error)) return;
        toast.warn(
          `Une erreur est survenue pendant la récupération des interventions archivées.`,
          {
            toastId: "archiveBilansSsoSmp"
          }
        );
        console.error(error);
        Sentry.captureException(error);
      } finally {
        setLoaderBar(false);
      };
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );

  const {
    isLoading: vehiclesIsLoading,
    data: vehicles,
  } = useQuery(
    ["archiveBilansSsoSmp", "vehicles"],
    async () => {
      return await http
      .get(`vehicles.json`, {
        signal: controller.signal,
      })
      .json()
      .then(res => {
        const vehicles = res.data.sort((left, right) => {
          const lhs = (left.name || "").toUpperCase();
          const rhs = (right.name || "").toUpperCase();
          return lhs > rhs ? 1 : -1;
        });
        let temp = [];
        vehicles.map(v => temp.push({
            value: v.name,
            label: v.name
          })
        );
        return temp;
      })
      .catch(error => {
        if (isAbortError(error)) return;
        console.error(error);
        Sentry.captureException(error);
        toast.warn("Une erreur est survenue lors de la récupération des engins");
        throw error;
      });
    },
    {cacheTime: 0},
  );

  const [filterCreatedAfter, setFilterCreatedAfter] = useState(match.location.query.createdAfter || "");
  const [filterCreatedBefore, setFilterCreatedBefore] = useState(match.location.query.createdBefore || "");
  const [filterSortBilan, setFilterSortBilan] = useState(match.location.query.createdEntity || "bilans");
  const [filterInter, setFilterInter] = useState(match.location.query.firstResponderSerial || "");
  const [filterVehicle, setFilterVehicle] = useState(match.location.query.vehicleNames || []);

  const currentPage = parseInt(match.location.query.page || "1", 10);

  const setFilters = () => {
    setFilterCreatedAfter(match.location.query.createdAfter || "");
    setFilterCreatedBefore(match.location.query.createdBefore || "");
    setFilterSortBilan(match.location.query.createdEntity || "bilans");
    setFilterInter(match.location.query.firstResponderSerial || "");
    setFilterVehicle(match.location.query.vehicleNames || []);
  };

  const sortBilanOptions = [
    {value: "bilans", label: "Réception du SSO SMP"},
    {value: "interventions", label: "Création du SSO SMP"},
  ];

  useEffect(() => {
    if (Object.keys(match.location.query).length === 0) handleResetFilter();
    refetchPayload();
  }, [ match, refetchPayload ]);

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const handleFormSubmit = ev => {
    ev.preventDefault();
    const options = {};
    options.createdEntity = filterSortBilan || "bilans";
    if (filterCreatedAfter !== "") options.createdAfter = filterCreatedAfter;
    if (filterCreatedBefore !== "") options.createdBefore = filterCreatedBefore;
    if (filterVehicle !== "" && filterVehicle.length > 0) options.vehicleNames = filterVehicle;
    if (filterInter !== "") options.firstResponderSerial = filterInter;

    router.push({
      pathname: "/interventions/sso-smp-archive",
      query: {
        page: "1",
        ...options
      }
    });
  };

  const handleFilterCreatedAfterChange = ev => {
    setFilterCreatedAfter(ev.target.value);
  };

  const handleFilterCreatedBeforeChange = ev => {
    setFilterCreatedBefore(ev.target.value);
  };

  const handleSortBilanChange = ev => {
    setFilterSortBilan(ev.value);
  };

  const handleFilterInterChange = ev => {
    setFilterInter(ev.target.value);
  };

  const handleFilterVehicleChange = ev => {
    if (ev ==  null) {
      setFilterVehicle([]);
    } else {
      const v = Array.isArray(ev) ? ev.map(x => x.label) : [];
      setFilterVehicle(v);
    }
  };

  const handleSelectAllVehicleType = (text) => {
    const temp = vehicles.filter(v => v.label.toLowerCase().includes(text.toLowerCase())).map(v => v.label);
    setFilterVehicle([...temp]);
  };

  const btnVehiclesFilter = useMemo(() => {
    if (vehicles) {
      return {
        vsm: vehicles.filter(v => v.label.toLowerCase().includes("vsm")).map(v => v.label),
        vls: vehicles.filter(v => v.label.toLowerCase().includes("vls")).map(v => v.label),
        vsav: vehicles.filter(v => v.label.toLowerCase().includes("vsav")).map(v => v.label),
      };
    }
    return null;
  }, [ vehicles ]);

  const handleResetFilter = () => {
    setFilterCreatedAfter("");
    setFilterCreatedBefore("");
    setFilterSortBilan("bilans");
    setFilterInter("");
    setFilterVehicle([]);
  };

  const count =
    payload === null || payload === undefined ? 0 : Math.ceil(payload.total / payload.per_page);

  const goTo = p =>
    router.push({
      pathname: "/interventions/sso-smp-archive",
      query: {
        ...match.location.query,
        page: p
      }
    });

  const pagination = (
    <BoundedPagination
      total={payload && payload.total}
      perPage={payload && payload.per_page}
      count={count}
      current={currentPage}
      onClick={goTo}
    />
  );

  return (
    <>
      <PageHeader>
        <Title>Interventions SSO SMP archivées</Title>
      </PageHeader>

      <SearchBarCompo canBeCollapsed={true}>
        <SearchBar>
          <FilterBar onSubmit={handleFormSubmit}>
            <div className="row">
              <div className="item-secondary">
                <Label htmlFor="filter-bilans-input">Filtrage par date de</Label>
                <Select
                  id="filter-bilans-input"
                  isSearchable={true}
                  isClearable={false}
                  options={sortBilanOptions}
                  value={sortBilanOptions.find(v => v.value === filterSortBilan) || null}
                  onChange={handleSortBilanChange}
                  placeholder=""
                />
              </div>
              <div className="item-primary">
                <Label htmlFor="filter-created-after-input">
                  {filterSortBilan === "bilans" ? "Réceptionnées" : "Créées"} à partir du
                </Label>
                <Input
                  id="filter-created-after-input"
                  type="date"
                  value={filterCreatedAfter}
                  max={filterCreatedBefore}
                  onChange={handleFilterCreatedAfterChange}
                />
              </div>
              <div className="item-primary">
                <Label htmlFor="filter-created-before-input">
                {filterSortBilan === "bilans" ? "Réceptionnées" : "Créées"} jusqu'au
                </Label>
                <Input
                  id="filter-created-before-input"
                  type="date"
                  value={filterCreatedBefore}
                  min={filterCreatedAfter}
                  onChange={handleFilterCreatedBeforeChange}
                />
              </div>
            </div>
            <div className="rowWrap">
              <div>
                <Label htmlFor="filter-inter-input">
                  {syoUtils.hasPerm(currentUser, 'web:interventions:primo-intervenant:to:numero-intervention')
                    ? "Numéro d'intervention" : "Numéro primo-intervenant"}
                </Label>
                <Input
                  id="filter-inter-input"
                  type="text"
                  value={filterInter}
                  onChange={handleFilterInterChange}
                  placeholder="Numéro complet"
                />
              </div>
              {(!vehiclesIsLoading && vehicles && vehicles.length > 0) && (
                <>
                  <div>
                    <Label htmlFor="filter-vehicles-input">Véhicule(s)</Label>
                    <Select
                      id="filter-vehicles-input"
                      isSearchable
                      isClearable
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      options={vehicles}
                      value={vehicles.filter(obj => filterVehicle.includes(obj.label))}
                      onChange={handleFilterVehicleChange}
                      placeholder="Véhicule(s)"
                      noOptionsMessage={() => "Aucun véhicule"}
                      components={{
                        ValueContainer
                      }}
                    />
                  </div>
                  {(btnVehiclesFilter && (btnVehiclesFilter.vsm.length !== 0 || btnVehiclesFilter.vsav.length !== 0 || btnVehiclesFilter.vls.length !== 0)) && (
                    <div>
                      <Label>Sélectionner tous les véhicules</Label>
                      <div>
                        {(btnVehiclesFilter && btnVehiclesFilter.vsm.length > 0) && (
                          <Button type="button" style={{marginRight: theme.thin}} onClick={() => handleSelectAllVehicleType("vsm")}>VSM</Button>
                        )}
                        {(btnVehiclesFilter && btnVehiclesFilter.vsav.length > 0) && (
                          <Button type="button" style={{marginRight: theme.thin}} onClick={() => handleSelectAllVehicleType("vsav")}>VSAV</Button>
                        )}
                        {(btnVehiclesFilter && btnVehiclesFilter.vls.length > 0) && (
                          <Button type="button" style={{marginRight: theme.thin}} onClick={() => handleSelectAllVehicleType("vls")}>VLS</Button>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="rowAction">
              <Button type="button" className="warn" onClick={() => handleResetFilter()}>Réinitialiser les filtres</Button>
              <Button className="filled" type="submit">Appliquer les filtres</Button>
            </div>
          </FilterBar>
        </SearchBar>
      </SearchBarCompo>

      <div>
        {payloadIsLoading ? (
          <LoadingSpinner className="center vh-50" />
        ) : (
          <>
            {payload === undefined ? (
              <>
                <div style={{marginLeft: "1rem", marginTop: "1rem"}}>
                  <h3>Une erreur est survenue pendant la récupération des interventions SSO SMP archivées.</h3>
                  <p>Veillez à disposer d'une connexion internet valide.</p>
                </div>
              </>
            ) : (
              <>
                <div style={{marginBottom: ".5rem", marginTop: "1rem"}}>{pagination}</div>
                {payloadIsFetching ? (
                  <LoadingSpinner className="center vh-50" />
                ) : (
                  <>
                    <ListSsoSmp
                      bilans={payload.bilans}
                      listingIdPerm="web:interventions_inactive:listing:identite"
                      showPriority={syoUtils.hasPerm(currentUser, "web:intervention:priorite")}
                      sortBilan={match.location.query.createdEntity || "bilans"}
                    />
                    <div>{pagination}</div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default Archive;
