import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import http from '../../http';
import * as Sentry from "@sentry/browser";
import { isAbortError } from '../../utils';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import { MobilisationClass } from '../class/Mobilisation';
import { MobilisationDispatchClass } from '../class/MobilisationDispatch';
import { MobilisationVictimClass } from '../class/MobilisationVictim';
import { data as syoData, utils as syoUtils } from "@nfsave/syo-bilan";
import LoaderBarContext from '../../ui/useLoaderBar';

import { Actions, FloatingHeader, Title } from "../../ui/PageHeader";
import Button from '../../ui/Button';
import LoadingSpinner from '../../ui/LoadingSpinner';
import FormGroup from "../../ui/FormGroup";
import Label from "../../ui/Label";
import Input from "../../ui/Input";
import Select from "../../ui/Select";
import { Container, Column, Row as RowCntnr } from "../../ui/FlexGrid";
import theme from '../../ui/theme';
import { Disclosure } from '../../ui/Disclosure';
import Textarea from '../../ui/Textarea';
import DateTimePicker from '../../ui/DateTimePicker';
import DeleteIcon from '@mui/icons-material/Delete';
import RadioGroup from '../../ui/RadioGroup';
import CurrentUserContext from '../../CurrentUserContext';

const Cntnr = styled.div`
  padding: 0 ${theme.thin};
  margin-top: ${theme.small};
`;

const Row = styled(RowCntnr)`
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const ContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const FormGroupRaw = styled(FormGroup)`
  display: flex;
  width: 100%;
  gap: ${theme.thin};

  & div.age {
    width: 100%;
  }
  & div.unit {
    width: fit-content;
  }
`;

const MobilizationTrigger = () => {
  const controller = new AbortController();
  const { currentUser } = useContext(CurrentUserContext);

  const date = DateTime.now();
  const { loaderBarState, setLoaderBar } = useContext(LoaderBarContext);

  const hasSerialAcesoPerm = syoUtils.hasPerm(currentUser, "web:mobilisations:num-inter-prefix-ace");

  const [ mob, setMob ] = useState(new MobilisationClass(hasSerialAcesoPerm));
  const [today, setToday] = useState({
    year: date.year,
    month: date.month,
    day: date.day,
    hour: date.hour,
    minute: date.minute,
  });

  const {
    isLoading: motivesIsLoading,
    data: motives,
  } = useQuery(
    ["mobilizations", "mobilizationTrigger", "motives"],
    async () => {
      return await http
      .get(`motives.json`, {
        signal: controller.signal,
      })
      .json()
      .then(res => res.data)
      .catch(error => {
        if (isAbortError(error)) return;
        console.error(error);
        Sentry.captureException(error);
        toast.warn("Une erreur est survenue lors de la récupération des motifs d'interventions");
        throw error;
      });
    },
  );

  const {
    isLoading: vehiclesIsLoading,
    data: vehicles,
  } = useQuery(
    ["mobilizations", "mobilizationTrigger", "vehicles"],
    async () => {
      return await http
      .get(`vehicles.json`, {
        signal: controller.signal,
      })
      .json()
      .then(res => {
        return res.data.sort((left, right) => {
          const lhs = (left.name || "").toUpperCase();
          const rhs = (right.name || "").toUpperCase();
          return lhs > rhs ? 1 : -1;
        });
      })
      .catch(error => {
        if (isAbortError(error)) return;
        console.error(error);
        Sentry.captureException(error);
        toast.warn("Une erreur est survenue lors de la récupération des engins");
        throw error;
      });
    },
  );

  const {
    isLoading: pListsIsLoading,
    data: pLists,
  } = useQuery(
    [ "mobilizations", "mobilizationTrigger", "parameterized-lists" ],
    async () => {
      return await http
      .get(`parameterized_lists.json`, {
        signal: controller.signal,
      }).json()
      .then(res => {
        return res.data.find(obj => obj.name === 'restricted-locations')?.items;
      })
      .catch(error => {
        if (isAbortError(error)) return;
        console.error(error);
        Sentry.captureException(error);
        toast.warn("Une erreur est survenue lors de la récupération des listes paramétrées");
        throw error;
      });
    },
  );

  const handleReset = () => {
    setMob(new MobilisationClass(hasSerialAcesoPerm));
    const newDate = DateTime.now();
    setToday({
      year: newDate.year,
      month: newDate.month,
      day: newDate.day,
      hour: newDate.hour,
      minute: newDate.minute,
    });
  };

  const handleMobSubmit = async (ev) => {
    try {
      ev.preventDefault();
      setLoaderBar(true);
      const payload = mob;
      payload.mobilised_at = DateTime.now().toISO();
      payload.victims.map((elem) => {
        if (elem.gender === 0) elem.gender = null;
        if (elem.age) elem.age = Number(elem.age);
        return elem;
      });
      await http.post(`mobilisations2.json`, {
        json: payload,
        signal: controller.signal
      });
      toast.success("Mobilisation déclenchée avec succès");
      handleReset();
    } catch (error) {
      toast.error("Le déclenchement de la mobilisation a échoué");
      console.error(error);
      Sentry.captureException({
        message: "Le déclenchement de la mobilisation a échoué",
        error: error
      });
    } finally {
      setLoaderBar(false);
    };
  };

  const handleMobChange = (field, value) => {
    if (value === undefined) value = null;
    if (field === "age" && isNaN(value)) return;
    if (field === "vehicles") {
      if (value ==  null) {
        value = [];
      } else {
        const v = Array.isArray(value) ? value.map(x => x.name) : [];
        value = v;
      };
    };
    setMob({
      ...mob,
      [field]: value
    });
  };

  const handleMobDispatchChange = (publicId, field, value) => {
    const index = mob.dispatches.findIndex(obj => obj.public_id === publicId);
    if (index === -1) return;
    const elem = mob.dispatches[index];
    switch (field) {
      case 'dispatched_at':
        elem['dispatched_at'] = DateTime.fromObject(value).toISO();
        break;
      case 'vehicle_name':
        elem['vehicle_id'] = value?.id || undefined;
        elem['vehicle_name'] = value?.name || undefined;
        break;
      default:
        break;
    }
    mob.dispatches[index] = elem;
    setMob({...mob});
  };

  const handleMobVictimChange = (idx, field, value) => {
    if (field === "age" && isNaN(value)) return;
    const elem = mob.victims[idx];
    elem[field] = value;
    mob.victims[idx] = elem;
    setMob({...mob});
  };

  const handleNewMobDispatch = () => {
    setMob({
      ...mob,
      dispatches: [...mob.dispatches, new MobilisationDispatchClass()]
    });
  };

  const handleNewMobVictim = () => {
    setMob({
      ...mob,
      victims: [...mob.victims, new MobilisationVictimClass()]
    });
  };

  const handleDeleteArrayElem = (scope, idx) => {
    mob[scope].splice(idx, 1);
    setMob({ ...mob });
  };

  useEffect(() => {
    return () => {
      controller.abort();
    }
  }, []);

  return (
    <>
      <FloatingHeader>
        <Title>Déclenchement mobilisation</Title>
        <Actions>
          <Button type="button" onClick={handleReset} disabled={loaderBarState} className="warn">Réinitialiser tous les champs</Button>
          <Button type="submit" onClick={handleMobSubmit} disabled={loaderBarState || mob.serial.length === 0}>Déclencher la mobilisation</Button>
        </Actions>
      </FloatingHeader>

      {(motivesIsLoading || vehiclesIsLoading || pListsIsLoading) ? (
        <LoadingSpinner className="center vh-50" />
      ): (
        <Cntnr>
          <form>
            <Disclosure
              title='Intervention'
            >
              <Container>
                <Row>
                  <Column>
                    <FormGroup>
                      <Label htmlFor="mobilisation-serial-input">Numéro d'intervention</Label>
                      <Input
                        id="mobilisation-serial-input"
                        value={mob.serial || ""}
                        onChange={(ev) => handleMobChange("serial", ev.target.value)}
                        required
                        placeholder="Requis"
                        invalid={mob.serial.length === 0}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="mobilisation-location_address-input">Lieu d'intervention</Label>
                      <Select
                        id="mobilisation-location_address-input"
                        isClearable={true}
                        isSearchable={true}
                        options={pLists}
                        value={pLists?.find(m => m.value === mob.location_address) || null}
                        getOptionLabel={option => `${option.value}`}
                        onChange={(value) => handleMobChange("location_address", value?.value)}
                        placeholder=""
                        noOptionsMessage={() => "Aucun lieu d'intervention"}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="mobilisation-location_city_name-input">Commune / ville</Label>
                      <Input
                        id="mobilisation-location_city_name-input"
                        type='text'
                        onChange={(ev) => handleMobChange("location_city_name", ev.target.value)}
                        value={mob.location_city_name || ""}
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup>
                      <Label htmlFor="mobilisation-nature-input">Motif d'intervention</Label>
                      <Select
                        id="mobilisation-nature-input"
                        isClearable={true}
                        isSearchable={true}
                        options={motives}
                        value={motives.find(m => m.value === mob.nature) || null}
                        getOptionLabel={option => `${option.value}`}
                        onChange={(value) => handleMobChange("nature", value?.value)}
                        placeholder=""
                        noOptionsMessage={() => "Aucun motif d'intervention"}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="mobilisation-location_address_details-input">
                        Observations sur l'emplacement où se déroule l'intervention
                      </Label>
                      <Textarea
                        id="mobilisation-location_address_details-input"
                        rows={4}
                        value={mob.location_address_details || ""}
                        onChange={(ev) => handleMobChange("location_address_details", ev.target.value)}
                      />
                    </FormGroup>
                  </Column>
                </Row>
              </Container>
            </Disclosure>

            <Disclosure
              title='ÉQUIPE&thinsp;&middot;&thinsp;S / VÉHICULE&thinsp;&middot;&thinsp;S'
            >
              <Container>
                {mob.dispatches.map((dispatch, idx) => (
                  <div key={dispatch.public_id}>
                    <ContainerHeader>
                      <h3>Équipe / véhicule {idx + 1}</h3>
                      <Button
                        className="warn"
                        type="button"
                        title="Supprimer l'équipe / véhicule"
                        onClick={() => handleDeleteArrayElem('dispatches', idx)}
                      >
                        <DeleteIcon />
                      </Button>
                    </ContainerHeader>
                    <Row>
                      <Column>
                        <FormGroup>
                          <Label htmlFor={`mobilisation-dispatch${idx}-vehicle_name-input`}>Équipe / véhicule</Label>
                          <Select
                            id={`mobilisation-dispatch${idx}-vehicle_name-input`}
                            isSearchable
                            isClearable
                            options={vehicles}
                            value={vehicles.find(obj => obj.id === dispatch.vehicle_id) || null}
                            onChange={(ev) => handleMobDispatchChange(dispatch.public_id, 'vehicle_name', ev)}
                            getOptionLabel={option => `${option.name}`}
                            getOptionValue={option => option.name}
                            placeholder=""
                            noOptionsMessage={() => "Aucun&thinsp;&middot;&thinsp;e équipe/véhicule"}
                            required
                          />
                        </FormGroup>
                      </Column>
                      <Column>
                        <FormGroup>
                          <Label htmlFor={`mobilisation-dispatch${idx}-dispatched_at-input`}>Heure de déclenchement</Label>
                          <DateTimePicker
                            id={`mobilisation-dispatch${idx}-dispatched_at-input`}
                            title='Heure de déclenchement'
                            picker='time'
                            empty={true}
                            today={today}
                            callback={(ev) => handleMobDispatchChange(dispatch.public_id, 'dispatched_at', ev)}
                          />
                        </FormGroup>
                      </Column>
                    </Row>
                    <hr />
                  </div>
                ))}
                <Button type='button' onClick={() => handleNewMobDispatch()}>Ajouter un&middot;e équipe / véhicule</Button>
              </Container>
            </Disclosure>

            <Disclosure
              title='Victime&thinsp;&middot;&thinsp;s'
            >
              <Container>
                {mob.victims.map((victim, idx) => (
                  <div key={idx}>
                    <ContainerHeader>
                      <h3>Victime {idx + 1}</h3>
                      <Button
                        className="warn"
                        type="button"
                        title='Supprimer la victime'
                        onClick={() => handleDeleteArrayElem('victims', idx)}
                      >
                        <DeleteIcon />
                      </Button>
                    </ContainerHeader>
                    <Row>
                      <Column>
                        <FormGroup>
                          <Label htmlFor={`mobilisation-victim${idx}-family_name-input`}>Nom de famille</Label>
                          <Input
                            id={`mobilisation-victim${idx}-family_name-input`}
                            type='text'
                            value={mob.victims[idx].family_name || ""}
                            onChange={ev => handleMobVictimChange(idx, 'family_name', ev.target.value)}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor={`mobilisation-victim${idx}-gender-input`}>Genre</Label>
                          <Select
                            id={`mobilisation-victim${idx}-gender-input`}
                            isSearchable
                            options={syoData.SEX_OPTIONS}
                            value={syoData.SEX_OPTIONS.find(h => h.value === victim.gender) || null}
                            onChange={(value) => handleMobVictimChange(idx, 'gender', value?.value)}
                          />
                        </FormGroup>
                        <FormGroupRaw>
                          <div className='age'>
                            <Label htmlFor={`mobilisation-victim${idx}-age-input`}>
                              Age de la victime
                            </Label>
                            <Input
                              id={`mobilisation-victim${idx}-age-input`}
                              type="tel"
                              value={mob.victims[idx].age || ""}
                              onChange={(ev) => handleMobVictimChange(idx, "age", ev.target.value)}
                            />
                          </div>
                          <div className='unit'>
                            <Label htmlFor={`mobilisation-victim${idx}-age_unit-input`}>
                              Unité de l'age
                            </Label>
                            <RadioGroup
                              id={`mobilisation-victim${idx}-age_unit-input`}
                              options={syoData.AGE_UNITS_OPTIONS}
                              radioValue={mob.victims[idx].age_unit}
                              onChange={ev => handleMobVictimChange(idx, 'age_unit', ev)}
                            />
                          </div>
                        </FormGroupRaw>
                      </Column>
                      <Column>
                        <FormGroup>
                          <Label htmlFor={`mobilisation-victim${idx}-given_name-input`}>Prénom</Label>
                          <Input
                            id={`mobilisation-victim${idx}-given_name-input`}
                            type='text'
                            value={mob.victims[idx].given_name || ""}
                            onChange={ev => handleMobVictimChange(idx, 'given_name', ev.target.value)}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor={`mobilisation-victim${idx}-birth_date-input`}>Date de naissance</Label>
                          <Input
                            id={`mobilisation-victim${idx}-birth_date-input`}
                            type='date'
                            value={mob.victims[idx].birth_date || ""}
                            onChange={ev => handleMobVictimChange(idx, 'birth_date', ev.target.value)}
                          />
                        </FormGroup>
                      </Column>
                    </Row>
                    <hr />
                  </div>
                ))}
                <Button type='button' onClick={() => handleNewMobVictim()}>Ajouter une victime</Button>
              </Container>
            </Disclosure>
          </form>
        </Cntnr>
      )}
    </>
  );
};

export default MobilizationTrigger;
