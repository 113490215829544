import React, { useContext, useEffect } from "react";
import http from "../../http";
import { useQuery } from "react-query";
import * as Sentry from "@sentry/browser";
import { toast } from "react-toastify";
import { isAbortError } from "../../utils";
import LoaderBarContext from "../../ui/useLoaderBar";

import PageHeader, { Title } from "../../ui/PageHeader";
import Form from "../Form";
import { Column, Container, Row } from "../../ui/FlexGrid";
import LoadingSpinner from "../../ui/LoadingSpinner";

function Create({ router }) {
  const controller = new AbortController();

  const { setLoaderBar } = useContext(LoaderBarContext);

  const { isLoading: rolesIsLoading, data: roles } = useQuery(
    "roles",
    async () => {
      return await http
      .get("roles.json", {
        signal: controller.signal,
      })
      .json()
      .then(res => {
        return res.filter(role => !role.name.includes("--ARCHIVÉ--")) || [];
      })
      .catch(error => {
        if (isAbortError(error)) return;
        console.error(error);
        Sentry.captureException(error);
        toast.warn("Une erreur est survenue lors de la récupération des roles utilisateurs");
        throw error;
      });
    },
    {cacheTime: 0},
  );

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const handleFormSubmit = async form => {
    setLoaderBar(true);
    try {
      await http.post(`user`, { json: { user: form } }).json();
      toast.success(`L'utilisateur ${form.full_name} a été ajouté avec succès.`);
      router.push("/utilisateurs");
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      toast.error(`Impossible d'ajouter l'utilisateur ${form.full_name}.`);
      throw error;
    } finally {
      setLoaderBar(false);
    };
  };

  return (
    <>
      <PageHeader>
        <Title>Ajout d'un nouvel utilisateur</Title>
      </PageHeader>
      {rolesIsLoading ? (
        <LoadingSpinner className="center vh-50" />
      ) : (
        <Container>
          <Row>
            <Column>
              <Form roles={roles} onSubmit={handleFormSubmit} />
            </Column>
          </Row>
        </Container>
      )}
    </>
  );
}

export default Create;
