import React, { useMemo, useState, useEffect, useContext } from "react";
import http from "../../http";
import { useQuery } from "react-query";
import * as Sentry from "@sentry/browser";
import { toast } from "react-toastify";
import { isAbortError } from "../../utils";
import InfiniteScroll from "react-infinite-scroll-component";
import LoaderBarContext from "../../ui/useLoaderBar";

import styled from "styled-components";
import theme from "../../ui/theme";
import PageHeader, {Actions, Cntnr, Title} from "../../ui/PageHeader";
import Button, {LinkButton} from "../../ui/Button";
import PermissionsList from "../../ui/PermissionsList";
import LoadingSpinner from "../../ui/LoadingSpinner";
import SearchBarCompo, { FilterBar, SearchBar } from "../../ui/SearchBar";
import Input, { InputSearchCntnr, InputSearchIcon } from "../../ui/Input";
import SearchIcon from '@mui/icons-material/Search';
import PersonIcon from "@mui/icons-material/Person";
import Tag from "../../ui/Tag";
import { BtnTabs, Tabs, TabsCntnr } from "../../ui/Tabs";

const RoleContainer = styled.div`
  border-top: 1px solid ${theme.grey5};
  padding: ${theme.thin} 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const RoleContent = styled.div``;

const RoleActions = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${theme.thin};

  & > *:not(:last-child) {
    margin-bottom: ${theme.thin};
  }

  @media (max-width: 800px) {
    margin-left: 0;
    width: 100%;
  }
`;

const RoleHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const RoleName = styled.h2`
  font-size: 1.25rem;
  margin-top: ${theme.thin};
`;

const RoleCounter = styled.p`
  display: flex;
  align-items: center;
`;

const RoleDescription = styled.p`
`;

const PermissionCounter = styled.p`
  margin-top: ${theme.thin};
`;

const perPage = 5;

export default function Roles({router}) {
  const controller = new AbortController();

  const { loaderBarState, setLoaderBar } = useContext(LoaderBarContext);

  const [ search, setSearch ]= useState("");
  const [ page, setPage] = useState(perPage);
  const [ hasMore, setHasMore ] = useState(false);
  const [ tabs, setTabs ] = useState("active");
  const [ actived, setActived ] = useState([]);
  const [ archived, setArchived ] = useState([]);

  const {
    isLoading: rolesIsLoading,
    isFetching: rolesIsFetching,
    data: roles,
    refetch: refetchRoles,
  } = useQuery(
    "roles",
    async () => {
      return await http
      .get(`roles.json`, {
        signal: controller.signal,
      })
      .json()
      .then(res => {
        let tempActive = [];
        let tempArchived = [];
        res.map(role => {
          if (role.name.includes("--ARCHIVÉ--")) {
            role.nameRaw = role.name.split("--ARCHIVÉ--").join("");
            tempArchived.push(role);
          } else {
            role.nameRaw = role.name;
            tempActive.push(role);
          };
        });
        tempArchived = alphabeticSort(tempArchived);
        tempActive = alphabeticSort(tempActive);
        setArchived([...tempArchived]);
        setActived([...tempActive]);
      })
      .catch(error => {
        if (isAbortError(error)) return;
        console.error(error);
        Sentry.captureException(error);
        toast.warn("Une erreur est survenue lors de la récupération des rôles");
        throw error;
      });
    },
    {
      onSuccess: () => {
        setPage(perPage);
      },
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const alphabeticSort = (array) => {
    return array.sort((left, right) => {
      const lhs = (left.nameRaw || "").toUpperCase();
      const rhs = (right.nameRaw || "").toUpperCase();
      return lhs > rhs ? 1 : -1;
    });
  };

  const handleRoleArchiving = async (role) => {
    if (loaderBarState) return;
    setLoaderBar(true);
    const name = "--ARCHIVÉ--" + role.name;
    const expPerm = role.experimental_permissions?.map(p => p.id) || [];
    const perm = role.permissions?.map(p => p.id) || [];
    const payload = {
      name,
      description: role.description,
      permission_ids: [...perm, ...expPerm],
    };
    await http.patch(`roles/${role.id}.json`, {
      json: { role: payload },
      signal: controller.signal,
    }).json()
    .then(() => {
      toast.success("Le rôle a bien été archivé.");
      refetchRoles();
    })
    .catch(error => {
      if (isAbortError(error)) return;
      console.error(error);
      Sentry.captureException(error);
      toast.warn("Une erreur est survenue lors de l'archivage du rôle");
      throw error;
    })
    .finally(() => {
      setLoaderBar(false);
    });
  };

  const handleRoleRestoring = async (role) => {
    if (loaderBarState) return;
    setLoaderBar(true);
    const name = role.name.split("--ARCHIVÉ--").join("");
    const expPerm = role.experimental_permissions?.map(p => p.id) || [];
    const perm = role.permissions?.map(p => p.id) || [];
    const payload = {
      name,
      description: role.description,
      permission_ids: [...perm, ...expPerm],
    };
    await http.patch(`roles/${role.id}.json`, {
      json: { role: payload },
      signal: controller.signal,
    }).json()
    .then(() => {
      toast.success("Le rôle a bien été restauré.");
      refetchRoles();
    })
    .catch(error => {
      if (isAbortError(error)) return;
      console.error(error);
      Sentry.captureException(error);
      toast.warn("Une erreur est survenue lors de la restauration du rôle");
      throw error;
    })
    .finally(() => {
      setLoaderBar(false);
    });
  };

  const handleTabsChange = (option) => {
    setTabs(option);
  };

  const clearFilter = () => setSearch("");

  const fetchMoreData = () => {
    if (rolesInfinite.length >= rolesFiltered.length) return setHasMore(false);
    setPage(page + perPage);
  };

  const searchRoles = (roles) => {
    return roles.filter(r => r.nameRaw.toLowerCase().includes(search.toLocaleLowerCase()));
  };

  const getRolesList = useMemo(() => {
    setPage(perPage);
    switch (tabs) {
      case "archived":
        return archived;

      default:
        return actived;
    };
  }, [ tabs, archived, actived ]);

  const rolesFiltered = useMemo(() => {
    return searchRoles(getRolesList);
  }, [ search, archived, actived, tabs ]);

  const rolesInfinite = useMemo(() => {
    const temp = rolesFiltered.slice(0, page);
    temp.length >= rolesFiltered.length ? setHasMore(false) : setHasMore(true);
    return temp;
  }, [ page, rolesFiltered ]);

  const tabsBtnOptions = [
    {
      option: "active",
      length: searchRoles(actived).length || 0,
      text: "Actif",
    }, {
      option: "archived",
      length: searchRoles(archived).length || 0,
      text: "Archivé",
    },
  ];

  return <>
    <PageHeader>
      <Title>Rôles utilisateurs</Title>
      <Actions>
        <LinkButton to="/gestionnaire/roles/new">Ajouter</LinkButton>
      </Actions>
    </PageHeader>

    <SearchBarCompo
      hasTag={true}
      tags={<>
        {search.length > 0 && <Tag text={search} callback={clearFilter} />}
      </>}
    >
      <SearchBar>
        <FilterBar>
          <InputSearchCntnr>
            <InputSearchIcon>
              <SearchIcon />
            </InputSearchIcon>
            <Input
              type="search"
              value={search}
              onChange={(ev) => setSearch(ev.target.value)}
              placeholder="Rôle"
            />
          </InputSearchCntnr>
          <Button className="warn" onClick={clearFilter}>Réinitialiser les filtres</Button>
        </FilterBar>
      </SearchBar>
    </SearchBarCompo>

    {rolesIsLoading || rolesIsFetching ? (
      <LoadingSpinner className="center vh-50" />
    ) : (
      <>
        <TabsCntnr>
          <Tabs>
            {tabsBtnOptions.map((btn, idx) => (
              <BtnTabs
                key={idx}
                onClick={() => handleTabsChange(btn.option)}
                className={`tabButton icon-right${tabs === btn.option ? " active" : ""}`}
              >
                {btn.text}{btn.length > 1 ? "s" : ""} {btn.length}
              </BtnTabs>
            ))}
          </Tabs>
        </TabsCntnr>
        <InfiniteScroll
          dataLength={rolesInfinite.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<div style={{height: "75px", marginTop: theme.medium}}>
            <LoadingSpinner className="center" />
          </div>}
        >
          <Cntnr>
            {rolesInfinite.length > 0 ? (
            <>
              {rolesInfinite.map(role => (
                <RoleContainer key={role.id}>
                  <RoleContent>
                    <RoleHeader>
                      <RoleName>{role.nameRaw}</RoleName>
                    </RoleHeader>
                    <RoleCounter>
                      Rôle assigné à {role.assigned_users_count}
                      <PersonIcon
                        role="img"
                        aria-hidden={false}
                        aria-label={`Utilisateur${role.assigned_users_count > 1 ? "s" : ""}`}
                      />
                    </RoleCounter>
                    <RoleDescription>{role.description}</RoleDescription>
                    <PermissionsList permissions={role.permissions} />
                    <PermissionCounter>Permissions expérimentales&thinsp;: {role.experimental_permissions.length}</PermissionCounter>
                  </RoleContent>
                  <RoleActions>
                    {tabs === "active" && (
                      <>
                        <LinkButton className="center" to={`/gestionnaire/roles/${role.id}/modifier`}>Modifier</LinkButton>
                        <LinkButton className="center" to={`/gestionnaire/roles/${role.id}/dupliquer`}>Dupliquer</LinkButton>
                      </>
                    )}
                    {tabs === "archived" ? (
                      <Button
                        onClick={() => handleRoleRestoring(role)}
                        className="warn"
                      >Restaurer</Button>
                    ) : (
                      <Button
                        onClick={() => handleRoleArchiving(role)}
                        disabled={role.assigned_users_count > 0 || loaderBarState}
                        className="warn"
                        title={role.assigned_users_count > 0 ? "Veuillez assigner les utilisateurs à un autre rôle avant de l'archiver" : ""}
                      >Archiver</Button>
                    )}
                  </RoleActions>
                </RoleContainer>
              ))}
            </>
            ) : (
              <p>Aucun rôle {tabs === "archived" ? "archivé" : "actif"}.</p>
            )}
          </Cntnr>
        </InfiniteScroll>
      </>
    )}
  </>;
}
