import React, { useContext, useEffect } from "react";
import { DateTime } from "luxon";
import { Link } from "found";
import http from "../../http";
import { useQuery } from "react-query";
import * as Sentry from "@sentry/browser";
import { toast } from "react-toastify";
import CurrentUserContext from "../../CurrentUserContext";
import CHARTS from "../charts";
import { isAbortError } from "../../utils";

import styled from "styled-components";
import Add from "@mui/icons-material/AddBox";
import PageHeader, { Actions, Title } from "../../ui/PageHeader";
import { Container, Row, Column as BaseColumn } from "../../ui/FlexGrid";
import Input from "../../ui/Input";
import { Preview } from "../DynamicChart";
import LoadingSpinner from "../../ui/LoadingSpinner";

const Column = styled(BaseColumn)`
  flex-basis: 320px;
  flex-grow: 0;
  flex-shrink: 0;
`;

const Card = styled.div`
  margin-bottom: 1rem;
`;

const CardTitle = styled.h4`
  text-align: center;
`;

const ChartContainer = styled.div`
  box-shadow: 0 0 5px #bbb;
  height: 150px;
`;

const BlockLink = styled(Link)`
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 57px;
`;

function Dashboard({ router, match }) {
  const controller = new AbortController();

  const { isLoading: chartsIsLoading, data } = useQuery(
    "charts",
    async () => {
      return await http
      .get(`charts`, {
        signal: controller.signal,
      })
      .json()
      .then(res => {
        return res;
      })
      .catch(error => {
        if (isAbortError(error)) return;
        console.error(error);
        Sentry.captureException(error);
        toast.warn("Une erreur est survenue lors de la récupération des graphiques");
        throw error;
      });
    },
  );

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const { currentUser } = useContext(CurrentUserContext);

  const canCreateCharts = () => true; //currentUser.organization_id === 1;

  let intervalStart = DateTime.local().startOf("month");
  if (typeof match.location.query.intervalStart !== "undefined") {
    intervalStart = DateTime.fromISO(
      match.location.query.intervalStart
    ).startOf("day");
  }
  let intervalEnd = DateTime.local().endOf("month");
  if (typeof match.location.query.intervalEnd !== "undefined") {
    intervalEnd = DateTime.fromISO(match.location.query.intervalEnd).endOf(
      "day"
    );
  }
  const defaultOrgs = [currentUser.organization_id];
  const charts = CHARTS.filter(c =>
    (c.organizations || defaultOrgs).includes(currentUser.organization_id)
  ) || [];
  const handleIntervalChange = boundName => ev => {
    if (ev.target.value == null || ev.target.value === "") {
      return;
    }
    router.push({
      pathname: "/statistiques/apercu",
      query: {
        ...match.location.query,
        [boundName]: ev.target.value
      }
    });
  };

  const chartConfig = form => {
    if (form.kind == null || form.kind.toLowerCase() === "xy") {
      return {
        xType: "time-utc",
        crosshair: true,
        gridLines: [{ kind: "horizontal" }],
        axis: [{ kind: "x" }, { kind: "y" }],
        series: form.dataSources.map(dataSource => ({
          kind: dataSource.display,
          curve: "curveMonotoneX",
          title: dataSource.label,
          source: dataSource.url
        }))
      };
    } else {
    }
  };

  return (
    <>
      <PageHeader>
        <Title>Statistiques</Title>
        <Actions>
          <span style={{ padding: ".5rem" }}>De</span>
          <Input
            style={{ width: 200 }}
            type="date"
            value={intervalStart.toISODate()}
            onChange={handleIntervalChange("intervalStart")}
          />
          <span style={{ padding: ".5rem" }}>à</span>
          <Input
            style={{ width: 200 }}
            type="date"
            value={intervalEnd.toISODate()}
            onChange={handleIntervalChange("intervalEnd")}
          />
        </Actions>
      </PageHeader>

      {(!chartsIsLoading && data) ? (
        <Container>
          <Row>
            {canCreateCharts() && (
              <Column>
                <Card>
                  <CardTitle>
                    <Link
                      to={{
                        pathname: `/statistiques/nouveau/`,
                        query: {
                          intervalStart: intervalStart.toISODate(),
                          intervalEnd: intervalEnd.toISODate()
                        }
                      }}
                    >
                      Nouveau graphique
                    </Link>
                  </CardTitle>
                  <ChartContainer>
                    <BlockLink
                      to={{
                        pathname: `/statistiques/nouveau/`,
                        query: {
                          intervalStart: intervalStart.toISODate(),
                          intervalEnd: intervalEnd.toISODate()
                        }
                      }}
                    >
                      <Add fontSize="large" />
                    </BlockLink>
                  </ChartContainer>
                </Card>
              </Column>
            )}

            {charts?.map((chart, id) => (
              <Column key={id}>
                <Card>
                  <CardTitle>
                    {chart.previewOnly === true ? (
                      chart.title
                    ) : (
                      <Link
                        to={{
                          pathname: `/statistiques/g/${chart.id}`,
                          query: {
                            intervalStart: intervalStart.toISODate(),
                            intervalEnd: intervalEnd.toISODate()
                          }
                        }}
                      >
                        {chart.title}
                      </Link>
                    )}
                  </CardTitle>
                  <ChartContainer>
                    <Preview
                      config={chart}
                      intervalStart={intervalStart}
                      intervalEnd={intervalEnd}
                    />
                  </ChartContainer>
                </Card>
              </Column>
            ))}

            {data.data.map((chart, id) => (
              <Column key={id}>
                <Card>
                  <CardTitle>
                    {chart.previewOnly === true ? (
                      chart.title
                    ) : (
                      <Link
                        to={{
                          pathname: `/statistiques/${chart.id}`,
                          query: {
                            intervalStart: intervalStart.toISODate(),
                            intervalEnd: intervalEnd.toISODate()
                          }
                        }}
                      >
                        {chart.title}
                      </Link>
                    )}
                  </CardTitle>
                  <ChartContainer>
                    <Preview
                      config={chartConfig(chart)}
                      intervalStart={intervalStart}
                      intervalEnd={intervalEnd}
                    />
                  </ChartContainer>
                </Card>
              </Column>
            ))}
          </Row>
        </Container>
      ) : (
        <LoadingSpinner className="center vh-50" />
      )}
    </>
  );
}

export default Dashboard;
