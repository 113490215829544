import {
  createBrowserRouter,
  hotRouteConfig,
  makeRouteConfig,
  RedirectException,
  Redirect,
  Route
} from "found";
import React, { useState, useEffect } from "react";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-vis/dist/style.css";
import "@reach/tabs/styles.css";
import { ThemeProvider } from "styled-components";
import ThemeModeContext, { useDarkMode } from "./ui/useDarkMode";
import { GlobalStyle } from "./ui/theme";
import * as Sentry from "@sentry/browser";

import Shell from "./Shell";
import Page404 from "./404";

import * as auth from "./auth";
import * as interventions from "./interventions";
import * as utilisateurs from "./utilisateurs";
import * as statistics from "./statistics";
import * as settings from "./settings";
import * as share from "./share";
import * as partners from "./partners";
import * as manager from "./manager";
import * as licenses from "./licenses";
import * as cgu from "./CGU";

import CurrentUserContext from "./CurrentUserContext";
import { signOut } from "./http";
import {QueryClient, QueryClientProvider} from "react-query";
import LoadingBar from "./ui/LoadingBar";
import LoaderBarContext, { useLoaderBar } from "./ui/useLoaderBar";

const BrowserRouter = createBrowserRouter({
  routeConfig: hotRouteConfig(
    makeRouteConfig(
      <Route>
        {auth.routes}
        {partners.routes}
        <Route
          getData={({ context }) => {
            if (context == null) {
              if (!window.location.pathname.split('/').includes('auth') && !localStorage.getItem('nfAuth')) {
                sessionStorage.setItem('nfTarget', window.location.pathname);
              }
              signOut();
              throw new RedirectException("/auth/sign-in");
            }
          }}
        >
          {cgu.routes}
          <Route
            getData={({context}) => {
              if (context.terms_of_usage_approval === null) {
                throw new RedirectException("/cgu")
              }
            }}
          >
            <Redirect from="/" to="/interventions/active" />
            <Route Component={Shell}>
              {interventions.routes}
              {utilisateurs.routes}
              {statistics.routes}
              {settings.routes}
              {manager.routes}
              {licenses.routes}
            </Route>
          </Route>
        </Route>
        {share.routes}
        <Route from="/*" Component={Page404}></Route>
      </Route>
    )
  ),
  renderError: ({ error }) => (
    <Page404 error={error} />
  ),
});

const queryClient = new QueryClient();

function App () {
  const [currentUser, setCurrentUser] = useState(null);
  const [theme, toggleTheme] = useDarkMode();
  const [ loader, toggleLoader ] = useLoaderBar();

  useEffect(()=>{
    let currentUser = localStorage?.getItem("nfCurrentUser") || null;
    if (currentUser !== null) {
      currentUser = JSON.parse(currentUser);
      setUser(currentUser);
    }
  },[]);

  const setUser = (currentUser) => {
    if (currentUser != null) {
      // localStorage.setItem("nfCurrentUser", JSON.stringify(currentUser));
      Sentry.configureScope(scope => {
        scope.setUser({
          id: currentUser.id,
          reactAppCommit: process.env.REACT_APP_VERSION || "",
          reactAppBuildDate: process.env.REACT_APP_APP_BUILD_DATE || "",
          reactAppTag: process.env.REACT_APP_TAG || "",
          organisationId: currentUser.organization_id,
          roleId: currentUser.role_id,
          rolePermissions: currentUser.permissions || [],
          userPermissions: {
            can_create_bilan: currentUser.can_create_bilan,
            can_list_all_bilan: currentUser.can_list_all_bilan,
            can_list_inactive_bilan: currentUser.can_list_inactive_bilan,
            can_download_bilan: currentUser.can_download_bilan,
            can_add_user: currentUser.can_add_user,
            can_edit_user: currentUser.can_edit_user,
            can_disable_user: currentUser.can_disable_user,
            can_edit_roles: currentUser.can_edit_roles,
            can_edit_organization: currentUser.can_edit_organization,
            can_create_organization: currentUser.can_create_organization,
            can_list_organization: currentUser.can_list_organization,
          },
        });
      });
    } else {
      // localStorage.removeItem("nfCurrentUser");
      Sentry.configureScope(scope => {
        scope.setUser(null);
      });
    }
    setCurrentUser(currentUser);
  };

  return (
    <ThemeModeContext.Provider value={{themeMode: theme, setThemeMode: toggleTheme}}>
      <LoaderBarContext.Provider value={{loaderBarState: loader, setLoaderBar: toggleLoader}}>
        <QueryClientProvider client={queryClient}>
          {/*<StyledEngineProvider injectFirst>*/}
            <ThemeProvider theme={{mode: theme || "light"}}>
              <GlobalStyle />
              <CurrentUserContext.Provider value={{currentUser: currentUser, setUser: setUser}}>
                <BrowserRouter matchContext={currentUser} />
                {loader && <LoadingBar />}
                <ToastContainer
                  transition={Slide}
                  position={"bottom-right"}
                  closeButton={false}
                  hideProgressBar={true}
                  pauseOnHover={false}
                  pauseOnFocusLoss={false}
                />
              </CurrentUserContext.Provider>
            </ThemeProvider>
          {/*</StyledEngineProvider>*/}
        </QueryClientProvider>
      </LoaderBarContext.Provider>
    </ThemeModeContext.Provider>
  );
}

export default App;
