import React, {useContext, useState, useEffect} from "react";
import http from "../../http";
import { useQuery } from "react-query";
import * as Sentry from "@sentry/browser";
import {toast} from "react-toastify";
import CurrentUserContext from "../../CurrentUserContext";
import LoaderBarContext from "../../ui/useLoaderBar";
import { isAbortError } from "../../utils";

import PageHeader, {Actions, Title} from "../../ui/PageHeader";
import Button from "../../ui/Button";
import {Column, Container, Row} from "../../ui/FlexGrid";
import Form from "../Form";
import LoadingSpinner from "../../ui/LoadingSpinner";
import EstablishmentCards from "../../components/EstablishmentCards";

export default function Edit({ match, router }) {
  const controller = new AbortController();

  const { currentUser } = useContext(CurrentUserContext);
  const { loaderBarState, setLoaderBar } = useContext(LoaderBarContext);
  const [disablePasswordButton, setDisablePasswordButton] = useState(false);
  const [disableBlockButton, setDisableBlockButton] = useState(false);

  const userId = match.params.userId;

  const { isLoading: userIsLoading, data: user, refetch: refetchUser } = useQuery(
    "user",
    async () => {
      return await http
      .get(`user/${userId}.json`, {
        signal: controller.signal,
      })
      .json()
      .then(res => {
        return res;
      })
      .catch(error => {
        if (isAbortError(error)) return;
        console.error(error);
        Sentry.captureException(error);
        toast.warn("Une erreur est survenue lors de la récupération de l'utilisateur");
        throw error;
      });
    },
    {
      cacheTime: 0,
    },
  );

  const { isLoading: rolesIsLoading, data: roles } = useQuery(
    "roles",
    async () => {
      return await http
      .get("roles.json", {
        signal: controller.signal,
      })
      .json()
      .then(res => {
        const response = res.filter(role => !role.name.includes("--ARCHIVÉ--")) || [];
        response.sort((left, right) => {
          const lhs = (left.name || "").toUpperCase();
          const rhs = (right.name || "").toUpperCase();
          return lhs > rhs ? 1 : -1;
        });
        return response;
      })
      .catch(error => {
        if (isAbortError(error)) return;
        console.error(error);
        Sentry.captureException(error);
        toast.warn("Une erreur est survenue lors de la récupération des roles utilisateurs");
        throw error;
      });
    },
    {
      cacheTime: 0,
    },
  );

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    /** Before changing the url clean the localStorage if needed */
    const removeNavigationListener = router.addNavigationListener(
      (location) => {
        if (!location.pathname.includes('/utilisateurs/')) {
          localStorage.removeItem("nameSearch");
          localStorage.removeItem("roleSearch");
          localStorage.removeItem("sortSearch");
          localStorage.removeItem("scroll");
        };
      }
    );
    return () => {
      removeNavigationListener();
    }
  }, [router]);

  // setLoader pas dans finally pour éviter un clignotement de l'état du disabled
  const handleFormSubmit = async form => {
    setLoaderBar(true);
    try {
      await http
      .patch(`user/${encodeURIComponent(user.id)}`, {json: {user: form}})
      .json();
      toast.success(`Les données de l'utilisateur ${user.full_name} ont bien été mises à jour.`);
      refetchUser()
      .then(() => {
        setLoaderBar(false);
      });
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      toast.error(`Impossible de mettre à jour les données de l'utilisateur ${
        user.full_name
      }.`);
      setLoaderBar(false);
      throw error;
    };
  };

  const handlePasswordReset = async ev => {
    ev.preventDefault();
    setDisablePasswordButton(true);
    setLoaderBar(true);
    try {
      await http.post(`user/${encodeURIComponent(user.id)}/password_reset`);
      toast.success(
        `Un courriel de réinitialisation de mot passe vient d'être envoyé à l'utilisateur ${
          user.full_name
        }.`
      );
    } catch (err) {
      Sentry.captureException(err);
      toast.error(
        `Impossible d'envoyer un courriel de réinitialisation de mot de passe à l'utilisateur ${
          user.full_name
        }.`
      );
    } finally {
      setDisablePasswordButton(false);
      setLoaderBar(false);
    }
  };

  const handleToggleAccountBlock = async ev => {
    ev.preventDefault();
    setDisableBlockButton(true);
    setLoaderBar(true);
    try {
      const action = user.blocked ? "unblock" : "block";
      await http.post(
        `user/${encodeURIComponent(user.id)}/${encodeURIComponent(action)}`
      );
      toast.warn(
        `Le compte Syopé de l'utilisateur ${user.full_name} vient d'être ${
          user.blocked ? "débloqué" : "bloqué"
        }.`
      );
      refetchUser();
    } catch (err) {
      Sentry.captureException(err);
      toast.error(
        `Impossible de ${
          user.blocked ? "débloquer" : "bloquer"
        } le compte Syopé de l'utilisateur ${user.full_name}.`
      );
    } finally {
      setDisableBlockButton(false);
      setLoaderBar(false);
    }
  };

  const getProvider = (phone, provider) => {
    if (phone !== null && phone !== undefined) {
      return "SMS";
    } else if (provider === "email") {
      return "Email";
    } else if (provider !== null || provider !== undefined) {
      return "AD";
    } else {
      return "-"
    }
  };

  const displayResetPass = () => getProvider(user?.phone_number, user?.provider) !== "AD"
    && !user?.blocked
    && (user && user.locked_at === null)
    && (user?.email !== null && user?.email.length > 0);

  const showMifareClassic = [
    1, // NFSAVE_INTERNAL_ORG
    2, // SAMU_35_ORG
    98, // NFSAVE_DEMO_ORG
    133, // NFSAVE_DEMO_REG_ORG
    134, // NFSAVE_DEMO_HOP_ORG
  ].includes(currentUser.organization_id);

  return (
    <>
      <PageHeader>
        <Title>
          Éditer l'utilisateur: {user?.full_name}
          {user?.blocked && <small>Utilisateur bloqué</small>}
          {(user && user.locked_at !== null) && <small>Utilisateur en quarantaine</small>}
        </Title>
        <Actions>
          {displayResetPass() && (
            <Button
              disabled={disablePasswordButton || loaderBarState}
              onClick={handlePasswordReset}
            >
              Réinitialiser le mot de passe
            </Button>
          )}
          {user && (
            <Button
              className={user?.blocked ? "not-validated" : "warn"}
              disabled={disableBlockButton || loaderBarState}
              onClick={handleToggleAccountBlock}
            >
              {user?.blocked ? "Débloquer" : "Bloquer"}
            </Button>
          )}
        </Actions>
      </PageHeader>
      {(
        userIsLoading || rolesIsLoading
      ) ? (
        <LoadingSpinner className="center vh-50" />
      ) : (
          <Container>
            <Row>
              <Column width={2}>
                <h2>Informations personnelles</h2>
                <Form roles={roles} initialData={user} onSubmit={handleFormSubmit}/>
                {showMifareClassic && (<>
                  <h2 style={{marginTop: "2rem"}}>Cartes d'établissement</h2>
                  <EstablishmentCards user={user} />
                </>)}
              </Column>
            </Row>
          </Container>
      )}
    </>
  );
}
