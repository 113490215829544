import React, { useContext, useState, useMemo } from "react";
import http from "../http";
import {toast} from "react-toastify";
import * as Sentry from "@sentry/browser";
import CurrentUserContext from "../CurrentUserContext";
import {DateTime} from "luxon";
import InfiniteScroll from "react-infinite-scroll-component";
import { getProvider, isoIsValid } from "../utils";

import PersonIcon from "@mui/icons-material/Person";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import GroupIcon from "@mui/icons-material/Group";
import BlockIcon from "@mui/icons-material/Block";
import LockIcon from '@mui/icons-material/Lock';
import theme, { ParagraphSecondary } from "../ui/theme";
import { Tabs, BtnTabs, TabsCntnr as TabsCntnrBase } from "../ui/Tabs";
import Card, { CardsContainer, CardContentContainer } from "../ui/Card";
import { RoundedLinkButton, RoundedMultipleButton } from "../ui/RoundedButton";
import LoadingSpinner from "../ui/LoadingSpinner";
import { RadioRoundedValidator } from "../ui/RadioValidator";
import FloatingBar from "../ui/FloatingBar";
import { Cntnr } from "../ui/PageHeader";
import styled from "styled-components";
import Select from "../ui/Select";

const TabsCntnr = styled(TabsCntnrBase)`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1080px) {
    flex-direction: column;
    gap: 1rem;
  }

  & .sortSelect {
    width: 250px;
    align-self: end;
  }
`;

const Warning = styled.div`
  border-left: ${theme.thin} ${theme.red} solid;
  padding-left: ${theme.thin};
`;

const VisualIndic = styled.div`
  position: absolute;
  top: ${theme.thin};
  right: ${theme.thin};
  & .warn {
    color: ${theme.red};
  }
`;

const perPage = 20;

const sortOptions = [
  {value: "a", label: "Alphabétique A - Z"},
  {value: "z", label: "Alphabétique Z - A"},
  {value: "+", label: "Création + récente"},
  {value: "-", label: "Création - récente"},
  {value: "+currentSign", label: "Connexion + récente"},
  {value: "-currentSign", label: "Connexion - récente"},
];

export default function List({users, roles, refreshUser, sortSearch, setSortSearch}) {
  const { currentUser } = useContext(CurrentUserContext);

  const [tabs, setTabs] = useState("active");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [ page, setPage ] = useState(perPage);
  const [ hasMore, setHasMore ] = useState(true);

  const toastId = React.useRef(null);

  const hasEditPerm = () => currentUser.can_edit_user;
  const hasDisablePerm = () => currentUser.can_disable_user;
  const displayResetPass = (user) => hasEditPerm()
    && getProvider(user.phone_number, user.provider) !== "AD"
    && !user?.blocked
    && (user && user.locked_at === null)
    && (user.email !== null && user.email.length > 0);

  /** To reset the password for one user */
  const handlePasswordReset = async (user) => {
    try {
      toastId.current = toast(<LoadingSpinner />, {autoClose: false, position: "bottom-right-floating"});
      toast.dismiss(toastId.current);
      await http.post(`user/${encodeURIComponent(user.id)}/password_reset`);
      toast.success(
        `Un courriel de réinitialisation de mot passe vient d'être envoyé à l'utilisateur ${
          user.full_name
        }.`,
        {position: "bottom-right-floating"}
      );
    } catch (err) {
      Sentry.captureException(err);
      toast.dismiss(toastId.current);
      toast.error(
        `Impossible d'envoyer un courriel de réinitialisation de mot de passe à l'utilisateur ${
          user.full_name
        }.`,
        {position: "bottom-right-floating"}
      );
    };
  };
  /** To block or unblock one user */
  const handleToggleAccountBlock = async (user) => {
    try {
      toastId.current = toast(<LoadingSpinner />, {autoClose: false, position: "bottom-right-floating"});
      const action = user.blocked ? "unblock" : "block";
      await http.post(
        `user/${encodeURIComponent(user.id)}/${encodeURIComponent(action)}`
      );
      await refreshUser();
      toast.dismiss(toastId.current);
      toast.warn(
        `Le compte Syopé de l'utilisateur ${user.full_name} vient d'être ${
          user.blocked ? "débloqué" : "bloqué"
        }.`,
        {position: "bottom-right-floating"}
      );
    } catch (err) {
      Sentry.captureException(err);
      toast.dismiss(toastId.current);
      toast.error(
        `Impossible de ${
          user.blocked ? "débloquer" : "bloquer"
        } le compte Syopé de l'utilisateur ${user.full_name}.`,
        {position: "bottom-right-floating"}
      );
    };
  };
  /** To reset the password for multiple users */
  const handlePasswordResetMultiple = async () => {
    let respErr = [];
    let tempSelected = [...selectedUsers];
    setSelectedUsers([]);
    try {
      toastId.current = toast(<LoadingSpinner />, {autoClose: false, position: "bottom-right-floating"});

      // Checks if a user has a login method through AD
      let usersAD = tempSelected.filter(user => getProvider(user.phone_number, user.provider) === "AD");
      if (usersAD.length > 0) {
        toast.dismiss(toastId.current);
        toast.error(`${usersAD.length} utilisateur${usersAD.length > 1 ? "s" : ""} dispose${usersAD.length > 1 ? "nt" : ""} d'une méthode de connexion via AD, veuillez le${usersAD.length > 1 ? "s" : ""} décocher pour réinitialiser les mots de passe des autres utilisateurs.`, {
          autoClose: 8000,
          hideProgressBar: false,
          pauseOnHover: true,
          closeOnClick: false,
          draggable: false,
          position: "bottom-right-floating"
        });
        return;
      };

      let res = await Promise.all(
        tempSelected.map(user => http.post(`user/${encodeURIComponent(user.id)}/password_reset`, { throwHttpErrors: false }))
      );

      respErr = res.filter(r => !r.ok);
      if (respErr.length > 0) throw respErr;

      toast.dismiss(toastId.current);
      toast.success(
        `Un courriel de réinitialisation de mot passe vient d'être envoyé à ${tempSelected.length} utilisateur${tempSelected.length > 1 ? "s" : ""}.`,
        {position: "bottom-right-floating"}
      );
    } catch (err) {
      let usersId = err.map(e => e.url.split('/')[e.url.split('/').length - 2]);
      let usersName = usersId.map((userId) => {
        let result = selectedUsers.find(u => u.id === userId);
        if (result === undefined) return "Utilisateur inconnu";
        return result.name;
      });

      toast.dismiss(toastId.current);
      Sentry.captureException(err);
      toast.warn(`Impossible d'envoyer un courriel de réinitialisation de mot de passe à ${err.length} utilisateur${err.length > 1 ? "s" : ""} sur ${tempSelected.length}:${usersName.map(u => (` ${u}`) )}`, {
        autoClose: 8000,
        hideProgressBar: false,
        pauseOnHover: true,
        closeOnClick: false,
        draggable: false,
        position: "bottom-right-floating"
      });
    };
  };
  /** To block or unblock multiple users */
  const handleToggleAccountBlockMultiple = async () => {
    let respErr = [];
    let tempSelected = [...selectedUsers];
    let action;
    if (tabs === "active") action = "block";
    if (tabs === "blocked") action = "unblock";
    setSelectedUsers([]);
    try {
      toastId.current = toast(<LoadingSpinner />, {autoClose: false, position: "bottom-right-floating"});
      let res = await Promise.all(
        tempSelected.map(user => http.post(`user/${encodeURIComponent(user.id)}/${encodeURIComponent(action)}`, { throwHttpErrors: false }))
      );

      respErr = res.filter(r => !r.ok);
      if (respErr.length > 0) throw respErr;
      await refreshUser();
      toast.dismiss(toastId.current);
      toast.warn(
        `${tempSelected.length === 1
          ? `Le compte Syopé de 1 utilisateur a été ${action === "unblock" ? "dé" : ""}bloqué.`
          : `Les comptes Syopé de ${tempSelected.length} utilisateurs ont été ${action === "unblock" ? "dé" : ""}bloqués.`
        }`,
        {position: "bottom-right-floating"}
      );
    } catch (err) {
      let usersId = err.map(e => e.url.split('/')[e.url.split('/').length - 2]);
      let usersName = usersId.map((userId) => {
        let result = selectedUsers.find(u => u.id == userId);
        if (result === undefined) return "Utilisateur inconnu";
        return result.name;
      });

      toast.dismiss(toastId.current);
      Sentry.captureException(err);
      toast.error(`${tempSelected.length === 1
          ? `Impossible de ${action === "unblock" ? "dé" : ""}bloquer le compte Syopé de 1 utilisateur sur ${tempSelected.length}:${usersName.map(u => (` ${u}`) )}`
          : `Impossible de ${action === "unblock" ? "dé" : ""}bloquer les comptes Syopé de ${err.length} utilisateurs sur ${tempSelected.length}:${usersName.map(u => (` ${u}`) )}`
        }`, {
        autoClose: 8000,
        hideProgressBar: false,
        pauseOnHover: true,
        closeOnClick: false,
        draggable: false,
        position: "bottom-right-floating"
      });
    };
  };

  const handleTabsChange = (value) => {
    clearSelectedUsers();
    setTabs(value);
  };

  const handleUserSelected = (user) => {
    if (selectedUsers.length < 10) {
      if (!isSelected(user.id)) {
        setSelectedUsers([...selectedUsers, user])
      } else {
        let index = selectedUsers.findIndex(sUser => sUser.id === user.id );
        let array = [...selectedUsers];
        array.splice(index , 1);
        setSelectedUsers([...array]);
      };
    } else if (selectedUsers.length === 10) {
      if (isSelected(user.id)) {
        let index = selectedUsers.findIndex(sUser => sUser.id === user.id );
        let array = [...selectedUsers];
        array.splice(index , 1);
        setSelectedUsers([...array]);
      }
    };
  };

  /** Select all
   * To be re-enabled when multiple actions will be supported natively by the API */
  const handleMassiveSelect = async () => {
    try {
      if (selectedUsers.length === actualUsers().length) {
        clearSelectedUsers();
      } else {
        setSelectedUsers([...actualUsers()]);
      };
    } catch {
      return;
    }
  };

  const handleSortChange = (ev) => {
    setSortSearch(ev.value);
  };

  const clearSelectedUsers = () => {
    setPage(perPage);
    setSelectedUsers([]);
  };

  const isSelected = (userId) => {
    if (selectedUsers.find(user => user.id === userId) === undefined) {
      return false;
    } else {
      return true;
    };
  };

  /** Multiple action button options for user card  */
  const optionsDropdown = (user) => {
    let options = [];
    // if (hasEditPerm) options.push({
    //   to: `/utilisateurs/${user.id}`,
    //   text: "Afficher l'utilisateur",
    //   type: "link"
    // });
    if (hasDisablePerm) options.push({
      onClick: () => handleToggleAccountBlock(user),
      text: user.blocked ? "Débloquer l'utilisateur" : "Bloquer l'utilisateur",
      type: "action"
    });
    return options;
  };
  const psswrdDropdown = (user) => {
    let options = [];
    if (hasEditPerm) options.push({
      onClick: () => handlePasswordReset(user),
      text: "Réinitialisation du mot de passe",
      type: "action"
    });
    return options;
  };

  /** Multiple action button options for multiple user selection  */
  const optionsDropdownMultiple = () => {
    let options = [];
    if (hasDisablePerm && tabs !== "all") options.push({
      onClick: () => handleToggleAccountBlockMultiple(),
      text: `${tabs === "active" ? "B" : "Déb"}loquer les utilisateurs`,
      type: "action"
    });
    return options;
  };
  const psswrdDropdownMultiple = () => {
    let options = [];
    if (hasEditPerm) options.push({
      onClick: () => handlePasswordResetMultiple(),
      text: "Réinitialisation des mot de passe",
      type: "action"
    });
    return options;
  };

  const getRoleName = (roleId) => {
    if (roleId === null) return "Rôle non défini";
    let resp = roles.find(elem => elem.id === roleId);
    if (resp === undefined) return "Le rôle attribué n'existe pas";
    return resp.name;
  };

  const getUserDate = (dt) =>
    isoIsValid(dt)
      ? DateTime.fromISO(dt).setLocale("fr-fr").toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
      : "Non disponible";

  const fetchMoreData = () => {
    if (usersInfinite.length >= actualUsers.length) return setHasMore(false);
    setPage(page + perPage);
  };

  const actualUsers = useMemo(() => {
    setPage(perPage)
    switch (tabs) {
      case "all":
        return users;

      case "active":
        return users.filter(user => !user.blocked);

      case "blocked":
        return users.filter(user => user.blocked);

      case "never":
        return users.filter(user => user.created_at.split(".")[0] === user.updated_at.split(".")[0]);

      case "fail":
        return users.filter(user => user.locked_at !== null);

      default:
        return users;
    };
  }, [ tabs, users ]);

  const usersInfinite = useMemo(() => {
    const temp = actualUsers.slice(0, page);
    temp.length >= actualUsers.length ? setHasMore(false) : setHasMore(true);
    return temp;
  }, [ page, actualUsers ]);

  const tabsBtnOptions = [
    {
      option: "all",
      length: users.length,
      text: "Tous",
      icon: <GroupIcon />
    },
    {
      option: "active",
      length: users.filter(user => !user.blocked).length,
      text: "Actif",
      icon: <PersonIcon />
    },
    {
      option: "blocked",
      length: users.filter(user => user.blocked).length,
      text: "Bloqué",
      icon: <BlockIcon />
    },
    {
      option: "never",
      length: users.filter(user => user.created_at.split(".")[0] === user.updated_at.split(".")[0]).length,
      text: "Aucune connexion",
      icon: <PersonOutlineOutlinedIcon />
    },
    {
      option: "fail",
      length: users.filter(user => user.locked_at !== null).length,
      text: "Quarantaine",
      icon: <LockIcon />
    },
  ];

  return (
    <>
      <TabsCntnr>
        <Tabs breakpoint="780px">
          {tabsBtnOptions.map((btn, index) => (
            <BtnTabs
              key={index}
              onClick={() => handleTabsChange(btn.option)}
              className={`tabButton icon-right${tabs === btn.option ? " active" : ""}`}
            >{btn.text}{btn.option !== "all" && btn.length > 1 ? "s" : ""} {btn.length} {btn.icon}
            </BtnTabs>
          ))}
        </Tabs>
        <Select
          isSearchable={false}
          isClearable={false}
          options={sortOptions}
          value={sortOptions.find(o => o.value === sortSearch) || null}
          onChange={handleSortChange}
          className="sortSelect"
        />
      </TabsCntnr>
      {tabs === "fail" && (
        <Cntnr>
          <Warning>
            <p>
              Les utilisateurs suivants sont en zone de quarantaine en raison d'un trop grand nombre de connexions erronées.<br />
              Veuillez contacter votre administrateur Syopé afin de procéder au déblocage de ces utilisateurs.
            </p>
          </Warning>
        </Cntnr>
      )}
      <InfiniteScroll
        dataLength={usersInfinite.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<div style={{height: "75px", marginTop: theme.medium}}>
          <LoadingSpinner className="center" />
        </div>}
      >
        <CardsContainer>
          {usersInfinite.length === 0 ? <p>Aucun utilisateur à afficher</p> : usersInfinite.map((user, index) => (
            <Card key={index} onClick={() => handleUserSelected(user)} className={isSelected(user.id) && "selected"}>
              {isSelected(user.id) && <RadioRoundedValidator label="Utilisateur sélectionné" top={theme.thin} left={theme.thin} />}
              <VisualIndic>
                {(user && user.blocked) && (
                  <span title="Utilisateur bloqué" className="warn">
                    <BlockIcon />
                  </span>
                )}
                {(user && user.locked_at !== null) && (
                  <span title="Utilisateur en quarantaine" className="warn">
                    <LockIcon />
                  </span>
                )}
              </VisualIndic>
              <CardContentContainer>
                <h2>{user.full_name}</h2>
                <h4>{getRoleName(user.role_id)}</h4>
                <ParagraphSecondary>{user.matricule === null || user.matricule.length === 0 ? "-" : user.matricule}</ParagraphSecondary>
              </CardContentContainer>
              <CardContentContainer>
                <h4>Identifiant&thinsp;:</h4>
                <p>{getProvider(user.phone_number, user.provider) !== "AD" ? user.uid : "AD"}</p>
              </CardContentContainer>
              <CardContentContainer className="large">
                <ParagraphSecondary><b>Méthode de connexion&thinsp;:</b> {getProvider(user.phone_number, user.provider)}</ParagraphSecondary>
                <ParagraphSecondary><b>Date de création&thinsp;:</b> {getUserDate(user.created_at)}</ParagraphSecondary>
                <ParagraphSecondary><b>Dernière modification&thinsp;:</b> {getUserDate(user.updated_at)}</ParagraphSecondary>
                <ParagraphSecondary><b>Dernière connexion&thinsp;:</b> {getUserDate(user.current_sign_in_at)}</ParagraphSecondary>
                <ParagraphSecondary><b>Tentatives de connexion échoué&thinsp;:</b> {user.failed_attempts}</ParagraphSecondary>
                {tabs === "fail" && (
                  <ParagraphSecondary><b>Mise en quarantaine&thinsp;:</b> {getUserDate(user.locked_at)}</ParagraphSecondary>
                )}
              </CardContentContainer>
              <CardContentContainer className="row">
                {displayResetPass(user) && (
                  <RoundedMultipleButton icon="password" tips="Réinitialisation du mot de passe" dropOptions={psswrdDropdown(user)} dropPosition="left" disabled={isSelected(user.id)} />
                )}
                {hasEditPerm() && (
                  <RoundedLinkButton type="edit" to={`/utilisateurs/${user.id}`} tips="Éditer l'utilisateur" disabled={isSelected(user.id)} />
                )}
                {hasDisablePerm() && (
                  <RoundedMultipleButton icon="more" tips="Plus d'actions" dropOptions={optionsDropdown(user)} dropPosition="right" disabled={isSelected(user.id)} />
                )}
              </CardContentContainer>
            </Card>
          ))}
        </CardsContainer>
      </InfiniteScroll>

      <FloatingBar
        content={<>{selectedUsers.length} <GroupIcon /></>}
        title={`${selectedUsers.length} utilisateur${selectedUsers.length > 1 ? "s" : ""} sélectionné${selectedUsers.length > 1 ? "s" : ""} (max 10)`}
      >
        {
          hasEditPerm() && (
          <RoundedMultipleButton
            icon="password"
            tips="Réinitialisation des mot de passe"
            dropOptions={psswrdDropdownMultiple()}
            dropPosition="right"
            disabled={selectedUsers.length === 0 ? true : false}
          />
        )}
        {(hasDisablePerm() && optionsDropdownMultiple().length !== 0) && (
          <RoundedMultipleButton
            icon="more"
            tips="Plus d'actions"
            dropOptions={optionsDropdownMultiple()}
            dropPosition="right"
            disabled={selectedUsers.length === 0 ? true : false}
          />
        )}
      </FloatingBar>
    </>
  );
};
