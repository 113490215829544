import { template as syoTemplate } from "@nfsave/syo-bilan";

export function makeSccarrLike(sectionNames = ["A", "B", "C", "D", "E"]) {
  return [
    {
      "@type": "grid",
      "key": "eaa87a98-83a6-4784-854b-dffb7dc49f4f",
      columns: 1,
      children: [
        { // Intervention / Équipe
          "@type": "card",
          key: "d724823d-cc2d-4f59-8936-f36fb8de3c5c",
          title: "Intervention / Équipe",
          gridColumn: "1/2",
          className: "cardCntnr",
          children: [
            {
              "@type": "grid",
              key: "98315351-5b78-464b-b4da-64df68e0fc13",
              columns: 3,
              children: [
                ...syoTemplate.ticketNumMotif,
                ...syoTemplate.equipeInfos,
              ],
            },
          ]
        },
      ],
    },
    {
      "@type": "grid",
      "key": "916903b2-0521-44e9-a3d4-32d26acc69b1",
      columns: 5,
      children: [
        { // Situation
          "@type": "card",
          key: "5516f091-9a5e-4f98-912b-c85ca7bf2232",
          title: "Situation",
          gridColumn: "1/2",
          children: [
            ...syoTemplate.situation,
          ],
        },
        { // Circonstances -> Contexte
          "@type": "card",
          key: "c2456c66-1b2f-42ee-a533-af0f20669e66",
          title: "Contexte",
          gridColumn: "2/4",
          children: [
            ...syoTemplate.circonstanciel,
            ...syoTemplate.anamnese,
            ...syoTemplate.examen,
            ...syoTemplate.hypotheseDiag,
            ...syoTemplate.sampler,
            ...syoTemplate.opqrst,
            ...syoTemplate.pPqrst,
            ...syoTemplate.qrPqrst,
            ...syoTemplate.sPqrst,
            ...syoTemplate.tPqrst,
          ],
        },
        { // Identité
          "@type": "card",
          key: "59060cb6-4180-4b82-b4b1-78da2dee2e2a",
          title: "Identité",
          gridColumn: "4/5",
          children: [
            ...syoTemplate.identite,
          ],
        },
        { // Contact
          "@type": "card",
          key: "104b79a4-ef67-4fcf-bbfb-41f4fe564bec",
          title: "Contact",
          gridColumn: "5/6",
          children: [
            ...syoTemplate.contact,
          ],
        },
      ],
    },
    {
      "@type": "grid",
      key: "6641c2b2-950d-4327-a580-56fbfe4701ad",
      columns: 1,
      children: [
        { // ACR Arrêt cardio-respiratoire
          "@type": "card",
          key: "6f652677-05e7-4f25-b2f1-3069a0fa6a96",
          title: "ACR",
          children: [
            ...syoTemplate.acr,
          ],
        }
      ],
    },
    {
      "@type": "grid",
      key: "8c0be844-5e7c-46d5-ac24-3a2b9f1dfe77",
      columns: 3,
      children: [
        { // Obstétrique
          "@type": "card",
          key: "8ad340a6-9cc0-4adf-8e77-101e7300d71a",
          title: "Obstétrique",
          gridColumn: "1/3",
          className: "cardCntnr",
          children: [
            {
              "@type": "grid",
              key: "ed0845e8-67b1-429b-9225-8d6bce5c9d13",
              columns: 2,
              children: [
                ...syoTemplate.obstetrique,
              ],
            },
          ],
        },
        { // Secours routier
          "@type": "card",
          key: "f047d819-1691-414d-8bba-ab7f45339350",
          title: "Secours routier / Accidents",
          gridColumn: "3/4",
          children: [
            ...syoTemplate.secoursRoutier,
          ],
        },
      ],
    },
    {
      "@type": "grid",
      key: "1d97ce6b-5442-45e3-8bd3-16fc7eb364ba",
      columns: 3,
      children: [
        { // Douleur Thoracique
          "@type": "card",
          key: "bcdc4e9d-167e-4daf-b529-c39b5cde3a6f",
          title: "Douleur Thoracique",
          gridColumn: "1/3",
          className: "cardCntnr",
          children: [
            {
              "@type": "grid",
              key: "21e3e937-8bbb-4f58-a1ed-f4201e02b512",
              columns: 2,
              children: [
                ...syoTemplate.douleurThoracique,
              ],
            },
          ]
        },
        { // NIHSS
          "@type": "card",
          key: "1cb6c6c3-a8bf-479e-8f29-99180d9ac0cb",
          title: "NIHSS",
          gridColumn: "3/4",
          children: [
            {
              "@type": "permission",
              key: "8d7060eb-7f9c-4604-8486-faf08c6a7093",
              permission: ["!web:bilan:extract-fast-out-of-neuro"],
              children: [
                ...syoTemplate.nihss,
              ],
            },
            {
              "@type": "permission",
              key: "2d9bdbab-c6e4-4a34-a0df-2080aa0e9330",
              permission: ["web:bilan:extract-fast-out-of-neuro"],
              children: [
                ...syoTemplate.nihssSansFast,
              ],
            },
          ]
        },
      ],
    },
    { // ABCDE LESIONNEL
      "@type": "grid",
      key: "15e293e3-9920-4b5b-b929-09af03176e68",
      columns: 5,
      children: [
        {
          "@type": "card",
          key: "b0919117-e2f7-4456-b1be-481f7485b05c",
          gridColumn: "1 / 4",
          className: "cardCntnr",
          children: [
            {
              "@type": "grid",
              key: "7d4cf16c-6cbd-4c69-9c6d-b126d5291966",
              columns: 3,
              children: [
                {
                  "@type": "card",
                  key: "bbdf133a-ad5e-4214-ae29-f27db4f948b9",
                  gridColumn: "1 / 3",
                  title: `${sectionNames[0]}`,
                  children: [
                    {
                      "@type": "grid",
                      key: "7c2058f9-5ce0-426f-a298-780daa327573",
                      columns: 2,
                      children: [
                        ...syoTemplate.aAbcde,
                      ],
                    },
                  ],
                },
                {
                  "@type": "card",
                  key: "e02ffba6-9423-4ac6-9021-090036ce7f4f",
                  gridColumn: "3 / 4",
                  title: "Gestes",
                  children: [
                    ...syoTemplate.aAbcdeGeste,
                  ],
                },
              ],
            },
            {
              "@type": "grid",
              key: "62d461bd-2550-43b4-a49f-9aca72f4f5d5",
              columns: 3,
              children: [
                {
                  "@type": "card",
                  key: "a370ded9-0959-4646-aeb8-89ee2206a615",
                  gridColumn: "1 / 3",
                  title: `${sectionNames[1]}`,
                  children: [
                    {
                      "@type": "grid",
                      key: "7c2058f9-5ce0-426f-a298-780daa327573",
                      columns: 2,
                      children: [
                        ...syoTemplate.bAbcde,
                      ],
                    },
                  ],
                },
                {
                  "@type": "card",
                  key: "47af03ca-145f-4b4d-a0cd-dfbfbcc54d6a",
                  gridColumn: "3 / 4",
                  title: "Gestes",
                  children: [
                    ...syoTemplate.bAbcdeGeste,
                    ...syoTemplate.gesteSmur,
                  ],
                },
              ],
            },
            {
              "@type": "grid",
              key: "767a0d34-5d5b-4c65-9271-1f3b02b920bb",
              columns: 3,
              children: [
                {
                  "@type": "card",
                  key: "853869c5-5e0a-44d3-9c72-5f02716001d0",
                  gridColumn: "1 / 3",
                  title: `${sectionNames[2]}`,
                  children: [
                    {
                      "@type": "grid",
                      key: "5fa0701a-82cb-4422-9cac-a6b7c027f7da",
                      columns: 2,
                      children: [
                        ...syoTemplate.cAbcde,
                      ],
                    },
                  ],
                },
                {
                  "@type": "card",
                  key: "69472023-f5a7-4edb-910d-f2aa86f6529f",
                  gridColumn: "3 / 4",
                  title: "Gestes",
                  children: [
                    ...syoTemplate.cAbcdeGeste,
                  ],
                },
              ],
            },
            {
              "@type": "grid",
              key: "9b8ceeb6-0c16-45ec-85ce-e7aaa0b49834",
              columns: 3,
              children: [
                {
                  "@type": "card",
                  key: "f4c2aa87-6814-4181-a7c9-27d53b266de4",
                  gridColumn: "1 / 3",
                  title: `${sectionNames[3]}`,
                  children: [
                    {
                      "@type": "permission",
                      key: "072f3f19-9efe-46e6-805a-fc56c0331088",
                      permission: ['!web:bilan:extract-fast-out-of-neuro'],
                      children: [
                        {
                          "@type": "grid",
                          key: "0c22be7d-26f8-4192-88ff-76b6ee015bf5",
                          columns: 2,
                          children: [
                            ...syoTemplate.dAbcde,
                          ],
                        },
                      ],
                    },
                    {
                      "@type": "permission",
                      key: "fb0add8c-927b-4387-ae09-10f4ece314a6",
                      permission: ['web:bilan:extract-fast-out-of-neuro'],
                      children: [
                        {
                          "@type": "grid",
                          key: "5257ca0a-3fc6-4d07-beba-4e16139c948d",
                          columns: 2,
                          children: [
                            ...syoTemplate.neurologiqueSansFast,
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  "@type": "card",
                  key: "46444252-25a9-4089-8a46-44502ab45e37",
                  gridColumn: "3 / 4",
                  title: "Gestes",
                  children: [
                    ...syoTemplate.dAbcdeGeste,
                  ],
                },
              ],
            },
            {
              "@type": "grid",
              key: "a9e00c90-40b5-454a-bd9d-06e9568c6c24",
              columns: 3,
              children: [
                {
                  "@type": "card",
                  key: "956b8036-8821-4138-b98a-acb776edbd2d",
                  gridColumn: "1 / 3",
                  title: `${sectionNames[4]}`,
                  children: [
                    {
                      "@type": "grid",
                      key: "62ce8474-1775-4a82-9ed4-1366689a0b21",
                      columns: 2,
                      children: [
                        ...syoTemplate.eAbcde,
                      ],
                    },
                  ],
                },
                {
                  "@type": "card",
                  key: "0adbd38f-0633-41fd-8317-8d0e6bbac89e",
                  gridColumn: "3 / 4",
                  title: "Gestes",
                  children: [
                    ...syoTemplate.eAbcdeGeste,
                  ],
                },
              ],
            },
          ],
        },
        {
          "@type": "card",
          key: "8a5895ce-2b87-4cc0-a6cc-7ea1fd25ef04",
          gridColumn: "4 / 6",
          className: "cardCntnr",
          children: [
            {
              "@type": "card",
              key: "e97b976d-7d26-4bd2-a199-4e578079d8fe",
              title: "Lésionnel",
              children: [
                ...syoTemplate.lesionnel,
                ...syoTemplate.lesionnelProtocol,
              ],
            },
            { // FAST
              "@type": "card",
              key: "b2296f7c-89ab-4380-9f88-41781df6de78",
              title: "FAST",
              children: [
                ...syoTemplate.neurologiqueFast,
                {
                  "@type": "permission",
                  key: "fadeb713-2164-430f-bb31-e3ca968822d3",
                  permission: ['web:bilan:extract-fast-out-of-neuro'],
                  children: [
                    ...syoTemplate.nihssParalysieFacialeFast,
                    ...syoTemplate.nihssMotriciteMembreFast,
                    ...syoTemplate.neuroHeureAppDeficitFast,
                    ...syoTemplate.neuroTroubleParoleFast,
                  ],
                },
              ],
            },
            { // Témoin
              "@type": "card",
              key: "26044904-7565-446d-a50b-e1d9b2356dc0",
              title: "Témoin",
              children: [
                ...syoTemplate.gesteTemoin,
              ],
            },
            { // Autres gestes
              "@type": "card",
              key: "ec30e2f8-39d5-446e-a4e7-161e53bf146d",
              title: "Autres gestes",
              children: [
                ...syoTemplate.gesteSmur35,
                ...syoTemplate.gesteAutres,
              ],
            },
            { // Prélèvement d'un bilan sanguin
              "@type": "card",
              key: "252ebb3e-12f8-4e6e-89ee-638da1c0bd7c",
              title: "Prélèvement d'un bilan sanguin",
              children: [
                ...syoTemplate.gesteBilanSanguin,
              ],
            },
            {
              "@type": "card",
              key: "f13c870f-e648-4d94-9859-35e40ae51bc5",
              title: "Devenir",
              children: [
                ...syoTemplate.devenir,
              ],
            },
          ]
        },
      ],
    },
    {
      "@type": "grid",
      "key": "923a43f5-ae2a-4105-929a-e65f6baad26e",
      columns: 3,
      children: [
        { // PISU
          "@type": "card",
          key: "5dc7ca00-6a25-43a6-89fe-db2296bbdd90",
          title: "PISU / GASSPPE",
          gridColumn: "1/2",
          children: [
            ...syoTemplate.pisu,
            ...syoTemplate.gassppe,
          ],
        },
        { // Geste technique
          "@type": "card",
          key: "e339f60c-ba14-427c-a7a7-f6b7b3736a94",
          title: "Gestes techniques",
          gridColumn: "2/3",
          children: [
            ...syoTemplate.gesteTechnique,
          ],
        },
        { // RCP
          "@type": "card",
          key: "65767940-b563-4eb9-88fe-483bf75cf752",
          title: "RCP",
          gridColumn: "3/4",
          children: [
            ...syoTemplate.rcp
          ],
        },
      ],
    },
    {
      "@type": "grid",
      key: "1fbd91e1-671d-46d2-9d11-12afd0d1f886",
      columns: 1,
      children: [
        { // Surveillances
          "@type": "card",
          key: "22fcd2af-d4ae-4e78-9826-e63aca89d3ca",
          title: "Surveillances",
          children: [
            ...syoTemplate.surveillances,
          ],
        },
      ],
    },
    // {
    //   "@type": "grid",
    //   key: "4719189b-d26f-43f2-b598-e3dd119ce70a",
    //   columns: 1,
    //   permission: ["web:bilan:multiparam"],
    //   children: [
    //     { // Multiparamétrique
    //       "@type": "card",
    //       key: "30ba9867-e19c-4240-8236-7777fc791372",
    //       title: "Multiparamétrique",
    //       children: [
    //         ...syoTemplate.multiparam,
    //       ],
    //     },
    //   ],
    // },
  ];
};