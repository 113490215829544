import { Link as BaseLink } from "found";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useMemo, useState } from "react";
import * as Sentry from "@sentry/browser";
import { hasPerm } from "../utils";
import { data as syoData } from "@nfsave/syo-bilan";

import styled from "styled-components";
import theme from "../ui/theme";
import Card from "../ui/Card";
import CurrentUserContext from "../CurrentUserContext";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ColorizeIcon from '@mui/icons-material/Colorize';
// import AttachFileIcon from '@mui/icons-material/AttachFile';

const Link = styled(BaseLink)`
  color: inherit;

  &:hover {
    color: ${theme.blue};
  }
`;

const BilanList = styled.ul`
  padding: ${theme.small};
  margin-bottom: 0;
`;

const BilanCard = styled(Card)`
  max-width: none;
  min-width: none;
  margin-bottom: ${theme.small};
  padding: ${theme.thin};
  border: none;
  position: relative;
`;

const BilanPriority = styled.div`
  width: ${theme.thin};
  height: 100%;
  background-color: ${props => theme[props.color]};
  position: absolute;
  left: 0px;
  top: 0px;
`;

const BilanLink = styled(Link)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;

  ${BilanCard}:hover & {
    color: ${theme.blue};
  }

  &:hover {
    text-decoration: none;
  }

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

const Lead = styled.div`
  font-size: 1.1rem;
`;

const Muted = styled.div`
  font-size: 0.9rem;
  color: #888;
`;

const ItemRow = styled.li`
  padding: 0.5rem 0;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;

  @media (min-width: 1024px) {
    flex-direction: row;
  }

  & + & {
    border-top: 1px solid #ddd;
  }
`;

const HeaderRow = styled(ItemRow)`
  font-weight: 600;

  & + ${ItemRow} {
    border-top: 1px solid #ddd;
  }
`;

const Column = styled.div`
  padding: 0.25rem 1rem;

  @media (min-width: 1024px) {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 0 0.5rem;
    min-width: 0;

    & > ${Lead}, & > ${Muted} {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @media (max-width: 1023px) {
    &:empty {
      display: none;
    }

    ${Lead} {
      display: inline;
    }

    ${Muted} {
      display: inline;

      :before {
        content: " (";
      }
      :after {
        content: ")";
      }
    }
  }
`;

const LocationColumn = styled(Column)`
  flex-basis: 24%;
`;

const IdentityColumn = styled(Column)`
  flex-basis: 23%;
`;

const EffectorColumn = styled(Column)`
  flex-basis: 13%;
`;

const DestinationColumn = styled(Column)`
  flex-basis: 23%;
`;

const ReceiverColumn = styled(Column)`
  flex-basis: 11%;
`;

const IndicatorColumn = styled(Column)`
  flex-basis: 6%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  svg {
    color: ${theme.grey3};
    &.new {
      color: ${theme.blue}
    }
    &.empty {
      color: ${theme.grey7};
    }
    &.pui- {
      &negative {
        color: ${theme.red};
      }
      &positive {
        color: ${theme.green};
      }
      &empty {
        color: ${theme.grey7};
      }
    }
  }

  @media (max-width: 1023px) {
    justify-content: start;
  }
`;

const FirstName = styled.span`
  text-transform: capitalize;
`;

const LastName = styled.span`
  text-transform: uppercase;
`;

const GENDERS = {
  0: "Indéterminé",
  1: "Homme",
  2: "Femme"
};

const CIVILITY = {
  0: "Indéterminé",
  1: "M.",
  2: "Mme."
};

const EMPTY_COMPLIANCE = {
  value: 0,
  label: "Non renseigné",
  kind: "empty",
};

const OPTIONS_COMPLIANCE = [
  ...syoData.PHARMACY_COMPLIANCE,
  EMPTY_COMPLIANCE,
];

function ListItem({ bilan, listingIdPerm, showPriority, visualIndicator, sortBilan, pui }) {
  const {currentUser} = useContext(CurrentUserContext);
  const yesterday = DateTime.local().minus({ days: 1 });
  const createdInterAt = DateTime.fromISO(bilan.intervention.created_at);
  const createdAt = DateTime.fromISO(bilan.created_at);
  const relativeInterTime = createdInterAt.setLocale("fr-fr").toRelative();
  const relativeTime = createdAt.setLocale("fr-fr").toRelative();
  const createDate =
    createdInterAt < yesterday
      ? `${createdInterAt.setLocale("fr-fr").toLocaleString({month: "long", day: "numeric"})}`
      : `${createdInterAt.setLocale("fr-fr").toLocaleString({hour: "numeric", minute: "2-digit"})}`;
  const receptionDate =
    createdAt < yesterday
      ? `${createdAt.setLocale("fr-fr").toLocaleString({month: "long", day: "numeric"})}`
      : `${createdAt.setLocale("fr-fr").toLocaleString({hour: "numeric", minute: "2-digit"})}`;
  const linkLocation = `/interventions${pui ? "/pui" : ""}/${bilan.intervention.numero}/bilans/${encodeURIComponent(bilan.id)}`;
  const identityTooltipParts = [bilan.victime.nom, bilan.victime.prenom].filter(
    s => s != null && s.trim().length > 0
  );
  const identityTooltip =
    identityTooltipParts.length === 0 ? (
      <em>Aucune identité renseignée</em>
    ) : (
      <><LastName>{identityTooltipParts[0]}</LastName> <FirstName>{(identityTooltipParts[1] || "")[0]}.</FirstName></>
    );
  const hasGender = ![0, '0'].includes(bilan.victime.sexe);
  const hasCivility = ![0, '0', undefined].includes(bilan.victime.civilite);
  const genderCivility = (hasGender && hasCivility)
    ? `${syoData.SEX_OPTIONS.find(e => e.value === bilan.victime.sexe)?.label || 'Indéterminé'}, ${syoData.CIVILITY_OPTIONS.find(e => e.value === bilan.victime.civilite)?.label || ''}`
    : (!hasGender && hasCivility)
      ? `${syoData.CIVILITY_OPTIONS.find(e => e.value === bilan.victime.civilite)?.label || 'Indéterminé'}`
      : `${syoData.SEX_OPTIONS.find(e => e.value === bilan.victime.sexe)?.label || 'Indéterminé'}`;

  const [ lastView, setLastView ] = useState({
    className: "",
    title: ""
  });
  const [ attachment, setAttachment ] = useState({
    className: "empty",
    title: "Pas de PJ"
  });

  useEffect(() => {
    const localInter = localStorage.getItem(bilan.intervention.numero);
    const dLocal = DateTime.fromISO(localInter);

    let view = {
      className: "",
      title: "Vue",
    };
    if (localInter === null || dLocal < createdAt){
      view.className = "new";
      view.title = "Changement non vue"
      setLastView(view);
    } else {
      setLastView(view);
    }
  }, [ bilan ]);

  const getPriorityTitle = useMemo(() => {
    switch (bilan.priorisation) {
      case 1:
        return "Bilan blanc";
      case 2:
        return "Bilan jaune";
      case 3:
        return "Bilan rouge";
      default:
        return "Bilan blanc";
    }
  }, [bilan]);

  const getPrioritycolor = useMemo(() => {
    switch (bilan.priorisation) {
      case 1:
        return "backgroundColor";
      case 2:
        return "yellow";
      case 3:
        return "red";
      default:
        return "backgroundColor";
    }
  }, [bilan]);

  const ageText = () => {
    switch (bilan.victime.age_unit) {
      case 1:
        return `jour${(bilan.victime.age !== "1" && bilan.victime.age !== 1) ? "s" : ""}`;
      case 2:
        return `mois`;
      case 3:
        return `an${(bilan.victime.age !== "1" && bilan.victime.age !== 1) ? "s" : ""}`;
      default:
        return `an${(bilan.victime.age !== "1" && bilan.victime.age !== 1) ? "s" : ""}`;
    }
  };

  const getSexeAgeSpan = useMemo(() => {
    return (
      <span>
        {genderCivility},
        {bilan.victime.age !== ""
          ? ` ${bilan.victime.age} ${ageText()}`
          : " Pas d'âge renseigné"}
      </span>
    );
  }, [ bilan ]);

  const getComplianceData = useMemo(() => {
    if (!bilan) return EMPTY_COMPLIANCE;
    return OPTIONS_COMPLIANCE.find(oc => oc.value === bilan.intervention?.latest_pharmacy_compliance?.value || 0);
  }, [ bilan ]);

    return (
      <BilanCard>
        {showPriority && (
          <BilanPriority color={getPrioritycolor} title={getPriorityTitle} />
        )}
        <BilanLink to={linkLocation}>
          <IdentityColumn>
            {hasPerm(currentUser, listingIdPerm)
            ? (
              <>
                <Lead>
                  <span>
                    {identityTooltip}
                  </span>
                </Lead>
                <Muted>
                  {getSexeAgeSpan}
                </Muted>
              </>
            ) : (
              <Lead>
                {getSexeAgeSpan}
              </Lead>
            )}
          </IdentityColumn>
          <LocationColumn>
            <Lead>
              <span title={bilan.equipe.adresse}>
                {bilan.equipe.adresse.substring(0, 60) || <em>Pas d'adresse renseignée</em>}
              </span>
            </Lead>
            <Muted>
              <span>
                {bilan.equipe.intervention_num !== "" ? (
                  <abbr title="Numéro primo-intervenant">
                    {bilan.equipe.intervention_num}
                  </abbr>
                ) : (
                  <em>Numéro primo-intervenant NR</em>
                )}{" "}
                —{" "}
                {bilan.info_tech.conformite !== 0 ? (
                  <abbr title="Numéro de dossier">
                    {bilan.info_tech.conformite}
                  </abbr>
                ) : (
                  <em>Numéro dossier NR</em>
                )}
              </span>
            </Muted>
          </LocationColumn>
          <EffectorColumn>
            <Lead>
              {bilan.equipe.vehicule.length === 0 ? (
                <em title="Véhicule inconnu">
                  Véhicule inconnu
                </em>
              ) : (
                <span title={bilan.equipe.vehicule}>
                  {bilan.equipe.vehicule}
                </span>
              )}
            </Lead>
            <Muted>
              <span title={bilan.intervention.performer}>
                {bilan.intervention.performer}
              </span>
            </Muted>
          </EffectorColumn>
          <DestinationColumn>
            {pui ? (
                <Lead>
                  <span>{bilan.intervention?.latest_pharmacy_compliance?.author}</span>
                </Lead>
            ) : (
              bilan.devenir.hopital && (
                <Lead>
                  <span title={bilan.devenir.hopital.nom}>
                    {bilan.devenir.hopital.nom}
                  </span>
                </Lead>
              )
            ) }
          </DestinationColumn>
          <ReceiverColumn>
            {sortBilan === "bilans" ? (
              <>
                <Lead>{receptionDate}</Lead>
                <Muted>
                  <abbr title={createdAt.setLocale("fr-fr").toLocaleString(DateTime.DATETIME_FULL)}>
                    {relativeTime}
                  </abbr>
                </Muted>
              </>
            ) : (
              <>
                <Lead>{createDate}</Lead>
                <Muted>
                  <abbr title={createdInterAt.setLocale("fr-fr").toLocaleString(DateTime.DATETIME_FULL)}>
                    {relativeInterTime}
                  </abbr>
                </Muted>
              </>
            )}
          </ReceiverColumn>
          {(visualIndicator && !pui) && (
            <IndicatorColumn>
              <div title={lastView.title}>
                <VisibilityOutlinedIcon className={lastView.className} />
              </div>
              {/* <div title={attachment.title}>
                <AttachFileIcon className={attachment.className} />
              </div> */}
            </IndicatorColumn>
          )}
          {pui && (
            <IndicatorColumn>
              {/* {visualIndicator && (
                <div title={lastView.title}>
                  <VisibilityOutlinedIcon className={lastView.className} />
                </div>
              )} */}
              <div title={getComplianceData?.label ?? "Non renseigné"}>
                <ColorizeIcon className={`pui-${getComplianceData?.kind ?? "empty"}`} />
              </div>
            </IndicatorColumn>
          )}
        </BilanLink>
      </BilanCard>
    );
}

function ListHeader({sortBilan, pui}) {
  return (
    <HeaderRow>
      <IdentityColumn>
        <Lead>Identité</Lead>
        <Muted>Sexe, Âge</Muted>
      </IdentityColumn>
      <LocationColumn>
        <Lead>Adresse</Lead>
        <Muted>N° primo-intervenant — N° de dossier</Muted>
      </LocationColumn>
      <EffectorColumn>
        <Lead>Effecteur</Lead>
      </EffectorColumn>
      <DestinationColumn>
        <Lead>{pui ? "Infirmier" : "Destination"}</Lead>
      </DestinationColumn>
      <ReceiverColumn>
        <Lead>Date de {sortBilan === "bilans" ? "réception" : "création"}</Lead>
        <Muted>Reçu depuis</Muted>
      </ReceiverColumn>
    </HeaderRow>
  );
}

function List({
  bilans,
  listingIdPerm,
  showPriority = false,
  visualIndicator = false,
  pui = false,
  sortBilan="bilans",
  users = [],
}) {
  if (pui) {
    // eslint-disable-next-line array-callback-return
    bilans.map(bilan => {
      const authorId = bilan.intervention?.latest_pharmacy_compliance?.authored_by_id;
      if (authorId && users && users.length > 0) {
        bilan.intervention.latest_pharmacy_compliance.author = users.find(e => e.value === authorId)?.label || undefined;
      }
    })
  }
  if (bilans !== null && bilans !== undefined) {
    return (
      <BilanList>
        <ListHeader sortBilan={sortBilan} pui={pui}/>
        {bilans.length > 0 ? (
          <>
            {bilans.map((bilan, index) => (
              <ListItem
                key={index}
                bilan={bilan}
                listingIdPerm={listingIdPerm}
                showPriority={showPriority}
                visualIndicator={visualIndicator}
                sortBilan={sortBilan}
                pui={pui}
              />
            ))}
          </>
        ) : (
          <li style={{margin: "1rem 0.5rem", listStyle: "none"}}>
            <span>Aucune intervention à afficher.</span>
          </li>
        )}
      </BilanList>
    );
  } else {
    Sentry.captureMessage("`bilans` est null ou undefined");
    console.warn("`bilans` est null ou undefined");
    return (<p>Une erreur est survenue lors de l'affichage des interventions.</p>);
  }
}

export default List;
