import React from "react";
import styled from "styled-components";
import theme from "../ui/theme";

import {MannequinVector, MannequinHandsVector, MannequinFootsVector, MannequinHeadVector} from "./MannequinVector";
import {
  data as syoData,
  utils as syoUtils,
} from "@nfsave/syo-bilan";

export function textifyFront(dummy) {
  return syoUtils.textifyForFront(syoData.TEXTIFY_FRONT, dummy);
}

export function textifyBack(dummy) {
  return syoUtils.textifyForFront(syoData.TEXTIFY_BACK, dummy);
}

export function textifyFrontHead(dummy) {
  return syoUtils.textifyForFront(syoData.TEXTIFY_FRONT_HEAD, dummy);
}

export function textifyFrontHandRight (dummy) {
  return syoUtils.textifyForFront(syoData.TEXTIFY_FRONT_HAND_RIGHT, dummy);
}

export function textifyBackHandRight (dummy) {
  return syoUtils.textifyForFront(syoData.TEXTIFY_BACK_HAND_RIGHT, dummy);
}

export function textifyFrontHandLeft (dummy) {
  return syoUtils.textifyForFront(syoData.TEXTIFY_FRONT_HAND_LEFT, dummy);
}

export function textifyBackHandLeft (dummy) {
  return syoUtils.textifyForFront(syoData.TEXTIFY_BACK_HAND_LEFT, dummy);
}

export function textifyFrontFootRight (dummy) {
  return syoUtils.textifyForFront(syoData.TEXTIFY_FRONT_FOOT_RIGHT, dummy);
}

export function textifyBackFootRight (dummy) {
  return syoUtils.textifyForFront(syoData.TEXTIFY_BACK_FOOT_RIGHT, dummy);
}

export function textifyFrontFootLeft (dummy) {
  return syoUtils.textifyForFront(syoData.TEXTIFY_FRONT_FOOT_LEFT, dummy);
}

export function textifyBackFootLeft (dummy) {
  return syoUtils.textifyForFront(syoData.TEXTIFY_BACK_FOOT_LEFT, dummy);
}

const MannequinCntnr = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: ${theme.small};
`;

const MannequinHeader = styled.div`
  display:flex;
  justify-content: space-between;
  width: 100%;
`;

export default function Mannequin({ lesionnel, version, position, sexe, typeAge }) {

  const viewBoxVersion = version === "v2" ? "0 0 1014 1955" : "0 0 375 782";
  const widhtVersion = version === "v2" ? "230px" : "130px";
  const heightVersion = version === "v2" ? "373px" : "273px";

  const getTextify = () => {
    if (position === "front") {
      return textifyFront(lesionnel).map(([key, line]) => (
        <li key={key}>{line}</li>
      ))
    }
    if (position === "back") {
      return textifyBack(lesionnel).map(([key, line]) => (
        <li key={key}>{line}</li>
      ))
    }
    return;
  };

  return (
    <MannequinCntnr>
      <MannequinHeader>
        <span>
          {position === "front" ? "D" : "G"}
        </span>
        <span>
          {position === "front" ? "Face avant" : "Face arrière"}
        </span>
        <span>
          {position === "front" ? "G" : "D"}
        </span>
      </MannequinHeader>

      <svg
        style={{ marginTop: "1.5rem", marginBottom: "0.5rem" }}
        width={widhtVersion}
        height={heightVersion}
        viewBox={viewBoxVersion}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <DefsMannequin />
        <MannequinVector
          lesionnel={lesionnel}
          position={position}
          version={version}
          sexe={sexe}
          typeAge={typeAge}
        />
      </svg>

      <ul>
        {getTextify()}
      </ul>
    </MannequinCntnr>
  );
}

export const MannequinHead = ({ lesionnel, side, position = 'front' }) => {
  const viewBoxVersion = "0 0 1014 1955";
  const widhtVersion = "230px";
  const heightVersion = "373px";

  const getTextify = () => {
    return textifyFrontHead(lesionnel).map(([key, line]) => (
      <li key={key}>{line}</li>
    ))
  }

  return (
    <MannequinCntnr>
      <MannequinHeader>
        <span>
          {position === "front" ? "D" : "G"}
        </span>
        <span>
          {position === "front" ? "Face avant" : "Face arrière"}
        </span>
        <span>
          {position === "front" ? "G" : "D"}
        </span>
      </MannequinHeader>

      <svg
        style={{ marginTop: "1.5rem", marginBottom: "0.5rem" }}
        width={widhtVersion}
        height={heightVersion}
        viewBox={viewBoxVersion}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <DefsMannequin />
        <MannequinHeadVector
          lesionnel={lesionnel}
          position={position}
          side={side}
        />
      </svg>

      <ul>
        {getTextify()}
      </ul>
    </MannequinCntnr>
  );
}

export const MannequinHands = ({ lesionnel, position, side }) => {
  const viewBoxVersion = "0 0 1589.6 1955.1";
  const widhtVersion = "230px";
  const heightVersion = "373px";
  const sideTxt = side === 'right' ? 'Main droite' : 'Main gauche';
  const positionTxt = position === "front" ? "Face dorsale" : "Face palmaire";

  const getTextify = () => {
    if (position === 'front') {
      if (side === 'right') {
        return textifyFrontHandRight(lesionnel).map(([key, line]) => (
          <li key={key}>{line}</li>
        ))
      } else {
        return textifyFrontHandLeft(lesionnel).map(([key, line]) => (
          <li key={key}>{line}</li>
        ))
      }
    } else if (position === 'back') {
      if (side === 'right') {
        return textifyBackHandRight(lesionnel).map(([key, line]) => (
          <li key={key}>{line}</li>
        ))
      } else {
        return textifyBackHandLeft(lesionnel).map(([key, line]) => (
          <li key={key}>{line}</li>
        ))
      }
    }
  };

  return (
    <MannequinCntnr>
      <MannequinHeader>
        <span></span>
        <span>
          {`${sideTxt}: ${positionTxt}`}
        </span>
        <span></span>
      </MannequinHeader>

      <svg
        style={{ marginTop: "1.5rem", marginBottom: "0.5rem" }}
        width={widhtVersion}
        height={heightVersion}
        viewBox={viewBoxVersion}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <DefsMannequin />
        <MannequinHandsVector
          lesionnel={lesionnel}
          position={position}
          side={side}
        />
      </svg>

      <ul>
        {getTextify()}
      </ul>
    </MannequinCntnr>
  );
}

export const MannequinFoots = ({ lesionnel, position, side }) => {
  const viewBoxVersion = "0 0 596 842";
  const widhtVersion = "230px";
  const heightVersion = "373px";
  const sideTxt = side === 'right' ? 'Pied droite' : 'Pied gauche';
  const positionTxt = position === "front" ? "Face dorsale" : "Face palmaire";

  const getTextify = () => {
    if (position === 'front') {
      if (side === 'right') {
        return textifyFrontFootRight(lesionnel).map(([key, line]) => (
          <li key={key}>{line}</li>
        ))
      } else {
        return textifyFrontFootLeft(lesionnel).map(([key, line]) => (
          <li key={key}>{line}</li>
        ))
      }
    } else if (position === 'back') {
      if (side === 'right') {
        return textifyBackFootRight(lesionnel).map(([key, line]) => (
          <li key={key}>{line}</li>
        ))
      } else {
        return textifyBackFootLeft(lesionnel).map(([key, line]) => (
          <li key={key}>{line}</li>
        ))
      }
    }
  };

  return (
    <MannequinCntnr>
      <MannequinHeader>
        <span>
          {position === 'front'
            ? side === 'right'
              ? 'Int' : 'Ext'
            : side === 'right'
              ? 'Ext' : 'Int'
          }
        </span>
        <span>
          {`${sideTxt}: ${positionTxt}`}
        </span>
        <span>
          {position === 'front'
            ? side === 'right'
              ? 'Ext' : 'Int'
            : side === 'right'
              ? 'Int' : 'Ext'
          }
        </span>
      </MannequinHeader>

      <svg
        style={{ marginTop: "1.5rem", marginBottom: "0.5rem" }}
        width={widhtVersion}
        height={heightVersion}
        viewBox={viewBoxVersion}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <DefsMannequin />
        <MannequinFootsVector
          lesionnel={lesionnel}
          position={position}
          side={side}
        />
      </svg>

      <ul>
        {getTextify()}
      </ul>
    </MannequinCntnr>
  );
}

const DefsMannequin = () => (
  <defs>          <linearGradient
    id="BrulureDouleurDeformation"
    x1="33%"
    y1="0%"
    x2="66%"
    y2="0%"
    spreadMethod="pad"
  >
    <stop offset="0%" stopColor="#F5A623" />
    <stop offset="15%" stopColor="#FEED01" />
    <stop offset="85%" stopColor="#FEED01" />
    <stop offset="100%" stopColor="#1FEC33" />
  </linearGradient>
    <linearGradient
      id="BrulureDouleurGonflemement"
      x1="33%"
      y1="0%"
      x2="66%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#F5A623" />
      <stop offset="15%" stopColor="#FEED01" />
      <stop offset="85%" stopColor="#FEED01" />
      <stop offset="100%" stopColor="#B72DCD" />
    </linearGradient>
    <linearGradient
      id="BrulureDouleurPlaie"
      x1="33%"
      y1="0%"
      x2="66%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#F5A623" />
      <stop offset="15%" stopColor="#FEED01" />
      <stop offset="85%" stopColor="#FEED01" />
      <stop offset="100%" stopColor="#FF2D2D" />
    </linearGradient>
    <linearGradient
      id="BrulureDouleurSection"
      x1="33%"
      y1="0%"
      x2="66%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#F5A623" />
      <stop offset="15%" stopColor="#FEED01" />
      <stop offset="85%" stopColor="#FEED01" />
      <stop offset="100%" stopColor="#3E2DD7" />
    </linearGradient>
    <linearGradient
      id="BrulureDouleurTrauma"
      x1="33%"
      y1="0%"
      x2="66%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#F5A623" />
      <stop offset="15%" stopColor="#FEED01" />
      <stop offset="85%" stopColor="#FEED01" />
      <stop offset="100%" stopColor="#8B572A" />
    </linearGradient>
    <linearGradient
      id="BrulureDeformationGonflement"
      x1="33%"
      y1="0%"
      x2="66%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#F5A623" />
      <stop offset="15%" stopColor="#1FEC33" />
      <stop offset="85%" stopColor="#1FEC33" />
      <stop offset="100%" stopColor="#B72DCD" />
    </linearGradient>
    <linearGradient
      id="BrulureDeformationPlaie"
      x1="33%"
      y1="0%"
      x2="66%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#F5A623" />
      <stop offset="15%" stopColor="#1FEC33" />
      <stop offset="85%" stopColor="#1FEC33" />
      <stop offset="100%" stopColor="#FF2D2D" />
    </linearGradient>
    <linearGradient
      id="BrulureDeformationSection"
      x1="33%"
      y1="0%"
      x2="66%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#F5A623" />
      <stop offset="15%" stopColor="#1FEC33" />
      <stop offset="85%" stopColor="#1FEC33" />
      <stop offset="100%" stopColor="#3E2DD7" />
    </linearGradient>
    <linearGradient
      id="BrulureDeformationTrauma"
      x1="33%"
      y1="0%"
      x2="66%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#F5A623" />
      <stop offset="15%" stopColor="#1FEC33" />
      <stop offset="85%" stopColor="#1FEC33" />
      <stop offset="100%" stopColor="#8B572A" />
    </linearGradient>
    <linearGradient
      id="BrulureGonflementPlaie"
      x1="33%"
      y1="0%"
      x2="66%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#F5A623" />
      <stop offset="15%" stopColor="#B72DCD" />
      <stop offset="85%" stopColor="#B72DCD" />
      <stop offset="100%" stopColor="#FF2D2D" />
    </linearGradient>
    <linearGradient
      id="BrulureGonflementSection"
      x1="33%"
      y1="0%"
      x2="66%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#F5A623" />
      <stop offset="15%" stopColor="#B72DCD" />
      <stop offset="85%" stopColor="#B72DCD" />
      <stop offset="100%" stopColor="#3E2DD7" />
    </linearGradient>
    <linearGradient
      id="BrulureGonflementTrauma"
      x1="33%"
      y1="0%"
      x2="66%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#F5A623" />
      <stop offset="15%" stopColor="#B72DCD" />
      <stop offset="85%" stopColor="#B72DCD" />
      <stop offset="100%" stopColor="#8B572A" />
    </linearGradient>
    <linearGradient
      id="BrulurePlaieSection"
      x1="33%"
      y1="0%"
      x2="66%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#F5A623" />
      <stop offset="15%" stopColor="#FF2D2D" />
      <stop offset="85%" stopColor="#FF2D2D" />
      <stop offset="100%" stopColor="#3E2DD7" />
    </linearGradient>
    <linearGradient
      id="BrulurePlaieTrauma"
      x1="33%"
      y1="0%"
      x2="66%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#F5A623" />
      <stop offset="15%" stopColor="#FF2D2D" />
      <stop offset="85%" stopColor="#FF2D2D" />
      <stop offset="100%" stopColor="#8B572A" />
    </linearGradient>
    <linearGradient
      id="BrulureSectionTrauma"
      x1="33%"
      y1="0%"
      x2="66%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#F5A623" />
      <stop offset="15%" stopColor="#3E2DD7" />
      <stop offset="85%" stopColor="#3E2DD7" />
      <stop offset="100%" stopColor="#8B572A" />
    </linearGradient>
    <linearGradient
      id="DouleurDeformationGonflement"
      x1="33%"
      y1="0%"
      x2="66%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#FEED01" />
      <stop offset="15%" stopColor="#1FEC33" />
      <stop offset="85%" stopColor="#1FEC33" />
      <stop offset="100%" stopColor="#B72DCD" />
    </linearGradient>
    <linearGradient
      id="DouleurDeformationPlaie"
      x1="33%"
      y1="0%"
      x2="66%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#FEED01" />
      <stop offset="15%" stopColor="#1FEC33" />
      <stop offset="85%" stopColor="#1FEC33" />
      <stop offset="100%" stopColor="#FF2D2D" />
    </linearGradient>
    <linearGradient
      id="DouleurDeformationSection"
      x1="33%"
      y1="0%"
      x2="66%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#FEED01" />
      <stop offset="15%" stopColor="#1FEC33" />
      <stop offset="85%" stopColor="#1FEC33" />
      <stop offset="100%" stopColor="#3E2DD7" />
    </linearGradient>
    <linearGradient
      id="DouleurDeformationTrauma"
      x1="33%"
      y1="0%"
      x2="66%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#FEED01" />
      <stop offset="15%" stopColor="#1FEC33" />
      <stop offset="85%" stopColor="#1FEC33" />
      <stop offset="100%" stopColor="#8B572A" />
    </linearGradient>
    <linearGradient
      id="DouleurGonflementPlaie"
      x1="33%"
      y1="0%"
      x2="66%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#FEED01" />
      <stop offset="15%" stopColor="#B72DCD" />
      <stop offset="85%" stopColor="#B72DCD" />
      <stop offset="100%" stopColor="#FF2D2D" />
    </linearGradient>
    <linearGradient
      id="DouleurGonflementSection"
      x1="33%"
      y1="0%"
      x2="66%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#FEED01" />
      <stop offset="15%" stopColor="#B72DCD" />
      <stop offset="85%" stopColor="#B72DCD" />
      <stop offset="100%" stopColor="#3E2DD7" />
    </linearGradient>
    <linearGradient
      id="DouleurGonflementTrauma"
      x1="33%"
      y1="0%"
      x2="66%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#FEED01" />
      <stop offset="15%" stopColor="#B72DCD" />
      <stop offset="85%" stopColor="#B72DCD" />
      <stop offset="100%" stopColor="#8B572A" />
    </linearGradient>
    <linearGradient
      id="DouleurPlaieSection"
      x1="33%"
      y1="0%"
      x2="66%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#FEED01" />
      <stop offset="15%" stopColor="#FF2D2D" />
      <stop offset="85%" stopColor="#FF2D2D" />
      <stop offset="100%" stopColor="#3E2DD7" />
    </linearGradient>
    <linearGradient
      id="DouleurPlaieTrauma"
      x1="33%"
      y1="0%"
      x2="66%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#FEED01" />
      <stop offset="15%" stopColor="#FF2D2D" />
      <stop offset="85%" stopColor="#FF2D2D" />
      <stop offset="100%" stopColor="#8B572A" />
    </linearGradient>
    <linearGradient
      id="DouleurSectionTrauma"
      x1="33%"
      y1="0%"
      x2="66%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#FEED01" />
      <stop offset="15%" stopColor="#3E2DD7" />
      <stop offset="85%" stopColor="#3E2DD7" />
      <stop offset="100%" stopColor="#8B572A" />
    </linearGradient>
    <linearGradient
      id="DeformationGonflementPlaie"
      x1="33%"
      y1="0%"
      x2="66%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#1FEC33" />
      <stop offset="15%" stopColor="#B72DCD" />
      <stop offset="85%" stopColor="#B72DCD" />
      <stop offset="100%" stopColor="#FF2D2D" />
    </linearGradient>
    <linearGradient
      id="DeformationGonflementSection"
      x1="33%"
      y1="0%"
      x2="66%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#1FEC33" />
      <stop offset="15%" stopColor="#B72DCD" />
      <stop offset="85%" stopColor="#B72DCD" />
      <stop offset="100%" stopColor="#3E2DD7" />
    </linearGradient>
    <linearGradient
      id="DeformationGonflementTrauma"
      x1="33%"
      y1="0%"
      x2="66%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#1FEC33" />
      <stop offset="15%" stopColor="#B72DCD" />
      <stop offset="85%" stopColor="#B72DCD" />
      <stop offset="100%" stopColor="#8B572A" />
    </linearGradient>
    <linearGradient
      id="DeformationPlaieSection"
      x1="33%"
      y1="0%"
      x2="66%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#1FEC33" />
      <stop offset="15%" stopColor="#FF2D2D" />
      <stop offset="85%" stopColor="#FF2D2D" />
      <stop offset="100%" stopColor="#3E2DD7" />
    </linearGradient>
    <linearGradient
      id="DeformationPlaieTrauma"
      x1="33%"
      y1="0%"
      x2="66%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#1FEC33" />
      <stop offset="15%" stopColor="#FF2D2D" />
      <stop offset="85%" stopColor="#FF2D2D" />
      <stop offset="100%" stopColor="#8B572A" />
    </linearGradient>
    <linearGradient
      id="DeformationSectionTrauma"
      x1="33%"
      y1="0%"
      x2="66%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#1FEC33" />
      <stop offset="15%" stopColor="#3E2DD7" />
      <stop offset="85%" stopColor="#3E2DD7" />
      <stop offset="100%" stopColor="#8B572A" />
    </linearGradient>
    <linearGradient
      id="GonflementPlaieSection"
      x1="33%"
      y1="0%"
      x2="66%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#B72DCD" />
      <stop offset="15%" stopColor="#FF2D2D" />
      <stop offset="85%" stopColor="#FF2D2D" />
      <stop offset="100%" stopColor="#3E2DD7" />
    </linearGradient>
    <linearGradient
      id="GonflementPlaieTrauma"
      x1="33%"
      y1="0%"
      x2="66%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#B72DCD" />
      <stop offset="15%" stopColor="#FF2D2D" />
      <stop offset="85%" stopColor="#FF2D2D" />
      <stop offset="100%" stopColor="#8B572A" />
    </linearGradient>
    <linearGradient
      id="GonflementSectionTrauma"
      x1="33%"
      y1="0%"
      x2="66%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#B72DCD" />
      <stop offset="15%" stopColor="#3E2DD7" />
      <stop offset="85%" stopColor="#3E2DD7" />
      <stop offset="100%" stopColor="#8B572A" />
    </linearGradient>
    <linearGradient
      id="PlaieSectionTrauma"
      x1="33%"
      y1="0%"
      x2="66%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#FF2D2D" />
      <stop offset="15%" stopColor="#3E2DD7" />
      <stop offset="85%" stopColor="#3E2DD7" />
      <stop offset="100%" stopColor="#8B572A" />
    </linearGradient>
    <linearGradient
      id="BrulureDouleur"
      x1="40%"
      y1="0%"
      x2="60%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#F5A623" />
      <stop offset="100%" stopColor="#FEED01" />
    </linearGradient>
    <linearGradient
      id="BrulureDeformation"
      x1="40%"
      y1="0%"
      x2="60%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#F5A623" />
      <stop offset="100%" stopColor="#1FEC33" />
    </linearGradient>
    <linearGradient
      id="BrulureGonflement"
      x1="40%"
      y1="0%"
      x2="60%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#F5A623" />
      <stop offset="100%" stopColor="#B72DCD" />
    </linearGradient>
    <linearGradient
      id="BrulurePlaie"
      x1="40%"
      y1="0%"
      x2="60%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#F5A623" />
      <stop offset="100%" stopColor="#FF2D2D" />
    </linearGradient>
    <linearGradient
      id="BrulureSection"
      x1="40%"
      y1="0%"
      x2="60%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#F5A623" />
      <stop offset="100%" stopColor="#3E2DD7" />
    </linearGradient>
    <linearGradient
      id="BrulureTrauma"
      x1="40%"
      y1="0%"
      x2="60%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#F5A623" />
      <stop offset="100%" stopColor="#8B572A" />
    </linearGradient>
    <linearGradient
      id="DouleurDeformation"
      x1="40%"
      y1="0%"
      x2="60%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#FEED01" />
      <stop offset="100%" stopColor="#1FEC33" />
    </linearGradient>
    <linearGradient
      id="DouleurGonflement"
      x1="40%"
      y1="0%"
      x2="60%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#FEED01" />
      <stop offset="100%" stopColor="#B72DCD" />
    </linearGradient>
    <linearGradient
      id="DouleurPlaie"
      x1="40%"
      y1="0%"
      x2="60%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#FEED01" />
      <stop offset="100%" stopColor="#FF2D2D" />
    </linearGradient>
    <linearGradient
      id="DouleurSection"
      x1="40%"
      y1="0%"
      x2="60%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#FEED01" />
      <stop offset="100%" stopColor="#3E2DD7" />
    </linearGradient>
    <linearGradient
      id="DouleurTrauma"
      x1="40%"
      y1="0%"
      x2="60%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#FEED01" />
      <stop offset="100%" stopColor="#8B572A" />
    </linearGradient>
    <linearGradient
      id="DeformationGonflement"
      x1="40%"
      y1="0%"
      x2="60%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#1FEC33" />
      <stop offset="100%" stopColor="#B72DCD" />
    </linearGradient>
    <linearGradient
      id="DeformationPlaie"
      x1="40%"
      y1="0%"
      x2="60%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#1FEC33" />
      <stop offset="100%" stopColor="#FF2D2D" />
    </linearGradient>
    <linearGradient
      id="DeformationSection"
      x1="40%"
      y1="0%"
      x2="60%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#1FEC33" />
      <stop offset="100%" stopColor="#3E2DD7" />
    </linearGradient>
    <linearGradient
      id="DeformationTrauma"
      x1="40%"
      y1="0%"
      x2="60%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#1FEC33" />
      <stop offset="100%" stopColor="#8B572A" />
    </linearGradient>
    <linearGradient
      id="GonflementPlaie"
      x1="40%"
      y1="0%"
      x2="60%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#B72DCD" />
      <stop offset="100%" stopColor="#FF2D2D" />
    </linearGradient>
    <linearGradient
      id="GonflementSection"
      x1="40%"
      y1="0%"
      x2="60%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#B72DCD" />
      <stop offset="100%" stopColor="#3E2DD7" />
    </linearGradient>
    <linearGradient
      id="GonflementTrauma"
      x1="40%"
      y1="0%"
      x2="60%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#B72DCD" />
      <stop offset="100%" stopColor="#8B572A" />
    </linearGradient>
    <linearGradient
      id="PlaieSection"
      x1="40%"
      y1="0%"
      x2="60%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#FF2D2D" />
      <stop offset="100%" stopColor="#3E2DD7" />
    </linearGradient>
    <linearGradient
      id="PlaieTrauma"
      x1="40%"
      y1="0%"
      x2="60%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#FF2D2D" />
      <stop offset="100%" stopColor="#8B572A" />
    </linearGradient>
    <linearGradient
      id="SectionTrauma"
      x1="40%"
      y1="0%"
      x2="60%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#3E2DD7" />
      <stop offset="100%" stopColor="#8B572A" />
    </linearGradient>
    <linearGradient
      id="Vide"
      x1="100%"
      y1="0%"
      x2="60%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#D8D8D8" />
      <stop offset="100%" stopColor="#D8D8D8" />
    </linearGradient>
    <linearGradient
      id="Section"
      x1="100%"
      y1="0%"
      x2="60%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#3E2DD7" />
      <stop offset="100%" stopColor="#3E2DD7" />
    </linearGradient>
    <linearGradient
      id="Plaie"
      x1="100%"
      y1="0%"
      x2="60%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#FF2D2D" />
      <stop offset="100%" stopColor="#FF2D2D" />
    </linearGradient>
    <linearGradient
      id="Gonflement"
      x1="100%"
      y1="0%"
      x2="60%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#B72DCD" />
      <stop offset="100%" stopColor="#B72DCD" />
    </linearGradient>
    <linearGradient
      id="Deformation"
      x1="100%"
      y1="0%"
      x2="60%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#1FEC33" />
      <stop offset="100%" stopColor="#1FEC33" />
    </linearGradient>
    <linearGradient
      id="Douleur"
      x1="100%"
      y1="0%"
      x2="60%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#FEED01" />
      <stop offset="100%" stopColor="#FEED01" />
    </linearGradient>
    <linearGradient
      id="Trauma"
      x1="100%"
      y1="0%"
      x2="60%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#8B572A" />
      <stop offset="100%" stopColor="#8B572A" />
    </linearGradient>
    <linearGradient
      id="Brulure"
      x1="100%"
      y1="0%"
      x2="60%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#F5A623" />
      <stop offset="100%" stopColor="#F5A623" />
    </linearGradient>
    <linearGradient
      id="CombinaisonInconnue"
      x1="100%"
      y1="0%"
      x2="60%"
      y2="0%"
      spreadMethod="pad"
    >
      <stop offset="0%" stopColor="#FFFFFF" />
      <stop offset="100%" stopColor="#FFFFFF" />
    </linearGradient>
  </defs>
);
