import React, { useEffect } from "react";
import http from "../../http";
import { useQuery } from "react-query";
import * as Sentry from "@sentry/browser";
import { toast } from "react-toastify";
import { isAbortError } from "../../utils";

import PageHeader, { Cntnr, Title } from "../../ui/PageHeader";
import LoadingSpinner from "../../ui/LoadingSpinner";

export default function Organization() {
  const controller = new AbortController();

  const { isLoading: currentOrgIsLoading, data: currentOrganization } = useQuery(
    "currentOrganization",
    async () => {
      return await http
      .get(`organizations/mine`, {
        signal: controller.signal,
      })
      .json()
      .then(res => {
        return res[0];
      })
      .catch(error => {
        if (isAbortError(error)) return;
        console.error(error);
        Sentry.captureException(error);
        toast.warn("Une erreur est survenue lors de la récupération de l'organisation");
        throw error;
      });
    },
    {cacheTime: 0},
  );

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  return <>
    <PageHeader>
      <Title>
        Organisation
      </Title>
    </PageHeader>
    {currentOrgIsLoading ? (
      <LoadingSpinner className="center vh-50" />
    ) : (
      <Cntnr>
        <dl>
          <dt>Nom</dt>
          <dd>{currentOrganization.name}</dd>
          <dt>Département</dt>
          <dd>{currentOrganization.departement}</dd>
        </dl>
      </Cntnr>
    )}
  </>;
}
