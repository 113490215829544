import React, { useContext, useMemo, useState } from 'react';
import {Column, Row} from "../ui/FlexGrid";
import { hasPerm } from "../utils";
import CurrentUserContext from "../CurrentUserContext";
import ThemeModeContext from "../ui/useDarkMode";
import { uniqBy } from "lodash";
import { makeNrcLike } from "./layout/nrc";
import { makeXabcdeLike, makeAbcdeLike } from "./layout/abcdeFnspf";
import { makeSccarrLike } from "./layout/sccarr";
import { makeMedicalLike } from "./layout/medical";
import {
  makeSsoSmpLike,
  makesSsoSmpExamenSso,
  makesSsoSmpContextActionSynthese,
  makesSsoSmpContextContactMad,
  makesSsoSmpContextPersonInPlace,
  makesSsoSmpExamenSmp,
  makesSsoSmpExamenBilanSso, makesSsoSmpExamenBilanSmp,
} from './layout/ssoSmp';
import {
  Spec as syoSpec,
  utils as syoUtils,
  template as syoTemplate,
  Surveillances, Lesionnel
} from "@nfsave/syo-bilan";
import { getLayout, hasData } from './utils';

import Mannequin, {MannequinFoots, MannequinHands, MannequinHead} from "./Mannequin";
import styled from "styled-components";
import theme from "../ui/theme";
import * as BS from "./styled/Bilan-styled";
import SurvGraph from "./SurvGraph";
import MultiParamGraph from "./MultiParamGraph";
import { Tabs, TabsCntnr, BtnTabs } from "../ui/Tabs";

import ShowChartIcon from '@mui/icons-material/ShowChart';
import TableChartIcon from '@mui/icons-material/TableChart';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import currentUserContext from "../CurrentUserContext";
import { Disclosure } from '../ui/Disclosure';

export const LAYOUT_ABCDE_FNSPF = Object.freeze(makeAbcdeLike());
export const LAYOUT_MARCHE = Object.freeze(makeXabcdeLike(["M", "A", "R", "C", "H", "E"]));
export const LAYOUT_XABCDE = Object.freeze(makeXabcdeLike(["X", "A", "B", "C", "D", "E"]));
export const LAYOUT_NRC = Object.freeze(makeNrcLike());
export const LAYOUT_SCCARR = Object.freeze(makeSccarrLike());
export const LAYOUT_MED = Object.freeze(makeMedicalLike());
export const LAYOUT_SSO_SMP = Object.freeze(makeSsoSmpLike());
export const LAYOUT_SSO_SMP_EXAMEN_SSO = (title, bilansLength) => Object.freeze(makesSsoSmpExamenSso(title, bilansLength));
export const LAYOUT_SSO_SMP_EXAMEN_SMP = (title, bilansLength) => Object.freeze(makesSsoSmpExamenSmp(title, bilansLength));
export const LAYOUT_SSO_SMP_EXAMEN_BILAN_SSO = Object.freeze(makesSsoSmpExamenBilanSso());
export const LAYOUT_SSO_SMP_EXAMEN_BILAN_SMP = Object.freeze(makesSsoSmpExamenBilanSmp());
export const LAYOUT_SSO_CONTEXT_PERSON_IN_PLACE = Object.freeze(makesSsoSmpContextPersonInPlace());
export const LAYOUT_SSO_CONTEXT_CONTACT_MAD = Object.freeze(makesSsoSmpContextContactMad());
export const LAYOUT_SSO_CONTEXT_ACTION_SYNTHESE = Object.freeze(makesSsoSmpContextActionSynthese());

function ViewLabel({children, ...props}) {
  return <BS.ViewLabelCntnr {...props}>{children}:</BS.ViewLabelCntnr>
}

function ViewContainer({children, ...props}) {
  return <BS.ViewCntnr {...props}>
    {children}
  </BS.ViewCntnr>
}
function ViewDottedContainer({children, ...props}) {
  return <BS.ViewCntnr className="dotted" {...props}>
    {children}
  </BS.ViewCntnr>
}

function LinkView({spec, bilan, currentUser}) {
  const specFormatted = spec.getValue(bilan, currentUser);
  return (
      <ViewContainer gridRow={specFormatted.gridRow}>
        <ViewLabel>{specFormatted.label}</ViewLabel>
        {specFormatted.emptyish ? "--" : <a href={specFormatted.value} target="_blank" rel="noreferrer">Lien vers le bilan</a>}
      </ViewContainer>
  );
}

function TextView({spec, bilan, currentUser, withLabel}) {
  const specFormatted = spec.getValue(bilan, currentUser);

  // Permet l'affichage de la mise en page tablette si retour à la ligne
  const content = specFormatted.emptyish
    ? <span>--</span>
    : specFormatted.value.includes("\n")
      ? <span>
          {specFormatted.value.split("\n").map((e, idx) => (
            <span key={idx}>{e}<br /></span >
          ))}
        </span>
      : <span>{specFormatted.value}</span>;

  return (
    <ViewContainer gridRow={specFormatted.gridRow}>
      {withLabel && <ViewLabel>{specFormatted.label}</ViewLabel>}
      <>{content}</>
    </ViewContainer>
  );
}

function DateTimeView({spec, bilan, currentUser, withLabel}) {
  const specFormatted = spec.getValue(bilan, currentUser);

  return (
    <ViewContainer gridRow={specFormatted.gridRow}>
      {withLabel && <ViewLabel>{specFormatted.label}</ViewLabel>}
      <span>{specFormatted.content}</span>
    </ViewContainer>
  );
}

function TristateView({spec, bilan, currentUser, withLabel}) {
  const specFormatted = spec.getValue(bilan, currentUser);

  return (
    withLabel ? (
      <ViewDottedContainer gridRow={specFormatted.gridRow}>
        <div>
          <ViewLabel>{specFormatted.label}</ViewLabel>
          <BS.TagBilanDottedCntnr>
            <BS.TagBilanCntnr color={specFormatted.color}>{specFormatted.content}</BS.TagBilanCntnr>
          </BS.TagBilanDottedCntnr>
        </div>
      </ViewDottedContainer>
    ) : (
      <ViewContainer gridRow={specFormatted.gridRow}>
        <BS.TagBilanCntnr color={specFormatted.color}>{specFormatted.content}</BS.TagBilanCntnr>
      </ViewContainer>
    )
  )
}

function SelectView({spec, bilan, currentUser, withLabel}) {
  const specFormatted = spec.getValue(bilan, currentUser);

  return (
    <ViewContainer className="flex" gridRow={specFormatted.gridRow}>
      {withLabel && <ViewLabel>{specFormatted.label}</ViewLabel>}
      {specFormatted.emptyish
        ? <span>--</span>
        : <BS.TagBilanCntnr color={specFormatted.color}>{specFormatted.content}</BS.TagBilanCntnr>
      }
    </ViewContainer>
  );
}

function SelectDoubleView({spec, bilan, currentUser, withLabel}) {
  const specFormatted = spec.getValue(bilan, currentUser);

  return (
      <ViewContainer className="flex" gridRow={specFormatted.gridRow}>
        {withLabel && <ViewLabel>{specFormatted.label}</ViewLabel>}
        {specFormatted.emptyish
            ? <span>--</span>
            : (
                <>
                  {<BS.TagBilanCntnr color={specFormatted.colorLeft}>{specFormatted.contentLeft}</BS.TagBilanCntnr>}
                  {specFormatted.textJonction}
                  {<BS.TagBilanCntnr color={specFormatted.colorRight}>{specFormatted.contentRight}</BS.TagBilanCntnr>}
                </>
            )
        }
      </ViewContainer>
  );
}

function SelectEditableView({spec, bilan, setEdit, currentUser, withLabel}) {
  const specFormatted = spec.getValue(bilan, currentUser);

  const content = () => {
    if (specFormatted.emptyish){
      return 0;
    } else {
      return specFormatted.value;
    };
  };
  const [valueState, setValueState] = useState(content());

  const handleChange = (ev) => {
    setValueState(parseInt(ev.value, 10));
    setEdit({path: spec.edit, value: parseInt(ev.value, 10)});
  }

  return (
    <ViewContainer className="flex" gridRow={specFormatted.gridRow}>
      {withLabel && <ViewLabel>{specFormatted.label}</ViewLabel>}
      <select value={valueState} onChange={(ev) => handleChange(ev.target)}>
        {specFormatted.options.map((opt, index) => (
          <option key={`${opt.label}-${index}`} value={opt.value}>{opt.value} - {opt.label}</option>
        ))}
        <option value={0}>NR</option>
      </select>
    </ViewContainer>
  );
};

function MultiselectView({spec, bilan, currentUser, withLabel}) {
  const specFormatted = spec.getValue(bilan, currentUser);

  return (
    <ViewContainer className="flex" gridRow={specFormatted.gridRow}>
      {withLabel && <ViewLabel>{specFormatted.label}</ViewLabel>}
      {specFormatted.emptyish
        ? <span>--</span>
        : specFormatted.contents.map((elem, index) =>
          <BS.TagBilanCntnr style={{marginLeft: "0.5rem"}} key={`${elem.label}-${index}`} color={elem.color}>{elem.label}</BS.TagBilanCntnr>
        )
      }
    </ViewContainer>
  );
};

const MultiselectDoubleView = ({spec, bilan, currentUser, withLabel}) => {
  const specFormatted = spec.getValue(bilan, currentUser);

  return (
      <ViewContainer className="flex" gridRow={specFormatted.gridRow}>
        {withLabel && <ViewLabel>{specFormatted.label}</ViewLabel>}
        {specFormatted.emptyish
            ? <span>--</span>
            : (
                <>
                  {specFormatted.contentsLeft.map((elem, index) =>
                      <BS.TagBilanCntnr style={{marginLeft: "0.5rem"}} key={`${elem.label}-${index}`} color={elem.color}>{elem.label}</BS.TagBilanCntnr>)}
                  {specFormatted.textJonction}
                  {specFormatted.contentsRight.map((elem, index) =>
                      <BS.TagBilanCntnr style={{marginLeft: "0.5rem"}} key={`${elem.label}-${index}`} color={elem.color}>{elem.label}</BS.TagBilanCntnr>)}
                </>
            )
        }
      </ViewContainer>
  );
};

const MultiParamView = () => {
  return (
    <ViewContainer>
      <MultiParamGraph />
    </ViewContainer>
  );
};

function IntegerView({spec, bilan, currentUser}) {
  const specFormatted = spec.getValue(bilan, currentUser);

  return (
    <ViewContainer className="flex" gridRow={specFormatted.gridRow}>
      <ViewLabel>{specFormatted.label}</ViewLabel>
      {specFormatted.emptyish
        ? <span>--</span>
        : <BS.TagBilanCntnr color={specFormatted.color}>{specFormatted.value}{specFormatted.unit && (<span>{specFormatted.unit}</span>)}</BS.TagBilanCntnr>
      }
    </ViewContainer>
  );
}

const ArrayView = ({spec, bilan, currentUser, adaptative}) => {
  const specFormatted = spec.getValue(bilan, currentUser);

  return (
      <>
        {specFormatted.value.length === 0 && (<p style={{marginLeft: "1rem"}}>Aucun élément à afficher</p>)}
        {specFormatted.value.map((elem, idx) => (
            <BilanAdaptative key={`${spec.key}-${idx}`} bilan={elem} layout={getLayout(specFormatted.template, elem)} adaptative={adaptative} mainCntnr={false} />
        ))}
      </>
  );
};

const TableView = ({spec, bilan, currentUser}) => {
  const specFormatted = spec.getValue(bilan, currentUser);

  const tableHeader = useMemo(() => {
    return (
      <thead>
        <tr>
          {getLayout(specFormatted.template, specFormatted.value[0])?.[0]?.children?.map(elem => (
              <th key={elem.key} className="rightBorder">{elem.label}</th>
          ))}
        </tr>
      </thead>
    );
  }, [ specFormatted ]);

  return (
      <BS.TableCntnr style={{marginLeft: theme.small}}>
        {specFormatted.value.length === 0 && (<p style={{marginLeft: "1rem"}}>Aucun élément à afficher</p>)}
        {tableHeader}
        <tbody>
            {specFormatted.value.map((elem, idx) => (
                <tr key={`${spec.key}-${idx}`}>
                  <BilanAdaptative bilan={elem} layout={getLayout(specFormatted.template, elem)} adaptative={false} mainCntnr={false} />
                </tr>
            ))}
        </tbody>
      </BS.TableCntnr>
  );
};

const TableItemView = ({spec, bilan, adaptative, setEdit}) => {
  let children = (spec.children || []).filter(spec => viewHasContent(spec, bilan, adaptative));
  if (adaptative && children.length > 0) {
    children = spec.children;
  };

  return (
      <>
        {children.map((spec, idx) => (
            <BS.TdCntnr key={`${spec.key}-${idx}`} className="alignL">
              <View key={spec.key} bilan={bilan} spec={spec} adaptative={adaptative} setEdit={setEdit} forceTitle={spec["@type"] === "card"} withLabel={false} />
            </BS.TdCntnr>
        ))}
      </>
  );
}

const FloatTimeView = ({spec, bilan, currentUser, withLabel}) => {
  const specFormatted = spec.getValue(bilan, currentUser);

  return (
    <ViewContainer className="flex" gridRow={specFormatted.gridRow}>
      {withLabel && <ViewLabel>{specFormatted.label}</ViewLabel>}
      {specFormatted.emptyish
        ? <span>--</span>
        : <BS.TagBilanCntnr color={specFormatted.color}>{specFormatted.content}</BS.TagBilanCntnr>
      }
    </ViewContainer>
  );
};

const ColoredSquare = styled.div`
  float: left;
  margin-right: 0.5rem;
  margin-top: 0.25rem;
  width: 1rem;
  height: 1rem;
  background-color: ${props => props.color};
`;

const Legend = styled(Column)`
  margin: ${theme.small} 0;
  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 0;
    li:not(:last-child) {
      margin-right: ${theme.medium};
    }
  }
`;

function LesionnelView({spec, bilan, adaptative}) {
  const hasV2 = bilan.lesionnel_avant_v2 !== undefined && bilan.lesionnel_arriere_v2 !== undefined;
  const { currentUser } = useContext(currentUserContext);
  const hasNeoChildMannequinPerm = syoUtils.hasPerm(currentUser, "feature:advanced:mannequin:neo-enfant");
  const lesionnel = new Lesionnel(bilan);

  const version = () => {
    if (!hasV2) return "v1";
    return (hasData(bilan.lesionnel_avant ?? {}) || hasData(bilan.lesionnel_arriere ?? {})) ? "v1" : "v2";
  };
  const sexe = lesionnel.typeSex;
  // Configuration du type de mannequin en fonction de l'age
  let typeAge = 'adulte';
  if (hasNeoChildMannequinPerm) {
    typeAge = lesionnel.typeAge;
  }

  return (
    <ViewContainer>
      {(!adaptative || (adaptative && lesionnel.hasData)) ? (
        <>
          <Row>
            <Column>
              <Mannequin
                  lesionnel={spec.values.front(bilan, version())}
                  version={version()}
                  position="front"
                  sexe={sexe}
                  typeAge={typeAge}
              />

              {bilan.lesionnel.desc_avant && <p><span style={{fontStyle: "italic"}}>Description complémentaire : </span>{bilan.lesionnel.desc_avant}</p>}
            </Column>
            <Column>
              <Mannequin
                  lesionnel={spec.values.back(bilan, version())}
                  version={version()}
                  position="back"
                  sexe={sexe}
                  typeAge={typeAge}
              />

              {bilan.lesionnel.desc_arriere && <p><span style={{fontStyle: "italic"}}>Description complémentaire : </span>{bilan.lesionnel.desc_arriere}</p>}
            </Column>
          </Row>
          <Row>
            <Legend>
              <ul style={{listStyle: "none"}}>
                <li>
                  <ColoredSquare color="#3E2DD7"/> Section
                </li>
                <li>
                  <ColoredSquare color="#F5A623"/> Brûlure
                </li>
                <li>
                  <ColoredSquare color="#FF2D2D"/> Plaie
                </li>
                <li>
                  <ColoredSquare color="#1FEC33"/> Déformation
                </li>
                <li>
                  <ColoredSquare color="#8B572A"/> Trauma
                </li>
                <li>
                  <ColoredSquare color="#B72DCD"/> Gonflement
                </li>
                <li>
                  <ColoredSquare color="#FEED01"/> Douleur
                </li>
              </ul>
            </Legend>
          </Row>
          {lesionnel.hasDataLesionnelAvantTeteV2 && (
              <Row>
                <Column>
                  <MannequinHead
                      lesionnel={lesionnel.lesionnelAvantTeteV2}
                      version={version()}
                      position="front"
                      side="head"
                  />
                </Column>
                <Column>
                  <MannequinHead
                      lesionnel={lesionnel.lesionnelAvantTeteV2}
                      version={version()}
                      position="front"
                      side="mouth"
                  />
                </Column>
                {bilan.lesionnel.desc_tete_avant && <p style={{marginLeft: '15px'}}><span style={{fontStyle: "italic"}}>Description complémentaire : </span>{bilan.lesionnel.desc_tete_avant}</p>}
              </Row>
          )}
          {lesionnel.hasDataLesionnelMainGaucheV2 && (
              <Row>
                <Column>
                  <MannequinHands
                      lesionnel={lesionnel.lesionnelMainGaucheV2}
                      version={version()}
                      position="front"
                      side="left"
                  />
                </Column>
                <Column>
                  <MannequinHands
                      lesionnel={lesionnel.lesionnelMainGaucheV2}
                      version={version()}
                      position="back"
                      side="left"
                  />
                </Column>
                {bilan.lesionnel.desc_main_gauche && <p style={{marginLeft: '15px'}}><span style={{fontStyle: "italic"}}>Description complémentaire : </span>{bilan.lesionnel.desc_main_gauche}</p>}
              </Row>
          )}
          {lesionnel.hasDataLesionnelMainDroitV2 && (
              <Row>
                <Column>
                  <MannequinHands
                      lesionnel={lesionnel.lesionnelMainDroitV2}
                      version={version()}
                      position="front"
                      side="right"
                  />
                </Column>
                <Column>
                  <MannequinHands
                      lesionnel={lesionnel.lesionnelMainDroitV2}
                      version={version()}
                      position="back"
                      side="right"
                  />
                </Column>
                {bilan.lesionnel.desc_main_droit && <p style={{marginLeft: '15px'}}><span style={{fontStyle: "italic"}}>Description complémentaire : </span>{bilan.lesionnel.desc_main_droit}</p>}
              </Row>
          )}
          {lesionnel.hasDataLesionnelPiedGaucheV2 && (
              <Row>
                <Column>
                  <MannequinFoots
                      lesionnel={lesionnel.lesionnelPiedGaucheV2}
                      version={version()}
                      position="front"
                      side="left"
                  />
                </Column>
                <Column>
                  <MannequinFoots
                      lesionnel={lesionnel.lesionnelPiedGaucheV2}
                      version={version()}
                      position="back"
                      side="left"
                  />
                </Column>
                {bilan.lesionnel.desc_pied_gauche && <p style={{marginLeft: '15px'}}><span style={{fontStyle: "italic"}}>Description complémentaire : </span>{bilan.lesionnel.desc_pied_gauche}</p>}
              </Row>
          )}
          {lesionnel.hasDataLesionnelPiedDroitV2 && (
              <Row>
                <Column>
                  <MannequinFoots
                      lesionnel={lesionnel.lesionnelPiedDroitV2}
                      version={version()}
                      position="front"
                      side="right"
                  />
                </Column>
                <Column>
                  <MannequinFoots
                      lesionnel={lesionnel.lesionnelPiedDroitV2}
                      version={version()}
                      position="back"
                      side="right"
                  />
                </Column>
                {bilan.lesionnel.desc_pied_droit && <p style={{marginLeft: '15px'}}><span style={{fontStyle: "italic"}}>Description complémentaire : </span>{bilan.lesionnel.desc_pied_droit}</p>}
              </Row>
          )}
        </>
      ) : (
        <Row>
          <Column>
            <p>Aucune lésion à afficher</p>
          </Column>
        </Row>
      )}
    </ViewContainer>
  );
}

function MedicamentsView({spec, bilan}) {
  return (
    <ViewContainer style={{overflowX: "auto"}}>
      <BS.TableCntnr>
        <thead>
        <tr>
          <BS.ThCntnr>Heure</BS.ThCntnr>
          <BS.ThCntnr className="alignR noBorder">Quantité</BS.ThCntnr>
          <BS.ThCntnr className="alignL">Unité</BS.ThCntnr>
          <BS.ThCntnr>Médicament</BS.ThCntnr>
          <BS.ThCntnr>Voie</BS.ThCntnr>
          <BS.ThCntnr>Protocole</BS.ThCntnr>
          <BS.ThCntnr>Porte-ampoule</BS.ThCntnr>
          <BS.ThCntnr>Lot</BS.ThCntnr>
          <BS.ThCntnr>Commentaire</BS.ThCntnr>
        </tr>
        </thead>
        <tbody>
        {spec.value(bilan).map((m, idx) => (
          <tr key={idx}>
            <BS.TdCntnr>{m.heure}</BS.TdCntnr>
            <BS.TdCntnr className="alignR noBorder">{m.dose}</BS.TdCntnr>
            <BS.TdCntnr className="alignL">{m.unite}</BS.TdCntnr>
            <BS.TdCntnr>{m.nom}</BS.TdCntnr>
            <BS.TdCntnr>
              {m.voie === null
              ? "--"
              : <ValueLabel
                value={m.voie}
                labels={[
                  [0, "--"],
                  [1, "Inhalé"],
                  [2, "Intramusculaire"],
                  [3, "Intranasal"],
                  [4, "Intrarectal"],
                  [5, "IV flash"],
                  [6, "IV lente"],
                  [7, "Per cutanée"],
                  [8, "Per OS"],
                  [9, "PSE"],
                  [10, "Sous-cutané"],
                  [11, "Sublinguale"],
                ]}
              />}
            </BS.TdCntnr>
            <BS.TdCntnr>{m.protocole === undefined || m.protocole === "NR" || m.protocole === "" ? "--" : m.protocole}</BS.TdCntnr>
            <BS.TdCntnr>{m.holder === "NR" || m.holder === "" ? "--" : m.holder}</BS.TdCntnr>
            <BS.TdCntnr>{m.lot === "NR" ? "--" : m.lot}</BS.TdCntnr>
            <BS.TdCntnr>{m.comment === "NR" ? "--" : m.comment}</BS.TdCntnr>
          </tr>
        ))}
        </tbody>
      </BS.TableCntnr>
    </ViewContainer>
  );
}

/**
 * @return {string}
 */
function ValueLabel({value, labels}) {
  const tuple = labels.find(([v]) => value === v);
  return tuple != null ? tuple[1] : "";
}

function SurveillancesView({bilan, adaptative}) {
  const syoSurveillances = new Surveillances(bilan);
  const syoSurvsInject = syoSurveillances.getInitSurveillancesInject().filter(s => !s.isEmpty) || [];

  const {currentUser} = useContext(CurrentUserContext);
  const { themeMode } = useContext(ThemeModeContext);

  /** Colours the background when it is an injection */
  const bgInject = (s) => {
    return {backgroundColor: s.type === "injection"
      ? themeMode === "light" ? "#dce8ff57" : "rgba(49, 49, 49, 0.34)"
      : ""
    };
  };

  /** Content for an injection */
  const injectContent = (surveillance) => {
    return (
      <>
        <em style={{marginRight: "0.25rem"}}>{surveillance.quantity === null
        ? "--"
        : surveillance.quantity}</em>
        {surveillance.unit === null ? "--" : surveillance.unit}
        <br />
        {surveillance.route_of_administration === null
        ? "Voie NR"
        : surveillance.route_of_administration
        }
        {surveillance.ampoule_holder_identification && (
          <><br />Ampoulier: {surveillance.ampoule_holder_identification}</>
        )}
        {surveillance.nursing_protocol_name && (
          <><br />Protocole: {surveillance.nursing_protocol_name}</>
        )}
      </>
    );
  };

  const [ tabsView, setTabsView ] = useState("tab");
  const tabsBtnOptions= [
    {
      opt: "tab",
      icon: <TableChartIcon className="icon-left" />,
      title: "Tableau",
    },
    {
      opt: "graph",
      icon: <ShowChartIcon className="icon-left" />,
      title: "Graphique",
    }
  ];

  const hasAbcde = hasPerm(currentUser, "web:bilan:surveillances:graph");

  const templateHead = [
    ...syoTemplate.survTime,
    ...((adaptative && syoSurveillances.hasInconscience) || !adaptative ? syoTemplate.survInconscience : []),
    ...((adaptative && syoSurveillances.hasAvpu) || !adaptative ? syoTemplate.survAvpu : []),
  ];
  const templateAb = [
    ...((adaptative && syoSurveillances.hasFr) || !adaptative ? syoTemplate.survFr : []),
    ...((adaptative && syoSurveillances.hasO2aa) || !adaptative ? syoTemplate.survO2aa : []),
    ...((adaptative && syoSurveillances.hasO2o2) || !adaptative ? syoTemplate.survO2o2 : []),
    ...((adaptative && syoSurveillances.hasSpo2WithO2) || !adaptative ? syoTemplate.survspo2o2 : []),
    ...((adaptative && syoSurveillances.hasSpco) || !adaptative ? syoTemplate.survSpco : []),
    ...((adaptative && syoSurveillances.hasEtco2) || !adaptative ? syoTemplate.survEtco : []),
    ...((adaptative && syoSurveillances.hasPeakFlow) || !adaptative ? syoTemplate.survPeakFlow : []),
  ];
  const templateC = [
    ...((adaptative && syoSurveillances.hasFc)|| !adaptative ? syoTemplate.survFc : []),
    ...((adaptative && syoSurveillances.hasPabd) || !adaptative ? syoTemplate.survPabd : []),
    ...((adaptative && syoSurveillances.hasPambd) || !adaptative ? syoTemplate.survPamd : []),
    ...((adaptative && syoSurveillances.hasPabg) || !adaptative ? syoTemplate.survPabg : []),
    ...((adaptative && syoSurveillances.hasPambg) || !adaptative ? syoTemplate.survPamg : []),
    ...((adaptative && syoSurveillances.hasHemoglobine) || !adaptative ? syoTemplate.survHemoglobine : []),
    ...((adaptative && syoSurveillances.hasHemocue) || !adaptative ? syoTemplate.survHemocue : []),
    ...((adaptative && syoSurveillances.hasShockIndex) || !adaptative ? syoTemplate.survShockIndex : []),
    ...((adaptative && syoSurveillances.hasTrc) || !adaptative ? syoTemplate.survTrc : []),
    ...((adaptative && syoSurveillances.hasPaleur) || !adaptative ? syoTemplate.survPaleurs : []),
  ];
  const templateD = [
    ...((adaptative && syoSurveillances.hasPupilles) || !adaptative ? syoTemplate.survPupilles : []),
    ...((adaptative && syoSurveillances.hasGlasgow) || !adaptative ? syoTemplate.survScoreGla : []),
    ...((adaptative && syoSurveillances.hasGlasgow) || !adaptative ? syoTemplate.survGlaYeux : []),
    ...((adaptative && syoSurveillances.hasGlasgow) || !adaptative ? syoTemplate.survGlaVerbal : []),
    ...((adaptative && syoSurveillances.hasGlasgow) || !adaptative ? syoTemplate.survGlaMotricite : []),
    ...((adaptative && syoSurveillances.hasGlycemie) || !adaptative ? syoTemplate.survGlycemie : []),
    ...((adaptative && syoSurveillances.hasScoreSed) || !adaptative ? syoTemplate.survScoreSed : []),
  ];
  const templateE = [
    ...((adaptative && syoSurveillances.hasTemperature) || !adaptative ? syoTemplate.survTemp : []),
    ...((adaptative && syoSurveillances.hasEva) || !adaptative ? syoTemplate.survDouleur : []),
    ...((adaptative && syoSurveillances.hasHbco) || !adaptative ? syoTemplate.survHbco : []),
  ];

  const categoryContent = (template, category = "") => {
    return template.map((spec, idx) => {
      const survSpec = new syoSpec(spec);
      const head = survSpec.getHead(bilan);
      return (
        <tr key={idx}>
          {(hasAbcde && idx === 0) && (
            <BS.ThBisCntnr rowSpan={template.length}>{category}</BS.ThBisCntnr>
          )}
          <BS.ThCntnr className={`${idx === 0 ? "alignL " : ""}sticky${hasAbcde ? "-50" : ""}`}>{head.label}{head.unit ? ` (${head.unit})` : ""}</BS.ThCntnr>
          {syoSurvsInject.map((surv, idxSurv) => {
            const item = survSpec.getValue(bilan, bilan, surv);
            if (item.webDisplay === "surveillance") return surveillanceContent(item, surv, idx, idxSurv);
            if (item.webDisplay === "common") return commonContent(item, surv, idx, idxSurv);
            return "";
          })}
        </tr>
      );
    });
  };

  const surveillanceContent = (item, surv, idx, idxSurv) => (
    <BS.TdCntnr key={`${item.key}-${idx}-${idxSurv}`} style={bgInject(surv)}>
      {surv.type === "surveillance" && (
        !item.emptyish ? (
          ![null, undefined].includes(item.valueUnit) ? <><em>{item.value}</em> {item.valueUnit}</> : <>{item.value} {item.valueUnit}</>
        ) : ( "--" )
      )}
    </BS.TdCntnr>
  );

  const commonContent = (item, surv, idx, idxSurv) => (
    <BS.TdCntnr key={`${item.key}-${idx}-${idxSurv}`} style={bgInject(surv)}>
      <>{item.value ?? "--"}</>
    </BS.TdCntnr>
  );

  const injectionsContent = () => {
    return uniqBy(syoSurveillances.iv_fluids, 'name').map((iv, ivIdx) => (
      <tr key={ivIdx}>
        {(hasAbcde && ivIdx === 0) && (
          <BS.ThBisCntnr rowSpan={uniqBy(syoSurveillances.iv_fluids, 'name').length  + uniqBy(syoSurveillances.medications, 'name').length}><LocalPharmacyIcon /></BS.ThBisCntnr>
        )}
        <BS.ThCntnr className={`alignL sticky${hasAbcde ? "-50" : ""}`}>{iv.name}</BS.ThCntnr>
        {syoSurvsInject.map((s, sIdx) => (
          <BS.TdCntnr key={sIdx} style={bgInject(s)}>
            {s.type === "injection" && s.name === iv.name
              ? injectContent(s)
              : ""}
          </BS.TdCntnr>
        ))}
      </tr>
    ))
  };

  const medicationsContent = () => {
    return uniqBy(syoSurveillances.medications, 'name').map((med, medIdx) => (
      <tr key={medIdx}>
        {(hasAbcde && uniqBy(syoSurveillances.iv_fluids, 'name').length === 0 && medIdx === 0) && (
          <BS.ThBisCntnr rowSpan={uniqBy(syoSurveillances.iv_fluids, 'name').length  + uniqBy(syoSurveillances.medications, 'name').length}><LocalPharmacyIcon /></BS.ThBisCntnr>
        )}
        <BS.ThCntnr className={`alignL sticky${hasAbcde ? "-50" : ""}`}>{med.name}</BS.ThCntnr>
        {syoSurvsInject.map((s, sIdx) => (
          <BS.TdCntnr key={sIdx} style={bgInject(s)}>
            {s.type === "injection" && s.name === med.name
              ? injectContent(s)
              : ""}
          </BS.TdCntnr>
        ))}
      </tr>
    ))
  };

  return (
    <ViewContainer style={{overflowX: "auto", margin: "0.125rem 0.5rem"}}>
      {hasPerm(currentUser, "web:bilan:surveillances:graph") && (
        <TabsCntnr style={{margin: "0", marginBottom: theme.thin}}>
          <Tabs>
            {tabsBtnOptions.map((btn, idx) => (
              <BtnTabs
                key={idx}
                onClick={() => setTabsView(btn.opt)}
                className={`tabButton${tabsView === btn.opt ? " active" : ""}`}
                title={btn.title}>
                {btn.icon}{btn.title}
              </BtnTabs>
            ))}
          </Tabs>
        </TabsCntnr>
      )}
      {syoSurvsInject.length > 0 ? (
        <>
          <ViewContainer>Ne sont affichés que les champs et surveillances renseignées:</ViewContainer>
          {tabsView === "tab" && (
            <BS.TableVerticalCntnr>
              <thead>
                <tr>
                  {hasAbcde && (
                    <BS.ThBisCntnr></BS.ThBisCntnr>
                  )}
                  <BS.ThCntnr className={`sticky${hasAbcde ? "-50" : ""}`}>Surveillance</BS.ThCntnr>
                  {syoSurvsInject.map((_s, idx) => (
                    <BS.ThCntnr key={idx}>
                      {_s.type === "surveillance" && (
                        _s.index > -1
                          ? `n°${_s.index + 1}`
                          : "Initiales"
                      )}
                    </BS.ThCntnr>
                  ))}
                </tr>
              </thead>
              <tbody>
                {categoryContent(templateHead)}
                {categoryContent(templateAb, "A - B")}
                {categoryContent(templateC, "C")}
                {categoryContent(templateD, "D")}
                {categoryContent(templateE, "E")}
                {injectionsContent()}
                {medicationsContent()}
                {categoryContent([...((adaptative && syoSurveillances.hasComment) || !adaptative ? syoTemplate.survComment : [])])}
                {syoSurveillances.hasApgar && categoryContent([...syoTemplate.survApgar], "APGAR")}
              </tbody>
            </BS.TableVerticalCntnr>
          )}
          {tabsView === "graph" && (
            <SurvGraph
              bilan={bilan}
              surveillances={syoSurvsInject}
              hasPeakFlow={syoSurveillances.hasPeakFlow}
            />
          )}
        </>
      ) : (
        <ViewContainer>Aucune surveillance ou injection à afficher</ViewContainer>
      )}
    </ViewContainer>
  );
}

function CardView({spec, bilan, adaptative, setEdit, currentUser}) {
  const cardChildren = (spec.children || []).filter(spec => viewHasContent(spec, bilan, adaptative, currentUser));
  const title = spec.title instanceof Function ? spec.title(currentUser) : spec.title;
  const hasPermChildren = cardChildren.some(e => e['@type'] === 'permission');
  let permChildren = [];
  if (hasPermChildren) {
    permChildren = cardChildren?.filter(spec => spec.children?.some(e => viewHasContent(e, bilan, adaptative, currentUser))) || [];
  }

  return (
      <BS.CardViewCntnr gridColumn={spec.gridColumn} className={spec.className}>
        {spec.title && (
            <h4>{((hasPermChildren && (permChildren.length > 0 || cardChildren.length > 0)) || (!hasPermChildren && cardChildren.length > 0)) ? title : ""}</h4>
        )}
        {cardChildren.map(spec => <View key={spec.key} bilan={bilan} spec={spec} adaptative={adaptative} setEdit={setEdit} />)}
      </BS.CardViewCntnr>
  );
}

function DisclosureView({spec, bilan, adaptative, setEdit, currentUser}) {
  const disclosureChildren = (spec.children || []).filter(spec => viewHasContent(spec, bilan, adaptative, currentUser));
  const title = spec.title instanceof Function ? spec.title(currentUser) : spec.title;

  return (
      <Disclosure
          title={title}
          defaultOpen={false}
          StyleCntnr={BS.DisclosureViewCntnr}
          children={
            <>
              {disclosureChildren.map(spec => <View key={spec.key} bilan={bilan} spec={spec} adaptative={adaptative} setEdit={setEdit} />)}
            </>
          }
      />
  );
}

function GridView({spec, bilan, adaptative, setEdit}) {
  let children = (spec.children || []).filter(spec => viewHasContent(spec, bilan, adaptative));
  if (adaptative && children.length > 0) {
    children = spec.children;
  }

  return (
      <BS.GridViewCntnr templateColumns={spec.columns} gridColumn={spec.gridColumn} className={spec.className}>
        {children.map(spec => <View key={spec.key} bilan={bilan} spec={spec} adaptative={adaptative} setEdit={setEdit} forceTitle={spec["@type"] === "card"} />)}
      </BS.GridViewCntnr>
  );
}

function RowView({spec, bilan, adaptative, setEdit}) {
  let children = (spec.children || []).filter(spec => viewHasContent(spec, bilan, adaptative));
  if (adaptative && children.length > 0) {
    children = spec.children;
  }

  return (
    <BS.GridViewCntnr templateColumns={spec.columns} gridColumn={spec.gridColumn} className={spec.className}>
      {children.map(spec => <View key={spec.key} bilan={bilan} spec={spec} adaptative={adaptative} setEdit={setEdit} forceTitle={spec["@type"] === "card"} />)}
    </BS.GridViewCntnr>
  );
}

const CategoryView = ({spec, bilan, adaptative}) => {
  const children = (spec.children || []).filter(spec => viewHasContent(spec, bilan, adaptative));
  return (
    <BS.CategoryViewCntnr>
      {spec.title && (
        <h5>{children.length > 0 ? spec.title : ""}</h5>
      )}
      {spec.subtitle && (
        <h6>{children.length > 0 ? spec.subtitle : ""}</h6>
      )}
      <BS.GridViewCntnr templateColumns={spec.columns} gridColumn={spec.gridColumn} className={spec.className}>
        {children.map(spec => <View key={spec.key} bilan={bilan} spec={spec} adaptative={adaptative} />)}
      </BS.GridViewCntnr>
    </BS.CategoryViewCntnr>
  );
};

const PermissionView = ({spec, bilan, adaptative}) => {
  const children = (spec.children || []).filter(spec => viewHasContent(spec, bilan, adaptative));
  return (
    <>
      {children.map(spec => <View key={spec.key} bilan={bilan} spec={spec} adaptative={adaptative} />)}
    </>
  );
};

function viewHasContent(spec, bilan, adaptative, currentUser = null) {
  switch (spec["@type"]) {
    case "grid":
    case "card":
    case "disclosure":
    case "category":
    case "permission":
    case 'tableItem':
      if (currentUser) {
        if (spec["permission"]) {
          let temp = false;
          for (const perm of spec["permission"]) {
            if (perm[0] === "!" && hasPerm(currentUser, perm.substring(1))) return null;
            if (
                hasPerm(currentUser, perm) ||
                (perm[0] === "!" && !hasPerm(currentUser, perm.substring(1)))
            ) temp = true;
          }
          return temp;
        }
      }
      return (spec.children || []).some(spec => viewHasContent(spec, bilan, adaptative));
    case "link":
    case "text":
    case "time":
    case "date":
    case "tristate":
      if (adaptative) return !syoUtils.emptyishTab.includes(syoUtils.unwrapValue(spec, bilan));
      return true;
    case "dateTime":
      let unwrap = syoUtils.unwrapValue(spec, bilan);
      if (adaptative) return !syoUtils.emptyishTab.includes(unwrap?.time) || !syoUtils.emptyishTab.includes(unwrap?.date);
      return true;
    case "lesionnel":
    case "surveillances":
    case "multiparam":
    case "selectEditable":
      return true;
    case "medicaments":
      if (adaptative) return syoUtils.unwrapValue(spec, bilan).length > 0;
      return true;
    case "integer":
      let integerValue = syoUtils.unwrapValue(spec, bilan);
      if (adaptative) return integerValue != null && Number.isNaN(integerValue) === false;
      return true;
    case "array":
    case "table":
      const unwrapArray = syoUtils.unwrapValue(spec, bilan);
      if (adaptative) return unwrapArray.length > 0;
      return true;
    case "floatTime":
      let floatTimeValue = syoUtils.unwrapValue(spec, bilan);
      if (adaptative) return !syoUtils.emptyishTab.includes(floatTimeValue) && floatTimeValue !== 0;
      return true;
    case "select":
      let selectValue = syoUtils.unwrapValue(spec, bilan);
      if (adaptative) return !syoUtils.emptyishTab.includes(selectValue) && Number.isNaN(selectValue) === false && (spec.options || []).find(opt => opt.value === selectValue) != null;
      return true;
    case "selectDouble":
      let selectDoubleValue = syoUtils.unwrapValue(spec, bilan);
      let selectDoubleValueBis = syoUtils.unwrapValue(spec, bilan, 'valueBis');
      if (adaptative) return !syoUtils.emptyishTab.includes(selectDoubleValue) && Number.isNaN(selectDoubleValue) === false && (spec.options || []).find(opt => opt.value === selectDoubleValue) != null &&
        !syoUtils.emptyishTab.includes(selectDoubleValueBis) && Number.isNaN(selectDoubleValueBis) === false && (spec.options || []).find(opt => opt.value === selectDoubleValueBis) != null;
      return true;
    case "multiselect":
      let multiselectValue = syoUtils.unwrapValue(spec, bilan);
      if (adaptative) return !syoUtils.emptyishTab.includes(multiselectValue) && Number.isNaN(multiselectValue) === false && syoUtils.multiselect2text(multiselectValue, spec.options || []) != null;
    case "multiselectDouble":
      let multiselectDoubleValue = syoUtils.unwrapValue(spec, bilan);
      let multiselectDoubleValueBis = syoUtils.unwrapValue(spec, bilan, 'valueBis');
      if (adaptative) return !syoUtils.emptyishTab.includes(multiselectDoubleValue) && Number.isNaN(multiselectDoubleValue) === false && syoUtils.multiselect2text(multiselectDoubleValue, spec.options || []) != null &&
        !syoUtils.emptyishTab.includes(multiselectDoubleValueBis) && Number.isNaN(multiselectDoubleValueBis) === false && syoUtils.multiselect2text(multiselectDoubleValueBis, spec.options || []) != null;
      return true;
    default:
      return null;
  };
};

// TODO("Ne pas affichager la ligne vide en cas de block permission")

function View({ spec, bilan, adaptative, setEdit, forceTitle = false, withLabel = true }) {
  const {currentUser} = useContext(CurrentUserContext);

  /** In adaptive view, does not display empty elements except for card to display the title */
  if (
    spec["@type"] !== "card"
    && !forceTitle
    && viewHasContent(spec, bilan, adaptative) !== true
    && adaptative
  ) return null;

  const specFormatted = new syoSpec(spec);

  /** Doesn't display if it isn't Puy du Fou */
  if (!specFormatted.hasScope(bilan)) return null;

  if (spec["permission"]) {
    let temp = false;
    for (const perm of spec["permission"]) {
      if (perm[0] === "!" && hasPerm(currentUser, perm.substring(1))) return null;
      if (
        hasPerm(currentUser, perm) ||
        (perm[0] === "!" && !hasPerm(currentUser, perm.substring(1)))
      ) temp = true;
    };
    if (!temp) return null;
  };
  /** Doesn't display if it haven't the permission. */
  if (!specFormatted.hasPerm(currentUser)) return null;

  switch (spec["@type"]) {
    case "grid":
      return <GridView bilan={bilan} spec={spec} adaptative={adaptative} setEdit={setEdit} />;
    case "card":
      return <CardView bilan={bilan} spec={spec} adaptative={adaptative} setEdit={setEdit} currentUser={currentUser} />;
    case "disclosure":
      return <DisclosureView bilan={bilan} spec={spec} adaptative={adaptative} setEdit={setEdit} currentUser={currentUser} />;
    case "category":
      return <CategoryView bilan={bilan} spec={spec} adaptative={adaptative} currentUser={currentUser} />;
    case "permission":
      return <PermissionView bilan={bilan} spec={spec} adaptative={adaptative} currentUser={currentUser} />;
    case "link":
      return <LinkView bilan={bilan} spec={specFormatted} currentUser={currentUser} />;
    case "text":
      return <TextView bilan={bilan} spec={specFormatted} currentUser={currentUser} withLabel={withLabel} />;
    case "time":
    case "date":
    case "dateTime":
      return <DateTimeView bilan={bilan} spec={specFormatted} adaptative={adaptative} currentUser={currentUser} withLabel={withLabel} />;
    case "tristate":
      return <TristateView bilan={bilan} spec={specFormatted} currentUser={currentUser} withLabel={withLabel} />;
    case "integer":
      return <IntegerView bilan={bilan} spec={specFormatted} currentUser={currentUser} withLabel={withLabel} />;
    case "array":
      return <ArrayView bilan={bilan} spec={specFormatted} currentUser={currentUser} adaptative={adaptative} />;
    case "table":
      return <TableView bilan={bilan} spec={specFormatted} currentUser={currentUser} />;
    case "tableItem":
      return <TableItemView bilan={bilan} spec={spec} adaptative={adaptative} setEdit={setEdit} />;
    case "floatTime":
      return <FloatTimeView bilan={bilan} spec={specFormatted} currentUser={currentUser} withLabel={withLabel} />;
    case "lesionnel":
      return <LesionnelView bilan={bilan} spec={spec} adaptative={adaptative}/>;
    case "select":
      return <SelectView bilan={bilan} spec={specFormatted} currentUser={currentUser} withLabel={withLabel} />;
    case "selectDouble":
      return <SelectDoubleView bilan={bilan} spec={specFormatted} currentUser={currentUser} withLabel={withLabel} />;
    case "selectEditable":
      return <SelectEditableView bilan={bilan} spec={specFormatted} setEdit={setEdit} currentUser={currentUser}  withLabel={withLabel}/>;
    case "multiselect":
      return <MultiselectView bilan={bilan} spec={specFormatted} currentUser={currentUser} withLabel={withLabel} />;
    case "multiselectDouble":
      return <MultiselectDoubleView bilan={bilan} spec={specFormatted} currentUser={currentUser} withLabel={withLabel} />;
    case "medicaments":
      return <MedicamentsView bilan={bilan} spec={spec}/>;
    case "surveillances":
      return <SurveillancesView bilan={bilan} adaptative={adaptative} />;
    case "multiparam":
      return <MultiParamView bilan={bilan} spec={spec}/>;
    default:
      return null;
  };
};

export default function BilanAdaptative({ bilan, layout, adaptative, setEdit, mainCntnr = true }) {
  return (
      <>
        {mainCntnr ? (
            <div style={mainCntnr ? {paddingTop: "1rem", paddingBottom: "1rem"} : {}}>
              {layout.map(spec => <View key={spec.key} bilan={bilan} spec={spec} adaptative={adaptative} setEdit={setEdit} />)}
            </div>
        ) : (
            layout.map(spec => <View key={spec.key} bilan={bilan} spec={spec} adaptative={adaptative} setEdit={setEdit} />)
        )}
      </>
  );
};
