import React from "react";
import {Redirect, Route} from "found";

import List from "./screens/List";
import Show from "./screens/Show";

export const routes = (
  <Route path="licenses">
    <Redirect from="/active" to="/licenses" />
    <Redirect from="/inactive" to="/licenses" />
    <Redirect from="/revoked" to="/licenses" />
    <Route path="/" Component={List} />
    <Route path=":licenceId" Component={Show} />
  </Route>
);
