import React, { useState, useEffect, useMemo, useContext } from "react";
import {DateTime} from "luxon";
import http from "../../http";
import { useQuery } from "react-query";
import * as Sentry from "@sentry/browser";
import { toast } from "react-toastify";
import { getProvider, hasPerm, isAbortError } from "../../utils";
import CurrentUserContext from "../../CurrentUserContext";
import LoaderBarContext from "../../ui/useLoaderBar";
import * as XLSX from "xlsx";

import styled from "styled-components";
import SearchIcon from '@mui/icons-material/Search';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PageHeader, { Actions, Title } from "../../ui/PageHeader";
import UsersList from "../List";
import Button, { LinkButton } from "../../ui/Button";
import Input, { InputSearchCntnr, InputSearchIcon } from "../../ui/Input";
import SelectBase from "../../ui/Select";
import Tag from "../../ui/Tag";
import theme from "../../ui/theme";
import LoadingSpinner from "../../ui/LoadingSpinner";
import SearchBarCompo, { SearchBar } from "../../ui/SearchBar";
import SaveAltIcon from '@mui/icons-material/SaveAlt';

const Select = styled(SelectBase)`
  width: auto;
  &.sortSelect {
    width: 210px;
  }

  @media (max-width: 680px) {
    width: 100%;

    &.sortSelect {
      margin: 0 0 ${theme.small} 0;
      width: 100%;
    }
  }
`;

const FilterBar = styled.form`
  width: 100%;

  div.row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    > * {
      padding-bottom: ${theme.small};
    }
    > *:not(:last-child) {
      padding-right: ${theme.small};
    }
    & .item-primary {
      width: 35%;
    }
    & .item-secondary {
      width: 30%;
    }
  }

  div.rowWrap {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    > * {
      padding-bottom: ${theme.small};
    }
    > *:not(:last-child) {
      padding-right: ${theme.small};
    }
    > div {
      width: 25%;
    }
  }

  div.rowAction {
    display: flex;
    justify-content: flex-end;
    > button {
      margin-bottom: ${theme.small};
    }
    > button:first-child {
      margin-right: ${theme.small};
    }
  }

  @media (max-width: 1000px) {
    flex-direction: column;

    div.row {
      > *:not(:last-child) {
        padding-right: ${theme.small};
      }
    }

    div.rowWrap {
      > *:nth-child(even) {
        padding-right: 0;
      }
      > div {
        width: 50%;
      }
    }
  }

  @media (max-width: 680px) {
    div.row {
      flex-direction: column;
      > * {
        padding-right: 0 !important;
      }
      & .item-primary,
      & .item-secondary {
        width: 100%;
      }
    }

    div.rowWrap {
      > *:not(:last-child) {
        padding-right: 0;
      }
      > div {
        width: 100%;
      }
    }

    div.rowAction {
      flex-direction: column;
      > button {
        width: 100%;
      }
      > button:first-child {
        margin-right: 0;
      }
    }
  }
`;


export default function List({ router }) {
  const { isLoading: usersIsLoading, isError: usersIsError } = useQuery(
    ["usersList", "users"],
    async () => {
      return await http
      .get(`user`, {
        signal: controller.signal,
      })
      .json()
      .then(res => {
        setUsersList(res);
        return res;
      })
      .catch(error => {
        if (isAbortError(error)) return;
        console.error(error);
        Sentry.captureException(error);
        toast.warn("Une erreur est survenue lors de la récupération des utilisateurs");
        throw error;
      });
    },
    {
      cacheTime: 0,
    },
  );

  const { isLoading: rolesIsLoading, data: roles, isError: rolesIsError } = useQuery(
    ["usersList", "roles"],
    async () => {
      return await http
      .get(`roles.json`, {
        signal: controller.signal,
      })
      .json()
      .then(res => {
        return res.filter(role => !role.name.includes("--ARCHIVÉ--")) || [];
      })
      .catch(error => {
        if (isAbortError(error)) return;
        console.error(error);
        Sentry.captureException(error);
        toast.warn("Une erreur est survenue lors de la récupération des roles utilisateurs");
        throw error;
      });
    },
    {
      cacheTime: 0,
    },
  );

  const controller = new AbortController();

  const { currentUser } = useContext(CurrentUserContext);
  const { setLoaderBar } = useContext(LoaderBarContext);

  const [nameSearch, setNameSearch] = useState("");
  const [roleSearch, setRoleSearch] = useState("");
  const [providerSearch, setProviderSearch] = useState(null);
  const [sortSearch, setSortSearch] = useState("a");
  const [usersList, setUsersList] = useState([]);

  useEffect(() => {
    /** Apply filters and scroll from the last search */
    if (localStorage.getItem("nameSearch") !== null) {
      let name = localStorage.getItem("nameSearch");
      let role = localStorage.getItem("roleSearch");
      let sort = localStorage.getItem("sortSearch");
      let scroll = localStorage.getItem("scroll");

      setNameSearch(name);
      setRoleSearch(role);
      setSortSearch(sort);
      window.scrollTo(0, scroll);
      localStorage.removeItem("nameSearch");
      localStorage.removeItem("roleSearch");
      localStorage.removeItem("sortSearch");
      localStorage.removeItem("scroll");
    };

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    /** Before changing url save filters and scroll */
    const removeNavigationListener = router.addNavigationListener(
      (location) => {
        if (location.pathname.includes('/utilisateurs/')) {
          setSession();
          return true;
        } else {
          localStorage.removeItem("nameSearch");
          localStorage.removeItem("roleSearch");
          localStorage.removeItem("sortSearch");
          localStorage.removeItem("scroll");
          return true;
        };
      }
    );
    return () => {
      removeNavigationListener();
    }
  }, [nameSearch, roleSearch, sortSearch]);

  /** Saves the filtering information and the scroll before a url change */
  const setSession = () => {
    localStorage.setItem("nameSearch", nameSearch);
    localStorage.setItem("roleSearch", roleSearch);
    localStorage.setItem("sortSearch", sortSearch);
    localStorage.setItem("scroll", window.pageYOffset);
  };

  const handleSearchChange = (ev) => {
    setNameSearch(ev.target.value);
  };

  const handleFormRoleChange = (ev) => {
    setRoleSearch(ev?.value || "");
  };

  const handleFormProviderChange = (ev) => {
    setProviderSearch(ev?.value || null);
  };

  const clearFilter = () => {
    setNameSearch("");
    setRoleSearch("");
    setProviderSearch(null);
  };

  const clearNameSearch = () => {
    setNameSearch("");
  };

  const clearRolesSearch = () => {
    setRoleSearch("");
  };

  const filterByNameOrMatricule = (user) => {
    if (
      user.full_name.toLowerCase().includes(nameSearch.toLowerCase()) ||
      (user.matricule !== null && user.matricule.toLowerCase().includes(nameSearch.toLowerCase()))
    ) {
      return true;
    } else {
      return false;
    };
  };

  const getRoleName = () => {
    if (roleSearch == "null") {
      return "Rôle non défini";
    } else if (roleSearch == "undefined") {
      return "Le rôle attribué n'existe pas";
    } else {
      return roles.find(role => role.id == roleSearch).name;
    };
  };

  const sortUsersLIst = () => {
    switch (sortSearch) {
      case "a":
        return usersList.sort((left, right) => {
          const lhs = (left.full_name || "").toUpperCase();
          const rhs = (right.full_name || "").toUpperCase();
          return lhs > rhs ? 1 : -1;
        });

      case "z":
        return usersList.sort((left, right) => {
          const lhs = (left.full_name || "").toUpperCase();
          const rhs = (right.full_name || "").toUpperCase();
          return lhs < rhs ? 1 : -1;
        });

      case "+":
        return usersList.sort((left, right) => {
          const lhs = DateTime.fromISO(left.created_at).toMillis();
          const rhs = DateTime.fromISO(right.created_at).toMillis();
          return lhs < rhs ? 1 : -1;
        });

      case "-":
        return usersList.sort((left, right) => {
          const lhs = DateTime.fromISO(left.created_at).toMillis();
          const rhs = DateTime.fromISO(right.created_at).toMillis();
          return lhs > rhs ? 1 : -1;
        });

      case "+currentSign":
        return usersList.sort((left, right) => {
          let lhs = DateTime.fromISO(left.current_sign_in_at).toMillis();
          let rhs = DateTime.fromISO(right.current_sign_in_at).toMillis();
          if (isNaN(lhs)) lhs = 0;
          if (isNaN(rhs)) rhs = 0;
          return lhs < rhs ? 1 : -1;
        });

      case "-currentSign":
        return usersList.sort((left, right) => {
          let lhs = DateTime.fromISO(left.current_sign_in_at).toMillis();
          let rhs = DateTime.fromISO(right.current_sign_in_at).toMillis();
          if (isNaN(lhs)) lhs = 0;
          if (isNaN(rhs)) rhs = 0;
          return lhs > rhs ? 1 : -1;
        });

      default:
        return usersList.sort((left, right) => {
          const lhs = (left.full_name || "").toUpperCase();
          const rhs = (right.full_name || "").toUpperCase();
          return lhs > rhs ? 1 : -1;
        });
    };
  };

  const dynamicSearch = useMemo(() => {
    let result = [];
    result = sortUsersLIst();
    result = result.filter(user => filterByNameOrMatricule(user));
    if (roleSearch.length !== 0) {
      if (roleSearch === "null") {
        result = result.filter(user => user.role_id == null);
      } else if (roleSearch === "undefined") {
        let roleId = [null, ...roles.map(role => role.id)];
        result = result.filter(user => !roleId.includes(user.role_id));
      } else {
        result = result.filter(user => user.role_id === roleSearch);
      };
    };
    if (providerSearch) {
      switch (providerSearch) {
        case 1:
          result = result.filter(user => getProvider(user.phone_number, user.provider) === "SMS");
          break;
        case 2:
          result = result.filter(user => getProvider(user.phone_number, user.provider) === "Email");
          break;
        case 3:
          result = result.filter(user => getProvider(user.phone_number, user.provider) === "AD");
          break;
        default:
          break;
      };
    };
    return result;
  }, [nameSearch, roleSearch, sortSearch, providerSearch, usersList]);

  const refreshUser = async () => {
    try {
      const newUsers = await http.get(`user`).json();
      setUsersList(newUsers);
    } catch (err) {
      return err;
    };
  };

  const providerOptions = [
    {value: 1, label: "SMS"},
    {value: 2, label: "Email"},
    {value: 3, label: "AD"},
  ];

  const roleOptions = useMemo(() => {
    if (rolesIsLoading) {
      return [
        {value: "null", label: "Rôle non défini"},
        {value: "undefined", label: "Le rôle attribué n'existe pas"},
      ];
    } else {
      return [
        {value: "null", label: "Rôle non défini"},
        {value: "undefined", label: "Le rôle attribué n'existe pas"},
        ...roles.map(role => ({value: role.id, label: role.name}))
      ];
    };
  }, [roles]);

  const exportCsv = async () => {
    setLoaderBar(true);
    try {
      const data = usersList.map(user => {
        return {
          "nom_complet": user.full_name,
          "matricule": user.matricule,
          "methode_de_connexion": user.provider,
          "identifiant_de_connexion": user.uid,
          "email": user.email,
          "telephone": user.phone_number,
          "role": roles.find(r => r.id === user.role_id)?.name || "Indéterminé",
          "bloqué": user.blocked ? "Oui" : "Non",
          "quarantaine": user.locked_at ? "Oui" : "Non",
          "date_de_création": user.created_at,
          "date_de_modification": user.updated_at,
          "peut_ajouter_un_utilisateur": user.can_add_user ? "Oui" : "Non",
          "peut_modifier_un_utilisateur": user.can_edit_user ? "Oui" : "Non",
          "peut_bloquer_un_utilisateur": user.can_disable_user ? "Oui" : "Non",
          "peut_créer_un_bilan": user.can_create_bilan ? "Oui" : "Non",
          "peut_lister_les_bilans": user.can_list_all_bilan ? "Oui" : "Non",
          "peut_lister_les_bilans_inactifs": user.can_list_inactive_bilan ? "Oui" : "Non",
          "peut_télécharger_un_bilan": user.can_download_bilan ? "Oui" : "Non",
          "peut_modifier_les_roles": user.can_edit_roles ? "Oui" : "Non",
        };
      });

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(wb, ws, "Utilisateurs");
      XLSX.writeFile(wb, `syope-utilisateurs-${DateTime.now().toFormat('dd-MM-yyyy')}.csv`);
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      toast.warn("Une erreur est survenue lors de la génération du fichier CSV");
      throw error;
    } finally {
      setLoaderBar(false);
    };
  };

  return (
    <>
      <PageHeader>
        <Title>Utilisateurs</Title>
        <Actions>
          {hasPerm(currentUser, "web:users:export_csv") && (
            <Button onClick={() => exportCsv()}><SaveAltIcon className="icon-left" />Télécharger (CSV)</Button>
          )}
          <LinkButton to="/utilisateurs/nouveau"><PersonAddIcon className="icon-left" />Ajouter</LinkButton>
        </Actions>
      </PageHeader>

      <SearchBarCompo
        hasTag={true}
        tags={<>
          {nameSearch.length !== 0 && <Tag text={nameSearch} callback={clearNameSearch} />}
          {roleSearch.length !== 0 && <Tag text={getRoleName()} callback={clearRolesSearch} />}
        </>}
      >
        <SearchBar>
          <FilterBar>
            <div className="row">
              <InputSearchCntnr className="item-primary">
                <InputSearchIcon>
                  <SearchIcon />
                </InputSearchIcon>
                <Input
                  value={nameSearch}
                  onChange={handleSearchChange}
                  type="search"
                  placeholder="Nom, prénom et matricule"
                />
              </InputSearchCntnr>
              <div className="item-primary">
                <Select
                  isSearchable={true}
                  isClearable={true}
                  options={roleOptions}
                  value={roleOptions.find(r => r.value === roleSearch) || null}
                  onChange={handleFormRoleChange}
                  placeholder="Rôle"
                />
              </div>
              <div className="item-secondary">
                <Select
                  isClearable={true}
                  options={providerOptions}
                  value={providerOptions.find(p => p.value === providerSearch) || null}
                  onChange={handleFormProviderChange}
                  placeholder="Méthode de connexion"
                />
              </div>
            </div>
            <div className="rowAction">
              <Button className="warn" type="button" onClick={clearFilter}>Réinitialiser les filtres</Button>
            </div>
          </FilterBar>
        </SearchBar>
      </SearchBarCompo>

      {((usersIsLoading || rolesIsLoading) && !usersIsError && !rolesIsError) ? (
        <LoadingSpinner className="center vh-50" />
      ) : (
        <>
          <UsersList users={dynamicSearch} roles={roles} refreshUser={refreshUser} sortSearch={sortSearch} setSortSearch={setSortSearch} />
        </>
      )}
    </>
  );
}
