import { Link, withRouter } from "found";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import {goToTarget, setSignInUrl, setTimeoutSession} from "../../utils";

import KeyIcon from "@mui/icons-material/VpnKey";
import PersonIcon from "@mui/icons-material/Person";

import http, { HTTPError } from "../../http";
import Input, { InputIcon } from "../../ui/Input";
import BaseButton from "../../ui/Button";
import BaseFormGroup from "../../ui/FormGroup";
import { DangerAlert } from "../../ui/Alert";
import LogoName from "../../components/LogoName";

import CurrentUserContext from "../../CurrentUserContext";
import LoaderBarContext from "../../ui/useLoaderBar";
import InputPassword from '../../ui/InputPassword';

const ScrollView = styled.div`
  min-height: 100vh;
  overflow-y: auto;
`;

const Container = styled.div`
  max-width: 480px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 1rem;
  flex-direction: column;

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 0px 8px 16px 10px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    margin-top: 15%;
    padding: 0 2rem 2rem;
    border-radius: 0.5rem;

    &:hover {
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    }
  }
`;

const FormGroup = styled(BaseFormGroup)`
  display: flex;
  flex-direction: row;
`;

const Fieldset = styled.fieldset`
  width: 100%;
  margin-bottom: 1rem;
`;

const LoginButton = styled(BaseButton)`
  margin: 0.5rem;
  margin-bottom: 1rem;
`;

function SignIn({ router, match }) {
  const userCtx = useContext(CurrentUserContext);
  const { loaderBarState, setLoaderBar } = useContext(LoaderBarContext);

  const [form, setForm] = useState({
    email: "",
    password: "",
    errors: [],
  });

  const handleEmailChange = ev => {
    setForm({ ...form, email: ev.target.value });
  };

  const handlePasswordChange = ev => {
    console.log("hey")
    setForm({ ...form, password: ev.target.value });
  };

  const handleFormSubmit = async ev => {
    ev.preventDefault();
    if (loaderBarState) return;
    setLoaderBar(true);
    const payload = {
      email: form.email,
      password: form.password
    };
    try {
      localStorage.clear();

      const opts = { json: payload };
      const userResponse = await http.post("auth/sign_in", opts).json();
      const orgResponse = await http.get("organizations/mine").json();
      const currentUser = { organization: orgResponse[0], ...userResponse.data };

      userCtx.setUser(currentUser);
      localStorage.setItem("nfCurrentUser", JSON.stringify(currentUser));
      // const customDisconnect = currentUser.permissions.find(elem => elem.key.includes('automatic:disconnect:after:'));
      setTimeoutSession(undefined);
      setSignInUrl(match.location.pathname);
      goToTarget(router);
    } catch (err) {
      if (err instanceof HTTPError) {
        try {
          const response = await err.response.json();
          setForm({ ...form, errors: response.errors ? response.errors : [response.error] });
        } catch {
          setForm({ ...form, errors: [err.response.statusText] });
        }
      } else {
        setForm({
          ...form,
          errors: [
            "L'identifiant et le mot de passe fournis ne correspondent pas."
          ]
        });
        console.error(err);
      }

    } finally {
      setLoaderBar(false);
    }
  };

  useEffect(() => {
    if (userCtx.currentUser !== null) {
      localStorage.clear();
    }
  }, []);

  return (
    <ScrollView>
      <Container>
        <form onSubmit={handleFormSubmit}>
          <LogoName />
          {form.errors.map((err, index) => (
            <DangerAlert key={index}>{err}</DangerAlert>
          ))}
          <Fieldset disabled={loaderBarState}>
            <FormGroup>
              <InputIcon className="grey">
                <PersonIcon />
              </InputIcon>
              <Input
                id="auth-username-input"
                type="text"
                placeholder="Nom d'utilisateur ou adresse e-mail"
                required
                value={form.email}
                onChange={handleEmailChange}
              />
            </FormGroup>
            <FormGroup>
              <InputPassword
                id="auth-password-input"
                value={form.password}
                callback={handlePasswordChange}
              />
            </FormGroup>
            <div style={{ textAlign: "center" }}>
              <LoginButton>
                {loaderBarState ? "Tentative de connexion en cours…" : "Connexion"}
              </LoginButton>
              <br />
              <Link to="/auth/forgot-password">Mot de passe oublié</Link>
              <hr />
              <Link to="/partage">
                Télécharger un bilan à partir d'un code de partage
              </Link>
            </div>
          </Fieldset>
        </form>
      </Container>
    </ScrollView>
  );
}

export default withRouter(SignIn);
