import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { DateTime } from "luxon";

import styled from "styled-components";
import theme from "../ui/theme";
import { AreaChartView, ComposedChartView, LineChartView } from "../components/Graph";

const MultiCntnr = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: 1040px) {
    flex-direction: column;
  }
`;

const MultiGraphCntnr = styled.div`
  width: 70%;

  @media (max-width: 1040px) {
    width: 100%;
  }
`;

const GraphTitle = styled.h5`
  color: ${theme.text};
  font-size: 1rem;
  text-transform: uppercase;
`;

const GraphChartCntnr = styled.div`
  width: 100%;
  padding: ${theme.small};
  &:not(:last-child) {
    border-bottom: 2px dashed ${theme.blueBilan};
  }

  @media (max-width: 1000px) {
    width: 100%
  }
`;

const LogsCntnr = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  max-height: 627px;
  padding: ${theme.small};
  background-color: ${theme.blueBilan};
  overflow-y: hidden;

  @media (max-width: 1040px) {
    width: 100%;
    max-height: 300px;
  }
`;

const LogsList = styled.ul`
  list-style: none;
  overflow-y: auto;
  height: 100%;
  padding: 0;
`;

const LogItem = styled.li`
  font-family: 'JetBrains Mono', SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

  &:last-child {
    scroll-snap-align: end;
  }

  span {
    &.warn {
      color: ${theme.orange}
    }
    &.error {
      color: ${theme.red}
    }
    &.success {
      color: ${theme.green}
    }
  }
`;

const logsText = [
  "Praesent ut mauris vestibulum, eleifend arcu sit amet, condimentum libero.",
  "Cras efficitur enim at ultricies luctus.",
  "Donec nec eros eu neque mollis congue sit amet eu magna.",
  "Nulla id lectus at quam condimentum vulputate sed vel magna.",
  "Vestibulum malesuada ante eu nisi placerat volutpat.",
  "Duis facilisis nisl vel lobortis fringilla.",
  "Etiam nec est at enim laoreet blandit.",
  "Praesent efficitur dui nec neque tincidunt, non blandit massa tincidunt.",
  "Vivamus quis est molestie, ornare arcu in, tincidunt turpis.",
  "In in neque et nulla pulvinar convallis.",
  "Quisque in eros vel tortor feugiat commodo sit amet non metus.",
  "Sed gravida nunc ut nunc fringilla, a pharetra sem faucibus.",
  "Fusce condimentum lectus et massa ultrices, ac ullamcorper sem fringilla.",
  "Donec volutpat nisi at nisi molestie, eu iaculis magna volutpat.",
  "Proin cursus tellus eu massa luctus semper.",
];

const logType = [
  "warn",
  "error",
  "success",
];

const getRandomIntInclusive = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min +1)) + min;
};

const View = ({graph, data}) => {
  const title = <GraphTitle>{graph.title}</GraphTitle>;
  switch (graph.type) {
    case "line":
      return (
        <GraphChartCntnr>
          {title}
          <LineChartView graph={graph} data={data} />
        </GraphChartCntnr>
      );
    case "area":
      return (
        <GraphChartCntnr>
          {title}
          <AreaChartView graph={graph} data={data} />
        </GraphChartCntnr>
      );
    case "composed":
      return (
        <GraphChartCntnr>
          {title}
          <ComposedChartView graph={graph} data={data} />
        </GraphChartCntnr>
      );

    default:
      return null;
  };
};

const MultiParamGraph = () => {
  const listRef = useRef(null);
  const [ oldScroll, setOldScroll ] = useState({});
  const [ logs, setLogs ] = useState([]);
  const [ data, setData ] = useState([]);
  const [ freqGraph, setFreqGraph ] = useState([
    {
      dataKey: "fr",
      type: "linear",
      name: "FR",
      unit: "cpm",
      hide: false,
    },
    {
      dataKey: "fc",
      type: "linear",
      name: "FC",
      unit: "bpm",
      hide: false,
    },
  ]);
  const [ constGraph, setConstGraph ] = useState([
    {
      dataKey: "spo2",
      type: "linear",
      name: "SpO² sous O2",
      unit: "%",
      hide: false,
    },
    {
      dataKey: "spco",
      type: "linear",
      name: "SpCO",
      unit: "%",
      hide: false,
    },
    {
      dataKey: "etco2",
      type: "linear",
      name: "EtCO²",
      unit: "mmHg",
      hide: false,
    },
  ]);

  const {
    data: params,
  } = useQuery(
    ["multiParams"],
    async () => {
      let tempData = data || [];
      let tempLogs = logs || [];
      tempData.push({
        fr: getRandomIntInclusive(15, 45),
        fc: getRandomIntInclusive(70, 115),
        spo2: getRandomIntInclusive(80, 100),
        spco: getRandomIntInclusive(0, 20),
        etco2: getRandomIntInclusive(25, 45)
      });
      if (getRandomIntInclusive(0, 1)) {
        setOldScroll(listRef.current.scrollTopMax);
        tempLogs.push(
          {
            type: "log",
            ts: DateTime.now().setLocale("fr").toLocaleString(DateTime.TIME_WITH_SECONDS),
            text: logsText[getRandomIntInclusive(0, 14)],
            logType: getRandomIntInclusive(0, 1) ? logType[getRandomIntInclusive(0, 2)] : "",
          }
        );
        setLogs([...tempLogs]);
      };
      setData([...tempData]);
      return;
    },
    {
      keepPreviousData: true,
      refetchInterval: 2000,
    }
  );

  // Makes the list scroll down
  useEffect(() => {
    let tempMax = listRef.current.scrollTopMax;
    let temp = listRef.current.scrollTop;
    if (
      (oldScroll === 0 && tempMax !== 0)
      || (oldScroll === temp)
    ) {
      listRef.current.scrollTop = tempMax;
    };
  }, [ logs ]);

  const graphs = [
    {
      type: "line",
      title: "Fréquences",
      syncId: "multiSync",
      data: freqGraph,
      setData: setFreqGraph,
    },
    {
      type: "line",
      title: "Constantes",
      syncId: "multiSync",
      data: constGraph,
      setData: setConstGraph,
    },
  ];

  return (
    <MultiCntnr>
      <MultiGraphCntnr>
        {graphs.map((graph, idx) => (
          <View key={idx} graph={graph} data={data} />
        ))}
      </MultiGraphCntnr>
      <LogsCntnr>
        <GraphTitle>Logs</GraphTitle>
        <LogsList ref={listRef}>
          {logs.length > 0 ? (
            <>
              {logs.map((log, idx) => (
                <LogItem key={idx}>
                  {log.ts} {log.logType && (<span className={`${log.logType}`}>{log.logType}</span>)} {`>`} {log.text}
                </LogItem>
              ))}
            </>
          ) : (
            <LogItem>Aucun log</LogItem>
          )}
        </LogsList>
      </LogsCntnr>
    </MultiCntnr>
  );
};

export default MultiParamGraph;