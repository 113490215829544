import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import * as Sentry from "@sentry/browser";
import {toast} from "react-toastify";
import http from "../http";
import { isAbortError } from "../utils";
import {DateTime} from "luxon";
import LoaderBarContext from "../ui/useLoaderBar";

import FormGroup from "../ui/FormGroup";
import Label from "../ui/Label";
import Input from "../ui/Input";
import {Column, Row as RowBase} from "../ui/FlexGrid";
import Button from "../ui/Button";
import styled from "styled-components";
import theme from "../ui/theme";
import LoadingSpinner from "../ui/LoadingSpinner";

const CardActions = styled.div`
  margin: 0 -${theme.small} -${theme.small};
  background: ${theme.grey6};
  padding: ${theme.thin};
  text-align: right;
`;

const CardForm = styled.form`
  padding: ${theme.small};
  border-radius: .25rem;
  border: 1px solid ${theme.grey6};
  max-width: 760px;
`;

const UnstyledList = styled.ul`
  list-style: none;
  padding: 0;
  max-width: 760px;
`;

const Card = styled.li`
  padding: ${theme.small};
  border-radius: .25rem;
  border: 1px solid ${theme.grey6};
  margin-top: ${theme.small};
`;

const Row = styled(RowBase)`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;

const EstablishmentCards = ({user}) => {
  const controller = new AbortController();

  const localize = timestamp => DateTime.fromISO(timestamp).toLocaleString(DateTime.DATE_FULL);

  const { loaderBarState, setLoaderBar } = useContext(LoaderBarContext);

  const [cardName, setCardName] = useState("");
  const [cardId, setCardId] = useState("");
  const [cardPin, setCardPin] = useState("");

  const {
    isLoading: cardsIsLoading,
    data: cards,
    refetch: refetchCards,
  } = useQuery(
    ["components", "establishmentCards", user.id],
    async () => {
      return await http
      .get(`user/${user.id}/cards_mifare_classic.json`, {
        signal: controller.signal,
      })
      .json()
      .then(res => {
       return res.data;
      })
      .catch(error => {
        if (isAbortError(error)) return;
        console.error(error);
        Sentry.captureException(error);
        toast.warn("Une erreur est survenue lors de la récupération de données");
        throw error;
      });
    },
    {
      cacheTime: 0,
    },
  );

  const handleCardFormSubmit = async (ev) => {
    ev.preventDefault();
    setLoaderBar(true);

    const payload = {
      name: cardName,
      card_id: cardId,
      pin: cardPin,
    };

    try {
      await http.post(`user/${encodeURIComponent(user.id)}/cards_mifare_classic`, {json: {card: payload}}).json();
      toast.success("La carte d'établissement a bien été ajouté.");
      refetchCards();
    } catch (e) {
      console.error(e);
      toast.warn("Une erreur est survenue lors de la création de la carte d'établissement");
      Sentry.captureException(e);
    } finally {
      setLoaderBar(false);
    };
  };

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      {cardsIsLoading ? (
        <LoadingSpinner className="center" />
      ) : (
        <>
          <CardForm onSubmit={handleCardFormSubmit}>
            <FormGroup>
              <Label htmlFor="user-mifare-classic-name-input">Nom de la carte</Label>
              <Input
                id="user-mifare-classic-name-input"
                required={true}
                value={cardName}
                onChange={ev => setCardName(ev.target.value)}
                disabled={loaderBarState}
              />
            </FormGroup>
            <Row>
              <Column width={4}>
                <FormGroup>
                  <Label htmlFor="user-mifare-classic-id-input">ID de la carte</Label>
                  <Input
                    id="user-mifare-classic-id-input"
                    required={true}
                    value={cardId}
                    onChange={ev => setCardId(ev.target.value)}
                    disabled={loaderBarState}
                    placeholder="A1B2C3D4"
                  />
                </FormGroup>
              </Column>
              <Column width={1}>
                <FormGroup>
                  <Label htmlFor="user-mifare-classic-pin-input">Code PIN</Label>
                  <Input
                    id="user-mifare-classic-pin-input"
                    required={true}
                    minlength={4}
                    maxlength={4}
                    inputmode="number"
                    value={cardPin}
                    onChange={ev => setCardPin(ev.target.value)}
                    disabled={loaderBarState}
                    placeholder="1234"
                  />
                </FormGroup>
              </Column>
            </Row>
            <CardActions>
              <Button disabled={loaderBarState}>Ajouter</Button>
            </CardActions>
          </CardForm>
          <UnstyledList>
            {cards.map(card =>
              <Card key={card.id}>
                <h3>{card.name}</h3>
                <p>
                  <em>Créé à {localize(card.created_at)}</em>
                </p>
                <CardActions>
                  <Button disabled={true}>Révoquer</Button>
                </CardActions>
              </Card>
            )}
          </UnstyledList>
        </>
      )}
    </>
  );
};

export default EstablishmentCards;