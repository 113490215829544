import React from "react";

import PageHeader, { Cntnr, Title } from "../../ui/PageHeader";

function MentionsLegales() {
  return (
    <>
      <PageHeader>
        <Title>Mentions légales</Title>
      </PageHeader>

      <Cntnr>
        <p>
          Le site internet de Syopé, ci-après dénommé «le Site» consultable à
          l’adresse «https://app.syope.fr» est la propriété de la société Tildev,
          société par actions simplifiée, immatriculée au Registre du Commerce et
          des Sociétés de Rennes sous le numéro 830 158 820, au capital social de 33
          350 euros, dont le siège social est situé 4F rue du Bordage –
          35510 Cesson-Sévigné.
          <br />
          Email : contact@syope.fr.
          <br />
          <br />
          Téléphone : 07 66 89 87 12
          <br />
          <br />
          Numéro de siret : 830 158 820 00019
          <br />
          <br />
          Directeur de la publication : Simon PEDRONO, en qualité de Dirigeant de Tildev.
          <br />
          <br />
          L’hébergeur du Site est la société GIP SIB, dont le siège est situé au 4
          Rue Professeur Jean Pecker, 35065 Rennes. Téléphone : 02 99 54 75 10.
          <br />
          <br />
        </p>
      </Cntnr>
    </>
  );
}

export default MentionsLegales;
