import { template as syoTemplate } from "@nfsave/syo-bilan";

export function makeNrcLike() {
  return [
    {
      "@type": "grid",
      "key": "dcbc144b-1c2b-4623-a859-30b29399cdbd",
      columns: 1,
      children: [
        { // Intervention / Équipe
          "@type": "card",
          key: "f142004e-7315-4f1f-b9a3-cc7c7e83bf48",
          title: "Intervention / Équipe",
          gridColumn: "1/2",
          className: "cardCntnr",
          children: [
            {
              "@type": "grid",
              key: "e7a40b38-4864-46c2-9449-5b4e1f8b1b86",
              columns: 3,
              children: [
                ...syoTemplate.ticketNumMotif,
                ...syoTemplate.equipeInfos,
              ],
            },
          ]
        },
      ],
    },
    {
      "@type": "grid",
      "key": "5757c9f0-f54e-485a-b87c-d457c3fbe449",
      columns: 5,
      children: [
        { // Circonstantiel
          "@type": "card",
          key: "e152b912-2513-4df3-8d63-4e3d76856f8c",
          title: "Circonstantiel",
          gridColumn: "1/3",
          children: [
            ...syoTemplate.circonstanciel,
            ...syoTemplate.situation,
          ],
        },
        { // Identité
          "@type": "card",
          key: "5516f091-9a5e-4f98-912b-c85ca7bf2232",
          title: "Identité",
          gridColumn: "3/5",
          children: [
            ...syoTemplate.identite,
          ],
        },
        { // Contact
          "@type": "card",
          key: "59060cb6-4180-4b82-b4b1-78da2dee2e2a",
          title: "Contact",
          gridColumn: "5/6",
          children: [
            ...syoTemplate.contact
          ],
        },
      ],
    },
    {
      "@type": "grid",
      "key": "6c832786-63cc-4cf2-8911-6729baa5bd50",
      columns: 3,
      children: [
        { // Anamnèse
          "@type": "card",
          key: "71f96f13-44fe-4df7-a3e1-ca1e07480de1",
          title: "Anamnèse",
          gridColumn: "1/2",
          children: [
            ...syoTemplate.anamnese,
          ],
        },
        { // Examen clinique
          "@type": "card",
          key: "61082c62-7bbd-483f-af71-8e0c697d5db9",
          title: "Examen clinique",
          gridColumn: "2/3",
          children: [
            ...syoTemplate.examen,
          ],
        },
        { // Hypothèse diagnostique
          "@type": "card",
          key: "18beaa2c-c8c5-4c55-8380-9293b5dce23f",
          title: "Hypothèse diagnostique",
          gridColumn: "3/4",
          children: [
            ...syoTemplate.hypotheseDiag,
          ],
        },
      ],
    },
    {
      "@type": "grid",
      "key": "bc4cfd16-7ea0-4f07-b117-e065446be36a",
      columns: 3,
      children: [
        { // MHTA
          "@type": "card",
          key: "30f50522-bbe6-4648-9cf8-2769f06526ff",
          title: "MHTA",
          gridColumn: "1/2",
          children: [
            ...syoTemplate.mhta,
            ...syoTemplate.poidsTaille,
            ...syoTemplate.lunchSampler,
          ],
        },
        { // PQRST
          "@type": "card",
          key: "4e81a876-e53e-4c42-a3d6-9c5eeea9909f",
          title: "PQRST",
          gridColumn: "2/3",
          children: [
            ...syoTemplate.pqrst,
            ...syoTemplate.opqrst,
          ],
        },
        { // Vital
          "@type": "card",
          key: "97338697-577c-441f-b1bc-60201bfcf52e",
          title: "Vital",
          gridColumn: "3/4",
          children: [
            //
            ...syoTemplate.obva,
            ...syoTemplate.circulatoireVital,
            ...syoTemplate.neurologiqueVital,
            ...syoTemplate.respiratoireVital,
          ],
        },
      ],
    },
    {
      "@type": "grid",
      key: "7749f57b-2bd6-462f-bc2f-fb149e34b1c6",
      columns: 1,
      children: [
        { // ACR Arrêt cardio-respiratoire
          "@type": "card",
          key: "e9288c17-b6b4-4111-bd13-889eac019cab",
          title: "Arrêt cardio-respiratoire",
          children: [
            ...syoTemplate.acr,
          ],
        },
      ],
    },
    {
      "@type": "grid",
      "key": "916903b2-0521-44e9-a3d4-32d26acc69b1",
      columns: 3,
      children: [
        { // Neurologique
          "@type": "card",
          key: "580a9588-e8d4-4ebc-8f15-a11004c125e1",
          title: "Neurologique",
          gridColumn: "1/2",
          children: [
            {
              "@type": "permission",
              key: "072f3f19-9efe-46e6-805a-fc56c0331088",
              permission: ['!web:bilan:extract-fast-out-of-neuro'],
              children: [
                {
                  "@type": "grid",
                  key: "0c22be7d-26f8-4192-88ff-76b6ee015bf5",
                  columns: 2,
                  className: "cardGrid",
                  children: [
                    ...syoTemplate.neurologique,
                  ],
                },
              ],
            },
            {
              "@type": "permission",
              key: "fb0add8c-927b-4387-ae09-10f4ece314a6",
              permission: ['web:bilan:extract-fast-out-of-neuro'],
              children: [
                {
                  "@type": "grid",
                  key: "5257ca0a-3fc6-4d07-beba-4e16139c948d",
                  columns: 2,
                  className: "cardGrid",
                  children: [
                    ...syoTemplate.neurologiqueSansFast,
                  ],
                },
              ],
            },
          ],
        },
        { // Circulatoire
          "@type": "card",
          key: "3fd4d977-689a-4ce4-8fa9-95494ac70792",
          title: "Circulatoire",
          gridColumn: "2/3",
          children: [
            {
              "@type": "grid",
              key: "76e9a92f-c6b2-462f-ae0d-896c76b9b105",
              columns: 2,
              className: "cardGrid",
              children: [
                ...syoTemplate.circulatoireNRC,
                ...syoTemplate.circulatoire,
              ],
            },
          ],
        },
        { // Respiratoire
          "@type": "card",
          key: "d48d23a1-3dcb-4a47-81b9-59e0ffb107a4",
          title: "Respiratoire",
          gridColumn: "3/4",
          children: [
            {
              "@type": "grid",
              key: "c5569f45-fc3b-42b6-b823-ec303d548824",
              columns: 2,
              className: "cardGrid",
              children: [
                ...syoTemplate.respirationReguliereNRC,
                ...syoTemplate.respiration,
              ],
            },
          ],
        },
      ],
    },
    {
      "@type": "grid",
      "key": "4a766f42-89a5-47ac-b494-9f33b6a8aa6d",
      columns: 4,
      children: [
        { // Obstétrique
          "@type": "card",
          key: "2c0c376f-af9b-4208-b8ad-20b66a59f25c",
          title: "Obstétrique",
          gridColumn: "1/2",
          children: [
            ...syoTemplate.obstetrique,
          ],
        },
        { // Douleur Thoracique
          "@type": "card",
          key: "1fbd86d6-b685-4520-b091-02330cfffb28",
          title: "Douleur Thoracique",
          gridColumn: "2/3",
          children: [
            ...syoTemplate.douleurThoracique,
          ],
        },
        { // Secours routier
          "@type": "card",
          key: "72972f38-399c-4540-be9c-c091bde3e884",
          title: "Secours routier / Accidents",
          gridColumn: "3/4",
          children: [
            ...syoTemplate.secoursRoutier,
          ],
        },
        { // NIHSS
          "@type": "card",
          key: "959d855d-f5b2-4f51-bebb-28b14cfb41e7",
          title: "NIHSS",
          gridColumn: "4/5",
          children: [
            {
              "@type": "permission",
              key: "9a930ee8-5ed3-45c1-82e8-a98381a58861",
              permission: ["!web:bilan:extract-fast-out-of-neuro"],
              children: [
                ...syoTemplate.nihss,
              ],
            },
            {
              "@type": "permission",
              key: "e6e2badd-d984-4bb6-977c-d75b1e187a10",
              permission: ["web:bilan:extract-fast-out-of-neuro"],
              children: [
                ...syoTemplate.nihssSansFast,
              ],
            },
          ]
        },
      ],
    },
    {
      "@type": "grid",
      "key": "ca3335f6-7028-4535-94be-c4c6fdd74045",
      columns: 2,
      children: [
        { // PISU
          "@type": "card",
          key: "356ca21b-ed3f-45f5-b8dd-88b91a329595",
          title: "PISU / GASSPPE",
          gridColumn: "1/2",
          children: [
            {
              "@type": "grid",
              key: "0b927247-ae79-4206-a78d-ad4aee65e189",
              columns: 2,
              className: "cardGrid",
              children: [
                ...syoTemplate.pisu,
                ...syoTemplate.gassppe,
              ]
            },
          ],
        },
        { // RCP
          "@type": "card",
          key: "9ffc2919-52b4-449d-b20a-6cc29ec3b92e",
          title: "RCP",
          gridColumn: "2/3",
          children: [
            {
              "@type": "grid",
              key: "7d51d39b-f850-48bb-a5c9-43febd955bad",
              columns: 2,
              className: "cardGrid",
              children: [
                ...syoTemplate.rcp,
              ],
            },
          ],
        },
      ],
    },
    {
      "@type": "grid",
      key: "7749f5bb-2bd6-462f-bc2f-fb149e34b1c6",
      columns: 2,
      children: [
        {
          "@type": "card",
          key: "76678d4d-a00b-4f40-907f-8b03e5e5599a",
          gridColumn: "1/2",
          className: "cardCntnr",
          children: [
            { // FAST
              "@type": "card",
              key: "ce17c84f-8fde-4aee-8919-c26fd6d3f9ab",
              title: "FAST",
              children: [
                ...syoTemplate.neurologiqueFast,
                {
                  "@type": "permission",
                  key: "40ffd940-e466-42e9-b344-ad90745e4cd1",
                  permission: ['web:bilan:extract-fast-out-of-neuro'],
                  children: [
                    ...syoTemplate.nihssParalysieFacialeFast,
                    ...syoTemplate.nihssMotriciteMembreFast,
                    ...syoTemplate.neuroHeureAppDeficitFast,
                    ...syoTemplate.neuroTroubleParoleFast,
                  ],
                },
              ],
            },
            { // Témoin
              "@type": "card",
              key: "cf5c8531-1940-4771-8360-21a2b8f5f310",
              title: "Témoin",
              children: [
                {
                  "@type": "grid",
                  key: "4fe5d29e-d53a-4f34-8921-d3e62b7b45f5",
                  columns: 2,
                  className: "cardGrid",
                  children: [
                    ...syoTemplate.gesteTemoin,
                  ],
                },
              ],
            },
            { // Gestes effectués
              "@type": "card",
              key: "12c4a24a-a5fd-4acc-83f0-93c59507a96b",
              title: "Gestes effectués",
              children: [
                {
                  "@type": "grid",
                  key: "0409dac8-26ab-4dd2-97ce-1302c733843d",
                  columns: 2,
                  className: "cardGrid",
                  children: [
                    ...syoTemplate.gesteA,
                    ...syoTemplate.gesteB,
                    ...syoTemplate.gesteC,
                    ...syoTemplate.gesteD,
                    ...syoTemplate.gesteE,
                    ...syoTemplate.gesteSmur,
                    ...syoTemplate.gesteInsufflation,
                    ...syoTemplate.gesteGuedel,
                    ...syoTemplate.gesteMaintienTete,
                    ...syoTemplate.gestePosition,
                    ...syoTemplate.gesteRetournementCollier,
                    ...syoTemplate.gesteDegagementUrg,
                    ...syoTemplate.gesteRetraitCasque,
                    ...syoTemplate.gesteKitProtection,
                    ...syoTemplate.gestePansementCompressif,
                    ...syoTemplate.gesteLiberationOBVA,
                    ...syoTemplate.gesteSmur35,
                    ...syoTemplate.gesteAutres,
                    ...syoTemplate.gesteTechnique,
                  ],
                },
              ],
            },
            { // Prélèvement d'un bilan sanguin
              "@type": "card",
              key: "1f5ea591-974c-4a1e-874e-bdce44f8c13c",
              title: "Prélèvement d'un bilan sanguin",
              children: [
                ...syoTemplate.gesteBilanSanguin,
              ],
            },
            { // Devenir
              "@type": "card",
              key: "2f4f5c67-9e78-4e61-be47-d204d744b0a0",
              title: "Devenir",
              children: [
                {
                  "@type": "grid",
                  key: "261c08c2-fc48-4b36-96cc-0d74d60f99ba",
                  columns: 2,
                  className: "cardGrid",
                  children: [
                    ...syoTemplate.devenir,
                  ],
                },
              ],
            },
          ],
        },
        {
          "@type": "card",
          key: "e8abf407-8672-40be-b625-f43617d8d7f2",
          gridColumn: "2/3",
          className: "cardCntnr",
          children: [
            { // Lésionnel
              "@type": "card",
              key: "f9eef3dc-a378-468b-a276-11004df0f719",
              title: "Lésionnel",
              children: [
                ...syoTemplate.lesionnel,
                ...syoTemplate.lesionnelProtocol,
                ...syoTemplate.expositionLesionnel,
              ],
            },
          ],
        },
      ]
    },
    {
      "@type": "grid",
      key: "28d95a57-97c2-4882-ad60-87020c854b6d",
      columns: 1,
      children: [
        { // Surveillances
          "@type": "card",
          key: "53b9bf7c-71ae-44bd-938c-af67de8226be",
          title: "Surveillances",
          children: [
            ...syoTemplate.surveillances,
          ],
        },
      ],
    },
    // {
    //   "@type": "grid",
    //   key: "b75fbd6f-4c6e-4080-824d-2725b2a566bf",
    //   columns: 1,
    //   permission: ["web:bilan:multiparam"],
    //   children: [
    //     { // Multiparamétrique
    //       "@type": "card",
    //       key: "e34a0cd2-5175-4a15-8f9c-dbf058362e68",
    //       title: "Multiparamétrique",
    //       children: [
    //         ...syoTemplate.multiparam,
    //       ],
    //     },
    //   ],
    // },
  ];
};