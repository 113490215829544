import React, { useState } from "react";

export const useLoaderBar = () => {
  const [ loader, toggleLoader ] = useState(false);

  return [ loader, toggleLoader ];
};

const LoaderBarContext = React.createContext({
  loaderBarState: null,
  setLoaderBar: () => {},
});

export default LoaderBarContext;