import { data as syoData, utils as syoUtils, template as syoTemplate } from "@nfsave/syo-bilan";
import { ValueLabel } from "../utils";

const glycemieInitial = ({label = "Glycémie", optionnal = true}) => {
  return {
    label,
    valuePath: (bilan) => {
      switch (bilan.medic_info.glycemie) {
        case -3:
        case "-3":
        case "-3.0":
          return "HI";
        case -2:
        case "-2":
        case "-2.0":
          return "LO";
        default:
          return bilan.medic_info.glycemie;
      }
    },
    unitClip: (bilan) => {
      switch (bilan.medic_info.glycemie_unite) {
        case 1:
          return " mmol/L";
        case 2:
          return " mg/dL";
        default:
          return " g/L";
      }
    },
    optionnal,
  };
};

const inconscienceInitial = ({label = "Inconscience", optionnal = true}) => {
  return {
    label,
    kind: "negative",
    "@type": "multiselect",
    valuePath: (bilan) => {
      let empty = [null, "null", undefined, "u", 0, "0", -1, "-1", 1, "1", "-1.0"];
      if (empty.includes(bilan.neurologique.smur_conscience)) {
        if (bilan.neurologique.inconscience === "y") bilan.neurologique.smur_conscience = 128;
        if (bilan.neurologique.inconscience === "n") bilan.neurologique.smur_conscience = 2;
        if (bilan.neurologique.inconscience === "u") bilan.neurologique.smur_conscience = 1;
      };
      let labels = [
        [1, "Conscient"],
        [2, "Agité"],
        [3, "Confus"],
        [4, "Désorienté"],
        [5, "Somnolent"],
        [6, "Obnubilé"],
        [7, "Inconscient"],
      ];
      let value = bilan.neurologique.smur_conscience;
      return labels
        .filter(([bit]) => (((value | 0) >> bit) & 1) === 1)
        .map(([, label]) => label)
        .join(",");
    },
    optionnal,
  };
};

const pupillesInitial = ({label = "Pupilles", optionnal = true}) => {
  return {
    label,
    valuePath: (bilan) => {
      if (bilan.medic_info.pupilles_bilateral) {
        const labelsBilateral = [
          [0, "RAS"],
          [1, "Non réactivité G"],
          [2, "Non réactivité D"],
          [3, "Myosis (serrée) G"],
          [4, "Myosis (serrée) D"],
          [5, "Intermédiaire G"],
          [6, "Intermédiaire D"],
          [7, "Mydriase (dilatée) G"],
          [8, "Mydriase (dilatée) D"],
        ];
        let valueBilateral = bilan.medic_info.pupilles_bilateral;
        return labelsBilateral
          .filter(([bit]) => (((valueBilateral | 0) >> bit) & 1) === 1)
          .map(([, label]) => label)
          .join(",");
      };
      let labels = [
        [0, "NR"],
        [1, "Anisocorie"],
        [2, "Réactives"],
        [3, "Aréactives"],
        [4, "Mydriase bilatérale"],
        [5, "Pas de RPM"],
        [6, "Myosis"],
        [7, "Symétriques"],
      ];
      let value = bilan.medic_info.pupilles;
      return labels
        .filter(([bit]) => (((value | 0) >> bit) & 1) === 1)
        .map(([, label]) => label)
        .join(",");
    },
    optionnal,
  };
};

const glasgowInitial = ({label = "GCS", optionnal = true}) => {
  return {
    label,
    valuePath: (bilan) => {
      let ya = parseInt(bilan.medic_info.gla_yeux, 10) || 0;
      let vb = parseInt(bilan.medic_info.gla_parole, 10) || 0;
      let mc = parseInt(bilan.medic_info.gla_motricite, 10) || 0;
      let total = ya === 0 || vb === 0 || mc === 0 ? "--" : ya + vb + mc;

      if (total === "--" && ya === 0 & vb === 0 && mc === 0) return null;

      return `${total}/15 (Y=${ya === 0 ? "--" : ya} V=${vb === 0 ? "--" : vb} M=${mc === 0 ? "--" : mc})`;
    },
    optionnal,
  };
};

const glasgowSurveillance = ({label = "GCS", optionnal = true}) => {
  return {
    label,
    valuePath: (surveillance) => {
      const hasGlasgow = (val) => (!Number.isNaN(val) || !val === -1);

      let y = parseInt((surveillance.glasgow_yeux || 0), 10);
      let v = parseInt((surveillance.glasgow_verbal || 0), 10);
      let m = parseInt((surveillance.glasgow_motricite || 0), 10);

      if (hasGlasgow(y) && hasGlasgow(v) && hasGlasgow(m)) {
        y = parseInt((surveillance.gla_yeux || 0), 10);
        v = parseInt((surveillance.gla_parole || 0), 10);
        m = parseInt((surveillance.gla_motricite || 0), 10);
      };

      if (Number.isNaN(y) || y === -1) y = 0;
      if (Number.isNaN(v) || v === -1) v = 0;
      if (Number.isNaN(m) || m === -1) m = 0;

      let total = y === 0 || v === 0 || m === 0 ? "--" : y + v + m;

      if (total === "--" && y === 0 & v === 0 && m === 0) return null;

      return `${total}/15 (Y=${y === 0 ? "--" : y} V=${v === 0 ? "--" : v} M=${m === 0 ? "--" : m})`;
    },
    optionnal,
  };
};

const pamInitial = ({label = "PAM", optionnal = true}) => {
  return {
    label,
    valuePath: (bilan) => {
      const pad = bilan.circulatoire.tension_droite;
      const pag = bilan.circulatoire.tension_gauche;
      if (syoUtils.computePam(pad) || syoUtils.computePam(pag)) {
        return `${syoUtils.computePam(pag) && `(${syoUtils.computePam(pag)})D`} ${syoUtils.computePam(pag) && `(${syoUtils.computePam(pag)})G`}`
      } else {
        return null
      };
    },
    optionnal,
  };
};

const pamSurveillance = ({label = "PAM", optionnal = true}) => {
  return {
    label,
    valuePath: (surveillance) => {
      const pamd = syoUtils.computePam(surveillance.pabd) || null;
      const pamg = syoUtils.computePam(surveillance.pabg) || null;
      if (pamd || pamg) return `${pamd ? `(${pamd})D` : ""}${pamg ? ` (${pamg})G` : ""}`;
      return null;
    },
    optionnal
  };
};

const trcSurveillance = ({label = "TRC > 3sec", optionnal = true}) => {
  return {
    label,
    valuePath: (surveillance) => {
      const temp = surveillance.trc;
      if (temp === 'y') return 'Oui';
      if (temp === 'n') return 'Non';
      return null;
    },
    optionnal
  };
};

const paleursSurveillance = ({label = "Pâleurs", optionnal = true}) => {
  return {
    label,
    valuePath: (surv) => {
      return syoData.PALEURS_OPTS.find(opt => opt.value === surv.paleur)?.label || null
    },
    optionnal
  };
};

const apgarSurv = [
  {
    label: "FC(APGAR)",
    valuePath: (surv) => {
      return syoData.APGAR_FC_OPTS.find(opt => opt.value === surv.apgar?.fc)?.label || null
    },
    optionnal: true,
  },
  {
    label: "FR(APGAR)",
    valuePath: (surv) => {
      return syoData.APGAR_FR_OPTS.find(opt => opt.value === surv.apgar?.fr)?.label || null
    },
    optionnal: true,
  },
  {
    label: "Tonus(APGAR)",
    valuePath: (surv) => {
      return syoData.APGAR_TONUS_OPTS.find(opt => opt.value === surv.apgar?.tonus)?.label || null
    },
    optionnal: true,
  },
  {
    label: "Réactivité(APGAR)",
    valuePath: (surv) => {
      return syoData.APGAR_REACTIVITE_OPTS.find(opt => opt.value === surv.apgar?.reactivite)?.label || null
    },
    optionnal: true,
  },
  {
    label: "Coloration(APGAR)",
    valuePath: (surv) => {
      return syoData.APGAR_COLORATION_OPTS.find(opt => opt.value === surv.apgar?.coloration)?.label || null
    },
    optionnal: true,
  }
];

const medicDosageUnit = ({label = "Dosage", optionnal = true}) => {
  return {
    label,
    valuePath: (surv) => {
      const getUnit = () => {
        if (surv.unit === undefined) {
          return "mL";
        } else {
          return ValueLabel({value: surv.unit, labels: [...syoData.MEDIC_UNITS, [0, "--"]]})
        };
      };
      if (!surv.quantity && surv.unit === undefined) return null;
      return `${surv.quantity ? surv.quantity : "--"} ${getUnit()}`;
    },
    optionnal,
  };
};

// Ne renvois rien si unit est à 0
const medicUnit = ({label = "Unité", optionnal = true}) => {
  return {
    label,
    valuePath: (surv) => {
      const getUnit = () => {
        if (surv.unit === undefined) {
          return "mL";
        } else {
          return ValueLabel({value: surv.unit, labels: [...syoData.MEDIC_UNITS, [0, "--"]]})
        };
      };
      if (!surv.quantity || (optionnal && surv.unit === 0)) return null;
      return `${getUnit()}`;
    },
    optionnal,
  };
};

const medicRoute = ({label = "Voie", optionnal = true}) => {
  return {
    label,
    valuePath: (surv) => {
      const getRoute = () => {
        if (surv.route_of_administration === null) {
          return "NR";
        } else {
          return ValueLabel({
            value: surv.route_of_administration,
            labels: [...syoData.ROUTE_OF_ADMINISTRATION],
          });
        };
      };
      return getRoute();
    },
    optionnal,
  };
};

export const CLIP_TEXT_TEMPLATE = `{{#infos}}
{{{label}}} : {{{value}}}{{{unit}}}
{{/infos}}

{{#red_dangers}}
{{{.}}}
{{/red_dangers}}
{{#green_dangers}}
{{{.}}}
{{/green_dangers}}

{{#red_safeties}}
{{{.}}}
{{/red_safeties}}
{{#green_safeties}}
{{{.}}}
{{/green_safeties}}

{{#dummy_frontIsNotEmpty}}
{{{dummy_front}}}{{/dummy_frontIsNotEmpty}}{{#dummy_backIsNotEmpty}}
{{{dummy_back}}}{{/dummy_backIsNotEmpty}}

{{#monitoring_with_medic}}SURVEILLANCES :
{{#assessments_with_medic}}  {{timestamp}}{{#vitals_with_medic}}  {{{label}}}={{{value}}}{{/vitals_with_medic}}
{{/assessments_with_medic}}{{/monitoring_with_medic}}
`;

export const CLIP_TEXT_TEMPLATE_COVID19 = `{{#infos}}
{{{label}}} : {{{value}}}{{{unit}}}
{{/infos}}

{{#mhta_v2.contentIsNotEmpty}}
{{#mhta_v2.content}}
  {{{.}}}
{{/mhta_v2.content}}
{{/mhta_v2.contentIsNotEmpty}}

{{#dummy_frontIsNotEmpty}}
{{{dummy_front}}}{{/dummy_frontIsNotEmpty}}{{#dummy_backIsNotEmpty}}
{{{dummy_back}}}{{/dummy_backIsNotEmpty}}

{{#monitoring_with_medic}}SURVEILLANCES :
{{#assessments_with_medic}}  {{timestamp}}{{#vitals_with_medic}}  {{{label}}}={{{value}}}{{/vitals_with_medic}}
{{/assessments_with_medic}}{{/monitoring_with_medic}}

{{#red_dangers}}
{{{.}}}
{{/red_dangers}}
{{#green_dangers}}
{{{.}}}
{{/green_dangers}}

{{#red_safeties}}
{{{.}}}
{{/red_safeties}}
{{#green_safeties}}
{{{.}}}
{{/green_safeties}}
{{#contact_v2.contentIsNotEmpty}}
CONTACT :
{{#contact_v2.content}}
  {{{.}}}
{{/contact_v2.content}}
{{/contact_v2.contentIsNotEmpty}}
`;
// Attention: légère différence entre le SAMU et le SDIS au 38
export const CLIP_TEXT_TEMPLATE_SAMU_38 = `Bilan VSAV TABLETTE SDIS

{{{identite_v2.age}}},{{{identite_v2.sex}}}
{{{location}}}
{{#identite_v2.naissance}}
{{{identite_v2.naissance}}}
{{/identite_v2.naissance}}

Bilan : {{{priority}}}
Catégorisation : {{equipeInfos.categorisation}}
Numéro primo-intervenant : {{primo}}
{{#ticketNumMotif_v2.contentFull}}
{{{.}}}
{{/ticketNumMotif_v2.contentFull}}
{{#circonstanciel_v2.contentFull}}
{{{.}}}
{{/circonstanciel_v2.contentFull}}{{#situation_v2.contentIsNotEmpty}}

SITUATION :
{{#situation_v2.content}}
{{{.}}}
{{/situation_v2.content}}
{{/situation_v2.contentIsNotEmpty}}

A : OBVA {{{aAbcde_38.obva}}}
{{#aAbcde_38.contentDanger}}
{{{.}}}
{{/aAbcde_38.contentDanger}}
B :
{{#bAbcde_v2.contentDanger}}
{{{.}}}
{{/bAbcde_v2.contentDanger}}
C : Hémorragie {{{cAbcde_38.hemorragie}}}
{{#cAbcde_38.contentDanger}}
{{{.}}}
{{/cAbcde_38.contentDanger}}
D : PCI {{{dAbcde_v2.pci_38}}}
{{#dAbcde_v2_sans_fast.contentDanger}}
{{{.}}}
{{/dAbcde_v2_sans_fast.contentDanger}}
E :
{{#eAbcde_v2.contentDanger}}
{{{.}}}
{{/eAbcde_v2.contentDanger}}
FAST :
{{#fast_v2.contentDanger}}
{{{.}}}
{{/fast_v2.contentDanger}}{{#dummy_frontIsNotEmpty}}

{{{dummy_front}}}{{/dummy_frontIsNotEmpty}}{{#dummy_backIsNotEmpty}}
{{{dummy_back}}}{{/dummy_backIsNotEmpty}}{{#accouchement_v2.contentIsNotEmpty}}

ACCOUCHEMENT :
{{#accouchement_v2.content}}
{{{.}}}
{{/accouchement_v2.content}}
{{/accouchement_v2.contentIsNotEmpty}}{{#apgar_v2.contentIsNotEmpty}}

APGAR :
{{#apgar_v2.content}}
{{{.}}}
{{/apgar_v2.content}}
{{/apgar_v2.contentIsNotEmpty}}{{#secoursRoutier_v2.contentIsNotEmpty}}

ACCIDENT / SECOURS ROUTIER :
{{#secoursRoutier_v2.content}}
{{{.}}}
{{/secoursRoutier_v2.content}}
{{/secoursRoutier_v2.contentIsNotEmpty}}{{#initial_vitalsIsNotEmpty}}

Surveillance Initiales :
{{#initial_vitals}}  {{{label}}}={{{value}}}
{{/initial_vitals}}
{{/initial_vitalsIsNotEmpty}}{{#monitoring}}

Surveillances :
{{#assessments}}  {{timestamp}}
{{#vitals}}
    {{{label}}}={{{value}}}
{{/vitals}}
{{/assessments}}
{{/monitoring}}{{#dyn_mhta_sampler_38.contentIsNotEmpty}}

MHTA/SAMPLER :
{{#dyn_mhta_sampler_38.content}}
{{{.}}}
{{/dyn_mhta_sampler_38.content}}
{{/dyn_mhta_sampler_38.contentIsNotEmpty}}{{#opqrst_v2.contentIsNotEmpty}}

OPQRST :
{{#opqrst_v2.content}}
{{{.}}}
{{/opqrst_v2.content}}
{{/opqrst_v2.contentIsNotEmpty}}{{#gestes_v2.contentAllIsNotEmpty}}

Gestes :
{{#gestes_v2.contentAll}}
{{{.}}}
{{/gestes_v2.contentAll}}
{{/gestes_v2.contentAllIsNotEmpty}}
`;
// Attention: légère différence entre le SAMU et le SDIS au 38
export const CLIP_TEXT_TEMPLATE_SDIS_38 = `Bilan VSAV TABLETTE SDIS

{{{identite_v2.age}}},{{{identite_v2.sex}}}
{{{location}}}
{{#identite_v2.naissance}}
{{{identite_v2.naissance}}}
{{/identite_v2.naissance}}

Bilan : {{{priority}}}
Catégorisation : {{equipeInfos.categorisation}}
Numéro primo-intervenant : {{primo}}
{{#ticketNumMotif_v2.contentFull}}
{{{.}}}
{{/ticketNumMotif_v2.contentFull}}
{{#circonstanciel_v2.contentFull}}
{{{.}}}
{{/circonstanciel_v2.contentFull}}

A : OBVA {{{aAbcde_38.obva}}}
{{#aAbcde_38.contentDanger}}
{{{.}}}
{{/aAbcde_38.contentDanger}}
B :
{{#bAbcde_v2.contentDanger}}
{{{.}}}
{{/bAbcde_v2.contentDanger}}
C : Hémorragie {{{cAbcde_38.hemorragie}}}
{{#cAbcde_38.contentDanger}}
{{{.}}}
{{/cAbcde_38.contentDanger}}
D : PCI {{{dAbcde_v2.pci_38}}}
{{#dAbcde_v2.contentDanger}}
{{{.}}}
{{/dAbcde_v2.contentDanger}}
E :
{{#eAbcde_v2.contentDanger}}
{{{.}}}
{{/eAbcde_v2.contentDanger}}{{#dummy_frontIsNotEmpty}}

{{{dummy_front}}}{{/dummy_frontIsNotEmpty}}{{#dummy_backIsNotEmpty}}
{{{dummy_back}}}{{/dummy_backIsNotEmpty}}{{#accouchement_v2.contentIsNotEmpty}}

ACCOUCHEMENT :
{{#accouchement_v2.content}}
{{{.}}}
{{/accouchement_v2.content}}
{{/accouchement_v2.contentIsNotEmpty}}{{#apgar_v2.contentIsNotEmpty}}

APGAR :
{{#apgar_v2.content}}
{{{.}}}
{{/apgar_v2.content}}
{{/apgar_v2.contentIsNotEmpty}}{{#secoursRoutier_v2.contentIsNotEmpty}}

ACCIDENT / SECOURS ROUTIER :
{{#secoursRoutier_v2.content}}
{{{.}}}
{{/secoursRoutier_v2.content}}
{{/secoursRoutier_v2.contentIsNotEmpty}}{{#initial_vitalsIsNotEmpty}}

Surveillance Initiales :
{{#initial_vitals}}  {{{label}}}={{{value}}}
{{/initial_vitals}}
{{/initial_vitalsIsNotEmpty}}{{#monitoring}}

Surveillances :
{{#assessments}}  {{timestamp}}
{{#vitals}}
    {{{label}}}={{{value}}}
{{/vitals}}
{{/assessments}}
{{/monitoring}}{{#dyn_mhta_sampler_38.contentIsNotEmpty}}

MHTA/SAMPLER :
{{#dyn_mhta_sampler_38.content}}
{{{.}}}
{{/dyn_mhta_sampler_38.content}}
{{/dyn_mhta_sampler_38.contentIsNotEmpty}}{{#opqrst_v2.contentIsNotEmpty}}

OPQRST :
{{#opqrst_v2.content}}
{{{.}}}
{{/opqrst_v2.content}}
{{/opqrst_v2.contentIsNotEmpty}}{{#gestes_v2.contentAllIsNotEmpty}}

Gestes :
{{#gestes_v2.contentAll}}
{{{.}}}
{{/gestes_v2.contentAll}}
{{/gestes_v2.contentAllIsNotEmpty}}
`;

export const CLIP_TEXT_TEMPLATE_SAMU_72 = `Bilan VSAV TABLETTE SDIS

{{{identite_v2.fullName}}},{{{identite_v2.age}}}{{#identite_v2.naissance}} {{{identite_v2.naissance}}}{{/identite_v2.naissance}},{{{identite_v2.sex}}}
Lieu d'intervention : {{{circonstanciel_v2.circumstantial_lieu}}}
{{{location}}}

Motif d'intervention : {{{circonstanciel_v2.circumstantial_reason}}}
Circonstances : {{{circonstanciel_v2.circumstantial_comment}}}

A :
{{#aAbcde_v2.contentDanger}}
{{{.}}}
{{/aAbcde_v2.contentDanger}}
{{#aAbcde_v2.gestures}}
{{{.}}}
{{/aAbcde_v2.gestures}}
{{^aAbcde_v2.contentDangerGesturesIsNotEmpty}}
NÉANT
{{/aAbcde_v2.contentDangerGesturesIsNotEmpty}}
B :
{{#bAbcde_v2.contentDanger}}
{{{.}}}
{{/bAbcde_v2.contentDanger}}
{{#bAbcde_v2.gestures}}
{{{.}}}
{{/bAbcde_v2.gestures}}
{{^bAbcde_v2.contentDangerGesturesIsNotEmpty}}
NÉANT
{{/bAbcde_v2.contentDangerGesturesIsNotEmpty}}
C :
{{#cAbcde_v2.contentDanger}}
{{{.}}}
{{/cAbcde_v2.contentDanger}}
{{#cAbcde_v2.gestures}}
{{{.}}}
{{/cAbcde_v2.gestures}}
{{^cAbcde_v2.contentDangerGesturesIsNotEmpty}}
NÉANT
{{/cAbcde_v2.contentDangerGesturesIsNotEmpty}}
D :
{{#dAbcde_v2.contentDanger}}
{{{.}}}
{{/dAbcde_v2.contentDanger}}
{{#dAbcde_v2.gestures}}
{{{.}}}
{{/dAbcde_v2.gestures}}
{{^dAbcde_v2.contentDangerGesturesIsNotEmpty}}
NÉANT
{{/dAbcde_v2.contentDangerGesturesIsNotEmpty}}
E :
{{#eAbcde_v2.contentDanger}}
{{{.}}}
{{/eAbcde_v2.contentDanger}}
{{#eAbcde_v2.gestures}}
{{{.}}}
{{/eAbcde_v2.gestures}}
{{^eAbcde_v2.contentDangerGesturesIsNotEmpty}}
NÉANT
{{/eAbcde_v2.contentDangerGesturesIsNotEmpty}}

{{#initial_vitals_72}}{{{.}}}
{{/initial_vitals_72}}

GESTES ÉFFECTUÉS :
{{#gestes_v2.contentAll}}
{{{.}}}
{{/gestes_v2.contentAll}}
{{^gestes_v2.contentAll}}
NÉANT
{{/gestes_v2.contentAll}}
{{#dummy_frontIsNotEmpty}}
{{{dummy_front}}}{{/dummy_frontIsNotEmpty}}{{#dummy_backIsNotEmpty}}
{{{dummy_back}}}{{/dummy_backIsNotEmpty}}
{{#monitoring}}
SURVEILLANCE :
{{#assessments}}  {{timestamp}}{{#vitals_72}}  {{{label}}}={{{value}}}{{/vitals_72}}
{{/assessments}}
{{/monitoring}}
`;

export const CLIP_TEXT_TEMPLATE_SAMU_56 = `BILAN ASSU INITIAL :

CIRCONSTANCES :
{{#ticketNumMotif_v2.contentIsNotEmpty}}
{{#ticketNumMotif_v2.content}}
{{{.}}}
{{/ticketNumMotif_v2.content}}
{{/ticketNumMotif_v2.contentIsNotEmpty}}
{{#circonstanciel_v2.contentIsNotEmpty}}
{{#circonstanciel_v2.content}}
{{{.}}}
{{/circonstanciel_v2.content}}
{{/circonstanciel_v2.contentIsNotEmpty}}
{{#anamnese_v2.contentIsNotEmpty}}
{{#anamnese_v2.content}}
{{{.}}}
{{/anamnese_v2.content}}
{{/anamnese_v2.contentIsNotEmpty}}

PARAMÈTRES VITAUX :{{#initial_vitals_56}}
{{{label}}} : {{{value}}}{{/initial_vitals_56}}

ANOMALIES :
{{#aAbcde_v2.contentDangerIsNotEmpty}}A :
{{{aAbcde_v2.contentDangerInline}}}
{{/aAbcde_v2.contentDangerIsNotEmpty}}
{{#bAbcde_v2.contentDangerIsNotEmpty}}B :
{{{bAbcde_v2.contentDangerInline}}}
{{/bAbcde_v2.contentDangerIsNotEmpty}}
{{#cAbcde_v2.contentDangerIsNotEmpty}}C :
{{{cAbcde_v2.contentDangerInline}}}
{{/cAbcde_v2.contentDangerIsNotEmpty}}
{{#dAbcde_v2.contentDangerIsNotEmpty}}D :
{{{dAbcde_v2.contentDangerInline}}}
{{/dAbcde_v2.contentDangerIsNotEmpty}}
{{#eAbcde_v2.contentDangerIsNotEmpty}}E :
{{{eAbcde_v2.contentDangerInline}}}
{{/eAbcde_v2.contentDangerIsNotEmpty}}{{#dummy_frontIsNotEmpty}}
{{{dummy_front}}}{{/dummy_frontIsNotEmpty}}{{#dummy_backIsNotEmpty}}
{{{dummy_back}}}{{/dummy_backIsNotEmpty}}

MHTA :
{{#mhta_v2.content}}
{{{.}}}
{{/mhta_v2.content}}

{{#monitoring}}
BILAN ASSU ÉVOLUTIF :
{{#assessments}}
{{timestamp}}{{#vitals_56}}  {{{label}}}:{{{value}}}{{/vitals_56}}
{{/assessments}}
{{/monitoring}}`;

export const CLIP_TEXT_TEMPLATE_SAMU_50 = `Bilan VSAV TABLETTE AMBULANCIERS

{{{identite_v2.fullName}}},{{{identite_v2.age}}}{{#identite_v2.naissance}} {{{identite_v2.naissance}}}{{/identite_v2.naissance}},{{{identite_v2.sex}}}
Lieu d'intervention : {{{circonstanciel_v2.circumstantial_lieu}}}
{{{location}}}

Motif d'intervention : {{{circonstanciel_v2.circumstantial_reason}}}
Circonstances : {{{circonstanciel_v2.circumstantial_comment}}}

A :
{{#aAbcde_v2.contentDanger}}
{{{.}}}
{{/aAbcde_v2.contentDanger}}
{{#aAbcde_v2.gestures}}
{{{.}}}
{{/aAbcde_v2.gestures}}
{{^aAbcde_v2.contentDangerGesturesIsNotEmpty}}
NÉANT
{{/aAbcde_v2.contentDangerGesturesIsNotEmpty}}
B :
{{#bAbcde_v2.contentDanger}}
{{{.}}}
{{/bAbcde_v2.contentDanger}}
{{#bAbcde_v2.gestures}}
{{{.}}}
{{/bAbcde_v2.gestures}}
{{^bAbcde_v2.contentDangerGesturesIsNotEmpty}}
NÉANT
{{/bAbcde_v2.contentDangerGesturesIsNotEmpty}}
C :
{{#cAbcde_v2.contentDanger}}
{{{.}}}
{{/cAbcde_v2.contentDanger}}
{{#cAbcde_v2.gestures}}
{{{.}}}
{{/cAbcde_v2.gestures}}
{{^cAbcde_v2.contentDangerGesturesIsNotEmpty}}
NÉANT
{{/cAbcde_v2.contentDangerGesturesIsNotEmpty}}
D :
{{#dAbcde_v2.contentDanger}}
{{{.}}}
{{/dAbcde_v2.contentDanger}}
{{#dAbcde_v2.gestures}}
{{{.}}}
{{/dAbcde_v2.gestures}}
{{^dAbcde_v2.contentDangerGesturesIsNotEmpty}}
NÉANT
{{/dAbcde_v2.contentDangerGesturesIsNotEmpty}}
E :
{{#eAbcde_v2.contentDanger}}
{{{.}}}
{{/eAbcde_v2.contentDanger}}
{{#eAbcde_v2.gestures}}
{{{.}}}
{{/eAbcde_v2.gestures}}
{{^eAbcde_v2.contentDangerGesturesIsNotEmpty}}
NÉANT
{{/eAbcde_v2.contentDangerGesturesIsNotEmpty}}

{{#initial_vitals_72}}{{{.}}}
{{/initial_vitals_72}}

GESTES ÉFFECTUÉS :
{{#gestes_v2.contentAll}}
{{{.}}}
{{/gestes_v2.contentAll}}
{{^gestes_v2.contentAll}}
NÉANT
{{/gestes_v2.contentAll}}
{{#dummy_frontIsNotEmpty}}
{{{dummy_front}}}{{/dummy_frontIsNotEmpty}}{{#dummy_backIsNotEmpty}}
{{{dummy_back}}}{{/dummy_backIsNotEmpty}}
{{#monitoring}}
SURVEILLANCE :
{{#assessments}}  {{timestamp}}{{#vitals_72}}  {{{label}}}={{{value}}}{{/vitals_72}}
{{/assessments}}
{{/monitoring}}
`;

export const CLIP_TEXT_TEMPLATE_SAMU_44 = `{{#vital_v2.contentDangerIsNotEmpty}}VITAL :
{{#vital_v2.contentDanger}}
  {{{.}}}
{{/vital_v2.contentDanger}}
{{/vital_v2.contentDangerIsNotEmpty}}
{{^vital_v2.contentDangerIsNotEmpty}}
VITAL : RAS
{{/vital_v2.contentDangerIsNotEmpty}}

CIRCONSTANCIEL :
{{{circonstanciel_v2.circumstantial_reason}}}
{{{circonstanciel_v2.circumstantial_comment}}}

{{#anamnese_v2.contentIsNotEmpty}}
ANAMNESE :
{{#anamnese_v2.contentRaw}}{{{value}}}{{/anamnese_v2.contentRaw}}

{{/anamnese_v2.contentIsNotEmpty}}
{{#examen_v2.contentIsNotEmpty}}
EXAMEN :
{{#examen_v2.contentRaw}}{{{value}}}{{/examen_v2.contentRaw}}

{{/examen_v2.contentIsNotEmpty}}
{{#hypotheseDiag_v2.contentIsNotEmpty}}
HYPOTHESE DIAGNOSTIQUE :
{{#hypotheseDiag_v2.contentRaw}}{{{value}}}{{/hypotheseDiag_v2.contentRaw}}

{{/hypotheseDiag_v2.contentIsNotEmpty}}
NEUROLOGIQUE/RESPIRATOIRE/CIRCULATOIRE :
{{#neurologique_v2.contentDangerIsNotEmpty}}{{#neurologique_v2.contentDanger}}
  {{{.}}}
{{/neurologique_v2.contentDanger}}
{{/neurologique_v2.contentDangerIsNotEmpty}}
{{#circulatoire_v2.contentDangerIsNotEmpty}}{{#circulatoire_v2.contentDanger}}
  {{{.}}}
{{/circulatoire_v2.contentDanger}}
{{/circulatoire_v2.contentDangerIsNotEmpty}}
{{#respiration_v2.contentDangerIsNotEmpty}}{{#respiration_v2.contentDanger}}
  {{{.}}}
{{/respiration_v2.contentDanger}}
{{/respiration_v2.contentDangerIsNotEmpty}}
{{#obstetrique_v2.contentIsNotEmpty}}

OBSTETRIQUE :
{{#obstetrique_v2.content}}
  {{{.}}}
{{/obstetrique_v2.content}}

{{/obstetrique_v2.contentIsNotEmpty}}{{#dummy_frontIsNotEmpty}}
{{{dummy_front}}}{{/dummy_frontIsNotEmpty}}{{#dummy_backIsNotEmpty}}
{{{dummy_back}}}{{/dummy_backIsNotEmpty}}

PARAMÈTRES VITAUX INITIAUX :{{#initial_vitals}}  {{{label}}}={{{value}}}{{/initial_vitals}}

ANTÉCÉDENTS : {{{mhta_antecedents}}}
TRAITEMENTS : {{{mhta_treatments}}}
{{#monitoring}}

PARAMÈTRES ÉVOLUTIFS :
{{#assessments}}
{{timestamp}}{{#vitals}}  {{{label}}}={{{value}}}{{/vitals}}
{{/assessments}}
{{/monitoring}}
`;

export const CLIP_TEXT_TEMPLATE_SAMU_49 = `{{#identite_v2.fullName_isNotEmpty}}{{{identite_v2.fullName}}}{{/identite_v2.fullName_isNotEmpty}}{{#initial_vitals_49_block_isNotEmpty}}
PARAMETRES VITAUX{{#initial_vitals_49_IsNotEmpty}}
{{#initial_vitals_49}}{{{label}}}:{{{value}}} {{/initial_vitals_49}}{{/initial_vitals_49_IsNotEmpty}}{{#initial_en_temp_49_IsNotEmpty}}
{{#initial_en_temp_49}}{{{label}}}:{{{value}}} {{/initial_en_temp_49}}{{/initial_en_temp_49_IsNotEmpty}}{{#initial_vitals.materiel_debit_02_isNotEmpty}}
{{#initial_vitals.materiel_debit_02}}{{{.}}} {{/initial_vitals.materiel_debit_02}}{{/initial_vitals.materiel_debit_02_isNotEmpty}}{{/initial_vitals_49_block_isNotEmpty}}

{{#circonstanciel_v2.contentIsNotEmpty}}CIRCONSTANCIEL{{#circonstanciel_v2.content}}
  {{{.}}}{{/circonstanciel_v2.content}}

{{/circonstanciel_v2.contentIsNotEmpty}}
{{#vital_v2.contentDangerIsNotEmpty}}VITAL (S+A){{#vital_v2.contentDanger}}
  {{{.}}}{{/vital_v2.contentDanger}}

{{/vital_v2.contentDangerIsNotEmpty}}
{{#respiration_v2.contentDangerIsNotEmpty}}RESPIRATOIRE (B){{#respiration_v2.contentDanger}}
  {{{.}}}{{/respiration_v2.contentDanger}}

{{/respiration_v2.contentDangerIsNotEmpty}}
{{#circulatoire_v2.contentDangerIsNotEmpty}}CIRCULATOIRE (C){{#circulatoire_v2.contentDanger}}
  {{{.}}}{{/circulatoire_v2.contentDanger}}

{{/circulatoire_v2.contentDangerIsNotEmpty}}
{{#acr_v2.contentDangerIsNotEmpty}}Fiche ACR infirmier{{#acr_v2.contentDanger}}
  {{{.}}}{{/acr_v2.contentDanger}}

{{/acr_v2.contentDangerIsNotEmpty}}
{{#douleurThoracique_v2.contentDangerIsNotEmpty}}Fiche Douleur Thoracique infirmier{{#douleurThoracique_v2.contentDanger}}
  {{{.}}}{{/douleurThoracique_v2.contentDanger}}

{{/douleurThoracique_v2.contentDangerIsNotEmpty}}
{{#neurologique_v2.contentDangerIsNotEmpty}}NEUROLOGIQUE (D){{#neurologique_v2.contentDanger}}
  {{{.}}}{{/neurologique_v2.contentDanger}}

{{/neurologique_v2.contentDangerIsNotEmpty}}
{{#nihss_v2.contentDangerIsNotEmpty}}Score Neuro NIHSS{{#nihss_v2.contentDanger}}
  {{{.}}}{{/nihss_v2.contentDanger}}

{{/nihss_v2.contentDangerIsNotEmpty}}
{{#dummy_isNotEmpty}}LÉSIONNEL AV/AR (E){{#dummy_frontIsNotEmpty}}
  {{{dummy_front}}}

{{/dummy_frontIsNotEmpty}}
{{#dummy_backIsNotEmpty}}
  {{{dummy_back}}}

{{/dummy_backIsNotEmpty}}{{/dummy_isNotEmpty}}
{{#mhta_v2.contentIsNotEmpty}}
MHTA
{{#mhta_v2.content}}
  {{{.}}}
{{/mhta_v2.content}}

{{/mhta_v2.contentIsNotEmpty}}
{{#gestes_v2.contentAllIsNotEmpty}}GESTES / MÉDICAMENTS
{{#gestes_v2.contentAll}}
  {{{.}}}
{{/gestes_v2.contentAll}}
{{#medications}}{{#assessments}}
  {{timestamp}}{{#medic}}  {{{label}}}:{{{value}}}{{/medic}}
{{/assessments}}

{{/medications}}
{{/gestes_v2.contentAllIsNotEmpty}}
{{#monitoring}}
ÉVOLUTION / SURVEILLANCES
{{#assessments}}
  {{timestamp}}{{#vitals}}  {{{label}}}:{{{value}}}{{/vitals}}
{{/assessments}}

{{/monitoring}}
{{#obstetrique_v2.contentDangerIsNotEmpty}}OBSTÉTRIQUE
{{#obstetrique_v2.contentDanger}}
  {{{.}}}
{{/obstetrique_v2.contentDanger}}
{{/obstetrique_v2.contentDangerIsNotEmpty}}
`;

export const CLIP_TEXT_TEMPLATE_SAMU_73 = `{{#identite_v2.fullName_isNotEmpty}}{{{identite_v2.fullName}}}{{/identite_v2.fullName_isNotEmpty}}{{#initial_vitals_49_block_isNotEmpty}}
PARAMETRES VITAUX{{#initial_vitals_49_IsNotEmpty}}
{{#initial_vitals_49}}{{{label}}}:{{{value}}} {{/initial_vitals_49}}{{/initial_vitals_49_IsNotEmpty}}{{#initial_en_temp_49_IsNotEmpty}}
{{#initial_en_temp_49}}{{{label}}}:{{{value}}} {{/initial_en_temp_49}}{{/initial_en_temp_49_IsNotEmpty}}{{#initial_vitals.materiel_debit_02_isNotEmpty}}
{{#initial_vitals.materiel_debit_02}}{{{.}}} {{/initial_vitals.materiel_debit_02}}{{/initial_vitals.materiel_debit_02_isNotEmpty}}{{/initial_vitals_49_block_isNotEmpty}}

{{#circonstanciel_v2.contentIsNotEmpty}}CIRCONSTANCIEL{{#circonstanciel_v2.content}}
  {{{.}}}{{/circonstanciel_v2.content}}

{{/circonstanciel_v2.contentIsNotEmpty}}
{{#vital_v2.contentDangerIsNotEmpty}}VITAL (X+A){{#vital_v2.contentDanger}}
  {{{.}}}{{/vital_v2.contentDanger}}

{{/vital_v2.contentDangerIsNotEmpty}}
{{#respiration_v2.contentDangerIsNotEmpty}}RESPIRATOIRE (B){{#respiration_v2.contentDanger}}
  {{{.}}}{{/respiration_v2.contentDanger}}

{{/respiration_v2.contentDangerIsNotEmpty}}
{{#circulatoire_v2.contentDangerIsNotEmpty}}CIRCULATOIRE (C){{#circulatoire_v2.contentDanger}}
  {{{.}}}{{/circulatoire_v2.contentDanger}}

{{/circulatoire_v2.contentDangerIsNotEmpty}}
{{#neurologique_v2.contentDangerIsNotEmpty}}NEUROLOGIQUE (D){{#neurologique_v2.contentDanger}}
  {{{.}}}{{/neurologique_v2.contentDanger}}

{{/neurologique_v2.contentDangerIsNotEmpty}}
{{#dummy_isNotEmpty}}LÉSIONNEL AV/AR (E){{#dummy_frontIsNotEmpty}}
  {{{dummy_front}}}

{{/dummy_frontIsNotEmpty}}
{{#dummy_backIsNotEmpty}}
  {{{dummy_back}}}

{{/dummy_backIsNotEmpty}}{{/dummy_isNotEmpty}}
{{#mhta_treatments}}TRAITEMENTS
  {{{.}}}

{{/mhta_treatments}}
{{#gestes_v2.contentAllIsNotEmpty}}GESTES / MÉDICAMENTS
{{#gestes_v2.contentAll}}
  {{{.}}}
{{/gestes_v2.contentAll}}
{{#medications}}{{#assessments}}
  {{timestamp}}{{#medic}}  {{{label}}}:{{{value}}}{{/medic}}
{{/assessments}}

{{/medications}}
{{/gestes_v2.contentAllIsNotEmpty}}
{{#monitoring}}
ÉVOLUTION / SURVEILLANCES
{{#assessments}}
  {{timestamp}}{{#vitals}}  {{{label}}}:{{{value}}}{{/vitals}}
{{/assessments}}

{{/monitoring}}
`;

export const CLIP_TEXT_TEMPLATE_SAMU_02 = `{{{circonstanciel_v2.circumstantial_reason}}}
{{{circonstanciel_v2.circumstantial_comment}}}

{{#xAbcde_v2.contentDangerGesturesIsNotEmpty}}VITAL / X :
{{#xAbcde_v2.contentDanger}}
{{{.}}}
{{/xAbcde_v2.contentDanger}}
{{#xAbcde_v2.gesturesInline}}{{{.}}}{{/xAbcde_v2.gesturesInline}}

{{/xAbcde_v2.contentDangerGesturesIsNotEmpty}}
{{#sampler_v2.sampler_not_empty}}
ATCD :{{#sampler_v2.sampler_s_optional}}
Signes ou symptômes : {{{.}}}{{/sampler_v2.sampler_s_optional}}{{#sampler_v2.sampler_a_optional}}
Allergies : {{{.}}}{{/sampler_v2.sampler_a_optional}}{{#sampler_v2.sampler_m_optional}}
Traitements : {{{.}}}{{/sampler_v2.sampler_m_optional}}{{#sampler_v2.sampler_p_optional}}
Antécédents : {{{.}}}{{/sampler_v2.sampler_p_optional}}

{{/sampler_v2.sampler_not_empty}}
{{#aAbcde_v2.contentDangerGesturesIsNotEmpty}}A :
{{#aAbcde_v2.contentDanger}}
{{{.}}}
{{/aAbcde_v2.contentDanger}}
{{#aAbcde_v2.gesturesInline}}{{{.}}}{{/aAbcde_v2.gesturesInline}}

{{/aAbcde_v2.contentDangerGesturesIsNotEmpty}}
{{#bAbcde_v2.contentDangerGesturesIsNotEmpty}}B :
{{#bAbcde_v2.contentDanger}}
{{{.}}}
{{/bAbcde_v2.contentDanger}}
{{#bAbcde_v2.gesturesInline}}{{{.}}}{{/bAbcde_v2.gesturesInline}}

{{/bAbcde_v2.contentDangerGesturesIsNotEmpty}}
{{#cAbcde_v2.contentDangerGesturesIsNotEmpty}}C :
{{#cAbcde_v2.contentDanger}}
{{{.}}}
{{/cAbcde_v2.contentDanger}}
{{#cAbcde_v2.gesturesInline}}{{{.}}}{{/cAbcde_v2.gesturesInline}}

{{/cAbcde_v2.contentDangerGesturesIsNotEmpty}}
{{#dAbcde_v2.contentDangerGesturesIsNotEmpty}}D :
{{#dAbcde_v2.contentDanger}}
{{{.}}}
{{/dAbcde_v2.contentDanger}}
{{#dAbcde_v2.gesturesInline}}{{{.}}}{{/dAbcde_v2.gesturesInline}}

{{/dAbcde_v2.contentDangerGesturesIsNotEmpty}}
{{#eAbcde_v2.contentDangerGesturesIsNotEmpty}}E :
{{#eAbcde_v2.contentDanger}}
{{{.}}}
{{/eAbcde_v2.contentDanger}}
{{#eAbcde_v2.gesturesInline}}{{{.}}}{{/eAbcde_v2.gesturesInline}}

{{/eAbcde_v2.contentDangerGesturesIsNotEmpty}}
{{#obstetrique_v2.contentIsNotEmpty}}
OBSTÉTRIQUE :
{{#obstetrique_v2.content}}
  {{{.}}}
{{/obstetrique_v2.content}}
{{/obstetrique_v2.contentIsNotEmpty}}{{#dummy_frontIsNotEmpty}}
{{{dummy_front}}}{{/dummy_frontIsNotEmpty}}{{#dummy_backIsNotEmpty}}
{{{dummy_back}}}{{/dummy_backIsNotEmpty}}

PARAMÈTRES VITAUX INITIAUX :{{#initial_vitals_02}}
{{{label}}} {{{value}}}{{/initial_vitals_02}}
`;


export const CLIP_TEXT_TEMPLATE_SAMU_37 = `{{#drmSerial_isNotEmpty}}N° DRM : {{drmSerial}}

{{/drmSerial_isNotEmpty}}
{{#circonstances_anamnese_v2.contentIsNotEmpty}}
ANAMNÈSE / CIRCONSTANCIEL :
  {{#circonstances_anamnese_v2.content}}{{{.}}}{{/circonstances_anamnese_v2.content}}

{{/circonstances_anamnese_v2.contentIsNotEmpty}}
{{#examen_v2.contentIsNotEmpty}}
EXAMEN :
  {{#examen_v2.contentRaw}}{{{value}}}{{/examen_v2.contentRaw}}

{{/examen_v2.contentIsNotEmpty}}
{{#hypotheseDiag_v2.contentIsNotEmpty}}
HYPOTHÈSE DIAGNOSTIQUE :
  {{#hypotheseDiag_v2.contentRaw}}{{{value}}}{{/hypotheseDiag_v2.contentRaw}}

{{/hypotheseDiag_v2.contentIsNotEmpty}}
{{#evolutions_v2.contentIsNotEmpty}}
ÉVOLUTION :
  {{#evolutions_v2.contentRaw}}{{{value}}}{{/evolutions_v2.contentRaw}}

{{/evolutions_v2.contentIsNotEmpty}}
{{#monitoring}}PARAMÈTRES VITAUX :{{#assessments}}
  N° {{index}} , {{timestamp}} ,{{#exos_37}} {{{label}}} {{{value}}} ,{{/exos_37}}{{/assessments}}{{/monitoring}}

{{#medications}}MÉDICAMENTS :{{#assessments}}
  N° {{index}} , {{timestamp}} :{{#medic_37}} {{{label}}}: {{{value}}} ,{{/medic_37}}{{/assessments}}{{/medications}}

`;

export const CLIP_TEXT_TEMPLATE_SAMU_17 = `{{#identite_v2.contentIsNotEmpty}}
IDENTITÉ
{{#identite_v2.content}}
{{{.}}}
{{/identite_v2.content}}
{{/identite_v2.contentIsNotEmpty}}

BILAN CIRCONSTANCIEL
{{{circonstanciel_v2.circumstantial_reason}}}, {{{circonstanciel_v2.circumstantial_comment}}}

{{#anamnese_v2.contentIsNotEmpty}}
ANAMNESE
{{#anamnese_v2.contentRaw}}{{{value}}}{{/anamnese_v2.contentRaw}}
{{/anamnese_v2.contentIsNotEmpty}}
{{#examen_v2.contentIsNotEmpty}}
EXAMEN
{{#examen_v2.contentRaw}}{{{value}}}{{/examen_v2.contentRaw}}
{{/examen_v2.contentIsNotEmpty}}
{{#hypotheseDiag_v2.contentIsNotEmpty}}
HYPOTHESES DIAGNOSTIC
{{#hypotheseDiag_v2.contentRaw}}{{{value}}}{{/hypotheseDiag_v2.contentRaw}}
{{/hypotheseDiag_v2.contentIsNotEmpty}}
BILAN PRIMAIRE
{{#xAbcde_v2.contentDangerGesturesIsNotEmpty}}X :
{{#xAbcde_v2.contentDanger}}
{{{.}}}
{{/xAbcde_v2.contentDanger}}
{{#xAbcde_v2.gesturesInline}}{{{.}}}{{/xAbcde_v2.gesturesInline}}
{{/xAbcde_v2.contentDangerGesturesIsNotEmpty}}
{{#aAbcde_v2.contentDangerGesturesIsNotEmpty}}A :
{{#aAbcde_v2.contentDanger}}
{{{.}}}
{{/aAbcde_v2.contentDanger}}
{{#aAbcde_v2.gesturesInline}}{{{.}}}{{/aAbcde_v2.gesturesInline}}
{{/aAbcde_v2.contentDangerGesturesIsNotEmpty}}
{{#bAbcde_v2.contentDangerGesturesIsNotEmpty}}B :
{{#bAbcde_v2.contentDanger}}
{{{.}}}
{{/bAbcde_v2.contentDanger}}
{{#bAbcde_v2.gesturesInline}}{{{.}}}{{/bAbcde_v2.gesturesInline}}
{{/bAbcde_v2.contentDangerGesturesIsNotEmpty}}
{{#cAbcde_v2.contentDangerGesturesIsNotEmpty}}C :
{{#cAbcde_v2.contentDanger}}
{{{.}}}
{{/cAbcde_v2.contentDanger}}
{{#cAbcde_v2.gesturesInline}}{{{.}}}{{/cAbcde_v2.gesturesInline}}
{{/cAbcde_v2.contentDangerGesturesIsNotEmpty}}
{{#dAbcde_v2.contentDangerGesturesIsNotEmpty}}D :
{{#dAbcde_v2.contentDanger}}
{{{.}}}
{{/dAbcde_v2.contentDanger}}
{{#dAbcde_v2.gesturesInline}}{{{.}}}{{/dAbcde_v2.gesturesInline}}
{{/dAbcde_v2.contentDangerGesturesIsNotEmpty}}
{{#eAbcde_v2.contentDangerGesturesIsNotEmpty}}E :
{{#eAbcde_v2.contentDanger}}
{{{.}}}
{{/eAbcde_v2.contentDanger}}
{{#eAbcde_v2.gesturesInline}}{{{.}}}{{/eAbcde_v2.gesturesInline}}
{{/eAbcde_v2.contentDangerGesturesIsNotEmpty}}
{{#obstetrique_v2.contentIsNotEmpty}}

OBSTÉTRIQUE :
{{#obstetrique_v2.content}}
  {{{.}}}
{{/obstetrique_v2.content}}
{{/obstetrique_v2.contentIsNotEmpty}}{{#dummy_frontIsNotEmpty}}
{{{dummy_front}}}{{/dummy_frontIsNotEmpty}}{{#dummy_backIsNotEmpty}}
{{{dummy_back}}}{{/dummy_backIsNotEmpty}}

PARAMÈTRES VITAUX INITIAUX :{{#initial_vitals}}  {{{label}}}={{{value}}}{{/initial_vitals}}
{{#monitoring}}PARAMÈTRES ÉVOLUTIFS :{{#assessments}}
{{timestamp}}{{#vitals}}  {{{label}}}={{{value}}}{{/vitals}}
{{/assessments}}{{/monitoring}}
{{#sampler_v2.sampler_not_empty}}ATCD :
{{#sampler_v2.sampler_s_optional}}Signes ou symptômes : {{{.}}}{{/sampler_v2.sampler_s_optional}}
{{#sampler_v2.sampler_a_optional}}Allergies : {{{.}}}{{/sampler_v2.sampler_a_optional}}
{{#sampler_v2.sampler_m_optional}}Traitements : {{{.}}}{{/sampler_v2.sampler_m_optional}}
{{#sampler_v2.sampler_p_optional}}Antécédents : {{{.}}}{{/sampler_v2.sampler_p_optional}}{{/sampler_v2.sampler_not_empty}}
{{#gestes_v2.contentAllIsNotEmpty}}Gestes effectuées : {{#gestes_v2.contentAllInline}}{{{.}}}{{/gestes_v2.contentAllInline}}{{/gestes_v2.contentAllIsNotEmpty}}
`;

export const CLIP_TEXT_TEMPLATE_SAMU_53 = `{{#xAbcde_v2.contentDangerGesturesIsNotEmpty}}
VITAL / X :
{{#xAbcde_v2.contentDanger}}
{{{.}}}
{{/xAbcde_v2.contentDanger}}
{{#xAbcde_v2.gesturesInline}}{{{.}}}{{/xAbcde_v2.gesturesInline}}

{{/xAbcde_v2.contentDangerGesturesIsNotEmpty}}
ATCD :
Signes ou symptômes : {{{sampler_v2.sampler_s}}}
Allergies : {{{sampler_v2.sampler_a}}}
Traitements : {{{sampler_v2.sampler_m}}}
Antécédents : {{{sampler_v2.sampler_p}}}

{{#aAbcde_v2.contentDangerGesturesIsNotEmpty}}A :
{{#aAbcde_v2.contentDanger}}
{{{.}}}
{{/aAbcde_v2.contentDanger}}
{{#aAbcde_v2.gesturesInline}}{{{.}}}{{/aAbcde_v2.gesturesInline}}

{{/aAbcde_v2.contentDangerGesturesIsNotEmpty}}
{{#bAbcde_v2.contentDangerGesturesIsNotEmpty}}B :
{{#bAbcde_v2.contentDanger}}
{{{.}}}
{{/bAbcde_v2.contentDanger}}
{{#bAbcde_v2.gesturesInline}}{{{.}}}{{/bAbcde_v2.gesturesInline}}

{{/bAbcde_v2.contentDangerGesturesIsNotEmpty}}
{{#cAbcde_v2.contentDangerGesturesIsNotEmpty}}C :
{{#cAbcde_v2.contentDanger}}
{{{.}}}
{{/cAbcde_v2.contentDanger}}
{{#cAbcde_v2.gesturesInline}}{{{.}}}{{/cAbcde_v2.gesturesInline}}

{{/cAbcde_v2.contentDangerGesturesIsNotEmpty}}
{{#dAbcde_v2.contentDangerGesturesIsNotEmpty}}D :
{{#dAbcde_v2.contentDanger}}
{{{.}}}
{{/dAbcde_v2.contentDanger}}
{{#dAbcde_v2.gesturesInline}}{{{.}}}{{/dAbcde_v2.gesturesInline}}

{{/dAbcde_v2.contentDangerGesturesIsNotEmpty}}
{{#eAbcde_v2.contentDangerGesturesIsNotEmpty}}E :
{{#eAbcde_v2.contentDanger}}
{{{.}}}
{{/eAbcde_v2.contentDanger}}
{{#eAbcde_v2.gesturesInline}}{{{.}}}{{/eAbcde_v2.gesturesInline}}

{{/eAbcde_v2.contentDangerGesturesIsNotEmpty}}
{{#obstetrique_v2.contentIsNotEmpty}}
OBSTÉTRIQUE :
{{#obstetrique_v2.content}}
  {{{.}}}
{{/obstetrique_v2.content}}
{{/obstetrique_v2.contentIsNotEmpty}}{{#dummy_frontIsNotEmpty}}
{{{dummy_front}}}{{/dummy_frontIsNotEmpty}}{{#dummy_backIsNotEmpty}}
{{{dummy_back}}}{{/dummy_backIsNotEmpty}}

PARAMÈTRES VITAUX INITIAUX :{{#initial_vitals_53}}
{{{label}}}={{{value}}}{{/initial_vitals_53}}
`;
export const CLIP_TEXT_TEMPLATE_SAMU_53_NEW = `{{#xAbcde_v2.contentDangerGesturesIsNotEmpty}}
VITAL / X :
{{#xAbcde_v2.contentDanger}}
{{{.}}}
{{/xAbcde_v2.contentDanger}}
{{#xAbcde_v2.gesturesInline}}{{{.}}}{{/xAbcde_v2.gesturesInline}}

{{/xAbcde_v2.contentDangerGesturesIsNotEmpty}}
{{#pqrst_v2.content}}
{{{.}}}
{{/pqrst_v2.content}}
{{#sampler_v2.sampler_s_not_empty}}Signes ou symptômes : {{{sampler_v2.sampler_s}}}{{/sampler_v2.sampler_s_not_empty}}
{{#sampler_v2.sampler_a_not_empty}}Allergies : {{{sampler_v2.sampler_a}}}{{/sampler_v2.sampler_a_not_empty}}
{{#sampler_v2.sampler_m_not_empty}}Traitements : {{{sampler_v2.sampler_m}}}{{/sampler_v2.sampler_m_not_empty}}
{{#sampler_v2.sampler_p_not_empty}}Antécédents : {{{sampler_v2.sampler_p}}}{{/sampler_v2.sampler_p_not_empty}}
{{#circonstanciel_v2.contentIsNotEmpty}}

CIRCONSTANCIEL :
{{#circonstanciel_v2.content}}
  {{{.}}}
{{/circonstanciel_v2.content}}
{{/circonstanciel_v2.contentIsNotEmpty}}
{{#secoursRoutier_v2.contentIsNotEmpty}}

SR :
{{#secoursRoutier_v2.contentDanger}}
  {{{.}}}
{{/secoursRoutier_v2.contentDanger}}
{{/secoursRoutier_v2.contentIsNotEmpty}}

{{#aAbcde_v2.contentDangerGesturesIsNotEmpty}}A :
{{#aAbcde_v2.contentDanger}}
{{{.}}}
{{/aAbcde_v2.contentDanger}}
{{#aAbcde_v2.gesturesInline}}{{{.}}}{{/aAbcde_v2.gesturesInline}}

{{/aAbcde_v2.contentDangerGesturesIsNotEmpty}}
{{#bAbcde_v2.contentDangerGesturesIsNotEmpty}}B :
{{#bAbcde_v2.contentDanger}}
{{{.}}}
{{/bAbcde_v2.contentDanger}}
{{#bAbcde_v2.gesturesInline}}{{{.}}}{{/bAbcde_v2.gesturesInline}}

{{/bAbcde_v2.contentDangerGesturesIsNotEmpty}}
{{#cAbcde_v2.contentDangerGesturesIsNotEmpty}}C :
{{#cAbcde_v2.contentDanger}}
{{{.}}}
{{/cAbcde_v2.contentDanger}}
{{#cAbcde_v2.gesturesInline}}{{{.}}}{{/cAbcde_v2.gesturesInline}}

{{/cAbcde_v2.contentDangerGesturesIsNotEmpty}}
{{#dAbcde_v2.contentDangerGesturesIsNotEmpty}}D :
{{#dAbcde_v2.contentDanger}}
{{{.}}}
{{/dAbcde_v2.contentDanger}}
{{#dAbcde_v2.gesturesInline}}{{{.}}}{{/dAbcde_v2.gesturesInline}}

{{/dAbcde_v2.contentDangerGesturesIsNotEmpty}}
{{#eAbcde_v2.contentDangerGesturesIsNotEmpty}}E :
{{#eAbcde_v2.contentDanger}}
{{{.}}}
{{/eAbcde_v2.contentDanger}}
{{#eAbcde_v2.gesturesInline}}{{{.}}}{{/eAbcde_v2.gesturesInline}}

{{/eAbcde_v2.contentDangerGesturesIsNotEmpty}}
{{#obstetrique_v2.contentIsNotEmpty}}
OBSTÉTRIQUE :
{{#obstetrique_v2.content}}
  {{{.}}}
{{/obstetrique_v2.content}}
{{/obstetrique_v2.contentIsNotEmpty}}{{#dummy_frontIsNotEmpty}}
{{{dummy_front}}}{{/dummy_frontIsNotEmpty}}{{#dummy_backIsNotEmpty}}
{{{dummy_back}}}{{/dummy_backIsNotEmpty}}{{#dummy_head_front_isNotEmpty}}
{{{dummy_head_front}}}{{/dummy_head_front_isNotEmpty}}{{#dummy_hand_right_isNotEmpty}}
{{{dummy_hand_right}}}{{/dummy_hand_right_isNotEmpty}}{{#dummy_hand_left_isNotEmpty}}
{{{dummy_hand_left}}}{{/dummy_hand_left_isNotEmpty}}{{#dummy_foot_right_isNotEmpty}}
{{{dummy_foot_right}}}{{/dummy_foot_right_isNotEmpty}}{{#dummy_foot_left_isNotEmpty}}
{{{dummy_foot_left}}}{{/dummy_foot_left_isNotEmpty}}

{{#initial_vitals_53_isNotEmpty}}
PARAMÈTRES VITAUX INITIAUX :{{#initial_vitals_53}}
{{{label}}}={{{value}}}{{/initial_vitals_53}}
{{/initial_vitals_53_isNotEmpty}}
{{#gestes_v2.contentAllIsNotEmpty}}

GESTES EFFECTUÉS :
{{#gestes_v2.contentAllInline}}
  {{{.}}}
{{/gestes_v2.contentAllInline}}

{{/gestes_v2.contentAllIsNotEmpty}}

{{#medications}}Thérapeutique :{{#assessments}}
  {{timestamp}}{{#medic}}  {{{label}}}:{{{value}}}{{/medic}}
{{/assessments}}

{{/medications}}
`;

export const CLIP_TEXT_TEMPLATE_NFSAVE_DEMO = `Identité : {{identite_v2.fullName}}
Âge : {{identite_v2.age}}
Numéro de dossier : {{drmSerial}}

Numéro de Sécu : {{identite_v2.socialSecurityNumber}}
Caisse Assurance Maladie : {{identite_v2.caisseAssuranceMaladie}}
Mutuelle : {{identite_v2.mutuelle}}

Destination : {{decisionDestination}}
`;

export const CLIP_TEXT_TEMPLATE_NRC_FULL = `{{#circonstanciel_v2.contentIsNotEmpty}}
CIRCONSTANCIEL :
{{#circonstanciel_v2.content}}
  {{{.}}}
{{/circonstanciel_v2.content}}

{{/circonstanciel_v2.contentIsNotEmpty}}
{{#identite_v2.contentIsNotEmpty}}
IDENTITÉ :
{{#identite_v2.content}}
  {{{.}}}
{{/identite_v2.content}}

{{/identite_v2.contentIsNotEmpty}}
{{#contact_v2.contentIsNotEmpty}}
CONTACT :
{{#contact_v2.content}}
  {{{.}}}
{{/contact_v2.content}}

{{/contact_v2.contentIsNotEmpty}}
{{#anamnese_v2.contentIsNotEmpty}}
ANAMNÈSE :
  {{#anamnese_v2.contentRaw}}{{{value}}}{{/anamnese_v2.contentRaw}}

{{/anamnese_v2.contentIsNotEmpty}}
{{#examen_v2.contentIsNotEmpty}}
EXAMEN :
  {{#examen_v2.contentRaw}}{{{value}}}{{/examen_v2.contentRaw}}

{{/examen_v2.contentIsNotEmpty}}
{{#hypotheseDiag_v2.contentIsNotEmpty}}
HYPOTHÈSE DIAGNOSTIQUE :
  {{#hypotheseDiag_v2.contentRaw}}{{{value}}}{{/hypotheseDiag_v2.contentRaw}}

{{/hypotheseDiag_v2.contentIsNotEmpty}}
{{#mhta_v2.contentIsNotEmpty}}
MHTA :
{{#mhta_v2.content}}
  {{{.}}}
{{/mhta_v2.content}}

{{/mhta_v2.contentIsNotEmpty}}
{{#pqrst_v2.contentIsNotEmpty}}
PQRST :
{{#pqrst_v2.content}}
  {{{.}}}
{{/pqrst_v2.content}}

{{/pqrst_v2.contentIsNotEmpty}}
{{#vital_v2.contentIsNotEmpty}}
VITAL :
{{#vital_v2.content}}
  {{{.}}}
{{/vital_v2.content}}

{{/vital_v2.contentIsNotEmpty}}
{{#acr_v2.contentIsNotEmpty}}
ACR :
{{#acr_v2.content}}
  {{{.}}}
{{/acr_v2.content}}

{{/acr_v2.contentIsNotEmpty}}
{{#neurologique_v2.contentDangerIsNotEmpty}}
NEUROLOGIQUE :
{{#neurologique_v2.contentDanger}}
  {{{.}}}
{{/neurologique_v2.contentDanger}}

{{/neurologique_v2.contentDangerIsNotEmpty}}
{{#circulatoire_v2.contentDangerIsNotEmpty}}
CIRCULATOIRE :
{{#circulatoire_v2.contentDanger}}
  {{{.}}}
{{/circulatoire_v2.contentDanger}}

{{/circulatoire_v2.contentDangerIsNotEmpty}}
{{#respiration_v2.contentDangerIsNotEmpty}}
RESPIRATION :
{{#respiration_v2.contentDanger}}
  {{{.}}}
{{/respiration_v2.contentDanger}}

{{/respiration_v2.contentDangerIsNotEmpty}}
{{#obstetrique_v2.contentIsNotEmpty}}
OBSTÉTRIQUE :
{{#obstetrique_v2.content}}
  {{{.}}}
{{/obstetrique_v2.content}}

{{/obstetrique_v2.contentIsNotEmpty}}
{{#secoursRoutier_v2.contentIsNotEmpty}}
SECOURS ROUTIER / ACCIDENTS :
{{#secoursRoutier_v2.content}}
  {{{.}}}
{{/secoursRoutier_v2.content}}

{{/secoursRoutier_v2.contentIsNotEmpty}}
{{#douleurThoracique_v2.contentIsNotEmpty}}
DOULEUR THORACIQUE :
{{#douleurThoracique_v2.content}}
  {{{.}}}
{{/douleurThoracique_v2.content}}

{{/douleurThoracique_v2.contentIsNotEmpty}}
{{#nihss_v2.contentIsNotEmpty}}
NIHSS :
{{#nihss_v2.content}}
  {{{.}}}
{{/nihss_v2.content}}

{{/nihss_v2.contentIsNotEmpty}}
{{#gestes_v2.contentAllIsNotEmpty}}
GESTES EFFECTUÉS :
{{#gestes_v2.contentAll}}
  {{{.}}}
{{/gestes_v2.contentAll}}

{{/gestes_v2.contentAllIsNotEmpty}}

{{#dummy_frontIsNotEmpty}}
{{{dummy_front}}}{{/dummy_frontIsNotEmpty}}{{#dummy_backIsNotEmpty}}
{{{dummy_back}}}{{/dummy_backIsNotEmpty}}

SURVEILLANCE INITIALE :
{{#initial_vitals}}  {{{label}}}={{{value}}}{{/initial_vitals}}

{{#monitoring}}SURVEILLANCES :
{{#assessments}}  {{timestamp}}{{#vitals}}  {{{label}}}={{{value}}}{{/vitals}}
{{/assessments}}{{/monitoring}}
`;

export const CLIP_TEXT_TEMPLATE_ABCDE_FULL = `{{#circonstanciel_v2.contentIsNotEmpty}}
CIRCONSTANCIEL :
{{#circonstanciel_v2.content}}
  {{{.}}}
{{/circonstanciel_v2.content}}

{{/circonstanciel_v2.contentIsNotEmpty}}
{{#identite_v2.contentIsNotEmpty}}
IDENTITÉ :
{{#identite_v2.content}}
  {{{.}}}
{{/identite_v2.content}}

{{/identite_v2.contentIsNotEmpty}}
{{#contact_v2.contentIsNotEmpty}}
CONTACT :
{{#contact_v2.content}}
  {{{.}}}
{{/contact_v2.content}}

{{/contact_v2.contentIsNotEmpty}}
{{#sampler_v2.contentIsNotEmpty}}
SAMPLER :
{{#sampler_v2.content}}
  {{{.}}}
{{/sampler_v2.content}}

{{/sampler_v2.contentIsNotEmpty}}
{{#opqrst_v2.contentIsNotEmpty}}
OPQRST :
{{#opqrst_v2.content}}
  {{{.}}}
{{/opqrst_v2.content}}

{{/opqrst_v2.contentIsNotEmpty}}
{{#anamnese_v2.contentIsNotEmpty}}
ANAMNÈSE :
  {{#anamnese_v2.contentRaw}}{{{value}}}{{/anamnese_v2.contentRaw}}

{{/anamnese_v2.contentIsNotEmpty}}
{{#examen_v2.contentIsNotEmpty}}
EXAMEN :
  {{#examen_v2.contentRaw}}{{{value}}}{{/examen_v2.contentRaw}}

{{/examen_v2.contentIsNotEmpty}}
{{#hypotheseDiag_v2.contentIsNotEmpty}}
HYPOTHÈSE DIAGNOSTIQUE :
  {{#hypotheseDiag_v2.contentRaw}}{{{value}}}{{/hypotheseDiag_v2.contentRaw}}

{{/hypotheseDiag_v2.contentIsNotEmpty}}
{{#acr_v2.contentIsNotEmpty}}
ACR :
{{#acr_v2.content}}
  {{{.}}}
{{/acr_v2.content}}

{{/acr_v2.contentIsNotEmpty}}
{{#obstetrique_v2.contentIsNotEmpty}}
OBSTÉTRIQUE :
{{#obstetrique_v2.content}}
  {{{.}}}
{{/obstetrique_v2.content}}

{{/obstetrique_v2.contentIsNotEmpty}}
{{#secoursRoutier_v2.contentIsNotEmpty}}
SECOURS ROUTIER / ACCIDENTS :
{{#secoursRoutier_v2.content}}
  {{{.}}}
{{/secoursRoutier_v2.content}}

{{/secoursRoutier_v2.contentIsNotEmpty}}
{{#douleurThoracique_v2.contentIsNotEmpty}}
DOULEUR THORACIQUE :
{{#douleurThoracique_v2.content}}
  {{{.}}}
{{/douleurThoracique_v2.content}}

{{/douleurThoracique_v2.contentIsNotEmpty}}
{{#rcp_v2.contentIsNotEmpty}}
RCP :
{{#rcp_v2.content}}
  {{{.}}}
{{/rcp_v2.content}}

{{/rcp_v2.contentIsNotEmpty}}
{{#xAbcde_v2.contentDangerGesturesIsNotEmpty}}
X :
{{#xAbcde_v2.contentDanger}}
  {{{.}}}
{{/xAbcde_v2.contentDanger}}
{{#xAbcde_v2.gestures}}
  {{{.}}}
{{/xAbcde_v2.gestures}}

{{/xAbcde_v2.contentDangerGesturesIsNotEmpty}}
{{#aAbcde_v2.contentDangerGesturesIsNotEmpty}}
A :
{{#aAbcde_v2.contentDanger}}
  {{{.}}}
{{/aAbcde_v2.contentDanger}}
{{#aAbcde_v2.gestures}}
  {{{.}}}
{{/aAbcde_v2.gestures}}

{{/aAbcde_v2.contentDangerGesturesIsNotEmpty}}
{{#bAbcde_v2.contentDangerGesturesIsNotEmpty}}
B :
{{#bAbcde_v2.contentDanger}}
  {{{.}}}
{{/bAbcde_v2.contentDanger}}
{{#bAbcde_v2.gestures}}
  {{{.}}}
{{/bAbcde_v2.gestures}}

{{/bAbcde_v2.contentDangerGesturesIsNotEmpty}}
{{#cAbcde_v2.contentDangerGesturesIsNotEmpty}}
C :
{{#cAbcde_v2.contentDanger}}
  {{{.}}}
{{/cAbcde_v2.contentDanger}}
{{#cAbcde_v2.gestures}}
  {{{.}}}
{{/cAbcde_v2.gestures}}

{{/cAbcde_v2.contentDangerGesturesIsNotEmpty}}
{{#dAbcde_v2.contentDangerGesturesIsNotEmpty}}
D :
{{#dAbcde_v2.contentDanger}}
  {{{.}}}
{{/dAbcde_v2.contentDanger}}
{{#dAbcde_v2.gestures}}
  {{{.}}}
{{/dAbcde_v2.gestures}}

{{/dAbcde_v2.contentDangerGesturesIsNotEmpty}}
{{#eAbcde_v2.contentDangerGesturesIsNotEmpty}}
E :
{{#eAbcde_v2.contentDanger}}
  {{{.}}}
{{/eAbcde_v2.contentDanger}}
{{#eAbcde_v2.gestures}}
  {{{.}}}
{{/eAbcde_v2.gestures}}

{{/eAbcde_v2.contentDangerGesturesIsNotEmpty}}
{{#gestes_v2.contentAllIsNotEmpty}}
GESTES EFFECTUÉS :
{{#gestes_v2.contentAll}}
  {{{.}}}
{{/gestes_v2.contentAll}}

{{/gestes_v2.contentAllIsNotEmpty}}
{{#dummy_frontIsNotEmpty}}
{{{dummy_front}}}{{/dummy_frontIsNotEmpty}}{{#dummy_backIsNotEmpty}}
{{{dummy_back}}}{{/dummy_backIsNotEmpty}}

SURVEILLANCE INITIALE :
{{#initial_vitals}}  {{{label}}}={{{value}}}{{/initial_vitals}}

{{#monitoring}}SURVEILLANCES :
{{#assessments}}  {{timestamp}}{{#vitals}}  {{{label}}}={{{value}}}{{/vitals}}
{{/assessments}}{{/monitoring}}
`;

export const CLIP_Y_GREEN = [
  {
    valuePath: "neurologique.repond_ordre",
    label: "Réponds aux ordres"
  },
  {
    valuePath: "neurologique.orientation_spatiale",
    label: "Orientation spaciale"
  },
  {
    valuePath: "neurologique.orientation_temporelle",
    label: "Orientation temporelle"
  },
  {
    valuePath: "neurologique.motricite_extremites",
    label: "Motricité aux extremités"
  },
  {
    valuePath: "respiration.ample",
    label: "Respiration ample"
  },
  {
    valuePath: "respiration.reguliere",
    label: "Respiration régulière"
  },
  {
    valuePath: "circulatoire.frappe",
    label: "Bien frappé"
  },
  {
    valuePath: "circulatoire.regulier",
    label: "Régulier"
  },
  {
    valuePath: "circulatoire.symetrique",
    label: "Pouls symétrique"
  }
];

export const CLIP_Y_RED = [
  {
    valuePath: "respiration.obva",
    label: "Obstruction des VA"
  },
  {
    valuePath: "circulatoire.hemorragie",
    label: "Hémorragie"
  },
  {
    valuePath: "neurologique.inconscience",
    label: "Inconscience"
  },
  {
    valuePath: "respiration.arret_respiratoire",
    label: "Arrêt ventilatoire"
  },
  {
    valuePath: "circulatoire.arret_circulatoire",
    label: "Arrêt cardiaque"
  },
  {
    valuePath: "obstetrique.premiere_grossesse",
    label: "1ère grossesse"
  },
  {
    valuePath: "obstetrique.perte_eaux",
    label: "Perte des eaux"
  },
  {
    valuePath: "obstetrique.envie_pousser",
    label: "Envie de pousser"
  },
  {
    valuePath: "neurologique.pci",
    label: "PCI"
  },
  {
    valuePath: "neurologique.trouble_parole",
    label: "Trouble de la parole"
  },
  {
    valuePath: "neurologique.paralysie_faciale",
    label: "Paralysie faciale"
  },
  {
    valuePath: "neurologique.convulsions",
    label: "Convulsions"
  },
  {
    valuePath: "neurologique.agitation",
    label: "Agitation"
  },
  {
    valuePath: "neurologique.pupilles_anormales",
    label: "Pupilles anormales"
  },
  {
    valuePath: "respiration.pauses",
    label: "Pauses respiratoires"
  },
  {
    valuePath: "respiration.superficielle",
    label: "Respiration superficielle"
  },
  {
    valuePath: "respiration.bruyante",
    label: "Respiration bruyante"
  },
  {
    valuePath: "respiration.difficile",
    label: "Respiration difficile"
  },
  {
    valuePath: "respiration.cyanose",
    label: "Cyanose"
  },
  {
    valuePath: "respiration.sueurs",
    label: "Sueurs"
  },
  {
    valuePath: "circulatoire.trc",
    label: "TRC > 3s"
  },
  {
    valuePath: "circulatoire.marbrures",
    label: "Marbrures"
  },
  {
    valuePath: "medic_info.dyspnee",
    label: "Dyspnée"
  },
  {
    valuePath: "medic_info.tirage",
    label: "Tirage"
  },
  {
    valuePath: "medic_info.encombrement",
    label: "Encombrement"
  },
  {
    valuePath: "medic_info.douleur_poitrine",
    label: "Douleur thoracique"
  }
];

export const CLIP_INFOS_BILAN = [
  {
    label: "Circonstance",
    valuePath: "circonstance.commentaire"
  },
  {
    label: "Anamnèse",
    valuePath: "geste.autre"
  },
  {
    label: "Examen médical",
    valuePath: "geste.prescription"
  },
  {
    label: "Examen",
    valuePath: "geste.examen",
  },
  {
    label: "Hypothèses diagnostic",
    valuePath: "geste.conclusion"
  },
  {
    label: "Plainte(s)",
    valuePath: "victime.plainte",
    unit: "\n"
  },
  {
    label: "Provoqué par",
    valuePath: "circonstance.provoque_par"
  },
  {
    label: "Qualité",
    valuePath: "circonstance.qualite"
  },
  {
    label: "Région",
    valuePath: "circonstance.region"
  },
  {
    label: "Sévérité",
    valuePath: "circonstance.severite",
    unit: "/10"
  },
  {
    label: "Temps depuis incident",
    valuePath: "circonstance.temps"
  },
  {
    label: "PCI",
    valuePath: "neurologique.pci",
    unit: " secondes"
  },
  {
    label: "o2aa",
    valuePath: "respiration.o2aa",
    unit: " %"
  },
  {
    label: "o2o2",
    valuePath: "respiration.o2o2",
    unit: " %"
  },
  {
    label: "SpCO",
    valuePath: "respiration.spco",
    unit: " %"
  },
  {
    label: "fv",
    valuePath: "respiration.fv",
    unit: " mvt/min"
  },
  {
    label: "fc",
    valuePath: "circulatoire.fc",
    unit: " bpm"
  },
  {
    label: "temperature",
    valuePath: "neurologique.temperature",
    unit: " °"
  },
  {
    label: "hemoglobine",
    valuePath: "medic_info.hemoglobine",
    unit: " g/dL"
  },
  glycemieInitial({label: "glycemie"}),
  {
    label: "tension droite",
    valuePath: "circulatoire.tension_droite"
  },
  {
    label: "tension gauche",
    valuePath: "circulatoire.tension_gauche"
  },
  {
    label: "glasgow_yeux",
    valuePath: (bilan) => {
      let ya = parseInt(bilan.medic_info.gla_yeux, 10) || 0;
      if (ya === 0 || ya === -1) {
        return "--";
      };
      return ya;
    }
  },
  {
    label: "glasgow_parole",
    valuePath: (bilan) => {
      let vb = parseInt(bilan.medic_info.gla_parole, 10) || 0;
      if (vb === 0 || vb === -1) {
        return "--";
      };
      return vb;
    }
  },
  {
    label: "glasgow_motricite",
    valuePath: (bilan) => {
      let mc = parseInt(bilan.medic_info.gla_motricite, 10) || 0;
      if (mc === 0 || mc === -1) {
        return "--";
      };
      return mc;
    }
  }
];

export const CLIP_INITIAL_VITALS_49 = [
  {
    label: "FR",
    valuePath: "respiration.fv"
  },
  {
    label: "SpO2",
    valuePath: "respiration.o2aa"
  },
  {
    label: "SpO2/O2",
    valuePath: "respiration.o2o2"
  },
  {
    label: "FC",
    valuePath: "circulatoire.fc"
  },
  {
    label: "PABD",
    valuePath: "circulatoire.tension_droite"
  },
  {
    label: "PABG",
    valuePath: "circulatoire.tension_gauche"
  },
  glasgowInitial({
    label: "GLASGOW"
  }),
];

export const CLIP_INITIAL_EN_TEMP_49 = [
  {
    label: "Douleur",
    valuePath: "circonstance.severite",
    unit: "/10",
  },
  {
    label: "T°",
    valuePath: "neurologique.temperature",
    unit: "",
  },
  glycemieInitial({
    label: "Gly"
  }),
];

export const CLIP_INITIAL_VITALS = [
  {
    label: "Température",
    valuePath: "neurologique.temperature",
    unit: "°C"
  },
  {
    label: "Hémoglobine",
    valuePath: "medic_info.hemoglobine",
    unit: " g/DL"
  },
  inconscienceInitial({}),
  {
    label: "FR",
    valuePath: "respiration.fv"
  },
  {
    label: "SpO2(ambiant)",
    valuePath: "respiration.o2aa"
  },
  {
    label: "SpO2(sous O2)",
    valuePath: "respiration.o2o2"
  },
  {
    label: "FC",
    valuePath: "circulatoire.fc"
  },
  {
    label: "PABD",
    valuePath: "circulatoire.tension_droite"
  },
  {
    label: "PABG",
    valuePath: "circulatoire.tension_gauche"
  },
  {
    label: "PAM",
    valuePath: "circulatoire.pam"
  },
  pamInitial({}),
  glycemieInitial({}),
  {
    label: "Douleur",
    valuePath: "circonstance.severite",
    unit: "/10"
  },
  pupillesInitial({}),
  glasgowInitial({}),
  {
    label: "EtCO2",
    valuePath: "respiration.etco2"
  }
];

export const CLIP_INITIAL_VITALS_FV_EVA = [
  {
    label: "FV",
    valuePath: "respiration.fv",
    unit: "cpm",
    optionnal: false,
  },
  {
    label: "FC",
    valuePath: "circulatoire.fc",
    unit: "bpm",
    optionnal: false,
  },
  {
    label: "SpO2(ambiant)",
    valuePath: "respiration.o2aa",
    unit: "%",
    optionnal: false,
  },
  {
    label: "SpO2(sous O2)",
    valuePath: "respiration.o2o2",
    unit: "%",
    optionnal: false,
  },
  {
    label: "TA INDACTEURS",
    valuePath: (bilan) => {
      const pad = bilan.circulatoire.tension_droite;
      const pabd = pad === "" ? `--(D)`: `${pad}${syoUtils.computePam(pad) && `(${syoUtils.computePam(pad)})`}D`;
      const pag = bilan.circulatoire.tension_gauche;
      const pabg = pag === "" ? `--(G)`: `${pag}${syoUtils.computePam(pag) && `(${syoUtils.computePam(pag)})`}G`;
      return `${pabd} ${pabg}`;
    },
    optionnal: false,
  },
  inconscienceInitial({optionnal : false}),
  {
    label: "EVA",
    valuePath: "circonstance.severite",
    unit: "/10",
    optionnal: false,
  },
  {
    label: "Température",
    valuePath: "neurologique.temperature",
    unit: "°C",
    optionnal: true,
  },
  {
    label: "Hémoglobine",
    valuePath: "medic_info.hemoglobine",
    unit: "g/DL",
    optionnal: true,
  },
  glycemieInitial({}),
  pupillesInitial({}),
  glasgowInitial({}),
  {
    label: "EtCO2",
    valuePath: "respiration.etco2",
    unit: "mmHg",
    optionnal: true,
  }
];

export const CLIP_INITIAL_VITALS_53 = [
  inconscienceInitial({}),
  glasgowInitial({}),
  pupillesInitial({}),
  {
    label: "PABD",
    valuePath: "circulatoire.tension_droite"
  },
  {
    label: "PABG",
    valuePath: "circulatoire.tension_gauche"
  },
  {
    label: "FC",
    valuePath: "circulatoire.fc"
  },
  {
    label: "FR",
    valuePath: "respiration.fv"
  },
  {
    label: "SpO2(ambiant)",
    valuePath: "respiration.o2aa"
  },
  {
    label: "SpO2(sous O2)",
    valuePath: "respiration.o2o2"
  },
  {
    label: "Température",
    valuePath: "neurologique.temperature",
    unit: "°C"
  },
  glycemieInitial({}),
  {
    label: "EN",
    valuePath: "circonstance.severite",
    unit: "/10"
  },
];

export const CLIP_INITIAL_VITALS_56 = [
  {
    label: "Conscience",
    "@type": "text",
    valuePath: (bilan) => {
      if (bilan.neurologique.inconscience === "y") return "Non";
      if (bilan.neurologique.inconscience === "n") return "Oui";
      if (bilan.neurologique.inconscience === "u") return null;
    },
    optionnal: true
  },
  {
    label: "FR",
    valuePath: "respiration.fv"
  },
  {
    label: "SpO2 AA",
    valuePath: "respiration.o2aa"
  },
  {
    label: "SpO2 sous O2",
    valuePath: (bilan) => {
      if (bilan.respiration.o2o2 === -1) return null;
      const debit = bilan.geste.inha <= 0 ? "" : ` / ${bilan.geste.inha}L/min`;
      return `${bilan.respiration.o2o2}${debit}`
    }
  },
  {
    label: "FC",
    valuePath: "circulatoire.fc"
  },
  {
    label: "PAD",
    valuePath: "circulatoire.tension_droite"
  },
  {
    label: "PAG",
    valuePath: "circulatoire.tension_gauche"
  },
  pamInitial({}),
  glycemieInitial({
    label: "Hgt"
  }),
  {
    label: "T°",
    valuePath: "neurologique.temperature"
  },
];

export const CLIP_INITIAL_VITALS_02 = [
  glasgowInitial({label: "G"}),
  {
    label: "TA",
    valuePath: (bilan) => {
      return bilan.circulatoire.tension_droite || bilan.circulatoire.tension_gauche;
    }
  },
  {
    label: "FC",
    valuePath: "circulatoire.fc"
  },
  {
    label: "FR",
    valuePath: "respiration.fv"
  },
  {
    label: "SAT(ambiant)",
    valuePath: "respiration.o2aa"
  },
  {
    label: "SpO2(sous O2)",
    valuePath: "respiration.o2o2"
  },
  {
    label: "T",
    valuePath: "neurologique.temperature"
  },
  glycemieInitial({label: "Gly"}),
  {
    label: "DLR",
    valuePath: "circonstance.severite",
    unit: "/10"
  },
];

// **********************************************************************************
// **********************************************************************************
// ***** MÉDICAMENTS *****
// **********************************************************************************
// **********************************************************************************
export const CLIP_MEDICATIONS = [
  {
    label: "Nom",
    valuePath: "name",
  },
  medicDosageUnit({}),
  medicRoute({}),
  {
    label: "Ampoulier",
    valuePath: "ampoule_holder_identification",
  },
  {
    label: "Protocole",
    valuePath: "nursing_protocol_name",
  },
  {
    label: "Commentaire",
    valuePath: "comment",
  },
];

export const CLIP_MEDICATIONS_37 = [
  {
    label: "Médicament",
    valuePath: "name",
  },
  medicRoute({label: "voie d'administration"}),
  {
    label: "Dosage",
    valuePath: "quantity",
  },
  medicUnit({}),
  {
    label: "Commentaire",
    valuePath: "comment",
  },
];

// **********************************************************************************
// **********************************************************************************
// ***** SURVEILLANCES *****
// **********************************************************************************
// **********************************************************************************
export const CLIP_SURVEILLANCE_VITALS = [
  {
    label: "Inconscience",
    valuePath: "inconscience",
    kind: "negative"
  },
  {
    label: "hemoglobine",
    valuePath: "hemoglobine",
    unit: " g/dL"
  },
  {
    label: "FR",
    valuePath: "fr"
  },
  {
    label: "SpO2(ambiant)",
    valuePath: "o2aa"
  },
  {
    label: "SpO2(sous O2)",
    valuePath: "o2o2"
  },
  {
    label: "SpCO",
    valuePath: "spco",
    unit: " %"
  },
  {
    label: "FC",
    valuePath: "fc"
  },
  {
    label: "PABD",
    valuePath: "pabd"
  },
  {
    label: "PABG",
    valuePath: "pabg"
  },
  pamSurveillance({}),
  {
    label: "Shock",
    valuePath: "shockIndex",
  },
  {
    label: "Glycémie",
    valuePath: "glycemie",
    unitClip: (surveillance) => {
      switch (surveillance.glycemie_unite) {
        case 1:
          return "mmol/L";
        case 2:
          return "mg/dL";
        default:
          return "g/L";
      }
    }
  },
  trcSurveillance({}),
  paleursSurveillance({}),
  {
    label: "Hbco",
    valuePath: "hbco",
    unit: "%",
  },
  {
    label: "Douleur",
    valuePath: "eva",
    unit: "/10",
  },
  {
    label: "Temp",
    valuePath: "temperature",
    unit: "°C",
  },
  {
    label: "État de conscience",
    valuePath: "score_sedation"
  },
  {
    label: "Pupilles",
    valuePath: (surveillance) => {
      let labels = [
        [0, "NR"],
        [1, "Anisocorie"],
        [2, "Réactives"],
        [3, "Aréactives"],
        [4, "Mydriase bilatérale"],
        [5, "Pas de RPM"],
        [6, "Myosis"],
        [7, "Symétriques"],
      ];
      let value = surveillance.pupilles;
      return labels
        .filter(([bit]) => (((value | 0) >> bit) & 1) === 1)
        .map(([, label]) => label)
        .join(",");
    }
  },
  glasgowSurveillance({}),
  {
    label: "Commentaire",
    valuePath: "comment",
  },
  ...apgarSurv,
];
export const CLIP_SURVEILLANCE_VITALS_WITH_MEDIC = [
  ...CLIP_SURVEILLANCE_VITALS,
  ...CLIP_MEDICATIONS,
];

export const CLIP_SURVEILLANCE_VITALS_FV_EVA = [
  {
    label: "Inconscience",
    valuePath: "inconscience",
    kind: "negative"
  },
  {
    label: "hemoglobine",
    valuePath: "hemoglobine",
    unit: " g/dL"
  },
  {
    label: "FV",
    valuePath: "fr"
  },
  {
    label: "SpO2(ambiant)",
    valuePath: "o2aa"
  },
  {
    label: "SpO2(sous O2)",
    valuePath: "o2o2"
  },
  {
    label: "SpCO",
    valuePath: "spco",
    unit: " %"
  },
  {
    label: "FC",
    valuePath: "fc"
  },
  {
    label: "PABD",
    valuePath: "pabd"
  },
  {
    label: "PABG",
    valuePath: "pabg"
  },
  {
    label: "Shock",
    valuePath: "shockIndex",
  },
  {
    label: "Glycémie",
    valuePath: "glycemie",
    unitClip: (surveillance) => {
      switch (surveillance.glycemie_unite) {
        case 1:
          return "mmol/L";
        case 2:
          return "mg/dL";
        default:
          return "g/L";
      }
    }
  },
  {
    label: "EVA",
    valuePath: "eva"
  },
  {
    label: "État de conscience",
    valuePath: "score_sedation"
  },
  {
    label: "Pupilles",
    valuePath: (surveillance) => {
      let labels = [
        [0, "NR"],
        [1, "Anisocorie"],
        [2, "Réactives"],
        [3, "Aréactives"],
        [4, "Mydriase bilatérale"],
        [5, "Pas de RPM"],
        [6, "Myosis"],
        [7, "Symétriques"],
      ];
      let value = surveillance.pupilles;
      return labels
        .filter(([bit]) => (((value | 0) >> bit) & 1) === 1)
        .map(([, label]) => label)
        .join(",");
    }
  },
  glasgowSurveillance({}),
  trcSurveillance({}),
  paleursSurveillance({}),
  {
    label: "Hbco",
    valuePath: "hbco",
    unit: "%",
  },
  {
    label: "Commentaire",
    valuePath: "comment",
  },
  ...apgarSurv,
];
export const CLIP_SURVEILLANCE_VITALS_FV_EVA_WITH_MEDIC = [
  ...CLIP_SURVEILLANCE_VITALS_FV_EVA,
  ...CLIP_MEDICATIONS,
];

export const CLIP_SURVEILLANCE_VITALS_53 = [
  {
    label: "Inconscience",
    valuePath: "inconscience",
    kind: "negative"
  },
  glasgowSurveillance({}),
  {
    label: "Pupilles",
    valuePath: (surveillance) => {
      let labels = [
        [0, "NR"],
        [1, "Anisocorie"],
        [2, "Réactives"],
        [3, "Aréactives"],
        [4, "Mydriase bilatérale"],
        [5, "Pas de RPM"],
        [6, "Myosis"],
        [7, "Symétriques"],
      ];
      let value = surveillance.pupilles;
      return labels
        .filter(([bit]) => (((value | 0) >> bit) & 1) === 1)
        .map(([, label]) => label)
        .join(",");
    }
  },
  {
    label: "PABD",
    valuePath: "pabd"
  },
  {
    label: "PABG",
    valuePath: "pabg"
  },
  {
    label: "hemoglobine",
    valuePath: "hemoglobine",
    unit: " g/dL"
  },
  {
    label: "FC",
    valuePath: "fc"
  },
  {
    label: "Shock",
    valuePath: "shockIndex",
  },
  {
    label: "FR",
    valuePath: "fr"
  },
  {
    label: "SpO2(ambiant)",
    valuePath: "o2aa"
  },
  {
    label: "SpO2(sous O2)",
    valuePath: "o2o2"
  },
  {
    label: "SpCO",
    valuePath: "spco",
    unit: " %"
  },
  {
    label: "Glycémie",
    valuePath: "glycemie",
    unitClip: (surveillance) => {
      switch (surveillance.glycemie_unite) {
        case 1:
          return "mmol/L";
        case 2:
          return "mg/dL";
        default:
          return "g/L";
      }
    }
  },
  trcSurveillance({}),
  paleursSurveillance({}),
  {
    label: "Hbco",
    valuePath: "hbco",
    unit: "%",
  },
  {
    label: "EN",
    valuePath: "eva"
  },
  {
    label: "Commentaire",
    valuePath: "comment",
  },
  ...apgarSurv,
];
export const CLIP_SURVEILLANCE_VITALS_53_WITH_MEDIC = [
  ...CLIP_SURVEILLANCE_VITALS_53,
  ...CLIP_MEDICATIONS,
];

export const CLIP_SURVEILLANCE_VITALS_56 = [
  {
    label: "État de conscience",
    valuePath: (surv) => {
      return syoData.SCORE_SEDATION_OPTS.find(opt => opt.value === surv.score_sedation)?.label || null
    },
  },
  {
    label: "FR",
    valuePath: "fr"
  },
  {
    label: "SpO2 AA",
    valuePath: "o2aa"
  },
  {
    label: "SpO2 sous O2",
    valuePath: "o2o2"
  },
  {
    label: "FC",
    valuePath: "fc"
  },
  {
    label: "PAD",
    valuePath: "pabd"
  },
  {
    label: "PAG",
    valuePath: "pabg"
  },
  pamSurveillance({}),
  {
    label: "Shock",
    valuePath: "shockIndex",
  },
  {
    label: "Hgt",
    valuePath: "glycemie",
    unitClip: (surveillance) => {
      switch (surveillance.glycemie_unite) {
        case 1:
          return "mmol/L";
        case 2:
          return "mg/dL";
        default:
          return "g/L";
      }
    }
  },
  trcSurveillance({}),
  paleursSurveillance({}),
  {
    label: "Hbco",
    valuePath: "hbco",
    unit: "%",
  },
  {
    label: "T°",
    valuePath: "temperature"
  },
];
export const CLIP_SURVEILLANCE_VITALS_56_WITH_MEDIC = [
  ...CLIP_SURVEILLANCE_VITALS_56,
  ...CLIP_MEDICATIONS,
];

// Ne pas modifier les labels, conçu pour être compatible avec les services Exos
export const CLIP_SURVEILLANCE_VITALS_EXOS_37 = [
  {
    label: "FR",
    valuePath: "fr",
  },
  {
    label: "SAT",
    valuePath: "o2o2"
  },
  {
    label: "FC",
    valuePath: "fc",
  },
  pamSurveillance({
    label: "TA",
  }),
  {
    label: "Gly",
    valuePath: "glycemie",
    unitClip: (surveillance) => {
      switch (surveillance.glycemie_unite) {
        case 1:
          return "mmol/L";
        case 2:
          return "mg/dL";
        default:
          return "g/L";
      }
    }
  },
  {
    label: "T",
    valuePath: "temperature",
  },
  {
    label: "DL",
    valuePath: "eva",
  },
  {
    label: "ETCO2",
    valuePath: "",
  },
  {
    label: "Shock",
    valuePath: "shockIndex",
  },
  trcSurveillance({}),
  paleursSurveillance({}),
  {
    label: "Hbco",
    valuePath: "hbco",
    unit: "%",
  },
];

export const CLIP_TEXT_TEMPLATE_ABANDONED_DAY = `
{{date}}

{{#abandoned_by_ch}}
- {{display_name_ch}}
  {{#abandoned_data}}
     - {{display_name_ch_service}}
         - {{display_name_vehicle}}
          {{#abandoned_items}}
            {{{.}}}
          {{/abandoned_items}}
  {{/abandoned_data}}
{{/abandoned_by_ch}}
`;
