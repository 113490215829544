import styled, {createGlobalStyle} from "styled-components";
import theming from "styled-theming";

const colors = {
  grey1: theming("mode", {
    light: "#333333",
    dark: "#BDD3FF",
  }),
  grey2: theming("mode", {
    light: "#444444",
    dark: "#FFFFFF",
  }),
  grey3: theming("mode", {
    light: "#8F8F8F",
    dark: "#A3A3A3",
  }),
  grey4: theming("mode", {
    light: "#BDBDBD",
    dark: "#D6D6D6",
  }),
  grey5: theming("mode", {
    light: "#DDDDDD",
    dark: "#F5F5F5"
  }),
  grey6: theming("mode", {
    light: "#F2F2F2",
    dark: "#4e5055",
  }),
  grey7: theming("mode", {
    light: "#D7D7D7",
    dark: "#606266",
  }),
  darkBlue: theming("mode", {
    light: "#042040",
    dark: "#191D24",
  }),
  darkGradientBlue: theming("mode", {
    light: "#002466",
    dark: "#2A313C",
  }),
  blue: theming("mode",{
    light: "#4786FF",
    dark: "#BDD3FF",
  }),
  blueTransparent: theming("mode",{
    light: "#4786FF00",
    dark: "#BDD3FF00",
  }),
  lightBlue: "#6B9EFF",
  blueBilan: theming("mode", {
    light: "#DCE8FF",
    dark: "#313235",
  }),
  green: theming("mode", {
    light: "#27AE60",
    dark: "#39B76E", // 96DFB4
  }),
  red: theming("mode", {
    light: "#EB5757",
    dark: "#FF6969", //FFC2C2
  }),
  orange: theming("mode", {
    light: "#FFB01F",
    dark: "#FFBF49",
  }),
  yellow: "#F2C94C",
  white: "#FFFFFF",
  black: "#000000",
  backgroundColor: theming("mode", {
    light: "#FFFFFF",
    dark: "#3b3d40",
  }),
  banner: theming("mode", {
    light: "#FFC75E",
    dark: "#E27450",
  }),
  subMenu: theming("mode", {
    light: "#DCE8FF",
    dark: "#343434",
  })
};

const spacing = {
  large: "2.5rem",
  medium: "2rem",
  small: "1rem",
  thin: "0.5rem",
};

const theme = {
  ...colors,
  ...spacing,
  menuWidth: "4rem",
  // Font
  title: colors.grey1,
  titleSecondary: colors.blue,
  text: colors.grey2,
  textSecondary: colors.grey3,
  // Effect
  boxShadowLarge: "0px 8px 16px 5px rgba(0,0,0,0.2)",
  boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
  borderRadius: "0.25rem",
};

export default theme;

export const ParagraphSecondary = styled.p`
  color: ${theme.textSecondary};
  font-size: 0.75rem;
`;

export const GlobalStyle = createGlobalStyle`
  * {
    transition: background-color 300ms ease-in-out, border 300ms ease-in-out;
  }

  body {
    background-color: ${theme.backgroundColor};
    color: ${theme.text};
  }
  h1, h2, h3, h4 {
    color: ${theme.grey1};
  }
  h5, h6 {
    color: ${theme.text};
  }
  h5 {
    font-size: 1rem;
  }
  h6 {
    font-size: 0.9rem;
  }
  textarea, select, input {
    background-color: ${theme.backgroundColor};
    color: ${theme.grey2};
  }

  input, select {
    max-height: 38px;
  }

  a {
    color: ${theme.blue};

    &:hover {
      color: ${theme.text};
    }
  }

  ul, ol {
    margin-bottom: 0;
  }

  .infinite-scroll-component {
    overflow: visible !important;
  }

  .recharts-tooltip-label {
    color: ${theme.text}
  }
  .recharts-default-tooltip {
    background-color: ${theme.backgroundColor} !important;
  }
`;

// Style for react-select dark/light mode
export const getSelectTheme = (theme) => {
  return ({
    primary: theme === "dark" ? "#BDD3FF" : "#4786FF", // blue
    primary25: theme === "dark" ? "#313235" : "#DCE8FF", // blueBilan
    neutral0: theme === "dark" ? "#3b3d40" : "#FFFFFF", // backgroundColor
    neutral10: theme === "dark" ? "#313235" : "#DDDDDD", // grey5
    neutral15: theme === "dark" ? "#F5F5F5" : "#DDDDDD", // grey5
    neutral20: theme === "dark" ? "#F5F5F5" : "#DDDDDD", // grey5
    neutral30: theme === "dark" ? "#D6D6D6" : "#BDBDBD", // grey4
    neutral40: theme === "dark" ? "#D6D6D6" : "#BDBDBD", // grey4
    neutral50: theme === "dark" ? "#A3A3A3" : "#8F8F8F", // grey3
    neutral60: theme === "dark" ? "#A3A3A3" : "#8F8F8F", // grey3
    neutral70: theme === "dark" ? "#FFFFFF" : "#444444", // grey2
    neutral80: theme === "dark" ? "#FFFFFF" : "#444444", // grey2
    neutral90: theme === "dark" ? "#FFFFFF" : "#444444", // grey2
  });
};