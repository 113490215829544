import { Route } from "found";
import React from "react";

import SignIn from "./screens/SignIn";
import Parsys from "./screens/Parsys";
import PasswordChallenge from "./screens/PasswordChallenge";
import PasswordReset from "./screens/PasswordReset";
import Sdis35Aad from "./screens/Sdis35Aad";
import NfsaveAadTest from "./screens/NfsaveAadTest";
import AmaDemo from "./screens/AmaDemo";
import PhoneChallenge from "./screens/PhoneChallenge";
import PhoneReset from "./screens/PhoneReset";
import Sdis38Aad from "./screens/Sdis38Aad";
import Sdis49Adfs from "./screens/Sdis49Adfs";
import Sdis64Adfs from "./screens/Sdis64Adfs";
import Samu79Aad from "./screens/Smur79FaAad";
import Sdis71Aad from "./screens/Sdis71Aad";
import Sdis73Aad from "./screens/Sdis73Aad";
import ResetEmpty from "./screens/ResetEmpty";
import Sdis37Oidc from "./screens/Sdis37Oidc";
import Sdis47Aad from "./screens/Sdis47Aad";
import Samu37Aad from "./screens/Samu37Aad";

export const routes = (
  <Route path="auth">
    <Route path="sign-in" Component={SignIn} />
    <Route path="parsys" Component={Parsys} />
    <Route path="sso-sdis35-aad" Component={Sdis35Aad} />
    <Route path="sso-samu37-aad" Component={Samu37Aad} />
    <Route path="sso-sdis38-aad" Component={Sdis38Aad} />
    <Route path="sso-sdis47-aad" Component={Sdis47Aad} />
    <Route path="sso-sdis49-adfs" Component={Sdis49Adfs} />
    <Route path="sso-sdis64-adfs" Component={Sdis64Adfs} />
    <Route path="sso-smur79fa-aad" Component={Samu79Aad} />
    <Route path="sso-nfsave-aad-test" Component={NfsaveAadTest} />
    <Route path="sso-ama-demo" Component={AmaDemo} />
    <Route path="sso-sdis71-aad" Component={Sdis71Aad} />
    <Route path="sso-sdis73-aad" Component={Sdis73Aad} />
    <Route path="sso-sdis37-oidc" Component={Sdis37Oidc} />
    <Route path="forgot-password" Component={PasswordChallenge} data={{
      subTitle: "Mot de passe oublié ?",
      tabRow: true,
    }} />
    <Route path="password-reset/:token" Component={PasswordReset} />
    <Route path="password-reset" Component={ResetEmpty} />
    <Route path="password-reset-resend" Component={PasswordChallenge} data={{
      subTitle: "Vous permettra de recevoir un nouveau lien sur votre boîte mail.",
      tabRow: false,
    }} />
    <Route path="forgot-phone" Component={PhoneChallenge} data={{
      subTitle: "Mot de passe oublié ?",
      tabRow: true,
    }} />
    <Route path="phone-reset/:token" Component={PhoneReset} />
    <Route path="phone-reset" Component={ResetEmpty} />
    <Route path="phone-reset-resend" Component={PhoneChallenge} data={{
      subTitle: "Vous permettra de recevoir un nouveau code.",
      tabRow: false,
    }} />
  </Route>
);
