import { RedirectException } from "found";
import ky from "ky";

export const API_ORIGIN =
  process.env.NODE_ENV === "production"
    ? "https://app.syope.fr"
    : "http://localhost:3000";

const AUTH_TOKEN = {
  accessToken: null,
  expiry: null,
  uid: null,
  client: null,
  tokenType: null
};

function checkStorage () {
  if (localStorage.getItem("nfAuth") !== null && AUTH_TOKEN.accessToken === null) {
    const access = JSON.parse(localStorage.getItem("nfAuth"));
    AUTH_TOKEN.accessToken = access.accessToken;
    AUTH_TOKEN.expiry = access.expiry;
    AUTH_TOKEN.uid = access.uid;
    AUTH_TOKEN.client = access.client;
    AUTH_TOKEN.tokenType = access.tokenType;
    setAuthToken(access);
  };
};

export function signOut() {
  AUTH_TOKEN.accessToken = null;
  AUTH_TOKEN.expiry = null;
  AUTH_TOKEN.uid = null;
  AUTH_TOKEN.client = null;
  AUTH_TOKEN.tokenType = null;
  window.parent.postMessage({ kind: "logout" }, "*");
}

function tokenManagementHook(response) {
  if (response.status === 401) {
    signOut();
    if (!response.url.includes("/auth/sign_in")) {
      throw new RedirectException({
        pathname: "/auth/sign-in"
      });
    } else {
      return response;
    };
  };
  const accessToken = response.headers.get("access-token");
  const expiry =
    response.headers.get("expiry") &&
    parseInt(response.headers.get("expiry"), 10);
  const uid = response.headers.get("uid");
  const client = response.headers.get("client");
  const tokenType = response.headers.get("token-type");
  if (accessToken != null) {
    AUTH_TOKEN.accessToken = accessToken;
    AUTH_TOKEN.expiry = expiry;
    AUTH_TOKEN.uid = uid;
    AUTH_TOKEN.client = client;
    AUTH_TOKEN.tokenType = tokenType;
    const nfAuth = {
      accessToken: accessToken,
      expiry: expiry,
      uid: uid,
      client: client,
      tokenType: tokenType
    };
    localStorage.setItem("nfAuth", JSON.stringify(nfAuth));
  }
  return response;
}

function credentialsHook(opts) {
  checkStorage();
  if (AUTH_TOKEN.accessToken != null) {
    opts.headers.set("access-token", AUTH_TOKEN.accessToken);
    opts.headers.set("expiry", AUTH_TOKEN.expiry);
    opts.headers.set("uid", AUTH_TOKEN.uid);
    opts.headers.set("client", AUTH_TOKEN.client);
    opts.headers.set("token-type", AUTH_TOKEN.tokenType);
  }
}

export default ky.extend({
  /*
  prefixUrl:
    process.env.NODE_ENV === "production"
      ? "https://app.nfsave.fr/api"
      : "/api",
   */
  prefixUrl: "/api",
  hooks: {
    beforeRequest: [credentialsHook],
    afterResponse: [tokenManagementHook]
  },
  timeout: 60000,
});

export function setAuthToken({ accessToken, expiry, uid, client, tokenType }) {
  AUTH_TOKEN.accessToken = accessToken;
  AUTH_TOKEN.expiry = expiry;
  AUTH_TOKEN.uid = uid;
  AUTH_TOKEN.client = client;
  AUTH_TOKEN.tokenType = tokenType;
}

export { HTTPError, TimeoutError } from "ky";
