import React, { useContext } from "react";
import { hasPerm } from "../../utils";
import CurrentUserContext from "../../CurrentUserContext";

import styled from "styled-components";
import theme from "../../ui/theme";
import PageHeader, { Cntnr, Title } from "../../ui/PageHeader";

const ReleaseItem = styled.li`
  margin-bottom: ${theme.thin};
`;

const ReleaseTitle = styled.strong`
display: block;`;

function ReleaseNote() {
  const {currentUser} = useContext(CurrentUserContext);

  return (
    <>
      <PageHeader>
        <Title>Note de version</Title>
      </PageHeader>

      <Cntnr>
        <h2>Avril 2023</h2>
        <ul>
          <ReleaseItem>
            <ReleaseTitle>Modification du bilan</ReleaseTitle>
            Affichage du mannequin lésionnel de manière dynamique.
          </ReleaseItem>
        </ul>
        <h2>Octobre 2022</h2>
        <ul>
          <ReleaseItem>
            <ReleaseTitle>Réglages utilisateurs</ReleaseTitle>
            Refonte de la modification de changement de mot de passe.
          </ReleaseItem>
        </ul>
        <h2>Juillet 2022</h2>
        <ul>
          <ReleaseItem>
            <ReleaseTitle>Modification du bilan</ReleaseTitle>
            Affichage du tableau des surveillances et injections de manière dynamique.
          </ReleaseItem>
          <ReleaseItem>
            <ReleaseTitle>Listing des utilisateurs</ReleaseTitle>
            Possibilité de filtrer la liste des utilisateurs par leur méthode de connexion.
          </ReleaseItem>
        </ul>
        <h2>Juin 2022</h2>
        <ul>
          <ReleaseItem>
            <ReleaseTitle>Listing des utilisateurs</ReleaseTitle>
            Affichage du nombre de connexion échoué d'un utilisateur.
          </ReleaseItem>
        </ul>
        <h2>Avril 2022</h2>
        <ul>
          <ReleaseItem>
            <ReleaseTitle>Modification du bilan</ReleaseTitle>
            <ul>
              {hasPerm(currentUser, "web:bilan:display_in_original_config") && (
                <li>Visualisation du bilan dans la configuration d'origine</li>
              )}
              <li>Affichage de l'horodatage des pièces jointes</li>
              <li>Affichage de l'incrémentation des exports dans l'historique</li>
            </ul>
          </ReleaseItem>
        </ul>
        <h2>Mars 2022</h2>
        <ul>
          {hasPerm(currentUser, "web:users:export_csv") && (
            <ReleaseItem>
              <ReleaseTitle>Listing des utilisateurs</ReleaseTitle>
              Possibilité d'exporter la liste des utilisateurs au format CSV.
            </ReleaseItem>
          )}
          <ReleaseItem>
            <ReleaseTitle>Export PDF du bilan</ReleaseTitle>
            Génération dynamique du contenu de l'export PDF du bilan.
          </ReleaseItem>
        </ul>
        <h2>Février 2022</h2>
        <ul>
          <ReleaseItem>
            <ReleaseTitle>Listing des utilisateurs</ReleaseTitle>
            Affichage de la dernière connexion d'un utilisateur.
            Possibilité de trier les utilisateurs par la date de leur dernière connexion.
            Ajout d'un filtrage supplémentaire permettant d'afficher les utilisateurs actuellement
            en zone de quarantaine à cause d'un trop grand nombre de tentatives de connexion erronées.
          </ReleaseItem>
          <ReleaseItem>
            <ReleaseTitle>Listing des interventions archivées</ReleaseTitle>
            Possibilité de filtrer les interventions par&thinsp;:
            <ul>
              <li>Numéro primo-intervenant</li>
              <li>Numéro de dossier DRM</li>
              <li>Véhicule</li>
              <li>Auteur</li>
            </ul>
          </ReleaseItem>
        </ul>
        <h2>Janvier 2022</h2>
        <ul>
          <ReleaseItem>
            <ReleaseTitle>L'application NFSave devient Syopé.</ReleaseTitle>
          </ReleaseItem>
        </ul>
        <h2>Décembre 2021</h2>
        <ul>
          <ReleaseItem>
            <ReleaseTitle>Modification du bilan</ReleaseTitle>
            <ul>
              {hasPerm(currentUser, "web:bilan:surveillances:graph") && (
                <li>Possibilité de visualiser les surveillances sous forme de tableau ou sous forme de graphique</li>
              )}
              <li>Ajout de sections au mannequin: colonne vertébrale en 4 sections</li>
              <li>Ajout de l'APGAR dans le tableau des surveillances</li>
            </ul>
          </ReleaseItem>
          {(hasPerm(currentUser, "manage:vehicles") || hasPerm(currentUser, "manage:relevant-facts")) && (
            <ReleaseItem>
              <ReleaseTitle>Gestion d'organisation</ReleaseTitle>
              Depuis la page "Gestion d'organisation", est maintenant accessible via le menu secondaire de cette page:
              <ul>
                {hasPerm(currentUser, "manage:vehicles") && (
                <li>L'onglet "Engins": permettant la gestion des engins. Création et suppression des engins</li>
                )}
                {hasPerm(currentUser, "manage:relevant-facts") && (
                <li>L'onglet "Faits remarquables": permettant l'affichage des faits remarquables de l'organisation</li>
                )}
              </ul>
            </ReleaseItem>
          )}
          <ReleaseItem>
            <ReleaseTitle>Listing des rôles</ReleaseTitle>
            <ul>
              <li>Affichage du nombre d'utilisateurs assignés au rôle.</li>
              <li>Affichage du nombre de permissions expérimentales assignées au rôle.</li>
              <li>Duplication d'un rôle.</li>
              <li>Archivage d'un rôle.</li>
            </ul>
          </ReleaseItem>
          <ReleaseItem>
            <ReleaseTitle>Listing des utilisateurs</ReleaseTitle>
            Ajout d'un filtrage supplémentaire permettant d'afficher les utilisateurs ne s'étant jamais connectés.
          </ReleaseItem>
          <ReleaseItem>
            <ReleaseTitle>Gestion d'un utilisateur</ReleaseTitle>
            Affichage et modification du numéro de téléphone.
            Uniquement pour les utilisateurs n'utilisant pas de méthode de connexion par AD.
          </ReleaseItem>
          <ReleaseItem>
            <ReleaseTitle>Correction de bogues et optimisation de la plateforme</ReleaseTitle>
          </ReleaseItem>
        </ul>
        <h2>Novembre 2021</h2>
        <ul>
          <ReleaseItem>
            <ReleaseTitle>Optimisation de la navigation mobile</ReleaseTitle>
            Le menu principal s'affichera en version mobile pour tout support inférieur à 800 pixels.
            En version mobile, un bouton apparait en bas à gauche de l'écran permettant
            l'ouverture du menu hamburger.
            En version bureau, le menu principal est affiché et directement accessible
            sur le côté gauche de l'écran.
          </ReleaseItem>
          <ReleaseItem>
            <ReleaseTitle>Optimisation de la navigation générale</ReleaseTitle>
            Ajout d'un bouton en bas à droite permettant un retour rapide en haut de page.<br />
            Les menus secondaires (présent sur l'écran de gestion d'organisation et
            sur l'écran utilisateur) sont rabattables par le biais d'un bouton.<br />
            Ajout de barres de recherche sur l'écran de gestion d'organisation.
          </ReleaseItem>
          <ReleaseItem>
            <ReleaseTitle>Modification du bilan</ReleaseTitle>
            <ul>
              <li>Possibilité de visualiser les bilans en vue SCCARR (adaptatif ou statique) depuis le sélecteur de vue d'un bilan</li>
              <li>Dans le tableau des surveillances, le peak flow et le score de sédation seront affichés uniquement si renseignés dans le bilan</li>
              <li>Affichage et rotation des pièces jointes depuis un diaporama sur la vue d'un bilan accessible depuis le menu latéral "Pièces jointes"</li>
              <li>Affichage du poids et de la taille dans la section "identité"</li>
            </ul>
          </ReleaseItem>
          <ReleaseItem>
            <ReleaseTitle>Listing des utilisateurs</ReleaseTitle>
            Si l'utilisateur utilise une méthode de connexion par AD, l'identifiant affiché est "AD".
          </ReleaseItem>
          {hasPerm(currentUser, "web:interventions:visualIndicator") && (
            <ReleaseItem>
              <ReleaseTitle>Listing des interventions</ReleaseTitle>
              Ajout d'un indicateur visuel permettant de savoir si un bilan a été vu depuis ça dernière mise à jour.
              Code couleur:
              <ul>
                <li>Bleu: bilan non vu depuis sa dernière mise à jour</li>
                <li>Gris: bilan vu depuis sa dernière mise à jour</li>
              </ul>
            </ReleaseItem>
          )}
          {hasPerm(currentUser, "web:settings:self:editEstablishmentCards") && (
            <ReleaseItem>
              <ReleaseTitle>Gestion des cartes d'établissement.</ReleaseTitle>
              Possibilité de gérer ses propres cartes d'établissement depuis la page des paramètres utilisateur.
              Paramètres utilisateur (bouton rond de couleur en bas du menu principal) -&gt; Cartes d'établissement (menu secondaire)
            </ReleaseItem>
          )}
        </ul>
        <h2>Septembre 2021</h2>
        <ul>
          {hasPerm(currentUser, "web:interventions:integrer-par-code") && (
            <ReleaseItem>
              <ReleaseTitle>Possibilité d'intégrer un bilan grâce à un code de partage.</ReleaseTitle>
              Sur la page des intervenions actives, un bouton portant la mention "Intégration par code"
              permet de renseigner un code de partage. Une fois validé, le bilan sera accessible dès
              le prochain rafraîchissement de la liste des interventions actives.
            </ReleaseItem>
          )}
          {hasPerm(currentUser, "web:intervention:priorite") && (
            <ReleaseItem>
              <ReleaseTitle>Affichage de la priorité du bilan.</ReleaseTitle>
              Affiche un bandeau de couleur permettant de visualiser le niveau de priorité du bilan.
              Ce bandeau est visible sur les pages: interventions actives et interventions archivées et
              sur la page de visualisation d'un bilan.
              (Rouge: bilan rouge, jaune-orangé: bilan jaune, invisible: bilan blanc)
            </ReleaseItem>
          )}
          {hasPerm(currentUser, "web:intervention:organisation") && (
            <ReleaseItem>
              <ReleaseTitle>Listing et ajout d'une organisation à un bilan.</ReleaseTitle>
              Sur la page de visualisation d'un bilan, ajoute un bouton portant la mention "Organisations"
              au menu latéral droit. Permettant de:
              <ul>
                <li>Lister les différentes organisations ayant accès au bilan</li>
                <li>Donner un droit d'accès au bilan à tous les membres d'une organisation</li>
              </ul>
            </ReleaseItem>
          )}
          <ReleaseItem>
            <ReleaseTitle>Affichage d'une version plus précise du mannequin.</ReleaseTitle>
            Afin de conserver une rétrocompatibilité avec d'anciens bilans,
            le mannequin affiché sera celui utilisé lors de la création du bilan.
          </ReleaseItem>
          <ReleaseItem>
            <ReleaseTitle>Ajout d'un mode sombre et d'un mode clair.</ReleaseTitle>
            Le thème est choisi automatiquement par rapport aux préférences du navigateur
            mais il est possible d'ajuster le thème à sa convenance.
          </ReleaseItem>
          <ReleaseItem>
            <ReleaseTitle>Ajout d'un menu secondaire permettant&thinsp;:</ReleaseTitle>
            <ul>
              <li>La modification du thème&thinsp;: mode sombre ou mode clair</li>
              <li>L'affichage d'un écran d'assistance</li>
              <li>L'affichage d'un écran permettant d'évaluer l'application</li>
            </ul>
          </ReleaseItem>
        </ul>
      </Cntnr>
    </>
  );
};

export default ReleaseNote;
