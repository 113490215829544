import React, { useState } from "react";
import { Link } from "found";
import styled from "styled-components";
import theme from "../../ui/theme";

import BaseNarrowContainer from "../../ui/NarrowContainer";
import Input from "../../ui/Input";
import { BlockButton } from "../../ui/Button";
import { Column, Container, Row } from "../../ui/FlexGrid";
import http from "../../http";
import logo from "../../ui/logo.png";
import { DangerAlert } from "../../ui/Alert";
import { triggerDownload } from "../../utils";

const Logo = styled.img`
  display: block;
  max-width: 384px;
  margin: 0 auto ${theme.small};
  padding: ${theme.large} 0;
`;

const NarrowContainer = styled(BaseNarrowContainer)`
  margin: 10% auto 0;
`;

export default function Share({ match }) {
  const [shareCode, setShareCode] = useState(match.location.query.code || "");
  const [error, setError] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);

  const handleShareCodeChange = ev => {
    setShareCode(ev.target.value);
  };

  const handleFormSubmit = async ev => {
    ev.preventDefault();
    setSubmitting(true)
    try {
      setError(null);
      const response = await http.get(
        `share/${encodeURIComponent(shareCode.toUpperCase())}`,
        {
          headers: {
            Accept: "application/pdf"
          }
        }
      );
      const contentDisposition = response.headers.get("content-disposition");
      const filename = contentDisposition.match(
        /^attachment;\s+filename="(.+\.pdf)"$/i
      )[1];
      const payload = await response.arrayBuffer();
      const blob = new Blob([payload], {
        type: "application/pdf"
      });
      await triggerDownload(filename, blob);
      setShareCode("");
    } catch (err) {
      console.error(err);
      setError(
        "Impossible de télécharger le fichier PDF associé au code de partage."
      );
    } finally {
      setSubmitting(false)
    }
  };

  return (
    <NarrowContainer>
      <Container>
        <Row>
          <Column>
            <Link to="/auth/sign-in">
              <Logo src={logo} alt="Logo Syopé" />
            </Link>
            <h1>Accès par code à un bilan</h1>
          </Column>
        </Row>
        <form onSubmit={handleFormSubmit}>
          {error && <DangerAlert>{error}</DangerAlert>}
          <Row>
            <Column width={3}>
              <Input
                placeholder="Code à 10 caractères généré par le CA"
                required
                value={shareCode}
                onChange={handleShareCodeChange}
                disabled={isSubmitting}
              />
            </Column>
            <Column>
              <BlockButton disabled={isSubmitting}>Télécharger</BlockButton>
            </Column>
          </Row>
        </form>
      </Container>
    </NarrowContainer>
  );
}
